import React, { useState, useCallback } from 'react';
import { Toast, Button } from 'react-bootstrap';

const ToastContext = React.createContext(null);
let id=0;

const ToastProvider = ({ children }) => {

    //expects toasts like this { title: '', message: '' }

    const [toasts, setToasts] = useState([]);

    const addToast = useCallback(content => {
        setToasts(toasts => [
          ...toasts,
          { id: id++, title: content.title, body: content.body, onClick:content.onClick, buttonText:content.buttonText }
        ]);
    }, [setToasts]);

    const removeToast = useCallback(id => {
        setToasts(toasts => toasts.filter(t => t.id !== id));
      }, [setToasts]);

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            <div className="toast-container">
                {/* may have to put custom coding to create portal here for toastcontainer to always be in one place */}
                { toasts.map( (thisToast) => {
                    return (
                    <Toast id={thisToast.id} onClose={() => {removeToast(thisToast.id)}}key={thisToast.id} >
                        <Toast.Header closeButton={true} closeLabel={false}>
                        <strong className="me-auto">Heads up: {thisToast.title} </strong>
                        </Toast.Header>
                        <Toast.Body>
                            { thisToast.body }
                        </Toast.Body>
                        {
                            thisToast.onClick && thisToast.buttonText?
                            <Button onClick={thisToast.onClick}>{thisToast.buttonText}</Button>
                            :null
                        }
                    </Toast>
                    )
                })}
                
        </div>
        {children}
        </ToastContext.Provider>
    )
}

const useToast = () => {
    const toastHelpers = React.useContext(ToastContext);
    return toastHelpers;
}

export { ToastContext, useToast };
export default ToastProvider;