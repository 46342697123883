import React from 'react';
import { Table, Button } from 'react-bootstrap'
import '../../../styles/layout/_layout.scss';
import EnumSelector from '../EnumSelector';
import NumericInput from '../NumericInput';

function ProductEditorCharacteristics({product, setProduct, apiKey, disableProductEdits}) {



  function addCharacteristic(){
    const characteristics = product.characteristics
    characteristics.push(
      {
        container_type: '',
        container_quantity: 0,
        cold_chain_capacity: null,
        shelf_life_mo: null,
        ov_shelf_life_hr: null,
        pq_date:null,
        licensure_date: null,
        deleted:false
      }
    )
    setProduct({...product, characteristics:characteristics})
  }

  function setRowContainerType(e, i){
    const characteristics = product.characteristics
    characteristics[i].container_type = e.label
    setProduct({
      ...product,
      characteristics: characteristics
    })
  }

  function setCharacteristic(field, value, i){
    if ((field === 'pq_date' || field === 'licensure_date') && value === ''){
      value = null;
    }
    const chars = [...product.characteristics]
    chars[i][field] = value
    setProduct({...product, characteristics: chars})
  }


  
  function deleteCharacteristic(index){
    const characteristics = [...product.characteristics]
    const prodChar = characteristics[index]
    if (!prodChar.product_characteristic_id){
      characteristics.splice(index, 1)
    } else{
      prodChar.deleted = true
    }
    setProduct({...product, characteristics: characteristics})
  }

  function undeleteCharacteristic(index){
    const characteristics = [...product.characteristics]
    const prodChar = characteristics[index]
    prodChar.deleted = false
    setProduct({...product, characteristics: characteristics})
  }


  return  (
    <div className=''>
      <Table size = "md">
        <thead className = "table-heading">
          <tr>
            <th>Container Type</th>
            <th>Container Qty</th>
            <th>Temperature</th>
            <th>VVM</th>
            <th>Cold Chain Capacity</th>
            <th>Shelf Life (mo)</th>
            <th>OV Shelf Life (hr)</th>
            <th>PQ Date</th>
            <th>Licensure Date</th>
            {disableProductEdits?
              null:
              <th className="clickable-text"
                onClick={addCharacteristic}>
                  add
              </th>
            }
            
          </tr>
        </thead>
        <tbody className ="table-body">
          {product.characteristics.map((c, i) => {
            return <tr key={i} >
              <td>
                <EnumSelector
                  apiKey={apiKey}
                  className='select-md'
                  enum_type = "container_type"
                  disabled={c.deleted||disableProductEdits}
                  value = {product.characteristics[i].container_type}
                  onChange = {(e) => setRowContainerType(e, i)} 
                />
              </td>
              <td>
                <NumericInput
                  className='form-ctrl'
                  value={c.container_quantity}
                  onChange={(value) => setCharacteristic("container_quantity", value, i)}
                  disabled={c.deleted || disableProductEdits}
                  allowDecimals={false}
                  allowCommas={false}
                />
              </td>
              <td>
              <input 
                  className='form-ctrl'
                  type='text'
                  disabled={c.deleted||disableProductEdits}
                  value = { c.temperature }
                  onChange={(e)=> setCharacteristic("temperature", 
                    e.target.value, i)}
                />
              </td>
              <td>
                <input 
                  className='form-ctrl'
                  type='text'
                  disabled={c.deleted||disableProductEdits}
                  value = { c.vvm }
                  onChange={(e)=> setCharacteristic("vvm", 
                    e.target.value, i)}
                />
              </td>
              <td>
                <input
                  className='form-ctrl' 
                  type='text'
                  disabled={c.deleted||disableProductEdits}
                  value = { c.cold_chain_capacity }
                  onChange={(e)=> setCharacteristic("cold_chain_capacity", 
                    e.target.value, i)}
                />
              </td>
              <td>
                <NumericInput
                  className='form-ctrl'
                  value={c.shelf_life_mo}
                  onChange={(value) => setCharacteristic("shelf_life_mo", value, i)}
                  disabled={c.deleted || disableProductEdits}
                  allowDecimals={false} 
                  allowCommas={false}   
                />
              </td>
              <td>
                <NumericInput
                  className='form-ctrl'
                  value={c.ov_shelf_life_hr}
                  onChange={(value) => setCharacteristic("ov_shelf_life_hr", value, i)}
                  disabled={c.deleted || disableProductEdits}
                  allowDecimals={true} 
                  allowCommas={false}  
                />
              </td>
              <td>
                <input
                  className='form-ctrl'
                  type='date'
                  disabled={c.deleted||disableProductEdits}
                  value = { c.pq_date }
                  onChange={(e)=> setCharacteristic("pq_date", 
                    e.target.value, i)}
                />
              </td>
              <td>
                <input
                  className='form-ctrl'
                  type='date'
                  disabled={c.deleted||disableProductEdits}
                  value = { c.licensure_date }
                  onChange={(e)=> setCharacteristic("licensure_date", 
                    e.target.value, i)}
                />
              </td>
              {
                disableProductEdits?null:
                <td>
                {
                  c.deleted?
                  <Button
                    variant='success'
                    size="sm"
                    className="mb-3" 
                    onClick = {() => undeleteCharacteristic(i)}>+</Button>:
                  <Button
                    variant='danger'
                    size="sm"
                    className="mb-3" 
                    onClick = {() => deleteCharacteristic(i)}>-</Button>
                }
                </td>
              }
            </tr>})
          }
        </tbody>
      </Table>
    </div>
  );
} 
export default ProductEditorCharacteristics;

