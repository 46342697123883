import React, { useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import utils from '../../utils';

function TriggerToolTip(props) {

  const target = useRef(null);

  return (
      <OverlayTrigger
        placement='top'
        target={target.current}
        overlay={
          <Tooltip
            placement='top'
            className="in" 
            id="tooltip-top"
            >
              <div dangerouslySetInnerHTML={props.tooltiptext ? utils.getHTML(props.tooltiptext) : ''}></div>
          </Tooltip>
        }
      >
        <span {...props}>
          { props.children }
        </span>
      </OverlayTrigger>
  )
}

export default TriggerToolTip