import React, { useState, useEffect, useRef } from 'react';
import {Button, Spinner} from 'react-bootstrap';
import { BlobServiceClient } from "@azure/storage-blob";

function BlobUploader({ fileTypes, serviceName, sizeLimit, folderName, fileNameGuideFn=null, buttonText='Add File', onFileAdded, sasToken }) {

    //this uploads image to specified storage, and returns a list of all files added
    const [ imgPicked, setImgPicked ] = useState();
    const [ uploading, setUploading ] = useState(false);
    const [ errMessage, setErrMessage ] = useState();

    const inputEl = useRef(null);

    const SERVICE_URL = `https://${serviceName}.blob.core.windows.net/`;

    //if fileNameGuideFn not specified, use file name when storing file
    if(fileNameGuideFn === null) {
        fileNameGuideFn = (fileName) => {
            return fileName;
        };
    }

    const resetFileInput = () => {
        if (inputEl.current) {
            inputEl.current.value = "";
            inputEl.current.type = "text";
            inputEl.current.type = "file";
        }
    }

    const createBlobInContainer = async (containerClient, file) => {

        let newFileName = fileNameGuideFn(file.name);

        // create blobClient for container
        const blobClient = containerClient.getBlockBlobClient(newFileName);
      
        // set mimetype as determined from browser with file upload control
        const options = { blobHTTPHeaders: { blobContentType: file.type } };
      
        // upload file
        try{
            await blobClient.uploadBrowserData(file, options);
            return { result: "success", message: `${SERVICE_URL}${folderName}/${newFileName}`};
        } catch (error) {
            return { result: "failure", message: error };
        }
        
    };
    

    const uploadBlob = async (file) => {
        const blobService = new BlobServiceClient(`${SERVICE_URL}?${sasToken}`);
          
        // get Container (folder)
        const containerClient = blobService.getContainerClient(folderName);

        let result = await createBlobInContainer(containerClient, file);

        onFileAdded(result);

        setImgPicked(null);
        resetFileInput();
    }

    

    useEffect( () => {
        //execute when imgPicked changed
        if(imgPicked) {
            setUploading(true);
            uploadBlob(imgPicked);
            setUploading(false);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ imgPicked ]);

    const selectImage = (evt) => {
        //if file meets sizelimit req, then setImgPicked

        for (const file of evt.target.files) {
            if(file.size <= sizeLimit) {
                setImgPicked(file);
                setErrMessage('');
            } else {
                setErrMessage('File too big - must be under ' + sizeLimit + ' bytes');
                resetFileInput();
            }
        }
    }
    
    return (
        <div className='file_uploader'>
            {
                uploading ?
                    <span className='loading-message'><Spinner animation="border" /> Uploading Image</span>
                :

                <>
                    <div className='error'>{ errMessage }</div>
                    <Button className='btn-sm-solid m-b-start-02 tertiary-button' onClick={() => {inputEl.current.click()}}>{buttonText}</Button>
                    <div>{imgPicked ? imgPicked.name : ''}</div>
                    <input 
                        disabled={uploading === true} 
                        type="file" 
                        accept={fileTypes} 
                        name="file-uploader"
                        style={{display: 'none'}} 
                        ref={inputEl} 
                        onChange={(e) => selectImage(e)} />
                </>
                
            }
            
        </div>
    )
}

export default BlobUploader