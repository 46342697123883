import React from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import SortFilterPageTable from '../SortFilterPageTable'

function WorkspaceRequestList({ wrkspAccessList, loading, accessRequestHandler }) {

  const ulConfig = {

    columns: [
        // {
        //   key: 'appCheck',
        //   type: 'COMPLEX',
        //   render: (row) => {
        //     return (
        //       <Row >
        //           <Col xs={2}>
        //               <input 
        //                 type="checkbox" 
        //                 // onChange='TODO' //{(e) => { addAppPermissions(e.target.name)}} 
        //                 // defaultChecked='TODO' //{appIsOn(thisApp.fields.apptype, userChecks)} 
        //                 onChange={(e) => { requestWorkspaceFromList(e.target.value, row)}} 
        //                 checked={row.id === checked} 
        //               />
        //           </Col>
        //       </Row>
        //     )
        //   }
        // },
        {
          key: 'appName',
          type: 'SIMPLE',
          title: 'Workspace Name',
          accessor: 'name',
          defaultSort: 'desc'
        },
        {
            key: 'actions',
            title: 'Actions',
            type: 'COMPLEX',
            render: (row) => { 
                return (
                    <span>
                        <Button 
                            onClick={() => { accessRequestHandler(row)}} 
                            variant="light" 
                            size="sm">Request Access
                        </Button>
                    </span>
                ) 
            }
        }
    ],
    filters: []
};

  return (

    <Container>
        <Row className='inner-section'>
          <Col>
            <h6>Manage Your Workspaces <span className="help-text">request access to other workspaces from your organization</span></h6>
            <span class="help-text">* Note: Select a workspace and apply changes. We'll send notifications to the appropriate workspace administrators.</span>
          </Col>
          {
            !loading ?
              wrkspAccessList && wrkspAccessList.length > 0 ?
              <SortFilterPageTable 
                config={ulConfig} 
                data={wrkspAccessList} 
              />
              : <div className='text-center no-items'>There's no more to add, you have access to all available workspaces</div>
            : <Spinner animation='border' />
          }
        </Row>
      </Container>
  )
}

export default WorkspaceRequestList