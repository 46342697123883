import AIContent from "./AIContent";

function Messages({turns}){

  return(
    <>
        {Array.isArray(turns) && turns.length > 0 ? (
            <div className="chat-history">
              {turns.map((t, i) => (
                <div
                  key={i}
                  className={`chat-message ${t.role === 'user' ? 'user-message' : 'assistant-message'}`}>
                  <AIContent turn={t} index={i} />
                </div>
              ))}
            </div>
          ):null}
    </>
  )
}

export default Messages;