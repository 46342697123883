import React from 'react';
import StoryTeaserLine from './StoryTeaserLine';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';

function NewsTeasers({ stories, showNumber, showImage = false, loadingTracker, className, urlprefix, showTags, entity, entityType, preview=false}) {

    const convertToStoryTeaser = (storyToConvert) => {
        //figure out reading time based on average reading speed of 200 words per minute.
        let timeInMinutes = 0;
        const numwords = storyToConvert.fields.body ? storyToConvert.fields.body.split(/\s/) : storyToConvert.body.body.split(/\s/);
        
        if(numwords > 0){
            timeInMinutes = Math.round(numwords / 200) + " minute(s)";
        } else {
            timeInMinutes = "less than a minute"
        }

        storyToConvert.readTime = timeInMinutes;

        return storyToConvert;
    }

    let myStories = stories.map( (thisStory) => {
            let theStory = thisStory.item ?  thisStory.item : thisStory;
            return convertToStoryTeaser(theStory);
        });
    return (
        <div className={className ? className : ''}>
            { 
                loadingTracker?
                    <ListSkeletonLoader preview={true} />
                    :
                    myStories ?
                        myStories.map( (thisStory, index) => {
                            let theStory = thisStory.item ?  thisStory.item : thisStory;
                            return index <= (showNumber-1) ? <StoryTeaserLine story={theStory} urlprefix={ urlprefix } showTags={showTags} entity={entity} entityType={entityType} />: null
                        })
                    :
                    <p>No stories found</p>
            }
        </div>
    )
}

export default NewsTeasers
