import React, {useState} from 'react';
import { Row, Col, } from 'react-bootstrap';
import DemandHealthToolbar from './DemandHealthToolbar';
import PMTTable from '../PMT/Table/PMTTable';
import pmtutils from '../PMT/pmtutils';

function DemandHealthDisplay({ topList, appName, configType, handleListUpdate, apiKey, header=null, subheader=null}) {
  const [ searchTerm, setSearchTerm ] = useState('');

  return (
    <div>
      <p className='body-large'>{header}</p>
      <p className='body-small'>{subheader}</p>
      <p className='body-small-condensed p-b-start-06 m-b-end'>Objectives as compiled from Gavi Roadmap targets and partner input, last updated in Q1 2024. Updating objectives will be a priority item in 2025.</p>

      <DemandHealthToolbar 
        searchTermHandler={(e) => setSearchTerm(e)}
        appTab='objectives'
      />
      {
        <Row>
          <Col className={`view-display`}>
            {
              topList && topList.length > 0 ?
                <PMTTable 
                  tableHeaderConfig={pmtutils.getTableconfig('OBJECTIVES', appName, topList, handleListUpdate, apiKey)}
                  formattedList={pmtutils.filterToSearchTerm(topList, searchTerm)}
                  appName={appName.fields.apptype}
                  handleListUpdate={handleListUpdate}
                  apiKey={apiKey}
                  configType={configType}
                />
                : ''
            }
          </Col>
        </Row>
      }
    </div>
  )
}

export default DemandHealthDisplay