import React from 'react';
import { Row, Col, Button, OverlayTrigger, Popover, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { PersonFill } from 'react-bootstrap-icons';
import utils from '../../../utils';

function TenantUserBlock({ currentTenant, raiseSupport, windowSize, tenantName=null, resetToken }) {

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  //show user icon with signed in user name next to it if 
  //signed in, otherwise, make a menu that they can sign in 
  //with

  const loginRequest = {
    scopes: ["User.Read"]
  };

  const showLoginPopup = () => {
    try {
      instance.loginPopup(loginRequest).catch(error => {
        console.log(error);
      });
    } catch (error) {
      console.log(error);
    }

  };

  const showLogoutPopup = () => {
    try {
      instance.logoutPopup().catch(error => {
        console.log(error);
      });

      //clear login stuff
      utils.removeForceTenantValue();
      //forcest the userToken to reset to null 
      //needed for Workspaces with the Alchemy App
      resetToken();
    } catch (error) {
      console.log(error);
    }
  };

  return (

    <>
      <div className="user-block">
        {
          isAuthenticated ?
            <OverlayTrigger
              placement='bottom'
              trigger='click'
              key='bottom'
              rootClose
              overlay={
                <Popover id={`popover-positioned-bottom`} className='signin-modal' style={{width: '300px'}}>
                  <div id="example-collapse-text" className={tenantName}>
                    <Row>
                      {
                        accounts && accounts[0] && accounts[0].name ?
                          <div className='tenant-info'>
                            <h5>
                              {accounts[0].name}
                            </h5>
                            <p>{accounts[0].username}</p>
                          </div>
                          : null
                      }
                    </Row>
                    <Row>
                      <Col className='setting-feedback-login'>
                   
                        {
                          //right now, only settings are for user manager, only show for them
                          accounts && accounts[0] && accounts[0].username && currentTenant?
                            
                            <Nav.Link as={Link} to={'/settings'}  onClick={() => document.body.click()}>
                              Settings
                            </Nav.Link>
                            
                          :null
                        }
                        <div className='support-request p-b-start-03' onClick={() => { raiseSupport(true) }}>
                          Help & Feedback
                        </div>
                        <Button className="tenant-sign-out" onClick={showLogoutPopup}>Sign Out</Button>
                      </Col>

                    </Row>
                  </div>
                </Popover>
              }
            >
              <Button id="nav-user-dropdown">
                { windowSize > 991 && accounts && accounts[0] && accounts[0].username ? accounts[0].username : '' }
                <PersonFill
                  className="user-login-icon"
                  style={{
                    width: "auto",
                    height: "1.5em"
                  }}
                />
              </Button>
            </OverlayTrigger>
            :
            <Row onClick={() => { showLoginPopup() }}>
              <Col xs={12} className="landing-signin">
              {accounts[0].username}
                <PersonFill
                  className="user-login-icon"
                  style={{
                    width: "auto",
                    height: "1.5em",
                    marginLeft: "5px"
                  }} />
              </Col>
            </Row>
        }
      </div>
    </>
  )
}

export default TenantUserBlock;