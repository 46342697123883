import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap'
import Select from 'react-select';

const DateSelector = ({
  selectedYear, setSelectedYear,
  selectedMonth, setSelectedMonth,
  selectedDay, setSelectedDay,
  yearLabel, monthLabel, dayLabel,
  className,
  showDaySelector,
  isYearEditable = true,
  isMonthEditable = true,
  isDayEditable = true,
  isClearable = true }) => {

  // State Variables
  const [daysOptions, setDaysOptions] = useState([]);


  // Year range of 50 years with the current year in the center
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 50 }, (_, index) => ({
    value: currentYear - 25 + index,
    label: (currentYear - 25 + index).toString(),
  }));

  // Months with "Choose Month" when editable and "Unknown" when non-editable
  const monthOptions = [
    { value: null, label: isMonthEditable ? 'Select Month' : 'Unknown' },
    { value: 1, label: 'Jan' },
    { value: 2, label: 'Feb' },
    { value: 3, label: 'Mar' },
    { value: 4, label: 'Apr' },
    { value: 5, label: 'May' },
    { value: 6, label: 'Jun' },
    { value: 7, label: 'Jul' },
    { value: 8, label: 'Aug' },
    { value: 9, label: 'Sep' },
    { value: 10, label: 'Oct' },
    { value: 11, label: 'Nov' },
    { value: 12, label: 'Dec' },
  ];


  // Update daysOptions based on the selected month and year
  useEffect(() => {
    if (selectedMonth === null) {
      setDaysOptions([{ value: null, label: isDayEditable ? 'Select month before day' : 'Unknown' }]);
    } else {
      const daysInMonth = new Date(selectedYear || currentYear, selectedMonth, 0).getDate();
      const newDaysOptions = [{ value: null, label: 'Select Day' }, ...Array.from({ length: daysInMonth }, (_, index) => ({
        value: index + 1,
        label: (index + 1).toString(),
      }))];
      setDaysOptions(newDaysOptions);
    }
  }, [selectedMonth, selectedYear, isDayEditable, currentYear]);

  return (
    <div className={`date-selector-container${className}`}>
      <Row>
        <Col>
          {yearLabel && <div className='input-text'>{yearLabel}</div>}
          <Select
            options={yearOptions}
            value={yearOptions.find(option => option.value === selectedYear)}
            onChange={(option) => setSelectedYear(option ? option.value : null)}
            isDisabled={!isYearEditable}
            placeholder="Select Year"
            isClearable
            className={className}
          />
        </Col>

        <Col>
          {monthLabel && <div className='input-text'>{monthLabel}</div>}
          <Select
            options={monthOptions}
            value={monthOptions.find(option => option.value === selectedMonth) || monthOptions[0]}
            onChange={(option) => setSelectedMonth(option.value)}
            isDisabled={!isMonthEditable}
            placeholder="Select Month"
            className={className}
          />
        </Col>

        {showDaySelector && (
          <Col>
            {dayLabel && <div className='input-text'>{dayLabel}</div>}
            <Select
              options={daysOptions}
              value={daysOptions.find(option => option.value === selectedDay) || { value: null, label: isDayEditable ? "Select Day" : "Unknown" }}
              onChange={(option) => setSelectedDay(option.value)}
              isDisabled={!isDayEditable || selectedMonth === null}
              placeholder="Select Day"
              className={className}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};


export default DateSelector;