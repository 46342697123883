import React, { useEffect, useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'

import '../../../styles/layout/_layout.scss'
import EnumSelector from '../EnumSelector';
import ObjectSelector from '../ObjectSelector'
import NumericInput from '../NumericInput';

function ProductEditorTechTransferRow({ product, setProduct, apiKey, organizations, disableProductEdits, index }) {
    const [originator, setOriginator] = useState([])
    const [partners, setPartners] = useState([])

    useEffect(() => {
        async function getData() {
            if (product.tech_transfer[index].originator_id !== null) {
                const currOrg = organizations.find(e => 
                   e.org_id === product.tech_transfer[index].originator_id
                )
                setOriginator(currOrg)
            }

            if (product.tech_transfer[index].partners !== undefined && product.tech_transfer[index].partners.length > 0) {
                const partnerList = product.tech_transfer[index].partners.map((e) => {
                    const result = organizations.find(i => e.org_id === i.org_id)
                    return result
                })
                setPartners(partnerList)
            } 
        }
        getData()
        return () => {}
    }, [product, apiKey, index, organizations])

    function deleteTechTransfer(index) {
        const tech_transfer = [...product.tech_transfer]
        tech_transfer.splice(index, 1)
        setProduct({ ...product, tech_transfer: tech_transfer })
    }

    return (
        <div key={index}>
            <Row>
                <Col className='max-width-30'>
                    <div className='input-text'><strong>Type:</strong></div>
                    <EnumSelector
                        enum_type={'tech_transfer_type'}
                        apiKey={apiKey}
                        disableCreate={true}
                        value={product.tech_transfer[index].tech_transfer_type}
                        onChange={(e) => {
                            const tech_transfer = product?.tech_transfer
                            tech_transfer[index].tech_transfer_type = e.value.enum
                            setProduct({ ...product, tech_transfer: tech_transfer })
                        }}
                    />
                    <div className='input-text'><strong>Year:</strong></div>
                    <NumericInput
                        disabled={disableProductEdits}
                        className='form-ctrl'
                        allowCommas={false}
                        allowDecimals={false}
                        value={product.tech_transfer[index].year}
                        onChange={(e) => {
                            const tech_transfer = product?.tech_transfer
                            tech_transfer[index].year = e
                            setProduct({ ...product, tech_transfer: tech_transfer })
                        }}
                    />

                </Col>
                <Col>
                    <div className='input-text'><strong>Organization:</strong></div>
                    <ObjectSelector
                        apiKey={apiKey}
                        data={organizations}
                        obj={originator ? originator : null}
                        onChange={(e) => {
                            const tech_transfer = product?.tech_transfer
                            tech_transfer[index].originator_id = e ? e.org_id : null
                            setProduct({ ...product, tech_transfer: tech_transfer })
                            setOriginator(e)
                        }}
                        isClearable={true}
                        type='organization'
                        disabled={disableProductEdits}
                    />
                    <div className='input-text'><strong>Partners:</strong></div>
                    <ObjectSelector
                        apiKey={apiKey}
                        data={organizations}
                        obj={partners.length > 0 && partners[0] !== undefined ? partners : null}
                        isMulti={true}
                        onChange={(e) => {
                            const tech_transfer = product?.tech_transfer
                            tech_transfer[index].partners = e.map((e) => {
                                return {
                                    org_id: e.org_id
                                }
                            })
                            setProduct({ ...product, tech_transfer: tech_transfer })
                            setPartners(tech_transfer[index].partners)
                        }}
                        isClearable={true}
                        type={'organization'}
                        disabled={disableProductEdits}

                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className='input-text'><strong>Description:</strong></div>
                    <textarea
                        disabled={disableProductEdits}
                        className='form-ctrl text-input'
                        value={product.tech_transfer[index].description}
                        onChange={(e) => {
                            const tech_transfer = product?.tech_transfer
                            tech_transfer[index].description = e.target.value
                            setProduct({ ...product, tech_transfer: tech_transfer })
                        }}
                    />
                </Col>
                <Col>
                    <div className='input-text'><strong>Source:</strong></div>
                    <input
                        disabled={disableProductEdits}
                        className='form-ctrl'
                        value={product.tech_transfer[index].source}
                        onChange={(e) => {
                            const tech_transfer = product?.tech_transfer
                            tech_transfer[index].source = e.target.value
                            setProduct({ ...product, tech_transfer: tech_transfer })
                        }}
                    />

                    <div className='input-text'><strong>Status:</strong></div>
                    <input
                        disabled={disableProductEdits}
                        className='form-ctrl'
                        value={product.tech_transfer[index].status}
                        onChange={(e) => {
                            const tech_transfer = product?.tech_transfer
                            tech_transfer[index].status = e.target.value
                            setProduct({ ...product, tech_transfer: tech_transfer })
                        }}
                    />
                    <Row>
                        <Col className=''>
                            <div className='input-text'><strong>Confidential?</strong></div>
                            <Form.Check
                                type='checkbox'
                                checked={product.tech_transfer[index].is_confidential}
                                onChange={(e) => {
                                    const tech_transfer = product?.tech_transfer
                                    tech_transfer[index].is_confidential = !tech_transfer[index].is_confidential
                                    setProduct({ ...product, tech_transfer: tech_transfer })
                                }}
                            />
                        </Col>
                        <Col className='display-flex align-items-center justify-end' >
                            {disableProductEdits ? null :
                                <Button
                                    size="sm"
                                    variant="danger"
                                    onClick={() => deleteTechTransfer(index)}>
                                    Delete
                                </Button>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr style={{background: 'white'}}></hr>
        </div>
        
    )
}

export default ProductEditorTechTransferRow