import React, { useState } from 'react';
import { Container, Row, Col, Button, Spinner, InputGroup, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';
import InviteUser from './InviteUser';
import CONSTANTS from '../../constants';
import AppAccessPicker from './AppAccessPicker';
import DataTable from 'react-data-table-component';
import { Search } from 'react-bootstrap-icons';

import utils from '../../utils';

export function WorkspaceUserList({ tenant, updateUserList, getWkspcUsers, refreshUserList, usersList, filteredList, deleteWkspcUsers, loading = false, updateWkspcUsers }) {

    const [changedUsers, setChangedUsers] = useState([]);
    let [deleteUserList, setDeleteUserList] = useState([]);
    const [ appShowing, setAppShowing ] = useState(false);
    const highestLevelForAssign = tenant.tenant.fields.fiveEyesLevel ? tenant.tenant.fields.fiveEyesLevel : 3;

    const [ filterValue, setFilterValue ] = useState();

    const toggleAppsShowing = () => {
        let appPopoverState = appShowing;

        setAppShowing(!appPopoverState);
    }

    //gets the security level selected to display in the select dropdown as default value
    const getSelectedValue = (usrRow, secLvlList) => {
        let secLevel = usrRow.securityLevel || null;

        if (secLevel) {
            let pickedItem = secLvlList.filter((thisItem) => {
                return parseInt(thisItem.value) === parseInt(secLevel);
            });

            return { ...pickedItem[0] };
        }

    };

    const handleSearch = (e) => {
        e.preventDefault();
        if ( e.code === "Backspace") {
            let newVal = filterValue.slice(0, -1);
            setFilterValue(newVal)
        } else {   
            setFilterValue(e.target.value)
        }
    }

    //Gets the dropdown options per security level
    const getSetSecLevel = (levelRoof, secLvls) => {
        return secLvls.filter((thisItem) => {
            return parseInt(thisItem.value) <= parseInt(levelRoof);
        });
    };

    const cols = [
        {
            name: 'Name',
            selector: row => row.displayName,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.mail,
            sortable: true
        },
        {
            name: 'Sec Lvl',
            cell: row => { return (<Select
                                id={`select-secLevel-${row.id}`}
                                name="secLevel"
                                options={getSetSecLevel(highestLevelForAssign, CONSTANTS.SETTINGS.SEC_LEVELS)}
                                onChange={e => onChangeAppList(e.value, row, 'secLevel')}
                                value={getSelectedValue(row, CONSTANTS.SETTINGS.SEC_LEVELS)} />) },
        },
        {
                name: 'Apps List',
                cell: row => { return (
                    <>
                        <OverlayTrigger
                            trigger="click"
                            placement='right-start'
                            key={row.id}
                            rootClose //this closes the popover when clicking outside of it
                            overlay={
                                <Popover className="p-05" show={appShowing}>
                                    <AppAccessPicker
                                        appList={tenant.tenant.fields.masterAppList}
                                        userInfo={row}
                                        onChange={onChangeAppList} />
                                </Popover>} >
                                <Button onClick={toggleAppsShowing}>Select Apps</Button>
                        </OverlayTrigger>
                        
                    </>
                )}
        }
    ];

    const onSetLevel = (val, user) => {
        //make copy of state
        let userList = Array.from(changedUsers);

        //find index of user
        let thisUserRecord = userList.findIndex((item) => {
            return item.id === user.id;
        });
        //if it does exist update record
        if (thisUserRecord > -1) {
            userList[thisUserRecord].securityLevel = val;
            setChangedUsers(userList);
        } else {
            // if it doesn't exist add it to the state
            user.securityLevel = val;
            setChangedUsers([...userList, user]);
        }
    };

    const updateAppsList = (change, apps) => {
        //check if app exists 
        if (apps && apps.includes(change)) {
            //if it does remove it 
            apps = apps.filter((item) => item !== change);
        } else {
            //if it doesn't add it
            apps.push(change);
        }
        //return to string
        let appsList = apps.join(',');
        return appsList;
    };

    const editDeleteUserList = (user, userList) => {
        if (deleteUserList.includes(user)) {
            let newList = deleteUserList.filter((item) => item.id !== user.id);
            setDeleteUserList(newList);
        } else {
            if (userList.includes(user)) {
                let thisUserRecord = userList.filter((item) => item.id !== user.id);
                setDeleteUserList(thisUserRecord);
            } else {
                setDeleteUserList([...userList, user]);
            }
        }
    };

    const onChangeAppList = (userChange, user, level) => {

        if (level === 'secLevel') {
            onSetLevel(userChange, user);
        }
        else if (level === 'delete-user') {
            let userList = Array.from(deleteUserList);
            editDeleteUserList(user, userList);
        }
        else {
            //make applist an array
            let appList = user.appsList.split(',');
            //make a copy of state
            let userList = Array.from(changedUsers);
            //look up to see if user exists in changedUsers 
            let thisUserRecord = userList.findIndex((item) => {
                return item.id === user.id;
            });
            //if it does exist send to update function 
            if (thisUserRecord > -1) {
                userList[thisUserRecord].appsList = updateAppsList(userChange, appList);
                setChangedUsers(userList);
            } else {
                // if it doesn't exist add it to the state
                let newChange = updateAppsList(userChange, appList);
                user.appsList = newChange;
                setChangedUsers([...userList, user]);
            }
        }
    };

    const updateUsers = async (e, action) => {
        //e.preventDefault();
        if (action === 'update-user') {
            //calls endpoint
            await updateWkspcUsers(changedUsers);
            //sends changedUsers upto WorkspaceSettingsPanel.js to be added to usersList state
            updateUserList(changedUsers, action);
            setChangedUsers([]);
        }
        if (action === 'delete-user') {
            for (let i = 0; i < deleteUserList.length; i++) {
                await deleteWkspcUsers(deleteUserList[i]);
            }
            updateUserList(deleteUserList, action);
            setDeleteUserList([]);
        }
    };

    const clearUsersUpdates = (e) => {
        e.preventDefault();
        //calls endpoint to refresh usersList to original list
        getWkspcUsers();
    };

    // const deleteUserFromList = (e, row) => {
    //     onChangeAppList(e, row, 'delete-user');
    // };

    const handleRowSelect = (e) => {
        let usList = e.selectedRows;
        
        setDeleteUserList(usList);
    }

    const exportCurrentUsers = () => {
        utils.exportExcel(usersList, 'UserExport');
    }

    const filterUserList = (user) => { 
        return user.displayName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 || user.mail.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 
    }

    return (

        usersList ?
            <Container>
                <Row className='inner-section'>
                    <Col>
                        <Row>
                            <Col><h6>Manage {tenant && tenant.tenant ? tenant.tenant.fields.name + ' ' : ' '} Workspace <span className="help-text">Manage members permissions within this workspace.</span></h6></Col>
                        </Row>

                        <Row className='tool-bar'>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="search-name"><Search /></InputGroup.Text>
                                    <Form.Control 
                                        placeholder="Search by User  / Email"
                                        onKeyUp={(e) => handleSearch(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className='more-act-col'>
                                <Row>
                                    <Col>
                                        <InviteUser
                                            className="text-right"
                                            tenID={tenant.tenant.fields.aDGroupID}
                                            usersList={usersList}
                                            getWkspcUsers={getWkspcUsers}
                                            refreshUserList={refreshUserList}
                                            workspaceName={tenant.tenant.fields.name} />
                                    </Col>
                                    <Col>
                                        <Button variant="primary" onClick={() => exportCurrentUsers()}>Export Users Shown</Button>
                                    </Col>
                                    <Col>
                                        <Button variant="light" onClick={(e) => updateUsers(e, 'delete-user')} disabled={deleteUserList && deleteUserList.length < 1}>{loading ? 'Removing User...':'Remove User'}</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        { usersList.length > 0 ?
                            <DataTable
                                data={filterValue ? usersList.filter(filterUserList) : usersList }
                                columns={cols}
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight="600px"
                                selectableRows
                                onSelectedRowsChange={(e) => { handleRowSelect(e) }} />
                            : <div>No workspace users to display</div>}
                    </Col>
                </Row>
                <Row className='setting-btns'>
                    <Col>
                        <Button
                            onClick={(e) => clearUsersUpdates(e)}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={(e) => updateUsers(e, 'update-user')}
                            disabled={loading}
                        >
                            {loading ? 'Loading...' : 'Save Changes'}
                        </Button>
                    </Col>
                </Row>
            </Container>
            : <Spinner animation='border' />

    );
}

export default WorkspaceUserList;