const filterUtils = {
    findFilterTitle: (defaultLabelName, filters, listToCheck, showDefaultLabelAlt=null) => {
    let labelName = showDefaultLabelAlt ? showDefaultLabelAlt : defaultLabelName;

    if (filters) {
        //check to see if this item has a filter entry
        let fndIndex = filters.findIndex((item) => {
            return item.field.toLowerCase() === defaultLabelName.toLowerCase();
        });

        if (fndIndex > -1) {
            //get the label off the list

            let foundArr = listToCheck.findIndex((item) => {
                return item.value.toLowerCase() === filters[fndIndex].value.toLowerCase();
            });
        
            if (foundArr > -1) {
                return listToCheck[foundArr].label;
            }

            
            return listToCheck[fndIndex].value;
        }
    }

    return labelName;
  }
}

export default filterUtils;