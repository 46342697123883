import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';

function SortOrderSelect({ sortOrder, sortOptions, handleSortChange }) {

    return (
        <Row className='padded-row'>
            <Col>
                {sortOptions?.length > 1 ? (
                    <div className='display-block'>
                        <label className="input-text">Sort Order </label>
                        <Select
                            options={sortOptions}
                            className="select"
                            value={sortOrder}
                            onChange={handleSortChange}
                        />
                    </div>
                ) : null}
            </Col>
        </Row>
    );
}

export default SortOrderSelect;
