import React, { useState, useEffect } from 'react';
import { Form, Row, Spinner, Button, Col } from 'react-bootstrap';
import BtnDynamic from '../Layout/BtnDynamic';
import { Save, XLg } from 'react-bootstrap-icons';
import DropdownDynamicWithSearch from '../Layout/DropdownDynamicWithSearch';
import PMTCONSTANTS from '../PMT/pmtConstants';
import EnumSelector from '../Alchemy/EnumSelector';

function AddEditAntigenModal({itemISO=null, show, handleClose, form, onChange, countries, userInfo, postActivityUpdate, apiKey, updateListWithComment, submitListItemComment, clearCommentInput}) {

    const [inputLoading, setInputLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const dialog = document.querySelector("dialog");
            if (show === true) {
                dialog.showModal();
            } 
            if (show === false) {
                dialog.close();
            }
            dialog.addEventListener('click', function(event) {
                let rect = dialog.getBoundingClientRect();
                let isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
                if (!isInDialog) {
                    dialog.close();
                    handleClose();
                }
            })
    },[show, handleClose])

    // //validates form to confirm all required fields are filled in
    const validateForm = (formToCheck) => {
        const { dh_antigen, dh_country_detail, priority, status, activity_type, details, source } = formToCheck;
        let newErrors = {};
        if (!dh_antigen || dh_antigen === '') {
            newErrors.dh_antigen = 'Antigen is Required';
        }
        if (!dh_country_detail.ISO || dh_country_detail.ISO === '') {
            newErrors.dh_country_detail = 'Country is Required';
        }  
        if (!priority || priority === '') {
            newErrors.priority = 'Priority is Required';
        }  
        if (!status || status === '') {
            newErrors.status = 'Progress is Required';
        }  
        if (!activity_type || activity_type === '') {
            newErrors.activity_type = 'Activity type is Required';
        }  
        if (!details || details === '') {
            newErrors.details = 'Details is Required';
        }  
        if (!source || source === '') {
            newErrors.source = 'Source is Required';
        }
        return newErrors;
    }

    const onSubmit = async(e) => {
        e.preventDefault();

        let copyObj = {...form};
        const errorsOnForm = validateForm(copyObj);
        let errorsArray = Object.keys(errorsOnForm);

        if (errorsArray && errorsArray.length < 1) {
            copyObj.updated_by = userInfo[0].displayName;
            postActivityUpdate(copyObj, 'popup')
            setInputLoading(true)
            setErrors(errorsOnForm)
        } else {
            setErrors(errorsOnForm)
        }
    }

    const getCountryValue = (form, countryObj) => {
        if(form && form.dh_country_detail && form.dh_country_detail.ISO) {
            return countryObj[form.dh_country_detail.ISO]
        }
    }

    const commentHandler = (e, id) => {
        let newDate = new Date().toISOString();
        let commentObj = {};
     
        //Adds comment info to obj
        commentObj.activity_id = id;
        commentObj.owner = userInfo[0].displayName;
        commentObj.created_on = newDate;
        commentObj.comment_value = e.target.value;
        updateListWithComment(commentObj);
    }

  return (
    <div className='modal-alignment'>
        <dialog id='int-modal' className='basic-dialog-styles' >
            <Form onSubmit={(e) => onSubmit(e)} className='max-width-100'>
                <Row>
                    <Col lg={11} className='p-i-end'>
                        <h3 className='heading-h3'>Add Activity</h3>
                    </Col>
                    <Col className='align-right p-i-start' lg={1}>
                        <XLg onClick={() => handleClose(itemISO ? itemISO : null)} />
                    </Col>
                </Row>
                <Row className='m-b-end-05'>
                    <Col>
                        <label className='subtitle-lg m-b-end-03'>Antigen</label>
                        <EnumSelector 
                            className="select-drop-down height-36px"
                            enum_type={'antigen_dh'}
                            apiKey={apiKey} 
                            isMulti={false} 
                            disableCreate={false} 
                            isClearable={true}
                            value={form && form.dh_antigen ? form.dh_antigen : null}
                            fieldName={'dh_antigen'}
                            onChange={(e) => onChange('dh_antigen', e.value)}
                        />
                        <div
                            className="error-message"
                            style={{
                            fontSize: '.875em',
                            color: '#dc3545',
                            marginTop: '0.25rem'
                        }}
                        >
                            {errors.dh_antigen}
                        </div>
                    </Col>
                    <Col>
                    <label className='subtitle-lg m-b-end-03'>Country</label>
                        <DropdownDynamicWithSearch
                            id='country'
                            handleSelect={onChange}
                            initialValue={getCountryValue(form, countries.countryObj)}
                            menuItems={countries.countryName}
                            className={'dropdown-select'}
                            fieldName={'country'}
                            padding={'p-b-03'}
                            font={'body-medium-condensed'}
                            disabled={form && form.deleted === true}
                            showDefaultOption={true}
                            searchable={false}
                        />
                        <div
                            className="error-message"
                            style={{
                            fontSize: '.875em',
                            color: '#dc3545',
                            marginTop: '0.25rem'
                        }}
                        >
                            {errors.dh_country_detail}
                        </div>
                    </Col>
                </Row>
                <Row className='m-b-end-05'>
                    <Col lg={6}>
                        <label className='subtitle-lg m-b-end-03'>Priority</label>
                        <DropdownDynamicWithSearch
                            id='priority'
                            handleSelect={onChange}
                            initialValue={form && form.priority ? form.priority :''}
                            menuItems={PMTCONSTANTS.DEMAND_PRIORITY_LIST}
                            className={'dropdown-select'}
                            padding={'p-b-03'}
                            fieldName={'priority'}
                            disabled={form && form.deleted === true}
                            showDefaultOption={true}
                            searchable={false}
                        />
                        <div
                            className="error-message"
                            style={{
                            fontSize: '.875em',
                            color: '#dc3545',
                            marginTop: '0.25rem'
                        }}
                        >
                            {errors.priority}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <Form.Label className='subtitle-lg'>Progress</Form.Label>
                        <DropdownDynamicWithSearch
                            showDeselect={true}
                            handleSelect={onChange}
                            initialValue={form && form.status ? form.status : "Select..."}
                            menuItems={PMTCONSTANTS.PROGRESS_LIST}
                            className={'dropdown-select'}
                            fieldName={'status'}
                            padding={'p-b-03'}
                            disabled={form && form.deleted === true}
                            searchable={false}
                            id='status'
                        />
                        <div
                            className="error-message"
                            style={{
                            fontSize: '.875em',
                            color: '#dc3545',
                            marginTop: '0.25rem'
                        }}
                        >
                            {errors.status}
                        </div>
                    </Col>
                </Row>
                <Row className='m-b-end-08'>
                    <Col lg={6}>
                        <label className='subtitle-lg m-b-end-03'>Activity Type</label>
                        <EnumSelector
                            className="select-drop-down height-36px"
                            enum_type={'activity_type_dh'}
                            apiKey={apiKey} 
                            isMulti={false} 
                            disableCreate={false} 
                            isClearable={true}
                            value={form && form.activity_type ? form.activity_type : null}
                            fieldName={'activity_type'}
                            onChange={(e) => onChange('activity_type', e.value)}
                        />
                        <div
                            className="error-message"
                            style={{
                            fontSize: '.875em',
                            color: '#dc3545',
                            marginTop: '0.25rem'
                        }}
                        >
                            {errors.activity_type}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <Form.Label className='subtitle-lg'>Activity Year</Form.Label>
                        <input
                            id='year'
                            className="input-underline full-width p-b-03 body-medium-condensed"
                            name='year'
                            disabled={form && form.deleted === true}
                            defaultValue={form && form.year ? form.year : ''}
                            onChange={(val) => onChange(val.target.name, val.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Form.Label className='subtitle-lg'>Details</Form.Label>
                    <Form.Group className="enter-item-name" controlId="item_id">
                        <Form.Control
                            className={form && form.deleted === true ? 'semibold input-underline' : 'input-underline'}
                            size="lg"
                            type="text"
                            as="textarea"
                            rows={3}
                            name="details"
                            isDisabled={form && form.deleted === true}
                            onChange={(val) => onChange(val.target.name, val.target.value)}
                            placeHolder={'Enter text'}
                            defaultValue={form && form.details ? form.details : ''}
                            plaintext={
                                form && form.deleted === true
                            }
                            readOnly={
                                form && form.deleted === true
                            }
                            isInvalid={errors.hasOwnProperty("details")}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.details}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                <Form.Label className='subtitle-lg m-b-start-08'>Source</Form.Label>
                    <Form.Group className="enter-item-name" controlId="item_id">
                        <Form.Control
                            className={form && form.deleted === true ? 'semibold input-underline' : 'input-underline'}
                            size="lg"
                            type="text"
                            as="textarea"
                            rows={1}
                            name="source"
                            isDisabled={form && form.deleted === true}
                            onChange={(val) => onChange(val.target.name, val.target.value)}
                            placeHolder={'Enter text'}
                            defaultValue={
                                form && form.deleted === true ?
                                    `${"* Archived * " + form.source                                    }`
                                    : form && form.source ?
                                        form.source : ""
                            }
                            plaintext={
                                form && form.deleted === true
                            }
                            readOnly={
                                form && form.deleted === true
                            }
                            isInvalid={errors.hasOwnProperty("source")}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.source}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                {
                    form && form.activity_id ?
                        <>
                            <h3 className='heading-h3 m-b-start-08'>Comments & Event History</h3>
                            {
                                form && form.comments && form.comments.length > 0 ?
                                    form.comments.map((comment, index) => {
                                        return (
                                            <>
                                                <Row key={index} className='p-03 comments'>
                                                    <Col><p>{comment.owner}</p></Col>
                                                    <Col className='comment-date-col'><p>{comment.created_on}</p></Col>
                                                </Row>
                                                <p className='p-03'>{comment.comment_value}</p>
                                            </>
                                        )
                                    })

                                    : <p className='p-03 text-secondary'>No comments yet...</p>
                            }
                            <Row className='m-b-end-08 input-comment-container'>
                                {
                                    !inputLoading ?
                                        <input
                                            className='light-input-med-padding input-underline'
                                            type="text"
                                            id='comment_value'
                                            name="comment_value"
                                            disabled={form && form.deleted && form.archved === true ? true : false}
                                            onChange={(e) => commentHandler(e, form.activity_id)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    submitListItemComment(e);
                                                }
                                            }}
                                            placeholder={"Enter comment here..."}
                                        />

                                        :
                                        <Spinner animation="border" />
                                }
                                <BtnDynamic
                                    classes={'intervention-comment-btns btn-ghost-lg'}
                                    content={{ name: 'Cancel' }}
                                    actionHandler={() => clearCommentInput()}
                                    loading={form && form.deleted && form.archved === true ? true : false}
                                />
                                <BtnDynamic
                                    classes={'surface-primary btn-ghost-border-lg intervention-comment-btns'}
                                    content={{ name: 'Comment' }}
                                    actionHandler={() => submitListItemComment()}
                                    loading={form && form.deleted && form.archved === true ? true : false}
                                />
                            </Row>
                        </>
                        : ''
                }
                <div className='btn-container justify-end'>
                    {/* why are we passing itemISO back on discard? */}
                    <Button
                        onClick={() => handleClose(itemISO ? itemISO : '')}
                        className="btn-row btn-ghost-border-lg"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={(e) => onSubmit(e)}
                        className="surface-brand-primary btn-primary-lg btn-row m-i-start-03 right btn"
                    >
                        <Save />
                        Save changes
                    </Button>
                </div>
            </Form>
        </dialog>
    </div>
  )
}

export default AddEditAntigenModal