import React from 'react';
import { Button } from 'react-bootstrap';
import { PlusCircle, Search } from 'react-bootstrap-icons'

function DemandHealthToolbar({searchTermHandler, handleShowListItemModal, appTab=null}) {
    
  return (
    <div className='m-b-end-03 align-right'>
      {
        appTab === 'objectives' ?
          <>
            <Search className='txt-primary'/>
            <input
                autoFocus
                className="antigen-search mx-3 my-2 w-auto border-none m-i-start-02"
                placeholder="Search by..."
                onChange={(e) => searchTermHandler(e.target.value)}
            />
          </>
          : <Button className="icon-btn surface-brand-primary btn-primary-lg m-i-start-03 display-inline-block" onClick={handleShowListItemModal}><PlusCircle />Add Activity</Button>
      }
    </div>
  )
}

export default DemandHealthToolbar