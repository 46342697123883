import React, { useState } from 'react';

import LBGantt from '../LBGantt';
import moment from 'moment';
import utils from '../../utils';

function MarketActionsGanttView({ marketID, marketList, viewStart, zoom, tenant, handleShowIntModal }) {

  const cropText = (text, stopAt) => {
    if(text.length > stopAt) {
      return text.slice(0,(stopAt-3)) + '...';
    }

    return text;
  }

  const getGanttList = (list) => {
    //market list is an array
    let gList = [];


    list.forEach( (item) => {
      item.interventions.forEach((thisItem, index) => {

          let retObj =
          {
            id: thisItem.id,
            start: thisItem.start_date ? moment(parseInt(thisItem.start_date)).format('L') : moment(parseInt(thisItem.date_created)).format('L'),
            end: thisItem.due_date ? moment(parseInt(thisItem.due_date)).format('L') : '',
            name: thisItem.name ? cropText(thisItem.name, 250) : '',
            link: `/${utils.findURLPath(tenant.tenant.fields.apps, "market_actions")}/interventions/${thisItem.id}`,
            status: thisItem.status.status.replaceAll(' ', '-'),
            fromList: { id: thisItem.list.id, name: thisItem.list.name },
            dueDateType: thisItem.due_date_type ? thisItem.due_date_type : ''
          }
         
          gList.push(retObj);
          
        })
    })

    return gList;
    
  }

  let [data] = useState(getGanttList(marketList));

  const ganttConfig = [
    {
      header: 'Intervention',
      type: 'text',
      accessor: 'name'
    },
    {
      header: '',
      type: 'link',
      accessor: 'link',
      iconName: 'BoxArrowRight'
    }
  ]

  return (
    <LBGantt
      zoom={zoom}
      currentView={viewStart}
      tasks={data} 
      groupByField={'status'} 
      groupByFieldLabel={'Task Status'}
      leftColumnsConfig={ganttConfig} 
      handleShowIntModal={handleShowIntModal} />
  )
}

export default MarketActionsGanttView;