import React from 'react'

function BulkResultsTable({ bulkAction, results }) {
    //format expected
    // [{ status: 'success || error', data: information provided by the function }]
    return (
        <div className='results-container small-table'>
            <h4>Results</h4>
            <table style={{width:'100%'}}>
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Status</th>
                        <th>Result</th>
                    </tr>
                </thead>
                <tbody className='scroll-pane'>
                    { 
                        results ?
                            results.map((result, idx) => {
                                return (
                                    <tr key={'result-' + idx}>
                                        <td>{ bulkAction }</td>
                                        <td
                                            className={result.status === 'error' ? 'error' : ''}>
                                            {result.status}
                                        </td>
                                        <td>{JSON.stringify(result.data)}</td>
                                    </tr>
                                )
                            })
                        :
                            <td colSpan='3'><em>No results to display</em></td>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default BulkResultsTable