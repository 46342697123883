import React, { useRef, useState } from 'react';
import Popover from 'react-awesome-popover'
import { Button, Row, Col, Dropdown} from 'react-bootstrap';
import FilterByFilterLine from './FilterByFilterLine';
import { ChevronDown } from 'react-bootstrap-icons';

import CONSTANTS from '../../constants';

function FilterByPopover(props) {

    const target=useRef(null);
    
    const [filterRows, setFilterRows] = useState([]);

    const fieldsAvailable = [
        { label: 'Created', fieldName: 'date_created', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.DATE_TYPE, value: 'datePicker'},
        { label: 'Last Updated', fieldName: 'date_updated', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.DATE_TYPE, value: 'datePicker'},
        { label: 'Intervention Title', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.TEXT_TYPE, value: ''},
        { label: 'Status', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.STATUS_TYPE, value: props.statusList },
        { label: 'Assigned To', fieldName: 'custom__Assigned To', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.ASSIGNEE_TYPE, value: props.assigneeList}
    ]

    const onFieldSet = (itemToSet) => {
        //find the item by id in our list
        const idxOfItem = filterRows.findIndex( (item) => {
            return item.id === itemToSet.id;
        });

        //set the field on that item
        let fRows = [...filterRows];
        fRows[idxOfItem][itemToSet.field] = itemToSet.value;
        setFilterRows(fRows);

        if(fRows[idxOfItem].fieldForFilter && fRows[idxOfItem].operator && fRows[idxOfItem].comparator) {
            //only submitted if all fields are filled out
            props.onChange({src: 'FILTERBY', data: fRows});
        }
    }

    const addRow = () => {
        let fRows = [...filterRows, {
            id: Math.random().toString(36).slice(2),
            fieldForFilter: null,
            operator: null,
            comparator: null
        }];
        setFilterRows(fRows);
    }

    const onDeleteLine = (id) => {
        //remove the filter from the array
        let fRowsD = [...filterRows];

        let indOfRow = fRowsD.findIndex( (item) => {
            return item.id === id;
        });

        fRowsD.splice(indOfRow, 1);
        setFilterRows(fRowsD);
        
        props.onChange({src: 'FILTERBY', data: fRowsD});
    }

    return (
        <Popover
            id="popover-filter-popover"
            className="toolbar-standard-dropdown"
            action="click"
            placement="bottom-end"
            overlayColor="rgba(0,0,0,0)"
        >
            <Dropdown ref={target} className="toolbar-standard-dropdown w-100">
                <Dropdown.Toggle className="dropdown-text-left w-100">
                    {props.opt.label} <ChevronDown />
                </Dropdown.Toggle>
            </Dropdown>

            <div id="popover-container-filter-by" className="popover-container filter-by no-arrow p-4">
                <div>
                <Row>
                    <Col>
                        <h4 className="filter-title mb-3">Filters</h4>
                    </Col>
                </Row>
                {filterRows.map((thisFilterRow) => (
                    <FilterByFilterLine
                        deps={{
                            assigneeList: props.assigneeList,
                            statusList: props.statusList,
                            hmfCategories: props.hmfCategories
                        }}
                        filterItem={thisFilterRow}
                        onSet={onFieldSet}
                        fieldList={fieldsAvailable}
                        onDeleteLine={onDeleteLine}
                    />
                ))}
                <div className="add-filter-wrapper text-center">
                    <Button className="add-filter-btn" onClick={() => addRow()}>
                        + Add Filter
                    </Button>
                </div>
                </div>
            </div>
        </Popover>
    );
}

export default FilterByPopover