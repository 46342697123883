import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ChevronDoubleLeft, ChevronLeft, ChevronRight, ChevronDoubleRight } from 'react-bootstrap-icons';

function Pager({ onChange }) {
  return (
    <ButtonGroup className="pager">
        <Button className='tertiary-button' name="full-rewind" onClick={(e) => { onChange({src: 'PAGER', data: 'full-rewind'})}}><ChevronDoubleLeft /></Button>
        <Button className='tertiary-button' name="rewind" onClick={(e) => { onChange({src: 'PAGER', data: 'rewind'})}}><ChevronLeft /></Button>
        <Button className='tertiary-button' name="forward" onClick={(e) => { onChange({src: 'PAGER', data: 'forward'})}}><ChevronRight /></Button>
        <Button className='tertiary-button' name="full-forward" onClick={(e) => { onChange({src: 'PAGER', data: 'full-forward'})}}><ChevronDoubleRight /></Button>
    </ButtonGroup>
  )
}

export default Pager