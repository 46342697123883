const LLM_SERVICE_URL = process.env.REACT_APP_LLM_SERVICE_URL
const LLM_SERVICE_API_KEY = process.env.REACT_APP_LLM_SERVICE_API_KEY

const headers = {
    'Content-Type': 'application/json',
    'access_token': LLM_SERVICE_API_KEY
  }
  
const getRequestOptions = {
    method: 'GET',
    headers:headers
  }

const aiUtils = {
    getThread: async (threadId) => {
        // The run is done now, so get back all messages from the thread.
        const messageList = await fetch(`${LLM_SERVICE_URL}get_formatted_messages?thread_id=${threadId}`, getRequestOptions);
        const messageListJson = await messageList.json();
        return messageListJson;
    }
}

export default aiUtils;