// useEffects are React18 ready
// TODO: (medium-high priority) enable alt_name editing. Roughly identical to the alt_names
// functionality in the organization editor

import React, { useState, useEffect } from 'react'; 

import _ from "lodash" // For making deep copies of objects
import { Form, FormControl, Button, Row, Col, Modal, Table, ButtonGroup, ButtonToolbar} from 'react-bootstrap'

import SimpleSelect from '../SimpleSelect'
import utils from "../../../utils"
import {useToast} from '../../ToastProvider'
import EnumSelector from '../EnumSelector'
import CONSTANTS from "../../../constants"
import '../../../styles/layout/_layout.scss'

function VaccineEditor(props) {
  
  /*--------------------------State variables---------------------------*/
  const [vaccine, setVaccine] = useState(null)
  const [errors, setErrors] = useState([])
  const partners = ['WHO', 'GAVI', 'BMGF', 'CHAI', 'UNICEF', ''];
  const {addToast} = useToast();

  useEffect(()=>{
    if (props.getFromLocalStore === true) {
      fetchFromLocalStore();
    } else {
      fetchData();
    }
    return () => {};
    // eslint-disable-next-line
  },[props.apiKey, props.id, addToast])

  async function fetchData(){
    if (props.id){
      let res = await utils.getData('get_vaccine', props.apiKey, "?vaccine_id="+props.id)
      if (typeof res === 'string') {
        addToast({
          title: 'Failed to get vaccine. Error code 2-a',
          body: res
        })
        props.closePopup();
      } else {
        if (res){
          setVaccine(res);
        }
      }
    } else{
      setVaccine(_.cloneDeep(CONSTANTS.SHELL_VACCINE))
    }
  }

  /*-------------------------Submit form and new antigen----------------------------------*/
  async function handleSubmit (e){
    e.preventDefault();
    e.stopPropagation();

    let foundVaccine = new Set();
    let foundNames = new Set()
    let submissionErrors = [];
  
    for (let i = 0; i<vaccine.alt_names.length; i++){

      if (foundVaccine.has(vaccine.alt_names[i].partner)){
        submissionErrors.push(vaccine.alt_names[i].partner + " has two or more alternatives")
      }

      if (foundNames.has(vaccine.alt_names[i].alt_subtype_name)){
        submissionErrors.push(vaccine.alt_names[i].alt_subtype_name + " appears twice")
      }

      if (vaccine.alt_names[i].partner !== ''){
        foundVaccine.add(vaccine.alt_names[i].partner)
      } else {
        foundNames.add(vaccine.alt_names[i].alt_subtype_name)
      }
    }

    setErrors(submissionErrors)
    if (submissionErrors.length > 0)  {
      sendToLocalStorage();
      addToast({
        title: 'Failed to submit vaccine. Error code 2-b',
        body: submissionErrors,
        onClick: props.toastClick,
        buttonText: "Reopen vaccine?"
      })
      props.closePopup();
      return
    }
    const data = vaccine
    var result
    e.preventDefault();
    e.stopPropagation();
    result = await utils.upsert('upsert_vaccine', props.apiKey, data)
    if (result !== null) {
      if (props.onSubmit){
        props.onSubmit(result)
      } 
      if (typeof result === 'string') {
        sendToLocalStorage();
        addToast({
          title: 'Failed to submit vaccine. Error code 2-c',
          body: result,
          onClick: props.toastClick,
          buttonText: "Reopen vaccine?"
        })
      }
      props.closePopup()
    }
  }

  /*--------------------------------Manage state variables-----------------------*/
  function addAntigenOnEnter(e) {
    const tempAntigens = vaccine.antigens
    tempAntigens.push({vaccine_id: vaccine.vaccine_id, antigen: e})
    setVaccine({...vaccine, antigens:tempAntigens})
  }

  function deleteAntigen(index) {
    const tempAntigens = vaccine.antigens
    tempAntigens.splice(index, 1)
    setVaccine({ ...vaccine, antigens: tempAntigens })
  }

  function printVaccine() {
    console.log(vaccine)
  }

  function addSynonym() {
    const tempAltNames = vaccine.alt_names
    tempAltNames.push({alt_subtype_name: '', partner: ''})
    setVaccine({...vaccine, alt_names:tempAltNames})
  }

  function setSynonym(synonym, index){
    const synonyms = [...vaccine.alt_names]
    synonyms[index].alt_subtype_name = synonym
    setVaccine({...vaccine, alt_names: synonyms})
  }

  function setPartner(partner, index){
    const synonyms = [...vaccine.alt_names]
    synonyms[index].partner = partner === null ? '' : partner
    setVaccine({...vaccine, alt_names: synonyms})
  }

  function deleteSynonym(index) {
    const tempAltNames = [...vaccine.alt_names]
    tempAltNames.splice(index, 1)
    setVaccine({...vaccine, alt_names: tempAltNames})
  }

  function handleDelete(){
    if (!vaccine.deleted){
      if(window.confirm("Are you sure you want to delete this vaccine?")){
        setVaccine({...vaccine, deleted:true})
      }
    } else{
      if(window.confirm("Are you sure you want to undelete this vaccine?")){
        setVaccine({...vaccine, deleted:false})
      }
    }
  }

  function fetchFromLocalStore(){
    const obj = localStorage.getItem('errorVaccine');
    const objJSON = JSON.parse(obj);
    setVaccine(objJSON.vaccine);
  }

  function sendToLocalStorage(){
    localStorage.setItem('errorVaccine', JSON.stringify({vaccine: vaccine}));
  }

  return (
    <div>
    {
      vaccine?
        <Modal show={true} className={'alc-app'}>
        <Modal.Header>
          <Modal.Title>Vaccine Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='input-text'>Vaccine Name</div>
          <input
            className='form-ctrl'
            type='text'
            required
            value={vaccine.vaccine}
            onChange={(e) => (e) = setVaccine({ ...vaccine, vaccine: e.target.value })}
          />
          <div className='input-text'>Vaccine Subtype</div>
          <input
            className='form-ctrl'
            type='text'
            required
            value={vaccine.vaccine_subtype}
            onChange={(e) => (e) = setVaccine({ ...vaccine, vaccine_subtype: e.target.value })}
          />
          <div className='input-text'>Vaccine Group</div>
          <EnumSelector
            className="select"
            enum_type="vaccine_group"
            apiKey={props.apiKey}
            value={vaccine.vaccine_group}
            onChange={(e) =>setVaccine({...vaccine, vaccine_group: e.label})} />
          <div className='input-text'>Gavi Visible</div>
          <Form.Check
            type='checkbox'
            checked={vaccine.gavi_visible}
            onChange={() => setVaccine({...vaccine, gavi_visible: !vaccine.gavi_visible})}
          />
          <div className='input-text'><strong>Delete vaccine</strong></div>
          <Form.Check
            type='checkbox'
            checked={vaccine.deleted}
            onChange={handleDelete}
          ></Form.Check>
          <div className='input-text'><strong>Antigens</strong></div>
          <div className='input-text'>Add Antigen:</div>
          <EnumSelector
            className="select"
            enum_type="antigen"
            apiKey={props.apiKey}
            value=''
            clearAfterSelect={true}
            onChange={(e) => addAntigenOnEnter(e.label)} />
          <ul>
          {vaccine.antigens.map((a, i) => {
            return <li key={i}><Row><Col xs={4}>{a.antigen}</Col><Col><Button size="sm" onClick={() => deleteAntigen(i)}>X</Button></Col></Row></li>
          })}
        </ul>
          
          
          <Table>
            <thead className='table-heading'>
              <tr>
                <td>Synonym</td>
                <td>Partners</td>
                <td>
                  <div className='clickable-text' onClick={addSynonym} size="sm">add</div>
                </td>
              </tr>
            </thead>
            <tbody>
              {vaccine.alt_names.map((ae, i) => {
                return <tr key={i}>
                  <td>
                    <FormControl value={ae.alt_subtype_name}
                      onChange={(e) => setSynonym(e.target.value, i)}
                    ></FormControl>
                  </td>
                  <td>
                    <SimpleSelect
                      options={partners}
                      onChange={(partner) => setPartner(partner, i)}
                      value={ae.partner}
                    />
                  </td>
                  <td>
                    <Button variant="danger" size="sm"
                      onClick={() => deleteSynonym(i)}>-</Button>
                  </td>
                </tr>
              })}
            </tbody>
          </Table>
          {errors.length > 0 ?
            <div>
              <div className="error">Errors:</div>
              {
                errors.map((e) => <li className="error">{e}</li>)
              }
            </div>
            : null}
            <ButtonToolbar className='editor-btn'>
              <ButtonGroup>
                <Button className='button-secondary m-i-end-02' onClick={props.closePopup} variant="secondary">Close</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button className='btn-primary m-i-end-02' onClick={handleSubmit} variant="primary">Submit</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick= { printVaccine } variant = "primary">Check Vaccine</Button>
              </ButtonGroup>
            </ButtonToolbar>
        </Modal.Body>
      </Modal>
      :null
    }
    </div>
  )
}

export default VaccineEditor;