import React from 'react';
import constants from '../../constants';

function WorkspaceCheckList({ masterList, changeHandler, wrkMessage }) {
  return (
    <>
        <div className="wrkspace-chooser">
            {
                masterList.map( (thisWkspc) => {
                    //force ghmh - they will be at least part of this workspace
                    let forcedCheckAndDisable = thisWkspc.tenantID === constants.GHMH_WKSPC_ID;

                    return (
                        <div>
                            <input 
                                style={{ marginRight: '8px'}}
                                type="checkbox"
                                defaultChecked={forcedCheckAndDisable}
                                disabled={forcedCheckAndDisable}
                                id={ thisWkspc.tenantID } 
                                onChange={ changeHandler }
                            /> 
                            <label htmlFor={thisWkspc.tenantID}>{ thisWkspc.tenantSlug }</label>
                        </div>
                    )
                })
            }
        </div>
        <div className="working-message">
            {
                wrkMessage && wrkMessage !== '' ?
                    wrkMessage
                : null
            }
        </div>
    </>
  )
}

export default WorkspaceCheckList