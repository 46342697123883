import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import ColumnSelector from './ColumnSelector';
import SortOrderSelect from './SortOrderSelect';

function SettingsPanel({ editor, handleCloseSettings, columnRowContent, dynamicColumns,
  metaData, handleColumnChange, columnData, settingsContent, handleSaveSettings, dynamicWidths,
  sortOrder, sortOptions, handleSortChange, appClass=null
}) {


  const renderEditorSettings = () => {
    if (editor === 'Products' && settingsContent) {
      return settingsContent.settingsButtons();
    }
    if (editor === 'CE' && settingsContent) {
      return settingsContent.activityButtons();
    }
    if (editor === 'Drug Product' && settingsContent) {
      return settingsContent.settingsButtons();
    }
    if (editor === 'Countries' && settingsContent) {
      return settingsContent.settingsButtons();
    }
    if (editor === 'Vaccines' && settingsContent) {
      return settingsContent.vaccineButtons();
    }
    if (editor === 'Price' && settingsContent) {
      return settingsContent.settingsButtons();
    }
    if (editor === 'Demand' && settingsContent) {
      return settingsContent.settingsButtons();
    }
    return "";
  };

  return (
    
    <div className={appClass}>
      <div className='settings-popover'>
      <div closeButton>
        <div className='settings-content'>
          <div className='settings-header'>More Options</div>
          <SortOrderSelect
            className='select'
            sortOrder={sortOrder}
            sortOptions={sortOptions}
            handleSortChange={handleSortChange}
          />
          {columnRowContent ? columnRowContent() : null}
          {dynamicColumns ? (
            <ColumnSelector
              data={metaData}
              onChange={handleColumnChange}
              columnData={columnData}
              isResizable={dynamicWidths}
            />
          ) : null}
          {renderEditorSettings()}
        </div>
        <div>
          <Row className='settings-footer justify-end'>
            <Col xs="auto" className='close'>
              <Button className='btn-sm-invisible-100 buttonSmall secondary-link' onClick={handleCloseSettings}>Close</Button>
            </Col>
            <Col xs="auto" className='confirm'>
              <Button className='btn-primary bg-blue-300 m-i-end-06 m-b-end-06' id='modal-submit' onClick={handleSaveSettings}>
                Apply Changes
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </div>
  );
}

export default SettingsPanel;