import React, { useState } from 'react';
import { Form, Dropdown, FormControl} from 'react-bootstrap';
import { ChevronDown } from 'react-bootstrap-icons';
import countryList from 'country-list';
import ReactCountryFlag from 'react-country-flag';


function CountryFilter({ selectHandler, clearFilter, field, filters }) {

  let [selectCountry, setSelectCountry] = useState('');
  let [searchTerm, setSearchTerm] = useState('');

  const handleSelect = (key) => {
    setSelectCountry(key);
    selectHandler(field, { label: key, value: key });
  }


  const filteredCountries = searchTerm ?
    countryList.getNames().filter((name) => 
      name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  : countryList.getNames();


  return (
      <Form className='standard-dropdown p-i'>
        <Dropdown
          name={"Country"}
          onSelect={(eKey) => { handleSelect(eKey) }}
        >
          <Dropdown.Toggle id='country-filter' className="align-left m-b-end">
            {clearFilter === true || selectCountry === '' 
            ? 'Select a Country' 
            : <> 
              <ReactCountryFlag
                style={{ width: "1.5em", height: 'auto' }}
                countryCode={countryList.getCode(selectCountry)}
                svg 
              />  
                {` ${selectCountry}`}
            </>} <ChevronDown />
          </Dropdown.Toggle>
          

          <Dropdown.Menu>
            <FormControl
              autoFocus
              className="mx-3 my-2 w-auto"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Dropdown.Item key="" eventKey="" className='text-start' placeholder="Select a Country..."></Dropdown.Item>
            {filteredCountries.map((countryName) => (
              <Dropdown.Item key={countryName} eventKey={countryName}  >
                <ReactCountryFlag
                  style={{ width: "1.5em", height: 'auto' }}
                  countryCode={countryList.getCode(countryName)}
                  svg /> {countryName}...
              </Dropdown.Item>
            ))}
            
          </Dropdown.Menu>
        </Dropdown>
      </Form>
  )
}

export default CountryFilter;