import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import filterUtils from '../../filterUtils';

function CountryFilter({ countryList, filterChangeHandler, curFilters }) {

  const handleSelect = (key) => {

    let foundArr = countryList.filter((item) => {
      return item.label.toLowerCase() === key.toLowerCase();
    });

    // Creates obj to send to the change handler function
    filterChangeHandler({
      field: 'country',
      value: foundArr.length > 0 ? foundArr[0].value : ''
    });
    
  };

  return (

    
    <DropdownButton
      className='dropdown-select'
      id="country-filter"
      title={filterUtils.findFilterTitle('Country', curFilters, countryList)}
      onSelect={(eventKey) => handleSelect(eventKey)} >
      <Dropdown.Item
        eventKey={'clear-filter-country'}
      >
        Select All
      </Dropdown.Item>
      {
        countryList.map((country, idx) => {
          return (
            <Dropdown.Item
              key={idx}
              eventKey={country.value}>
              {country.label}
            </Dropdown.Item>
          )
        })
      }
    </DropdownButton>
  )
}

export default CountryFilter