import React, { useEffect, useState } from 'react';
import Select from 'react-select';

function ReactTblMultiTagPickerCell({ initialValue, row, column, updateMyData, selectionValues, forName, customField=null }) {

    const [ myValue, setMyValue ] = useState([]);
    
    useEffect(() => {
        if (initialValue && initialValue.length > 0) {
            setMyValue(initialValue)
        }
    },[initialValue])

    const onChange = (vals) => {
        let retArray = []
        if (vals && vals.length > 0) {
            vals.forEach((val) => {
                retArray.push(val.value)
            })
        }
        setMyValue(retArray);
    };

    const onBlur = () => {
        if(forName === 'hmfCategory'){
            updateMyData(row, column, [myValue]);
        }else{
            updateMyData(row, column, myValue);
        }
    };

    const getInitialValues = (initVals) => {
        let retArr = [];

        if (initVals) {
            selectionValues.forEach((valObj) => {
                initVals.forEach((val) => {
                    if(valObj.value === val) {
                        retArr.push(valObj);
                    };
                });
            });
        };
        return retArr;
    }


    return (
        <div onBlur={onBlur}>
            <Select
                value={getInitialValues(myValue)}
                isMulti
                name="colors"
                options={selectionValues}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(val) => onChange(val)}
            />
        </div>
    )
}

export default ReactTblMultiTagPickerCell;