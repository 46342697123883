import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import { InfoCircleFill, PlusCircle, Trash } from 'react-bootstrap-icons';
import PhaseEditor from './PhaseEditor';
import ModalDynamic from '../../Layout/ModalDynamic';


function DoseSequenceEditor({doseSequence, onChange, onDelete, apiKey, childGeographies, prodChars, userName, open, 
    tooltip, getTooltipContentByKey, errors, dsIndex, gender, minAge, maxAge, minAgeUnit, maxAgeUnit,
    geographyId, appClass}) {
  const [showWarning, setShowWarning] = useState(false);

  
  function addPhase(){
    const temp = doseSequence.phases
    temp.push({
      'phase':null,
      'phase_goal':null,
      'phase_target_population':null,
      'planned_start_date': null,
      'planned_start_month':null,
      'planned_start_year':null,
      'planned_end_date':null,
      'planned_end_month':null,
      'planned_end_year':null,
      'phase_status':null,
      'delay_reason':null,
      'delayed':false,
      'actual_start_date':null,
      'actual_start_month':null,
      'actual_start_year':null,
      'actual_end_date':null,
      'actual_end_month':null,
      'actual_end_year':null,
      'info_source':null,
      'info_source_date':null,
      'info_source_detail':null,
      'comments':null,
      'user_updated':null,
      'fields_updated':null,
      'target_geographies':[],
      'has_unknown_geo': false
    })
    onChange({...doseSequence, phases:temp})
  }




  function handleDeletePhase(i){
    const temp = [...doseSequence.phases];
    temp.splice(i, 1);
    onChange({...doseSequence, phases:temp})
  }

  function handlePhaseChange(phase, i){
    const temp = [...doseSequence.phases];
    temp[i] = phase;
    onChange({...doseSequence, phases:temp});
  }



  // ---- Delete Warning Modal --- //
 // show delete warning
 const showWarningModal = () => {
  setShowWarning(true)    
}

// close delete warning
const handleClose = () => {
  setShowWarning(false)
}
// add warning icon to dynamic buttons in modal
const addWarningIcon = (name) => {
  return (
    <>
        <InfoCircleFill className='btn-icon' />{name}
    </>
  )
}

// add trash icon to dynamic buttons in modal
const addTrashIcon = (name) => {
  return (
    <>
      {name}<Trash className='btn-icon' />
    </>
  )
}

// warning modal body content
const getModalBody = () => {
  return (
    <div className='m-b-end-07'>
      <p className='body-med txt-primary'>Are you sure you want to delete this Dose Sequence and all phases within it?</p>
      <p className='body-med txt-tertiary'>This change is permanent.</p>
    </div>
  )
}


// pop up alerting the user about the delete
const deleteConfirmationModal = {
  title: addWarningIcon('Warning'),
  body: getModalBody(),
  app: 'Global Health Alchemy',
  subName: addTrashIcon('Delete')
  }


  return (
    <>
      <div className='input-text'><strong>Dose Sequence: {doseSequence.dose_sequence}</strong></div>
      <div className='toolbar-buttons display-flex justify-end'>
        <Button
          className='btn-primary m-i-end-04'
          onClick={showWarningModal}
        >Delete Dose Sequence
          <Trash className='m-i-start-02' />
        </Button>
        <Button
          className='button-primary btn-primary'
          onClick={addPhase}
        >Add Phase
          <PlusCircle className='m-i-start-02' />
        </Button>
      </div>
      {!open ?
        doseSequence.phases.map((phase, phaseIndex) => (
          <React.Fragment key={phaseIndex}>
            <PhaseEditor
              key={`${dsIndex}-${phaseIndex}`}
              phase={phase}
              onDelete={() => handleDeletePhase(phaseIndex)}
              onChange={(e) => handlePhaseChange(e, phaseIndex)}
              apiKey={apiKey}
              childGeographies={childGeographies}
              prodChars={prodChars}
              userName={userName}
              doseSequence={doseSequence}
              i={phaseIndex}
              open={open}
              tooltip={tooltip}
              getTooltipContentByKey={getTooltipContentByKey}
              gender={gender}
              minAge={minAge}
              maxAge={maxAge}
              minAgeUnit={minAgeUnit}
              maxAgeUnit={maxAgeUnit}
              geographyId={geographyId}
              errors={errors.filter(e => e.dsIndex === dsIndex && e.phaseIndex === phaseIndex).flatMap(e => e.messages)}
              appClass={appClass}
            />
          </React.Fragment>
        ))
        : null}

      <ModalDynamic
        classes={{modal:'warning-border'}}
        show={showWarning}
        content={deleteConfirmationModal}
        handleClose={handleClose}
        submitRequest={onDelete}
      />
    </>
  )
}

export default DoseSequenceEditor;