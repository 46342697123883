import React, { useState } from 'react';
import { Card, Row, Col, Image, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Pagination from 'rc-pagination';
import localeInfo from '../locale_en_US';
import FilterBarOne from './Layout/Filters/FilterBarOne';
import utils from '../utils';
import BreadCrumbBar from './Layout/Nav/BreadCrumbBar';

function FilteredPagedHorizList ({ rawList, filtersToUse, appName, numPerPage, layoutArray, showFilter=true }) {
    
    //initially, set filteredList to rawList
    let [ filteredList, setFilteredList ] = useState(rawList);
    let [ curPage, setCurPage ] = useState(1);

    const NUMBER_PER_PAGE = numPerPage || 10;

    const filters = filtersToUse || [];

    let curApp = window.location.pathname;
    curApp = curApp.split('/');
    curApp = curApp[1].toUpperCase();

    //from layout array to list info objects - className, fieldvalue, type

    const filterChanged = ( filtervalue ) => {

        //this is triggered by the filterbar, and changes the filteredList value
        if(filtervalue) {

            //first, reset to the OG List
            let newShapeOfList = [...rawList];

            //second, see what filters/sorts applied
            let hasSortBy = utils.containsAFilter(filtervalue, 'Sort By');
            

            //lastly, if sorted, sort
            if(hasSortBy) {
                newShapeOfList = utils.sortByDate(newShapeOfList, 'datepublished', utils.getValueForSortBy(filtervalue));
            }

            let hasSortByCountry = utils.containsAFilter(filtervalue, 'COUNTRY_FILTER');
            
            if(hasSortByCountry) {
                newShapeOfList = utils.filterToCountry(newShapeOfList, utils.getValueForCountry(filtervalue));
            }

            //filters by company type
            let hasSortByCompanyType = utils.containsAFilter(filtervalue, 'Company Type');

            if (hasSortByCompanyType) {
                newShapeOfList = utils.filterToCompanyType(newShapeOfList, utils.getValueforCompanyType(filtervalue));
            }

            if (utils.containsAFilter(filtervalue, 'NAME_FILTER')) {
                let newName = utils.getValueForName(filtervalue);
                if (newName && newName !== "") {
                    newShapeOfList = newShapeOfList.filter((item) => {
                        return (
                            item.fields.name ? item.fields.name.toLowerCase().trim().indexOf(newName.toLowerCase().trim())> -1
                                : false
                        );
                    })
                }
            }

            //reset our pagination
            setCurPage(1);
            
            //set new value of list
            setFilteredList(newShapeOfList);
        }
    }

    const handlePageClick = (num) => {
        setCurPage(num);
    }

    //filters - sort by (most recent, date, ...?), Country & search filter (this may be 1.2)
    return (
        <>
            <BreadCrumbBar appName={appName} />
            <h2 className='page-header'>{ appName }</h2>

            {
                showFilter === true ?
                    <FilterBarOne filters={ filters } onFilterChanged={ filterChanged } />
                : <div className='no-filters'></div>
            }
        
            <div className={`list-container ${curApp}`}>
                {
                    filteredList && filteredList.length > 0 ?
                    <>
                    { utils.getPageOfResults(filteredList, curPage, NUMBER_PER_PAGE).map ( (thisItem) => {

                        return (
                            <Row>
                                <Col>
                                    <Card className="app-list-item weird-corners">
                                        <Row className="mr-0">
                                            {
                                                layoutArray.map( (thisLayoutItem) => {
                                                    return (
                                                        <Col 
                                                            className={`${thisLayoutItem.className} gh-hub-list first-col`} 
                                                            lg={thisLayoutItem.type === 'text' ? 8 : 2}
                                                        >
                                                            { thisLayoutItem.type === 'img' ?
                                                                <Image 
                                                                    className={thisLayoutItem.meta && thisLayoutItem.meta.className ? thisLayoutItem.meta.className : ''} 
                                                                    src={thisItem.fields[thisLayoutItem.fieldName].hasOwnProperty('url') ? thisItem.fields[thisLayoutItem.fieldName].url : ''}  />
                                                                : 
                                                                
                                                                thisLayoutItem.type === 'item-link' ?
                                                                    <div className='app-list-btn'>
                                                                        <LinkContainer to={`${thisLayoutItem.path}/${thisItem.fields[thisLayoutItem.fieldName]}`}>
                                                                            <Button className="tertiary-button btn-sm-solid">{thisLayoutItem.label}</Button>
                                                                        </LinkContainer>
                                                                    </div>

                                                                
                                                                : thisItem.fields[thisLayoutItem.fieldName]
                                                            }
                                                    </Col>)
                                                })
                                            }
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        )
                    })
                }
                    <div className="text-center">
                        <Pagination 
                            total={filteredList.length}
                            onChange={ (item) => handlePageClick(item)}
                            current={curPage}
                            locale={localeInfo} />
                    </div>
                    </>
                : <div className="text-center">No Results with the Current Filter</div>
                }
            </div>
        </>
    )
}

export default FilteredPagedHorizList
