import React from 'react';
import { PyramidEmbedContainer, Filter } from '@pyramid-embed/embed-react';

function CompanyAnalysisFieldDisplay({ tabInfo, fieldsToDisplay, currentlyShown }) {


    const getOptionsForPyramid = (fieldsSrc, thisField) => {
        let retObj = { contentId: fieldsSrc[thisField.fieldName]};

        if( fieldsSrc && fieldsSrc.pyramidCompanyLabel) {
            let newFilter = Filter.create();
            newFilter.addUniqueName(`[Capability Assessment].[Company].[${fieldsSrc.pyramidCompanyLabel}]`);

            retObj.filters = newFilter;
        }

        return retObj;
    }

    return (
        currentlyShown && fieldsToDisplay ?
            tabInfo.fields.map((thisFld) => {
                return (
                    thisFld.type === 'RTF' ?
                        <p dangerouslySetInnerHTML={{ __html: fieldsToDisplay[thisFld.fieldName] }} />
                        :
                        <PyramidEmbedContainer
                            options={getOptionsForPyramid(fieldsToDisplay, thisFld)}
                            host={process.env.REACT_APP_PYRAMID_DEV_URL}
                            style={{
                                width: '100%',
                                height: '300px'
                            }} />

                )
            })
        : null
    )
}

export default CompanyAnalysisFieldDisplay