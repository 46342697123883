
const NavUtils = {
    hasAppAccess: function (tenantRecord, app) {
        let appAccess = null;
        //sometimes tenantRecord has a subobject tenant
        if(tenantRecord.tenant) {
            appAccess = tenantRecord.tenant.fields.apps;
        } else {
            appAccess = tenantRecord.fields.apps;
        }

        if(appAccess) {
            let justApp = appAccess.filter( (thisApp) => {
                return thisApp.fields.apptype.toLowerCase() === app.toLowerCase();
            });

            return justApp.length > 0;
        }

        return false;
        
    }

}

export default NavUtils;