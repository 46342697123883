import React from 'react';
import { Badge, Row, Col } from 'react-bootstrap';
import { PersonFill, Calendar } from 'react-bootstrap-icons';
import PMTCONSTANTS from '../pmtConstants';

function PMTKanbanCard(props) {

  return (
    <>
    <div id={props.id} className={props.archived === true ? "lb-'kanban'-card archived" : "lb-kanban-card"}>
      <label className='label-medium m-b-end-06 text-secondary' onClick={() => props.handleShowListItemModal(props.id)}>{props.list_name}</label>
      {
          props.archived && props.archived === true &&
          <Badge className='archived-badge badge-radius-lg label-medium m-l-start-03'>Archived</Badge>
        }
      <Row>
        <Col xs={10}>
          <h4 className='body-med m-b-end-06' onClick={() => props.handleShowListItemModal(props.id)}>
          {props.title ? props.title : ''}
          </h4>
        </Col>
        {/* TODO: Will add in check box for batch archiving later */}
        <Col xs={2} className='tool-tip'>
          <input type='checkbox' name={props.id} onClick={(event) => props.handleArchiveSelection(event)}/>
        </Col>
      </Row>
        { 
          props.description && props.description.length > 0 ?
            <p>
                { props.description }
            </p>
          : null 
        }
        {
          //In the future may need to alter for future apps
          props.customField_3 && props.customField_3.length > 0 ?
            props.customField_3.map((item, idx) => {
              if (item) {
                return <Badge className={`${PMTCONSTANTS[props.appName.fields.apptype].CUSTOMFIELD_3_MULTI_SELECT_COLORS[item]} m-i-end-03 badge-radius-lg label-medium`}>{item}</Badge>
              } else {
                return '';
              } 
            })
          : null
        }
        {
          props.customField_7 ?
            <Row className='m-b-start-06'>
              <p className='body-med'><Calendar className='m-i-end-03 m-b-end-02'/>{`${PMTCONSTANTS[props.appName.fields.apptype].CUSTOM_KEYS.customField_7}: ${props.customField_7}`}</p>
            </Row>
          : null
        }
        {
          props.assigned_to ?
            <Row className='m-b-start-06'>
              <Col xs={1}>
                <PersonFill />
              </Col>
              <Col className="person-responsible">
                <span className='body-med'>{props.assigned_to}</span>
              </Col>
            </Row>
          : null
        }
    </div>
  </>
  )
}

export default PMTKanbanCard