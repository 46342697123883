import React, { useState, useEffect } from 'react';
import { Spinner, Table, Row, Col, InputGroup, Form, Button } from 'react-bootstrap'
import ProductCapacityRow from './ProductCapacityRow';
import ModalDynamic from '../../Layout/ModalDynamic';
import { InfoCircleFill } from 'react-bootstrap-icons';

function ProductCapacityTable({capacityDetails, recipient, confidentiality, onDelete, onDeleteRecipient,
                onNew, columnYears, showHistory, onChange, disabled}) {

  const [ showLow, setShowLow ] = useState(false);
  const [ showHigh, setShowHigh ] = useState(false);
  const [ showModal, setShowModal ] = useState(false); // high/low category deletion warning
  const [ category, setCategory ] = useState();
  const [ showWarning, setShowWarning] = useState(false); // all capacity deletion warning



  // This useEffect sets the status of the toggles based on the existence of the estimate.
  useEffect(()=>{
    const setShowStatuses = (recipient, confidentiality, capacity) => {

      for(let i = 0; i < capacity.length; i++) {
        if ( capacity[i].confidentiality_type === confidentiality
          && capacity[i].capacity_type === recipient
          && capacity[i].capacity_estimate_category === 'Low') {
          setShowLow(true)
        }
        if ( capacity[i].confidentiality_type === confidentiality
          && capacity[i].capacity_type === recipient
          && capacity[i].capacity_estimate_category === 'High') {
          setShowHigh(true)
        }
      }
    }
  
    setShowStatuses(recipient, confidentiality, capacityDetails)
  }, [recipient, confidentiality, capacityDetails])

  const filterCapacityType = (type, recipient, confidentiality, capacity) => {
    for(let i = 0; i < capacity.length; i++) {
      if ( capacity[i].confidentiality_type === confidentiality
        && capacity[i].capacity_type === recipient
        && capacity[i].capacity_estimate_category === type ) {
        return i;
      }
    }
  }

  const handleClose = () => {
    if(category === 'Low') {
      setShowLow(true)
    } 
    if(category === 'High') {
      setShowHigh(true)
    }
    setShowModal(false);
    setShowWarning(false);
  }

  const handleShow = (e) => {

    const newCapObj = {
      capacities: [],
      capacity_estimate_category: e.name,
      capacity_type: recipient,
      confidentiality_type: confidentiality
    }

    if( e.name === 'Low' && showLow === true ) {
      setShowLow(false);
      setShowModal(true);
      setCategory(e.name);
    }

    if(e.name === 'High' && showHigh === true) {
      setShowHigh(false);
      setShowModal(true);
      setCategory(e.name);
    }

    if( e.name === 'Low' && showLow === false ) {
      onNew(newCapObj);
      setShowLow(true);
    }

    if(e.name === 'High' && showHigh === false) {
      capacityDetails.push(newCapObj)
      setShowHigh(true);
    }
  }
  
  const onDeleteCategory = () => {
    setShowModal(true)
    let categoryRow = filterCapacityType(category, recipient, confidentiality, capacityDetails)
    onDelete(categoryRow)
  }


  const deleteCapacityType = () =>{
    onDeleteRecipient(recipient)
  }
  
  const getModalBody = (recipient) => {
    return (
      <div className='m-b-end-07'>
        <p className='body-med txt-primary'>{`Are you sure you want to remove all capacity for ${recipient}?`}</p>
        <p className='body-med txt-tertiary'>This change is permanent.</p>
      </div>
    )
  }

  const getWarningModalBody = (recipient) => {
    return (
      <div className='m-b-end-07'>
        <p className='body-med txt-primary'>{`Are you sure you want to delete the capacity estimate category for ${category}?`}</p>
        <p className='body-med txt-tertiary'>{`You have manually entered data for the ${recipient} ${category} estimate category. Unselecting the ${category} checkbox will overwrite that data permanently.`}</p>
      </div>
    )
  }

  const addIcon = (name) => {
    return (
      <>
          <InfoCircleFill className='btn-icon' />{name}
      </>
    )
  }

  // when user deletes all capacity
  const deleteRowConfirmationModal = {
    type: recipient,
    title: addIcon('Warning'),
    body: getModalBody(recipient),
    app: 'Global Health Alchemy',
    subName: 'Yes, Confirm'
  }

  const showWarningModal = () => {
    setShowWarning(true)    
  }


  // when user un-checks high or low
  const deleteLowHighConfirmationModal = {
    type: recipient,
    title: addIcon('Warning'),
    body: getWarningModalBody(recipient),
    app: 'Global Health Alchemy',
    subName: 'Yes, Confirm'
  }


  return (
    capacityDetails ? 
      <>
        <Row>
          <Col lg={9} xl={9} className='col-left'>
            <h5>{recipient}</h5>
          </Col>
          {disabled?null:
              <Col lg={3} xl={3} className='col-right col-tenant'>
                <InputGroup className='low-check'>
                  <Form.Check 
                    label='Add Low'
                    name='Low'
                    checked={showLow}
                    value={showLow}
                    onChange= {(e) => handleShow({value: e.target.value, name: e.target.name})}
                  />
                </InputGroup>
                <InputGroup className='high-check'>
                  <Form.Check type="checkbox"
                    label="Add High"
                    name='High'
                    checked={showHigh}
                    value={showHigh}
                    onChange={(e) => handleShow({value: e.target.value, name: e.target.name})}
                  />
                </InputGroup>
                <Button size='sm' variant='danger' onClick={showWarningModal}>-</Button>
              </Col>
          }
          
        </Row>
        <Row>
          <Col>
            <Table size='sm' className='capacity-table'>
              <thead className='table-heading'>
                <tr>
                  <th></th>
                  <th>Internal Comment</th>
                  {
                    columnYears.map((item, index) => {
                      return (
                        <th key={index}>{item}</th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody className='table-body'>
                {
                  showLow === true ?
                    <ProductCapacityRow
                      show={showLow}
                      category={'Low'}
                      confidentiality={confidentiality}
                      capacityDetails={capacityDetails}
                      capacityType={recipient}
                      onChange={onChange}
                      columnYears={columnYears}
                      showHistory={showHistory}
                      disabled={disabled}
                    />
                  : null
                }
                  <ProductCapacityRow
                    show={true}
                    category={'Base'}
                    confidentiality={confidentiality}
                    capacityDetails={capacityDetails}
                    capacityType={recipient}
                    onChange={onChange}
                    columnYears={columnYears}
                    showHistory={showHistory}
                    disabled={disabled}
                  />
                {
                  showHigh === true ?
                    <ProductCapacityRow
                      show={showHigh}
                      category={'High'}
                      confidentiality={confidentiality}
                      capacityDetails={capacityDetails}
                      capacityType={recipient}
                      onChange={onChange}
                      columnYears={columnYears}
                      showHistory={showHistory}
                      disabled={disabled}
                    />
                  : null
                }
              </tbody>
            </Table>
            </Col>
        </Row>  
          <ModalDynamic
            classes={{modal:'warning-border'}}
            show={showModal}
            content={deleteLowHighConfirmationModal}
            recipient={recipient} 
            handleClose={handleClose}
            submitRequest={onDeleteCategory}
          />
          <ModalDynamic
            classes={{modal:'warning-border'}}
            show={showWarning}
            content={deleteRowConfirmationModal}
            recipient={recipient} 
            handleClose={handleClose}
            submitRequest={deleteCapacityType}
          />
    </>

      
    : <Spinner animation='border' />
  )
}

export default ProductCapacityTable;