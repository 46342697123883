import React, { useState } from 'react';
import { Button, Table, Row, Col } from 'react-bootstrap'
import EnumSelector from '../EnumSelector';
import { PlusCircle, Trash, ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import Collapse from 'react-bootstrap/Collapse';
import ToolTip from '../../Layout/ToolTip';
import NumericInput from '../NumericInput';

function CoverageEditor({ coverage, onChange, apiKey, tooltip, getTooltipContentByKey, errors }) {
  const [open, setOpen] = useState(true);

  function addCoverage() {
    const temp = [...coverage]
    temp.push({ coverage: null, coverage_type: null })
    onChange(temp)
  }

  function handleChange(value, field, i) {
    const temp = [...coverage]
    temp[i][field] = value
    onChange(temp)
  }

  function handleDelete(i) {
    const temp = [...coverage];
    temp.splice(i, 1);
    onChange(temp)
  }


  return (
    <>
      <Row className='m-b-start-10'>
        <Col>
          <div className='input-text body-large'><strong>Coverage </strong>  <ToolTip className='app-tooltip' tooltip={getTooltipContentByKey(tooltip, 'ce_coverage')} /></div>
        </Col>
      </Row>
      <Table size='sm'>
        <thead className='table-heading'>
          <tr>
            <th><strong>Coverage</strong></th>
            <th><strong>Coverage Type * </strong></th>
            <th className="align-right">
              <Button onClick={() => setOpen(!open)}
                aria-controls="collapse-table"
                aria-expanded={open}
                className='btn-sm-invisible-dark'
              >
                {open ? <ChevronUp /> : <ChevronDown />}
              </Button>
            </th>
          </tr>
        </thead>
        <Collapse in={open}>
          <tbody className='table-body'>
            {coverage ? coverage.map((c, i) => {
              return <tr>
                <td>
                  <NumericInput
                    className='textarea'
                    placeholder='##.#'
                    value={c.coverage !== null ? c.coverage : null}
                    onChange={(value) => handleChange(value, 'coverage', i)}
                    allowDecimals={true}
                    allowCommas={true}
                  />
                </td>
                <td>
                  <EnumSelector
                    enum_type='coverage_type'
                    onChange={(e) => handleChange(e.label, 'coverage_type', i)}
                    value={c.coverage_type}
                    apiKey={apiKey}
                  />
                </td>
                <td className='align-right'>
                  <Button className='cancel-btn' onClick={() => handleDelete(i)}>
                    Delete Coverage <Trash className='btn-icon' />
                  </Button>
                </td>
              </tr>
            })
              : null
            }
          </tbody>
        </Collapse>
        {open && (
          <tfoot>
            <tr>
              <td colSpan="3" className="align-right">
                <Button onClick={addCoverage}>Add Coverage <PlusCircle /></Button>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>

      {errors.map((error, index) => (
        <div key={index} className="error">{error}</div>
      ))}

    </>
  )
}

export default CoverageEditor;