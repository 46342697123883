import React from 'react'

function PyramidDataDisplay({ pyrData }) {

  return (
    <div>
        {
            pyrData.length > 0 ?
                pyrData.map( (thisRole) => {
                    return <p>{thisRole.roleName}</p>
                })
            : <p>Not In Pyramid</p>
        }
    </div>
  )
}

export default PyramidDataDisplay