import OpenAIService from '../OpenAI/OpenAIService';

import React from 'react';


function GVMMAssistant({apiKey, userName,  appClass}) {
  const description = <>
  Your dedicated digital companion for navigating the vast data modules of the Global Vaccine Market Model (GVMM) – the premier database housing essential data in Global Health. Each data point is meticulously curated by Global Health experts at Linksbridge.
  <br/><br/>
  With the GVMM Assistant, you can easily analyze, explore, and visualize critical Global Health data using natural language. Whether you're a seasoned expert or new to data analysis, no coding or database knowledge is required.  
  <br/><br/>
  You’ll have access to key modules such as Vaccine Demand, Vaccine Pricing, WAP Pricing Projections, Vaccine Supply, Product Module, Country Module, and more.
  <br/><br/>
  Feel free to ask any questions and gain valuable insights into vaccines and the global health landscape!
  </>
  return (
    <OpenAIService
      apiKey={apiKey}
      userName={userName}
      assistantId='asst_bik8vpLbD8NQ5tnqdUmTZniF'
      welcomeMessage='Introducing the GVMM Assistant!'
      appClass={appClass}
      assistantName='GVMM Assistant'
      description={description}
    />
  )
}

export default GVMMAssistant;