import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DynamicIcon from '../DynamicIcon';
import TriggerToolTip from './TriggerToolTip';

import UserUtils from '../../UserUtils';

function UserJourneyRow({ userRecords, timeSlotArray, filters={} }) {
    // gets userRecords and displays them in a reverse time line with spanStartDate as the starting point on the right
    //expects userjounrey data in the form of an reverse sorted on eventDateTime array of objects with the following structure:
    /*
        [
            {
                userPrincipalName: <string of user principal name>,
                eventDateTime: <ISO date string>,
                eventType: <string of event type> - will be 'log-in', 'app access', or 'data change',
                relevantData: <empty object if none, otherwise object with relevant data>
                    for app access: 'accessed workspace: [WORKSPACE_SLUG] app: [APP_TYPE]'
            },
            ...
        ]

        timeSlotArray is an reverse sorted by endDateTime array of objects with the following structure: 
        [
            { 
                startDateTime: <ISO date string>,
                endDateTime: <ISO date string>,
            }
        ]
    */

    const getSplitItem = (rec, which='workspace') => {
        const whichItem = {
            'workspace': 0,
            'app': 1
        }

        let mtches = rec.match(/(?<=\[).+?(?=\])/g);

        
        if(mtches && mtches.length > 1) {
            return mtches[whichItem[which.toLowerCase()]].toLowerCase();
        }

        return mtches && mtches.length > 0 ? mtches[0] : '';

    }

    const getWkspcName = (rec) => {
        if(rec.eventType === 'app access') {
            //split the workspace name
            return getSplitItem(rec.relevantData, 'workspace').toLowerCase();
        }

        if(rec.eventType === 'log-in') {
            return rec.relevantData.toLowerCase();
        }

        return '';
    }

    const eventIsWithinTimeSlot = (event, timeSlot) => {
        let eventDateTime = event.eventDateTime;
        let timeSlotStart = new Date(timeSlot.startDateTime).getTime();
        let timeSlotEnd = new Date(timeSlot.endDateTime).getTime();

        if ( eventDateTime >= timeSlotStart && eventDateTime <= timeSlotEnd) {

            return true;
        } else {
            return false;
        }
    }

    const filterLoginsWithin1DayInSameWorkspace = (timeSlottedEventArray) => {
        //take out repetitive logins within 1 day of each other in the same workspace
        let filteredRecords = [];

        for(let x=0; x<=timeSlottedEventArray.length-1; x++) {
            let curRec = timeSlottedEventArray[x];
            let curRecWkspc = getWkspcName(curRec);

            if(curRec.eventType === 'log-in') {
                let curRecTime = new Date(curRec.eventDateTime).getTime();

                //check to see if there is another login event within 1 day of this one in the same workspace
                let foundWithin1Day = false;
                for(let y=x+1; y<timeSlottedEventArray.length; y++) {
                    let nextRec = timeSlottedEventArray[y];
                    let nextRecWkspc = getWkspcName(nextRec);

                    if(nextRec.eventType === 'log-in' && curRecWkspc === nextRecWkspc) {
                        let nextRecTime = new Date(nextRec.eventDateTime).getTime();

                        if(nextRecTime - curRecTime <= 86400000) {
                            foundWithin1Day = true;
                            break;
                        }
                    }
                }

                if(!foundWithin1Day) {
                    filteredRecords.push(curRec);
                }
            } else {
                filteredRecords.push(curRec);
            }
        }

        return filteredRecords;
    }

    const hasValueMarkedTrue = (obj, whatItem) => {
        if(obj.hasOwnProperty(whatItem)) {
            return obj[whatItem] === true;
        } 

        return false;
    }

    const filterRecByForm = (rec, frm) => {

        let matches = false;

        matches = hasValueMarkedTrue(frm.recType, rec.eventType || rec.what.raw);

        if(rec.relevantData) {
            let wkspcAndAppType = UserUtils.getWkspcAndAppFromRec(rec.relevantData || rec.where.raw);
            
            if(wkspcAndAppType && wkspcAndAppType.length > 0) {
                matches = hasValueMarkedTrue(frm.workspaces, wkspcAndAppType[0]);

                //only check app matches if workspace matches
                if(matches === true) {
                    matches = hasValueMarkedTrue(frm.apps, wkspcAndAppType[1]);
                }
            }

        }

        return matches;
    }

    const buildUpTimeSlots = (timeSlotArr, userRecs, filters) => {
        //build up the time slots with the user records that fall within that time slot
        let timeSlotUserRecords = [];

        //first, go through each record
        timeSlotUserRecords = timeSlotArr.map((timeSlot) => {
            let timeSlotRecs = userRecs.filter((record) => {
                return eventIsWithinTimeSlot(record, timeSlot);
            });

            return timeSlotRecs;
        });

        timeSlotUserRecords = filterLoginsWithin1DayInSameWorkspace(timeSlotUserRecords);

        if(Object.keys(filters).length > 0) {
            //filter out records that don't match the filters
            timeSlotUserRecords = timeSlotUserRecords.map((timeSlot) => {
                return timeSlot.filter((record) => {
                    return filterRecByForm(record, filters);
                });
            });
        }

        return timeSlotUserRecords;
    }

    //in the end, we want to return a list of time slots with the user records that fall within that time slot
    let userMasterRecsByTimeSlot = buildUpTimeSlots(timeSlotArray, userRecords, filters);

    const formatUsPrincName = (ogName) => {
        let retStr = '';

        //first split #EXT# off the user name
        let splitStr = ogName.split('#EXT#');

        retStr = splitStr[0];

        //next, split the user name by the '@' symbol or '_'
        splitStr = retStr.split('@');

        if(splitStr.length > 1) {
            retStr = splitStr[0];
        } else {
            splitStr = retStr.split('_');
            retStr = splitStr[0];
        }

        //return formatted name
        return retStr;
    }

    // const getAppAccessed = (rec) => {
    //     if(rec.eventType === 'app access') {
    //         //split the workspace name
    //         return getSplitItem(rec.relevantData, 'app').toLowerCase();
    //     }

    //     return '';
    // }

    const getToolTipText = (rec) => {
        let retStr = '';

        retStr = new Date(rec.eventDateTime).toLocaleDateString() + ' ' + new Date(rec.eventDateTime).toLocaleTimeString() + ' : ';

        if(rec.eventType === 'app access') {
            retStr = retStr + `Accessed workspace: ${getSplitItem(rec.relevantData, 'workspace')} app: ${getSplitItem(rec.relevantData, 'app')}`;
        }

        if(rec.eventType === 'log-in') {
            retStr = retStr + `Logged in to ${rec.relevantData}`;
        }

        if(rec.eventType === 'data change') {
            retStr = retStr + `Data change in ${rec.relevantData}`;
        }

        return retStr

    }  

    let typeOfAccess = {
        'log-in': 'PersonFillLock',
        'app access': 'Windows',
        'data change': 'PencilFill'
    }

    return (
        <Row>
            <Col 
                xs={1} 
                style={{borderRight: '1px solid gray', borderBottom: '1px solid lightgray', fontSize: '12px', overflow: 'hidden'}} >
                    <TriggerToolTip tooltiptext={userRecords[0].userPrincipalName}>
                        {formatUsPrincName(userRecords[0].userPrincipalName)}
                    </TriggerToolTip>
            </Col>
            <Col xs={11}>
                <Row>
                    {
                        userMasterRecsByTimeSlot.map((timeSlot, index) => {
                            return (
                                <Col key={index} xs={1} style={{borderRight: '1px solid gray', borderBottom: '1px solid lightgray'}}>
                                    { 
                                        timeSlot.map((record) => {
                                            
                                            let wkspc = getWkspcName(record);
                                            
                                            return (
                                                <TriggerToolTip
                                                    tooltiptext={getToolTipText(record)}>
                                                    <DynamicIcon iconName={typeOfAccess.hasOwnProperty(record.eventType.toLowerCase()) ? typeOfAccess[record.eventType.toLowerCase()] : typeOfAccess['log-in']} className={`user-journey-icon ${wkspc}-icon`} />
                                                </TriggerToolTip> 
                                            )
                                            
                                        })
                                    }
                                </Col>)
                        })
                    }
                </Row>
            </Col>

        </Row>
    )
}

export default UserJourneyRow