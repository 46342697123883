import React from 'react';
import CountriesDropDown from './CountriesDropDown';

function CountryPicker({ countries, onPick }) {
  return (
    < CountriesDropDown 
      ctryList={countries}
      countryChangeHandler={onPick} />
  )
}

export default CountryPicker