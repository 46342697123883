import React from 'react';
import AlchemyApp from '../../components/Alchemy/AlchemyApp';
import { useHistory } from 'react-router';
import CONSTANTS from '../../constants';
import NavUtils from '../../NavUtils';

function AlchemyAppPage({tenant, preview, tooltip, getTooltipContentByKey}) {

  const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.GLOBAL_HEALTH_ALCHEMY);

    if(!hasAccess) {
        history.push('/');
    }

  return (
    <AlchemyApp preview={preview} tenantSetting={tenant} tooltip={tooltip} getTooltipContentByKey={getTooltipContentByKey} />
  )
}

export default AlchemyAppPage;
