import React from 'react';
import MarketActionsApp from '../../components/MarketActions/MarketActionsApp';
import { useHistory } from 'react-router';
import CONSTANTS from '../../constants';
import NavUtils from '../../NavUtils';

function MarketActionsAppPage({ tenant, token }) {

  const history = useHistory();
  let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.MARKET_ACTIONS);

  if(!hasAccess) {
      history.push('/');
  }

  return (
    token ?
      <div>
        <MarketActionsApp tenantSetting={ tenant } preview={ false } token={ token } />
      </div>
      : null
  )
}

export default MarketActionsAppPage;