import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import ArticleItem from '../News/ArticleItem';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';

import utils from '../../utils';
import TrackingUtils from '../../TrackingUtils';

function CEArticleDisplay({ workspace }) {

  let { articleID } = useParams();

  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState();
    
  const { accounts } = useMsal();

  //hubspot analytics
  useEffect( () => {
    utils.initializeTracking();
  }, []);

  const articleItemAdapter = (rawArticle) => {

    let retArticleShape = { ...rawArticle.fields }

    retArticleShape.smallTitle = rawArticle.fields.title.length > 45 ? rawArticle.fields.title.substring(0,40) + '...' : rawArticle.fields.title;

    return retArticleShape;
  }

  //Gets single article and saves in state
  const getArticle = async (storyID) => {

    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/cms-content?id=${articleID}`, {});

    let jsonresponse = await response.json();
    let convArticle = articleItemAdapter(jsonresponse);
    setArticle(convArticle);

    TrackingUtils.sendTrackingInfo(jsonresponse, accounts[0], 'ce-news');
}


  useEffect(() => {
    setLoading(true);
    getArticle();
    setLoading(false)
    // eslint-disable-next-line
  }, []);

  //convert to article that can work with the ArticleDisplay component

  return (
      <div className='article-display'>
        {
          !loading && article ?
          <>
                <Col>
                    <BreadCrumbBar 
                        appName="CE_NEWS" 
                        item={article} 
                        displayFieldName="smallTitle" />
                </Col>
                <ArticleItem
                    workspace={workspace}
                    article={article} />
            </>
            :
            <ListSkeletonLoader preview={false} />
        }
      </div>
  )
}

export default CEArticleDisplay;