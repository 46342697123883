import { Row, Col } from 'react-bootstrap';
import { useState } from 'react';

import '../../styles/layout/_layout.scss'
import ExcelDataImporter from './ExcelDataImporter'
import GetAgilityTemplate from './GetAgilityTemplate' 

function PriceImporter({ apiKey, contentId, importColumns, endpoint }) {
  const [xferMessage, setXferMessage] = useState()
  const [errors, setErrors] = useState([])
  const [extraContent, setExtraContent] = useState(null)
  return (
    <>
      <Row className = 'padded-row'>
        <Col>
          <GetAgilityTemplate contentId={contentId}/>{' '}
          <ExcelDataImporter
            columns = {importColumns}
            endpoint = {endpoint}
            setExtraContent={setExtraContent}
            setErrors={setErrors}
            setStatus={setXferMessage}
            apiKey={apiKey}
          />
          {extraContent}
          <div>{xferMessage}</div>
          <div>
            {errors.length > 0 ?
              <div>
                <div className="error">Errors:</div>
                {
                  errors.map((e, i) => <li key ={i} className="error">{e}</li>)
                }
              </div>
              : null
            }
            
          </div>
        
        </Col>
        
      </Row>
    </>
  )
}


export default PriceImporter;