import React from 'react';
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';

function ReactTblCellOnClickModal({myValue, handleShowIntModal, rowID}) {
  return (
    <Row className='click-row' onClick={() => handleShowIntModal(rowID)}>
    <Col lg={10}>
        <div className='truncate'>{myValue}</div> 
    </Col>
    <Col className='icon-col' lg={2}>
        {
            myValue && myValue.length > 0 ?
                <OverlayTrigger 
                    placement='top'
                    overlay={
                    <Tooltip
                        placement='top'
                        className="in" 
                        id="tooltip-top"
                    >
                        {myValue}
                    </Tooltip>
                    }
                >
                    <InfoCircleFill className='info-icon'/>
                </OverlayTrigger>
            : <InfoCircleFill className='info-icon'/>
        }
    </Col>
</Row>
  )
}

export default ReactTblCellOnClickModal