import { Button} from 'react-bootstrap';
import React, {useEffect, useState} from 'react'
import { format } from "date-fns";

import utils from '../../utils';


/*
  userName is the email of the person triggering the transfer
  apiKey is the normal read-write api key
  disabled will disable the button--preventing clicks
  endpoint is the transfer endpoint to hit. This should be a subprocess that returns immediately and runs the background.
  module is the module in the stat.status table that this button read from
  environment is the environment in the stat.status table that this button reads from
  setStatus is an OPTIONAL function from the parent component that accepts a string to inform the parent component of the current status of the transfer
  disableRefresh enables or disables the automatic 2 second re-pull from the database
*/

function TransferButton({userName, apiKey, disabled, endpoint, module, environment, setStatus=null, disableRefresh=false}){
  const [ currentStatus, setCurrentStatus] = useState();
  useEffect(()=>{
    // Hit the status endpoint every two seconds to see if anyone has trigged
    // the transfer
    async function getStatus(){
      // If a modal of similar, the parent component can disable the refresh
      if (disableRefresh) return;

      var status = await utils.getData('get_status', 
            apiKey, 
            `?module=${module}&environment=${environment}`)
      if (!status) return;
      status.trigger_date = new Date(status.trigger_date + 'Z')
      setCurrentStatus(status)
      if (setStatus){
        setStatus(status.status);
      }
      
    }
    // If the status has already been populated, then refresh it every 2 seconds
    if (currentStatus){
      let interval = setInterval(() => {
        getStatus()
      }, 2000);
      return () => clearInterval(interval);
    } else {
      // Otherwise get it immediately
      getStatus();
    }
    
    
  },[apiKey, environment, module, setStatus, disableRefresh, currentStatus])

  return (
    <>
      {currentStatus ?
        <div>
          <Button className='btn-primary bg-blue-300 m-b-start-08 m-b-end-03'
            onClick={() =>
              utils.upsert(endpoint + "?triggered_by=" + userName,
                apiKey,
                '')
            }
            disabled={disabled}
          >
            Post to {environment[0].toUpperCase() + environment.slice(1)}
          </Button>
          <div className='basic-text'>
            <strong>Last {environment[0].toUpperCase() + environment.slice(1)} Update:</strong>
          </div>
          <div className='basic-text'>
          <strong> By: </strong>{currentStatus.triggered_by}
          </div>
          <div className='basic-text'>
          <strong>On: </strong>{format(new Date(currentStatus.trigger_date), "M/d/yy h:mm a")}
          </div>
          <div className='basic-text'>
            <strong>Status: </strong>{currentStatus.status}
          </div>
          {currentStatus.status_message?
            <div className='basic-text'>
              {currentStatus.status_message}
            </div>
            : null
          }
          {
            currentStatus.completion ?
              <div className='basic-text'>Completion:{(currentStatus.completion * 100).toFixed(1)}%</div>
              : null
          }
        </div>
        : null
      }
    </>
  )
}

export default TransferButton;