import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { BlobServiceClient } from "@azure/storage-blob";

function FileListFieldDisplay({ fieldValue, folderName, onChange, serviceName, sasToken }) {

    //the fileList is an array of file urls, it returns the whole list onchange

    //TODO - if we have a different storage setup, we'll need to set this up for passing
    const SERVICE_URL = `https://${serviceName}.blob.core.windows.net/`;

    const deleteFile = (fileUrl) => {
        //go through array and remove the matching url
        let changedFieldValues = [...fieldValue];

        //get index of url in array

        let indexOfUrl = changedFieldValues.findIndex( (thisItem) => {
            return thisItem.indexOf(fileUrl) > -1;
        });

        //delete the file from the container
        const blobService = new BlobServiceClient(`${SERVICE_URL}?${sasToken}`);
          
        // get Container (folder)
        const containerClient = blobService.getContainerClient(folderName);

        //get blob name from the full url
        const myBlobNameArray = changedFieldValues[indexOfUrl].split('/');

        let myBlobName = myBlobNameArray[myBlobNameArray.length -1];

        containerClient.deleteBlob(myBlobName, {})
        .then( (res)=> {

                if(res.errorCode === undefined) {
                    //delete that index
                    changedFieldValues.splice(indexOfUrl, 1);

                    //call onChange with the new list
                    onChange(changedFieldValues);
                }
            });

    }

    const getDisplayFileName = (url) => {
        const myBlobNameArray = url.split('/');

        return myBlobNameArray[myBlobNameArray.length -1];
    }

    return (
        <>
        {
            fieldValue.length > 0 ? fieldValue.map( (thisUrl) => {
                return (
                    <Row style={{borderBottom: '1px solid darkgrey', paddingTop: '8px', paddingBottom: '8px'}}>
                        <Col xs={11}>
                            <a href={thisUrl} target='_blank' rel="noreferrer">{getDisplayFileName(thisUrl)}</a>
                        </Col>
                        <Col xs={1}>
                            <Button onClick={(e) => deleteFile(thisUrl)}><X /></Button>
                        </Col>
                    </Row>
                )
            }) 
            : null
        }
        </>
    )
}

export default FileListFieldDisplay