import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
// import moment from 'moment';
import { Link } from 'react-router-dom';

import { stripHtml } from "string-strip-html";
import TagDisplay from '../TagDisplay';
import utils from '../../utils';

function StoryTeaserLine({ story, urlprefix, showTags, noImage }) {

    const returnStoryGuts = () => {

        let fixedBody = story.fields.preview_text ? story.fields.preview_text : stripHtml(story.fields.body).result.substr(0,80) + '...';
  
        const showDate = (record) => {
     
            if (record.fields.hasOwnProperty('datepublished')) {
                return utils.getDateString(record.fields.datepublished, 'long');
            } 
        }

        return (
            <div className="story-teaser">
                <Link to={`${urlprefix ? urlprefix : 'news'}/${story.contentID}`}>
                    <h5>{story.fields.title}</h5>
                
                    <p className="smaller-body">
                    { story.fields.preview_text ? (story.fields.preview_text.slice(0,100)) + '...' : fixedBody }
                    </p>
                

                    <small>
                        { showDate(story) }
                    </small>
                </Link>
            </div>
        );
    }

    return (
        <Row className="story-line-row">
            {
                ((story.fields && story.fields.showNoImage && story.fields.showNoImage === true) || (story.body)) || noImage ?
                    null
                :
                <Col xs={3}>
                    {
                        story.fields &&  story.fields.image && story.fields.image.length > 0 ?
                            <Image src={story.fields.image} thumbnail />
                        : null
                    }
                </Col>
            }
            <Col xs={ ((story.fields && story.fields.showNoImage && story.fields.showNoImage === true) || (story.fields) || noImage) ? 12 : 9 }>
                <Link to={`${urlprefix ? urlprefix : 'news'}/${story.contentId}`}>
                {    
                    returnStoryGuts() 
                }
                </Link>
                { showTags && story.fields.tags ? 
                        <div>
                            <TagDisplay tagList={story.fields.tags} flatList={false}/>
                        </div>
                    : null
                }
            </Col>
        </Row>
    )
}

export default StoryTeaserLine
