import React, { useState, useRef, useEffect } from 'react';
import * as XLSX from "xlsx";
import { Button, Table} from 'react-bootstrap'

import utils from "../../../utils"
import '../../../styles/layout/_layout.scss'
import TableHeader from '../TableHeader';

function PriceImporter({ apiKey, refreshData, setErrors, setXferMessage, setExtraContent }) {
  // Stores the actual excel data as json
  const [xlData, setXlData] = useState(null)
  // For convenience this stores the keys for a single row.
  const [rowKeys, setRowKeys] = useState(null)
  const ref = useRef();

  // Any thoughts on this code patterns? I feel like this might be a little too clever/cute
  // and not readable enough. It's intended to house the expected excel columns, their 
  // corresponding api column, the data type, and the mandatory status.
  const columns = [
    {xlCol:'Price Id', apiCol:'temple_id', type:'number', mandatory:true},
    {xlCol:'Vial', apiCol:'vial', type:'number', mandatory:false},
    {xlCol:'Price Low', apiCol:'price_low', type:'number', mandatory:true},
    {xlCol:'Price High', apiCol:'price_high', type:'number', mandatory:false},
    {xlCol:'Year', apiCol: 'year', type:'number', mandatory:true},
    {xlCol:'Vaccine Sub-Type', apiCol:'vaccine_sub_type', type:'string', mandatory:true},
    {xlCol:'Country', apiCol: 'recipient', type:'string', mandatory:true},
    {xlCol:'Supplier', apiCol: 'supplier', type:'string', mandatory:false},
    {xlCol:'Market', apiCol:'market_segment', type:'string', mandatory:true},
    {xlCol:'Source', apiCol:'source', type:'string', mandatory:true},
    {xlCol:'Confidentiality', apiCol:'confidentiality', type:'string', mandatory:true},
    {xlCol:'Source URL', apiCol:'source_url', type:'string', mandatory:true},
    {xlCol:'Procurement Mechanism', apiCol:'procurement_mechanism', type:'string', mandatory:true},
    {xlCol:'Published Date', apiCol: 'publish_date', type:'date', mandatory:true},
    {xlCol:'Brand', apiCol:'brand', type:'string', mandatory:false},
    {xlCol:'Distributor', apiCol: 'distributor', type:'string', mandatory:false},
    {xlCol:'Presentation', apiCol:'presentation', type:'string', mandatory:false},
    {xlCol:'Pharmaceutical Form', apiCol: 'pharmaceutical_form', type:'string', mandatory:false},
    {xlCol:'Comments', apiCol:'comments', type:'string', mandatory:false},
    {xlCol:'PAHO Country of Origin', apiCol:'paho_origin', type:'string', mandatory:false},
    {xlCol:'Target Demographic', apiCol:'target_demographic', type:'string', mandatory:true},
  
  ]


  /*--------------------------State variables---------------------------*/
  function readData(e){
    setErrors([])
    setXferMessage(null)
    e.preventDefault();
    if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets["Price Upload"];
          const json = XLSX.utils.sheet_to_json(worksheet, {defval: null});

          // Convert the published date if it exists
          const formattedJson = json.map((row) => {
            if (Object.keys(row).includes('Published Date')) {
                let excelDate = row['Published Date']; // serial date
                let jsDate = new Date(Math.round((excelDate - 25568) * 864e5)); // javascript date
                let localDate = new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate()); // cut time zone from javascript date
                return {
                    ...row,
                    'Published Date': localDate
                };
            }
            return row;
        });
        
          setXlData(formattedJson)
          setRowKeys(Object.keys(json[0]))
        };
        reader.readAsArrayBuffer(e.target.files[0]);
        ref.current.value=null;
    }
  }

  function clear(){
    setXlData(null)
    setRowKeys(null)
    setErrors([])
    ref.current.value=null;
  }

  async function submit(){
    // Reset the success message and establish a variable to house errors
    setXferMessage('Checking for local errors')
    var submissionErrors = []
    setErrors([])

    // Check that all necessary keys exist in the data
    for (const mc of columns){
      if (rowKeys.includes(mc.xlCol)===false){
        submissionErrors.push("This file does not have a/an " + mc.xlCol +" column")
      }
    }
    setErrors(submissionErrors)
    if (submissionErrors.length > 0){
      return
    }

    // Check for missing or incorrect types
    // Start by establishing dictionaries to contain the error count by column
    const nullCounts = {}
    const wrongTypeCounts = {}
    var badDates = 0
    for (const col of columns){
      if (col.mandatory){
        nullCounts[col.xlCol] = 0
      }
      if (col.type === 'number'){
        wrongTypeCounts[col.xlCol] = 0
      }
    }

    // Now get the error count
    const apiData =[]
    for (const row of xlData){
      var apiRow = {}
      for (const col of columns){
        if (col.mandatory && (row[col.xlCol] === null)){
          nullCounts[col.xlCol] += 1
        } else {
          if (col.type === 'number' && 
              (row[col.xlCol] !== null && typeof(row[col.xlCol]) !== 'number')
            ){
            wrongTypeCounts[col.xlCol] += 1
          }
        }

        if (col.type === 'date') {
          let date = new Date(row[col.xlCol]);
          if (isNaN(date)) {
              badDates += 1;
          } else {
              apiRow[col.apiCol] = date.toISOString().split('T')[0];  // Ensure date remains YYYY-MM-DD
          }
        }
        else if (col.apiCol === 'temple_id') {
          apiRow[col.apiCol] = String(row[col.xlCol])
        } 
        else {
          apiRow[col.apiCol] = row[col.xlCol]; //  // Converts between the excel column name and the api column name
        }
      }  
      apiData.push(apiRow)
    }
    
    // Check if the error count is greater than 0. If so, capture the error.
    for (const col of columns){
      if (nullCounts[col.xlCol] > 0){
        submissionErrors.push("The "+col.xlCol+" column has "+nullCounts[col.xlCol]
          +" row(s) with a null value")
      }
      if (wrongTypeCounts[col.xlCol] > 0) {
        submissionErrors.push("The "+col.xlCol+" column has "+wrongTypeCounts[col.xlCol]
          +" row(s) with a non-numeric value")
      } 
    }
    if (badDates >0){
      submissionErrors.push("There is/are " + badDates + " row(s) that have a Published Date that isn't a date.")
    }
    // Now report the error(s) to the user
    setErrors(submissionErrors)
    if (submissionErrors.length > 0){
      return
    }
    setXferMessage("Attempting to insert prices")
    // Call api to submit
    console.log(apiData)
    const result = await utils.upsert("insert_prices", apiKey, apiData)
    if (result.length > 0){
      setErrors(result)
      return
    }
    // Finally successful!
    clear()
    refreshData()
    setXferMessage("Success!")
  }

  function handleInputClick(){
    ref.current.click()
  }

  useEffect(()=>{
    if(xlData === null||rowKeys===null){
      setExtraContent(null)
    } else {
      setExtraContent(
        <div >
          <div>First 5 rows:</div>
          <div className = 'scrollable'>
            <Table size = "sm">
              <thead className="table-heading">
                <tr>
                  {rowKeys.map(
                      (row) => {return <TableHeader title={row}/>}
                    )
                  }
                </tr>
              </thead>
              <tbody>
                {
                  xlData.slice(0, 5).map((row) => {
                    return <tr>
                      {rowKeys.map((key) => {
                        return <td>{
                          (key === 'Published Date') ?
                            new Date(row[key]).toLocaleDateString() :
                            row[key]
                        }</td>
                      })
                      }
                    </tr>
                  })
                }
              </tbody>
            </Table>
          </div>
          <Button onClick={submit}>Submit</Button>{' '}
          <Button onClick={clear}>Clear</Button>
          
        </div>
      )
    }
    // eslint-disable-next-line
  }, [xlData, rowKeys])


  return (
    <>
      <Button className='btn-primary bg-blue-300 m-b-start-03 m-i-end-02' onClick={handleInputClick}>Import Price</Button>
      <input ref={ref} type = "file" onChange = {readData} style={{"display":"none"}}/>
    </>
  )
}


export default PriceImporter;