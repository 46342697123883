import React, { useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import LeadershipProfileDetailsModal from './LeadershipProfileDetailsModal';
import utils from '../../utils';

function LeadershipListDisplay({ company, fieldName }) {
    
    const [modalShown, setModalShown] = useState(false);
    const [ profileToShow, setProfileToShow ] = useState(null);

    const showModal = () => {
        setModalShown(true);
    }
    
    let useFieldName = fieldName;

    if(fieldName) {
        useFieldName = fieldName;
    }

    const profileSelected = (id, fieldNameToUse) => {
        let indexOfSelected = company.fields[fieldNameToUse].findIndex( (thisItem) => {
            return thisItem.contentID === id;
        });

        if(indexOfSelected > -1){
            setProfileToShow(company.fields[fieldNameToUse][indexOfSelected]);
        }
        
    }

    let theList = company.fields[useFieldName]; 

    let companyLeaderList = Array.from(theList);

    companyLeaderList.sort( (a, b) => {
        return parseInt(a.fields.sortOrder) - parseInt(b.fields.sortOrder);
    });
    
    return (
        <Container className="leadership-container">
            <Row md={1} lg={2} xl={3} xxl={3}>
                { company && company.fields.hasOwnProperty(useFieldName) ?
                    companyLeaderList.map( (thisProfile, index) => {
                        return (
                            <>
                                <Col className='gx-2' xxl={4}>
                                    <Card 
                                        className="leader-profile-card smaller-weird-corners shadowed"
                                        onMouseDown={ (event) => profileSelected(thisProfile.contentID, useFieldName)} 
                                        onClick={ () => {showModal()}} 
                                        id={'leader-'+thisProfile.contentID} 
                                         >
                                            <Card.Img variant="top" src={thisProfile.fields.photo.url} />
                                            <Card.Body>
                                                <Card.Title>{ thisProfile.fields.name }</Card.Title>
                                                { thisProfile.fields.position ?
                                                    <Card.Subtitle dangerouslySetInnerHTML={utils.getHTML(thisProfile.fields.position)} />
                                                    : <Card.Subtitle></Card.Subtitle> }
                                            </Card.Body>
                                    </Card> 
                                </Col>
                                
                            </>
                        )
                    })
                : null }
            </Row>
            <LeadershipProfileDetailsModal 
                profile={profileToShow}
                show={modalShown}
                onHide={()=> {setModalShown(false)}}
            />
        </Container>
    )
}

export default LeadershipListDisplay
