import React, { useState, useEffect } from 'react';
import { Card, Spinner, Row, Col } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import utils from '../../utils';
import SubscribedTagsList from './SubscribedTagsList';

function NotificationSettingsPanel({tenant}) {

    const [ loading, setLoading ] = useState(false);
    const [ emailPrefs, setEmailPrefs ] = useState();
    const [ getNotifications, setGetNotifications ] = useState(true);

    const { instance, accounts } = useMsal();

    const request = {
        scopes: ["User.Read"]
    };

    //prefs should be posted as [{ type: '', for: '', target: ''}]

    const convertToPrefsList = (objArray) => {
        return objArray.map( (item) => {
            let itemArray = item.rowKey.split('::');
            return { type: itemArray[0], for: itemArray[1], target: itemArray[2]};
        });
    }

    //gets all user preferences
    const getUserPrefs = async () => {
        setLoading(true);
        let token = await utils.getAccessToken(instance, accounts[0], request);

        try {
            let userPrefResp = await utils.getUserEmailPrefs(token)
            
            await setEmailPrefs(convertToPrefsList(userPrefResp));
        } catch(error) {
            console.log("error: ", error);
        }
        
        setLoading(false);
    }


    //updates user preferences and adds new subscriptions
    const saveUserPrefs = async (emailPrefToUpdate) => {
    
        let token = await utils.getAccessToken(instance, accounts[0], request);
        try {
            await utils.saveUserEmailPrefs(emailPrefToUpdate, token)
            setGetNotifications(true)
        } catch(error) {
            console.log("error: ", error);
        }
    }

    // deletes notitification subscriptions per app or tag
    const deleteUserPrefs = async (prefToDelete) => {
        let token = await utils.getAccessToken(instance, accounts[0], request);
        try {
            let res = await utils.deleteUserEmailPrefs(prefToDelete, token)
            if (res.ok) {
                await getUserPrefs();
                setGetNotifications(true);
            }
        } catch(error) {
            console.log('error: ', error)
        }
    }
    

    // fires when user hits the unsubscribe btn deletes single subscriptions(all apps) or all subscriptions depending on what user chooses
    const saveChanges = async (allTagsSelected, listOfTags) => {

        if (allTagsSelected === false) {
            let tagsToDelete = emailPrefs.filter((item) => listOfTags.includes(item.target));
            setLoading(true);
            for (let i = 0; i < tagsToDelete.length; i ++) {
                await deleteUserPrefs(tagsToDelete[i])
            }
            setLoading(false);
            setGetNotifications(true);
        } 
        else {
            setLoading(true)
            for (let i = 0; i < emailPrefs.length; i++) {
                await deleteUserPrefs(emailPrefs[i])
            }
            setLoading(false)
            setGetNotifications(true);
            setEmailPrefs([]);
        }
    }

    useEffect( () => {
        if (getNotifications === true) {
            getUserPrefs();
            setGetNotifications(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getNotifications]);


    // is fired off when the user clicks a checkbox in the notification table/ auto updates subscriptions
    const handleEdits = async (typeOfEdit, dataForEdit, action) => {

        if (action === 'call-api') {
            if (typeOfEdit === 'ADD') {
                //is just an obj so needs array brackets to be sent to API call
                await saveUserPrefs([dataForEdit])
            }
    
            if (typeOfEdit === 'REMOVE') {
                await deleteUserPrefs(dataForEdit);
            }
        }
    }

    return (
        
            <Card className='settings'>
                <Card.Title>
                    Email Notifications
                </Card.Title>
                { !loading && emailPrefs ?
                    <>
                        <Row>
                            <Col>
                                <SubscribedTagsList 
                                    subscriptionList={emailPrefs}
                                    onListEdited={handleEdits}
                                    tenant={tenant}
                                    saveChanges={saveChanges}
                                    saveUserPrefs={saveUserPrefs}
                                />
                            </Col>
                        </Row>
                    </>
                : <><Row><Col><Spinner animation='border' /></Col></Row></> }
            </Card>
         
    )
}

export default NotificationSettingsPanel