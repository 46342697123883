import OpenAIService from '../OpenAI/OpenAIService';

import React from 'react';


function Amadya({apiKey, userName,  appClass}) {
  const description = `Amadya is here to empower you with actionable intelligence 
    and unparalleled expertise on the latest advancements, analysis of market dynamics, 
    or strategic foresight. Welcome to a new era of informed decision-making, guided by the precision and proficiency of Amadya.`
  return (
    <OpenAIService
      apiKey={apiKey}
      userName={userName}
      assistantId='asst_mkbvazi7ezYgTwTGeo2sBUSY'
      appClass={appClass}
      welcomeMessage='Welcome to Amadya'
      assistantName='Amadya'
      description={description}
    />
  )
}

export default Amadya;