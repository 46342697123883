import React, { useState, useEffect } from 'react';
import {Form} from 'react-bootstrap';

function ProductCapacityRow({confidentiality, category, capacityType, capacityDetails, 
          onChange, columnYears, disabled}) {

  const [ capacityRow, setCapacityRow ] = useState('');
  const [ indexRow, setIndexRow ] = useState();
  
  let nfObject = new Intl.NumberFormat('en-US');
    
  useEffect(() => {
    const findRowValues = (list) => {
      list.forEach((item, index) => {
        if(item.confidentiality_type === confidentiality
          && item.capacity_estimate_category === category 
          && capacityType === item.capacity_type) {
            setCapacityRow(item)
            setIndexRow(index)
          }
      })
    }
    findRowValues(capacityDetails)
  }, [capacityDetails, capacityType, category, confidentiality])

  const onChangeCommentHandler = (newChange) => {
    // updates capacityRow with new comment value
    capacityRow.comments = newChange;
    //sends updated row and index up the component change to update the product
    onChange(capacityRow, indexRow)
  }

  const onChangeCellHandler = (newChange, year) => {
    //filters through capacity to find the item with the year of the new change and removes it from the array
    var tempArry = capacityRow.capacities.filter((cellItem) => {
      return cellItem.year !== year;
    })
  
    if (newChange && newChange.toString() !== '0') {
      newChange = newChange.replaceAll(',', '');
      tempArry = tempArry.concat({estimate_value: newChange.toString(), year: year})
    }

    //adds the new change to the filtered array and updates the row
    capacityRow.capacities = [...tempArry];
    //sends updated row and index up the component change to update the product
    onChange(capacityRow, indexRow)
  }

  const findCellValue = (year) => {
    // filters through capacities and returns the item.year that matches the cell year
    let cellVal = capacityRow.capacities.filter((item) => {
      return item.year === year
    })
    // if there is a cellValue return the estimate value for cell
    if (cellVal.length > 0) {
      return cellVal[0].estimate_value;
    }
  }
  return (
    capacityRow ?
      <tr>
        <td>{category.charAt(0).toUpperCase() + category.slice(1)}</td>
        <td>
          <textarea
            className='comments'
            type='text'
            value={capacityRow.comments ? capacityRow.comments : ''}
            onChange={(e) => onChangeCommentHandler(e.target.value)}
            disabled={disabled}
          />
        </td>
        
        {
          columnYears.map((year, index) => {
            return (
              <td key={index}>
                <Form.Control                 
                  className='capacity-cells'
                  type='text'
                  min={0}
                  max={20}
                  value={findCellValue(year) ? nfObject.format(findCellValue(year)) : 0}
                  onChange={(e) => onChangeCellHandler(e.target.value, year)}
                  disabled={disabled}
                />
              </td>
            )
          })
        }
      </tr>
    : null
  )
}

export default ProductCapacityRow