import React, { useState, useEffect } from 'react';
import utils from '../../utils';

function ReactTblDatePkrEditCell({ initialValue, row, column, updateMyData }) {

    const [ myValue, setMyValue ] = useState(initialValue);
    // const [ setMarkedForChange ] = useState(false);

    const onChange = e => {
        let myNewDate;
        //convert back to unix timestamp
        if(e.target.value.length > 0){
            let myDate = e.target.value.split('-');;
            let newDate = new Date(myDate[0], myDate[1]-1, myDate[2]);
            myNewDate = newDate.getTime();
        }else{
            myNewDate = null;
        }
        setMyValue(myNewDate);
        // setMarkedForChange(true);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(row, column, myValue);
    };

    const dateFormatter = (val) => { 
        
        let newDate = new Date(parseInt(val));
        const newFormattedDate = utils.getDashDateString(utils.getDateString(newDate, 'newDate'));
        return newFormattedDate;
    }

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        //set marked for change to false
        // setMarkedForChange(false);
        setMyValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);


    return (
        <>
        {
            initialValue ?
                <input
                    type="date"
                    name={`due-date-${row}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={myValue === 'noDate' ? 'yyyy-mm-dd' : dateFormatter(myValue)}
                />
            : ''
        }
    </>
    )
}

export default ReactTblDatePkrEditCell