import React, { useState, useEffect } from 'react';
import { Row, Modal, Button, Table, Col, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import EnumSelector from '../EnumSelector';
import CONSTANTS from '../../../constants';
import utils from '../../../utils';
import ObjectSelector from '../ObjectSelector';

function DrugEditor(props) {
  const [showEditor] = useState(true);
  const [drug, setDrug] = useState();
  const [indications, setIndications] = useState();
  const [errors, setErrors] = useState();

  const closeDrugPopup = () => {
    props.closePopup(!showEditor);
  }

  function checkDrug() {
    console.log('Drug ', drug)
    console.log('Indications ', indications)
  }


  function addIndication(){
    const indications = drug.indication_phases
    indications.push({
      indication_id:null,
      indication:{
        indication_name:null,
        indication_type:null
      },
      development_phase:null
    })
    setDrug({...drug, indication_phases:indications})
  }

  async function handleSubmit (e){
    e.preventDefault();
    e.stopPropagation();

    let submissionErrors = [];
    let foundIndicationPhases = new Set();

    // No error checking in the therapeutic itself. 
    drug.indication_phases.forEach(function(ip, i){
      if (ip.development_phase===null){
        submissionErrors.push("The development phase in position "+(i+1)+" is blank")
      }
      if (ip.indication_id === null){
        submissionErrors.push("The indication in position "+(i+1)+" is blank")
      }
      if (foundIndicationPhases.has(ip.indication_id)){
        submissionErrors.push(ip.indication.indication_name+" exists more than once")
      }
      foundIndicationPhases.add(ip.indication_id)
    })
    setErrors(submissionErrors)

    if (submissionErrors && submissionErrors.length > 0)  {
      return
    }
    
    const data = drug
    
    const result = await utils.upsert('upsert_drug', props.apiKey, data)
    //sends drug to be added as default for selection in Drug objSelector
    
    if (typeof result === 'string'){
      window.alert('Error: ' + result)
    } else {
      props.closePopup()
    }
  }

  const getIndicationData = async () => {
    let indicationResult = await utils.getData('get_indications', props.apiKey)
    if (indicationResult != null){
      setIndications(indicationResult);
    }
  } 

  useEffect(()=>{
    async function getData(){
      getIndicationData()

      if (props.id === null){
        setDrug(CONSTANTS.SHELL_DRUG)
        return
      }
      const result = await utils.getData("get_drug", props.apiKey, "?drug_id="+props.id)
      if (result !== null) {
        setDrug(result)
      }else{
        alert("Data failed to load")
        setDrug(CONSTANTS.SHELL_DRUG)
      }
    }
    getData()
  // eslint-disable-next-line
  },[props.id, props.apiKey])

  return (
    <div>
      <Modal size='xl' show={showEditor} className='drug-editor'>
        <Modal.Header>
          <Modal.Title>Drug Editor</Modal.Title>
        </Modal.Header>
    {
    drug && indications ?
        <Modal.Body>
          
          <Row>
            <Col>
              <div className='input-text'>Drug Name</div>
              <input type='text'
                className='form-ctrl'
                value={drug.drug_name !== null ? drug.drug_name : ''}
                onChange={(e) => (e) = setDrug({ ...drug, drug_name: e.target.value })}
              ></input>
            </Col>
            <Col>
              <div className='input-text'>Generic Name</div>
              <input type='text'
                className='form-ctrl'
                value={drug.generic_name !== null ? drug.generic_name : ''}
                onChange={(e) => (e) = setDrug({ ...drug, generic_name: e.target.value })}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='input-text'>Global Data Drug ID</div>
              <input type='text'
                className='form-ctrl'
                value={drug.global_data_drug_id !== null ? drug.global_data_drug_id : ''}
                readOnly
             ></input>
            </Col>
            <Col>
              <div className='input-text'>Mechanism of Action</div>
              <input type='text'
                className='form-ctrl'
                value={drug.mechanism_of_action !== null ? drug.mechanism_of_action : ''}
                onChange={(e) => (e) = setDrug({ ...drug, mechanism_of_action: e.target.value })}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='input-text'>Molecule Type</div>
              <input type='text'
                className='form-ctrl'
                value={drug.molecule_type !== null ? drug.molecule_type : ''}
                onChange={(e) => (e) = setDrug({ ...drug, molecule_type: e.target.value })}
              ></input>
            </Col>
            <Col>
              <div className='input-text'>Route Of Administration</div>
              <input type='text'
                className='form-ctrl'
                value={drug.route_of_administration !== null ? drug.route_of_administration : ''}
                onChange={(e) => (e) = setDrug({ ...drug, route_of_administration: e.target.value })}
              ></input>
            </Col>
          </Row>
          
            <div><strong>Indications</strong></div>
            <Table>
              <thead className='table-heading'>
                <tr>
                  <th>Indication</th>
                  <th>Indication Type</th>
                  <th>Phase</th>
                  <th><div className='clickable-text' onClick={addIndication}>add</div></th>
                </tr>
              </thead>
              <tbody>
                {
                  drug.indication_phases.map((ip, i) => {
                      return (
                      <tr>
                        <td> 
                          <ObjectSelector
                            obj={ip.indication}
                            apiKey={props.apiKey}
                            data={indications} 
                            type={'indications'} 
                            appClass={props.appClass}
                            onSubmit={getIndicationData}
                            onChange={(e) =>{
                              const temp = drug.indication_phases
                              temp[i].indication_id = e.indication_id
                              temp[i].indication = e
                              setDrug({...drug, indication_phases: temp})
                              }
                            }
                          /> 
                        </td>
                        <td className='input-text'>{ip.indication.indication_type}</td>
                        <td>
                          <EnumSelector
                            apiKey={props.apiKey}
                            enum_type="development_phase"
                            value={drug.indication_phases[i].development_phase}
                            onChange={(e) => {
                              const temp = drug.indication_phases
                              temp[i].development_phase = e.label
                              setDrug({ ...drug, indication_phases: temp })
                            }}
                          />

                        </td>
                        <td className='right-align'>
                          <Button 
                            onClick={(e)=>{
                                const temp = [...drug.indication_phases]
                                temp.splice(i, 1)
                                setDrug({...drug, indication_phases: temp})
                              }}
                            variant='danger'
                            size='sm'
                          >-</Button></td>
                      </tr>
                      )
                  })
                }
              </tbody>
            </Table>
          {errors && errors.length > 0 ? 
            <div>
              <div className="error">Errors:</div>
              {
                errors.map((e) => <li className="error">{e}</li>)
              }
            </div>
            : null} 
            <ButtonToolbar className='editor-btn'>
              <ButtonGroup>
                <Button onClick={closeDrugPopup} variant="secondary" className='button-secondary m-i-end-03'>Close</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick={(e) => handleSubmit(e)} className='button-primary m-i-end-03'>Submit</Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button className='check-drug-btn' onClick={(e) => checkDrug(e)}>Check Drug</Button>
              </ButtonGroup>
            </ButtonToolbar>
        </Modal.Body>
     : null
    } 
      </Modal>
  </div>
  )
}

export default DrugEditor