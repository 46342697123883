import React, { useState } from 'react';
import { Table, Row, Col, Button, InputGroup, Form } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import utils from '../utils';

function SortFilterPageTable({ config, data, className, updateUsers, checked, loading }) {
    //uses it's own state as data coming in is the whole data, that is then filtered within this
    const [ filterValue, setFilterValue ] = useState();

    const exportCurrentUsers = () => {
        utils.exportExcel(data, 'UserExport');
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if ( e.code === "Backspace") {
            let newVal = filterValue.slice(0, -1);
            setFilterValue(newVal)
        } else {   
            setFilterValue(e.target.value)
        }
    }

    return (
        <>
        {
            className === "show-toolbar" ?
                <Row className='tool-bar'>
                    <Col>
                        <InputGroup>
                            <InputGroup.Text id="search-name"><Search /></InputGroup.Text>
                            <Form.Control 
                                placeholder="Search by User  / Email"
                                onKeyUp={(e) => handleSearch(e)}
                            />
                        </InputGroup>
                    </Col>
                    <Col className='more-act-col'>
                        <Col>
                            <Button variant="primary" onClick={() => exportCurrentUsers()}>Export Users Shown</Button>
                        </Col>
                        <Col>
                            <Button variant="light" onClick={(e) => updateUsers(e, 'delete-user')} disabled={checked === false ? true : false}>{loading ? 'Removing User...':'Remove User'}</Button>
                        </Col>
                    </Col>
                </Row>
            : null
        }
            <Row>
                <Col>
                    <Table 
                        responsive className={className ? className : ''} >

                        <thead>
                            <tr>
                            {
                                config.columns.map( (thisCol, idx) => {
                                    return (
                                        <th key={thisCol.key ? thisCol.key : `col-${idx}`}>
                                            {
                                                thisCol.title
                                            }
                                        </th>
                                    )    
                                })
                            }   
                            </tr>   
                        </thead>

                        <tbody>
                            {

                                data.filter((user) => {
                            
                                    return filterValue && filterValue.length > 0 ? 
                                        user.displayName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
                                           user.mail.toLowerCase().indexOf(filterValue.toLowerCase()) > -1  
                                        : true
                                 }).map( (thisRow, idx) => {
                                    return (
                                        <tr key={idx}>
                                            {
                                                config.columns.map( (thisCol, index) => {
                                                    return (
                                                        <td key={thisCol.key ? thisCol.key+'-'+idx : `row-${idx}-col-${index}`}>
                                                            {
                                                                thisCol.type === 'SIMPLE' ? thisRow[thisCol.accessor] : thisCol.render(thisRow)
                                                            }
                                                        </td>
                                                    )    
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}

export default SortFilterPageTable