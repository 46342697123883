import React from 'react';
import { Button } from 'react-bootstrap'
import NumericInput from '../NumericInput';


function PriceDetailEditor(props) {

  return (
    <tr>
      <td>
        {props.price_detail.temple_id}
      </td>
      <td>
        <NumericInput
          className='form-ctrl'
          value={props.price_detail.year}
          onChange={(newValue) => {
            props.onChange({ ...props.price_detail, year: newValue }); 
          }}
          allowDecimals={false} 
          allowCommas={false}   
          placeholder=''
          disabled={false} 
        />
      </td>
      <td>
        <input
          className='form-ctrl'
          value={props.price_detail.publish_date}
          type='date'
          onChange={(e)=>props.onChange({...props.price_detail, publish_date:e.target.value})}
        />
      </td>
      <td>
        <NumericInput
          className="form-ctrl"
          placeholder=""
          value={props.price_detail.price_low}
          onChange={(e)=> props.onChange({...props.price_detail, price_low: e ? e : null})}
          allowDecimals={true}
          allowCommas={true}
        />
      </td>
      <td>
         <NumericInput
          className="form-ctrl"
          placeholder=""
          value={props.price_detail.price_high}
          onChange={(e)=> props.onChange({...props.price_detail, price_high: e ? e : null})}
          allowDecimals={true}
          allowCommas={true}
        />
      </td>
      <td>
        <textarea
          className='full-width'
          value={props.price_detail.comments}
          onChange={(e)=>props.onChange({...props.price_detail, comments:e.target.value})}
        />
      </td>
      
      <td>
        <textarea
          className='full-width'
          value={props.price_detail.source_url}
          onChange={(e)=>props.onChange({...props.price_detail, source_url:e.target.value})}
        />
      </td>
      
      <td className='right-align'>
        <Button className='btn-primary' variant='danger' onClick={props.onDelete} size='sm'>-</Button>
      </td>
    </tr>
  )
}

export default PriceDetailEditor;