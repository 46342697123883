import React, { useState, useEffect } from 'react'
import SelectFilter from './SelectFilter'
import { Col, Container, Row, Button } from 'react-bootstrap'
import CONSTANTS from '../../../constants';
import CountryFilter from './CountryFilter';
import SearchFilter from './SearchFilter';
import { X } from 'react-bootstrap-icons';



function FilterBarAgility({ data, metaData, rowsPerPage=10, myFilterListChanged }) {
  // Stores the filters. Example data structure {'form': 'Liquid', 'product_name': ['Prevnar', 'Prevenar]}
  const [filters, setFilters] = useState({})
  //TODO: add functionality to hold filters in state
    // const [filters, setFilters] = useState(initialFilterState ? initialFilterState : {})
  // Holds the data after filtering
  const [filteredData, setFilteredData] = useState([])
  let [ clearFilter, setClearFilter ] = useState(false);

  // For filtering data
  useEffect(()=>{
   
      if (!data || data.length===0){
        return []
      }
      //creates copy of data array
      var temp = [...data]
      //turns filters into an array of strings['companyType', 'country']
      const filterKeys = Object.keys(filters) 

      //loops through array of filters
      for (let i = 0; i<filterKeys.length; i++ ){
        let curField = filterKeys[i]
        let curFilter = filters[curField]
  
        if(curFilter && (curFilter===null || curFilter.length===0)){
          continue
        }
        let filterValue;
        //if curFilter is an array map through and set each value to filterValue
        if (Array.isArray(curFilter)){
          filterValue = curFilter.map((elt)=>{return elt.value})
          //otherwise set filterValue to curFilter.value
        } else {
          filterValue = curFilter && curFilter.value ? curFilter.value : ''
        }
  
        //if filterISArray = true it sets each filter to its own variable
        const filterIsArray = Array.isArray(filterValue)
        const dataIsArray = curField && temp && temp[0] ? Array.isArray(temp[0][curField]) : null;
        let curFilter1=filterIsArray?filterValue[0]:null
        let curFilter2=filterIsArray&&filterValue.length>1?filterValue[1]:null
        let curFilter3=filterIsArray&&filterValue.length>2?filterValue[2]:null
        let curFilter4=filterIsArray&&filterValue.length>3?filterValue[3]:null
        let curFilter5=filterIsArray&&filterValue.length>4?filterValue[4]:null
        
        /*
        Case 1: Filter and data are strings or null
        Case 2: Filter is a list, data is string or null
        Case 3: Filter is string or null, data is a list
        Case 4: Filter is a list, data is a list
        */
        switch(true){
          case !filterIsArray&&!dataIsArray:
            temp = temp.filter(
              d=>(
                d.fields[curField].toLowerCase().includes(filterValue.toLowerCase())
              ))
            break
          case !filterIsArray&&dataIsArray:
            temp = temp.filter(
              d=>(
                d.fields[curField].includes(filterValue)
              ))
            break
          case filterIsArray&& !dataIsArray:
            temp=temp.filter(
              d=>(
                d.fields[curField]===curFilter1||
                (d.fields[curField]===curFilter2&&curFilter2)||
                (d.fields[curField]===curFilter3&&curFilter3)||
                (d.fields[curField]===curFilter4&&curFilter4)||
                (d.fields[curField]===curFilter5&&curFilter5)
              )
            )
            break
          case filterIsArray&&dataIsArray:
            temp=temp.filter(
              d=>(
                d.fields[curField].includes(curFilter1)||
                (d.fields[curField].includes(curFilter2)&&curFilter2)||
                (d.fields[curField].includes(curFilter3)&&curFilter3)||
                (d.fields[curField].includes(curFilter4)&&curFilter4)||
                (d.fields[curField].includes(curFilter5)&&curFilter5)
              )
            )
            break
          default:
            console.log("Unknown option, please review")
        }
      }
      // Handle page number updates after filtered data changes
      setFilteredData(temp)

  }, [filters, rowsPerPage, data, myFilterListChanged])

  //filter handler takes a filter and field 
  function handleFilterChange(field, filter){
    //resets filters and send 'clear-filters' up level to reset data
    if ( field === 'clear-filters' ) {
      setClearFilter(true);
      setFilters({});
      myFilterListChanged('clear-filters')
    } else {
      setClearFilter(false);
      //makes a copy of the filter obj
      const tempFilters = {...filters}
      //finds the field in the filter dictionary and replaces its value with the new change
      tempFilters[field]=filter
      //sets filters in state
      setFilters(tempFilters)
    }
  }
  
  //Finds correct label name to display on the UI
  const findFilterTitle = (thisFilterDropdown) => {
    if (thisFilterDropdown.displayField.toUpperCase() === 'SORT_BY_RELEVANCE') {
        return CONSTANTS.FILTER_TITLES.SORT_BY_RECENT;
    } else {
        return CONSTANTS.FILTER_TITLES[thisFilterDropdown.displayField.toUpperCase()];
    }
  }

  return (
    <Container className="filter-bar">
      <Row>
          {
            //Loops through a list of app specific filters to display
              metaData.map((thisFilterDropdown, index) => {
                  return (
                    <Row key={index} className="filter-box">
                      <span className="filter-select-title">
                           {findFilterTitle(thisFilterDropdown)}
                       </span>
                       {
                          CONSTANTS.FILTER_TYPES[thisFilterDropdown.displayField.toUpperCase()] === CONSTANTS.FILTER_TYPES.COUNTRY_FILTER ?
                              <CountryFilter 
                                selectHandler={handleFilterChange}
                                //send a boolean to decipher if a default value exists and should display
                                clearFilter={clearFilter}
                                //JSON field to look for to filter data
                                field={thisFilterDropdown.filterField}
                              />
                              :
                              CONSTANTS.FILTER_TYPES[thisFilterDropdown.displayField.toUpperCase()] === CONSTANTS.FILTER_TYPES.NAME_FILTER ?
                                  <SearchFilter 
                                    selectCompanyHandler={handleFilterChange} 
                                    //JSON field to look for to filter data
                                    field={thisFilterDropdown.filterField}
                                    //send a boolean to decipher if a default value exists and should display
                                    clearFilter={clearFilter}
                                  />
                                  :
                                      <SelectFilter 
                                        curFilters={filters}
                                        filteredData={filteredData} 
                                        options={CONSTANTS.FILTER_LISTS[thisFilterDropdown.displayField.toUpperCase()]}
                                        //JSON field to look for to filter data
                                        field={thisFilterDropdown.filterField}
                                        isMulti={thisFilterDropdown.isMulti ? thisFilterDropdown.isMulti : null} 
                                        onChange={(e)=>handleFilterChange(thisFilterDropdown.filterField, e)}
                                      />
                       }
                    </Row>
                )
              })
          }
      </Row>
      <Row className="agility-filter-btn filter-btn">
          <Col className='clear-filter' > 
            <Button className='btn-sm-invisible-100 secondary-link' onClick={() =>  handleFilterChange('clear-filters')}>
              <span><X/></span> 
              Clear Filters
            </Button>
          </Col>
          <Col>
            <Button className='btn-primary side-col-btn tertiary-button' onClick={() => myFilterListChanged(filteredData)}>Apply Filters</Button>
          </Col>
        </Row>
    </Container>

  )
}

export default FilterBarAgility;