import React from 'react';
import VaccineMarketsListPage from '../../pages/vaccineMarkets/VaccineMarketsListPage';
import vaccineMarketsPreviewImage from '../../assets/vaccine-markets-cover.png';
import { Image, Spinner } from 'react-bootstrap';


function VaccineMarketsApp({ tenantSetting, preview = false, tenantApp}) {

  return (
    tenantSetting ?
      !preview ?
        <div className='market-analysis'>
          <VaccineMarketsListPage tenant={tenantSetting} />
        </div>
        :
        <div className='preview-img-div'>
          <Image className='preview-card-img' src={tenantApp.fields.previewImage && tenantApp.fields.previewImage.url ? tenantApp.fields.previewImage.url : vaccineMarketsPreviewImage} />
        </div>
      : <Spinner animation='border' />
  )
}

export default VaccineMarketsApp;