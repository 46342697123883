import React from 'react';
import { VectorMap } from "react-jvectormap";

function WorldVectorMap({ options={}, regionClickHandler, highlightedCountries }) {

    //this component shows a world map 

    const handleClick =(e, cntryCode) => {
        regionClickHandler(cntryCode);
    }

    let containerStyle = options.containerStyle ? options.containerStyle : {
        width: "100%",
        height: "520px"
    };

    let fillAndStrokes = options.fillAndStrokes ? options.fillAndStrokes : {
        fill: "#e4e4e4",
        "fill-opacity": 0.9,
        stroke: "none",
        "stroke-width": 0,
        "stroke-opacity": 0
    };

    let hoverStyles = options.hoverStyles ? options.hoverStyles : {
        "fill-opacity": 0.8,
        cursor: "pointer"
    };

    let regionsSelectable = options.regionsSelectable ? options.regionsSelectable : false;

    let zoomOnScroll = options.zoomOnScroll ? options.zoomOnScroll : false;

    return (
        <VectorMap
            map={"world_mill"}
            backgroundColor="transparent" 
            zoomOnScroll={zoomOnScroll}
            containerStyle={containerStyle}
            onRegionClick={handleClick} //gets the country code
            containerClassName="map"
            regionStyle={{
                initial: fillAndStrokes,
                hover: hoverStyles,
                selectedHover: {}
            }}
            regionsSelectable={regionsSelectable}
            series={{
                regions: [
                    {
                        values: highlightedCountries, //this is your data
                        scale: ["#e17060", "#ff0000"], //your color game's here
                        normalizeFunction: "polynomial"
                    }
                ]
            }}
        />
    )
}

export default WorldVectorMap