import React, {useMemo} from 'react';
import { Row, Col } from 'react-bootstrap';
import CountryMap from './CountryMap';

import utils from '../../utils';

function CountryProfilePanel({ ISO, countryInfo, contentMaster }) {

    const specFormat = {
        last_known_gni: "number",
        total_population : "number"
    }

    let countryProfileLayout = useMemo(() => {
        return utils.formatForInfo(contentMaster.fields.countryProfileLayout, countryInfo, specFormat);
    
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [ISO]);

    return (
        <>
            <Row>
                <Col>
                    <div dangerouslySetInnerHTML={{ __html: countryProfileLayout }} />
                </Col>
                <Col>
                    <CountryMap 
                        country={countryInfo} />
                </Col>
            </Row>
        </>
        
    )
}

export default CountryProfilePanel