const checkForCharacteristicErrors = (characteristics) => {
  // Check charactersitics errors
  var pc
  const foundPcs = new Set()
  const characteristicErrors = []
  for (let i = 0; i<characteristics.length; i++){
    pc = characteristics[i]
    if (pc.container_quantity < 0){
      characteristicErrors.push("Product characteristic "+(i+1) + " must have 0 or more doses")
    }
    if (foundPcs.has(pc.container_type + pc.container_quantity)){
      characteristicErrors.push("Product characteristic " + (i+1)+ " already exists")
    }
    foundPcs.add(pc.container_type+pc.container_quantity)
  }

  
  return characteristicErrors
}

export default checkForCharacteristicErrors;