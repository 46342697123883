
const UserUtils = {
    updateUsers: async (changedUsers, tenID, token) => {
        let addedParams = `tenid=${tenID}`;
        
        try {
            let resp = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/elevate-users/${tenID}?${addedParams}`, {
                headers: {
                    "Authorization": "Bearer " + token.accessToken ? token.accessToken : token
                },
                method: 'POST',
                body: JSON.stringify(changedUsers)
            });

            return { status: 'success', data: resp };
        } catch (error) {
            console.log(error);
            return { status: 'error', data: error };
        }
    },

    deleteUsers: async (usersToDelete, tenID, token) => {
        let addedParams = `tenid=${tenID}`;
        let report = [];
        
        for(let user in usersToDelete) {
            let gotID = user.id ? user.id : user.user_id ? user.user_id : user;

            try {
                let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/current-user/${gotID}?${addedParams}`, {
                    headers: {
                        "Authorization": "Bearer " + token.accessToken ? token.accessToken : token
                    },
                    method: 'DELETE',
                })
                
                report.push({ status: 'success', data: { userID: gotID, response: response }});
            } catch (error) {
                console.log(error);
                report.push({ status: 'fail', data: { userID: gotID, response: error }});
            }
        }

        return report;
    },

    requestWorkspaceForUsers: async (usersToRequestFor, wkspcID, tenID, token) => {
        let addedParams = `tenid=${tenID}`;
        let report = [];

        for(let user in usersToRequestFor) {
            let gotID = user.id ? user.id : user.user_id ? user.user_id : user;

            try {
                let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/request-access/${wkspcID}?${addedParams}`, {
                    headers: {
                    "Authorization": "Bearer " + token.accessToken
                    },
                    method: 'POST',
                    body: JSON.stringify(gotID)
                });
                
                report.push({ status: 'success', data: { userID: gotID, response: response }});

            } catch (error) {
                console.log(error);
                report.push({ status: 'fail', data: { userID: gotID, response: error }});
            }
        }

        return report;
    },

    suAddToWorkspaceForUsers: async (usersToRequestFor, wkspcID, tenID, token) => {
        let addedParams = `tenid=${tenID}`;
        let report = [];

        for(let thisUser=0; thisUser < usersToRequestFor.length; thisUser++) {
            let gotID = usersToRequestFor[thisUser].user_id;

            console.log('gotID: ', gotID);

            try {
                let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/super-add-wkspc/${wkspcID}?${addedParams}`, {
                    headers: {
                    "Authorization": "Bearer " + token.accessToken
                    },
                    method: 'POST',
                    body: JSON.stringify({id: gotID})
                });
                
                report.push({ status: 'success', data: { userID: gotID, response: response }});

            } catch (error) {
                console.log(error);
                report.push({ status: 'fail', data: { userID: gotID, response: error }});
            }
        }

        return report;
    },

    getWkspcAndAppFromRec: (recStr) => {
        //receives a string with 'accessed workspace: [wkspc] app: [app_type]'
        //returns an array: [wkspc, app_type] or null if no matches
        let items = recStr.match(/(?<=\[).+?(?=\])/g);

        return items;
    }
}

export default UserUtils