import React from 'react';
import IntelligenceApp from '../../components/Intelligence/IntelligenceApp';
import { useHistory } from 'react-router';
import CONSTANTS from '../../constants';
import NavUtils from '../../NavUtils';

function IntelligenceAppPage({ tenant, token }) {

    const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.INTELLIGENCE);

    if(!hasAccess) {
        history.push('/');
    }

    return (
        token ?
            <div className="full-intelligence-app">
                <IntelligenceApp tenantSetting={ tenant } preview={ false } />
            </div>
        : null
    )
}

export default IntelligenceAppPage;