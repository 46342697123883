import React from 'react';
import PBIEmbeddedViz from '../../PBIEmbeddedViz';

// import { models } from "powerbi-client";


function MarketActionsSummaryView({appName, reportID, datasetID}) {

    return (
        <div>
            <PBIEmbeddedViz 
                // pageSizeType={models.PageSizeType.Widescreen}
                // displayOption={models.DisplayOption.FitToPage}
                // zoomLevel={1.2}
                reportID={reportID}
                datasetID={datasetID}
                initWidth={'100%'}
                initHeight={'750'}
            />
        </div>
    )
}

export default MarketActionsSummaryView;