import React from 'react';
import '../../../styles/layout/_layout.scss'

import ObjectSelector from '../ObjectSelector'
import EnumSelector from '../EnumSelector'
import { Row, Col } from 'react-bootstrap';

function ProductEditorAdjuvant({ product, setProduct, apiKey, organizations, disableProductEdits }) {
  /*----------------------State Variables-----------------------------------*/

  function setAdjuvant(field, value) {
    let adjuvant = product.adjuvant
    if (!adjuvant) {
        adjuvant = {
          product_id: product?.product_id,
          adjuvant_name: null,
          adjuvant_class: null,
          org_id: null,
          organization: null,
          source: null
      }
    }

    switch (field) {
      case 'name':
        adjuvant.adjuvant_name = value === '' ? null : value
        break
      case 'class':
        adjuvant.adjuvant_class = value === '' ? null : value
        break
      case 'source':
        adjuvant.source = value === '' ? null : value
        break
      case 'org':
        if (value === null) {
          adjuvant.org_id = null
          adjuvant.organization = null
        } else {
          adjuvant.org_id = value.org_id
          adjuvant.organization = value
        }
        break
      default:
        break
    }

    setProduct({
      ...product,
      adjuvant: adjuvant
    })
  }

  return (
    <div>
      <Row>
        <Col>
          <div className='input-text'>Adjuvant Name</div>
          <input
            disabled={disableProductEdits}
            className='form-ctrl'
            value={product.adjuvant?.adjuvant_name}
            onChange={(e) => setAdjuvant('name', e.target.value)}
          />
          <div className='input-text'>Adjuvant Class</div>
          <EnumSelector
            disabled={disableProductEdits}
            enum_type='adjuvant_class'
            onChange={(e) => setAdjuvant('class', e.label)}
            value={product.adjuvant?.adjuvant_class}
            apiKey={apiKey}
            isClearable={true}
          />
        </Col>
        <Col>
          <div className='input-text'>Organization</div>
          <ObjectSelector
            apiKey={apiKey}
            data={organizations}
            onChange={(e) => setAdjuvant('org', e)}
            isClearable={true}
            type='organization'
            obj={product.adjuvant?.organization}
            disabled={disableProductEdits}
          />
          <div className='input-text'>Source</div>
          <input
            disabled={disableProductEdits}
            className='form-ctrl'
            value={product.adjuvant?.source}
            onChange={(e) => {setAdjuvant('source', e.target.value)}}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ProductEditorAdjuvant