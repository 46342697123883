import React from 'react';
import * as Icons from 'react-bootstrap-icons';

function DynamicIcon({iconName, className}) {

    const {[ iconName ]: Icon } = Icons;
  
    return (
        <Icon className={className}></Icon>
    )
}

export default DynamicIcon