import React, { useEffect, useState, useCallback } from 'react';

const NumericInput = ({ value, onChange, allowDecimals=true, allowCommas=false, className=null, placeholder=null, disabled=false}) => {
  
  // State variable to manage the display value
  const [displayValue, setDisplayValue] = useState('');

  // Function to format the value with commas if allowCommas is true
  const formatValue = useCallback((value) => {
    if (allowCommas && value !== null && value !== undefined && value !== '') {
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    }
    return value;
  }, [allowCommas]);

  // Update the display value whenever the actual value changes
  useEffect(() => {
    setDisplayValue(formatValue(value));
  }, [value, formatValue]);

  // Handle input changes, sanitize the input, and update the state
  const handleChange = (event) => {
    let inputValue = event.target.value;

    // Remove all non-numeric characters except the decimal point
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    // Remove extra decimal points if decimals are allowed
    if (allowDecimals) {
      const parts = inputValue.split('.');
      if (parts.length > 2) {
        inputValue = parts[0] + '.' + parts.slice(1).join('');
      }
    } else {
      inputValue = inputValue.split('.')[0]; // Remove decimal part if decimals are not allowed
    }

    const rawValue = allowCommas ? inputValue.replace(/,/g, '') : inputValue;
    const numericValue = rawValue !== '' ? parseFloat(rawValue) : null;

    setDisplayValue(formatValue(rawValue));
    if (onChange) {
      onChange(numericValue);
    }
  };

  return (
    <div>      
      {/* Input element for numeric input - type must be text to allow custom formatting (commas)*/}
      <input
        type="text"
        value={displayValue}
        onChange={handleChange}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

export default NumericInput;
