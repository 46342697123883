import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Badge, Spinner, Button } from 'react-bootstrap';
import BtnDynamic from '../Layout/BtnDynamic';
import {XLg} from 'react-bootstrap-icons';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import DropdownDynamicWithSearch from '../Layout/DropdownDynamicWithSearch';
import MultiSelectDynamic from '../Layout/MultiSelectDynamic';

function InterventionViewModal({ show, loading = false, tenant, form, showArchive, marketID = null, handleArchived, toggleArchived, handleClose, intervention = null, onSubmit, errors, onChange, assigneesToPass, vaccinesToPass, suppliersToPass, interventionID, inputLoading, submitInterventionComment, updateInterventionComment, clearCommentInput, comments, posting = false, mode }) {

    const [showModal, setShowModal] = useState(true)

    const statusesToPass = [
        'Not started',
        'In progress',
        'Delayed',
        'Deprioritized',
        'Complete'
    ]

    const dueDateTypesToPass = [
        'Ongoing',
        'Annual',
        'N/A',
        'Fixed date'
    ]

    const formatAssignees = (rawList) => {
        return rawList.map( (thisItem) => {
            return thisItem.mail;
        })
    }

    useEffect(() => {
        const dialog = document.querySelector("dialog");
            if (show === true) {
                dialog.showModal();
            } 
            if (show === false) {
                dialog.close();
            }
            if (showModal === false) {
                dialog.close();
            }
            dialog.addEventListener('click', function(event) {
                let rect = dialog.getBoundingClientRect();
                let isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
                if (!isInDialog) {
                    dialog.close();
                    handleClose();
                }
            })
        },[show, showModal, handleClose])

    //content for dynamic button
    const archiveBtnContent = {
        name: intervention && intervention.archived === true ? 'Un-archive intervention' : 'Archive intervention'
    }

    //returns value for customfields
    const getCustomFieldName = (customFields, fieldName) => {
        if (customFields) {
            let fieldValueArr = customFields.filter((item) => {

                return item.name.toLowerCase() === fieldName.toLowerCase();
            });

            if (fieldValueArr.length > 0) {
                if (fieldValueArr[0].hasOwnProperty('value')) {
                    return fieldValueArr[0].value;
                }
            }
        }

    }

    const dateFormatter = (val) => {
        let newDate = new Date(parseInt(val));
        const newFormattedDate = utils.getDashDateString(utils.getDateString(newDate, 'newDate'));
        return newFormattedDate;
    }

    const convertDateHandler = (dateName, dateValue) => {

        //eslint-disable-next-line
        let newDate = dateValue.replace(/-/g, '\/');
        newDate = new Date(newDate).getTime();

        onChange(dateName, newDate);
    }

    const convertToDate = (date) => {
        const newDate = new Date(JSON.parse(date));
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const timeOfDay = newDate.getHours() >= 12 ? 'PM' : 'AM';

        const month = months[newDate.getMonth()];
        const day = newDate.getDate();
        const year = newDate.getFullYear();
        let hours = newDate.getHours() % 12;
        hours = hours ? hours : 12;
        const minutes = newDate.getMinutes();

        const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${timeOfDay}`;
        return formattedDate;
    }

    //returns value for status dropdown
    const getStatusValue = (intervention = null, placeHolder) => {
        if (intervention) {
            let found = CONSTANTS.MARKET_ACTIONS_STATUS_SELECT.filter(
                (option) => {
                    return intervention && intervention.status && intervention.status.status && option.label.toLowerCase() === intervention.status.status
                }
            );

            if (found.length > 0) {
                return found[0].label;
            }
        }

        return placeHolder;
    }

    //finds value to display on dropdowns
    const findInitialValue = (selected, int, field) => {
        let retVal;
        if (selected) {
            retVal = selected;
        }
        else if (int && int.custom_fields) {
            retVal = getCustomFieldName(intervention.custom_fields, field)
        }
        return retVal
    }

    //Converts dropdown values to the expexted backend value and send to set in form
    const switchToValue = (field, val) => {
        switch (field) {
            case 'dueDateType':
                onChange(field, CONSTANTS.MARKET_ACTIONS_DUE_DATE_TYPE_VAL_OBJ[val]);
                break;
            case 'priority':
                onChange(field, CONSTANTS.MARKET_ACTIONS_PRIORITY[val]);
                break;
            case 'accuracy':
                onChange(field, CONSTANTS.MARKET_ACTIONS_ACCURACY_VAL_OBJ[val]);
                break;
            case 'hmfCategory':
                onChange(field, val.map((item) => CONSTANTS.MARKET_ACTIONS_HMF_CATEGORY_VALUE_OBJ[item]));
                break;
            case 'priorInnovation':
                onChange(field, CONSTANTS.MARKET_ACTIONS_PRIOR_INNOVATION[val]);
                break;
            default:
                break;
        }
    }

    const handleShowArchived = () => {
        setShowModal(!showModal)
        handleArchived();
    }
    
    const getInitialLabelVals = (hmfArray) => {
        let retArr = [];
        
        if (hmfArray && hmfArray.length > 1) {
            hmfArray.forEach((category) => {
                retArr.push(CONSTANTS.MARKET_ACTIONS_REVERSE_HMF_CATEGORY[category])
            })
            return retArr;
        }
    }

    return (
        <div className='modal-alignment'>
            <dialog id='int-modal' className='basic-dialog-styles' >
                    {
                    !loading && (!interventionID || (intervention && intervention.custom_fields)) ?
                        <Form onSubmit={(e) => onSubmit(e)} className='max-width-100'>
                            <>
                                <Row>
                                    <Col lg={11} className='p-i-end'>
                                        <h4 className='m-b-end-03'>Intervention Title{intervention && intervention.archived && intervention.archived === true ? <Badge className='descriptor-badge m-i-start-04 vertical-align'>{'Archived'}</Badge> : ''}</h4>
                                        <Form.Group className="enter-task-name " controlId="taskName">
                                            <input type="hidden" name="listID" value={marketID} />
                                            <Form.Control
                                                className={intervention && intervention.archived === true ? 'semibold input-underline m-b-end-08 overflow-hidden' : 'input-underline m-b-end-08 body-med overflow-hidden'}
                                                size="lg"
                                                type="text"
                                                as="textarea"
                                                rows={2}
                                                name="taskName"
                                                isDisabled={intervention && intervention.archived === true}
                                                onChange={(val) => onChange(val.target.name, val.target.value)}
                                                placeHolder={'Enter text'}
                                                defaultValue={
                                                    intervention && intervention.archived === true ?
                                                        `${"* Archived * " + intervention.name}`
                                                        : intervention && intervention.name ?
                                                            intervention.name : ""
                                                }
                                                plaintext={
                                                    intervention && intervention.archived === true
                                                }
                                                readOnly={
                                                    intervention && intervention.archived === true
                                                }
                                                isInvalid={errors.hasOwnProperty("taskName")}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.taskName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col className='align-right p-i-start' lg={1}>
                                        <XLg onClick={() => handleClose(interventionID ? interventionID : '')} />
                                    </Col>
                                </Row>
                                <Row className='m-b-end-05'>
                                    <Col lg={6}>
                                        <Form.Group className="status-select" controlId="status">
                                            <Form.Label className='label-medium'>Status</Form.Label>
                                            <DropdownDynamicWithSearch
                                                showDeselect={false}
                                                handleSelect={onChange}
                                                initialValue={form && form.status ? form.status : getStatusValue(intervention, "Select...")}
                                                menuItems={statusesToPass}
                                                className={'dropdown-select'}
                                                fieldName={'status'}
                                                disabled={intervention && intervention.archived === true}
                                                searchable={false}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>Assignee</Form.Label>
                                        <DropdownDynamicWithSearch
                                            showDeselect={true}
                                            handleSelect={onChange}
                                            initialValue={findInitialValue(form && form.assignedTo ? form.assignedTo : null, intervention, "Assigned To")}
                                            menuItems={formatAssignees(assigneesToPass)}
                                            className={'dropdown-select'}
                                            fieldName={'assignedTo'}
                                            disabled={intervention && intervention.archived === true}
                                            searchable={true}
                                            id='assignee'
                                        />
                                    </Col>
                                </Row>
                                <Row className='m-b-end-05'>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>Product</Form.Label>
                                        <Form.Group className="vaccine-select" controlId="vaccine">
                                            <DropdownDynamicWithSearch
                                                showDeselect={false}
                                                handleSelect={onChange}
                                                initialValue={findInitialValue(form && form.vaccine
                                                    ? form.vaccine : null, intervention, "vaccine")}
                                                menuItems={vaccinesToPass}
                                                className={'dropdown-select'}
                                                fieldName={'vaccine'}
                                                disabled={intervention && intervention.archived === true}
                                                showDefaultOption={false}
                                                searchable={true}
                                            />
                                            <div
                                                className="error-message"
                                                style={{
                                                    fontSize: ".875em",
                                                    color: "#dc3545",
                                                    marginTop: "0.25rem",
                                                }}
                                            >
                                                {errors.vaccine}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>Supplier</Form.Label>
                                        <Form.Group className="supplier-select" controlId="supplier">
                                            <DropdownDynamicWithSearch
                                            showDeselect={true}
                                                handleSelect={onChange}
                                                initialValue={findInitialValue(form && form.supplier
                                                    ? form.supplier : null, intervention, "supplier")}
                                                menuItems={suppliersToPass}
                                                className={'dropdown-select'}
                                                fieldName={'supplier'}
                                                disabled={intervention && intervention.archived === true}
                                                showDefaultOption={true}
                                                searchable={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Label className='label-medium'>Product roadmap</Form.Label>
                                    <Form.Group>
                                        <Form.Control
                                            placeholder='Enter text'
                                            className='input-underline m-b-end-05'
                                            size="lg"
                                            type="text"
                                            as="textarea"
                                            rows='2'
                                            id='vaccineRoadmap'
                                            disabled={intervention && intervention.archived === true}
                                            name='vaccineRoadmap'
                                            onChange={val => onChange(val.target.name, val.target.value)}
                                            defaultValue={intervention ? getCustomFieldName(intervention.custom_fields, "Vaccine Roadmap") : ''}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Label className='label-medium'>Objective</Form.Label>
                                    <Form.Group>
                                        <Form.Control
                                            placeholder='Enter text'
                                            className='input-underline m-b-end-05'
                                            size="lg"
                                            type="text"
                                            as="textarea"
                                            rows='2'
                                            id='objective'
                                            disabled={intervention && intervention.archived === true}
                                            name='objective'
                                            onChange={val => onChange(val.target.name, val.target.value)}
                                            defaultValue={intervention ? getCustomFieldName(intervention.custom_fields, "Objective") : ''}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Label className='label-medium'>Target outcome</Form.Label>
                                    <Form.Group>
                                        <Form.Control
                                            placeholder='Enter text'
                                            size="lg"
                                            className='input-underline m-b-end-05'
                                            type="text"
                                            as="textarea"
                                            rows='3'
                                            id='targetOutcome'
                                            disabled={intervention && intervention.archived === true}
                                            name='targetOutcome'
                                            onChange={val => onChange(val.target.name, val.target.value)}
                                            defaultValue={intervention ? getCustomFieldName(intervention.custom_fields, "Objective") : ''}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <Row className='m-b-end-05'>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>Stakeholder accountable</Form.Label>
                                        <input
                                            id='stakeholderAccountable'
                                            className="striped-field input-underline full-width"
                                            name="stakeholderAccountable"
                                            disabled={intervention && intervention.archived === true}
                                            defaultValue={intervention ? getCustomFieldName(intervention.custom_fields, "Stakeholder Accountable") : ''}
                                            onChange={(val) =>
                                                onChange(val.target.name, val.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>Stakeholder supporting</Form.Label>
                                        <input
                                            id='stakeholderSupporting'
                                            className="stakeholder-supporting-input input-underline full-width"
                                            name="stakeholderSupporting"
                                            disabled={intervention && intervention.archived === true}
                                            defaultValue={intervention ? getCustomFieldName(intervention.custom_fields, "Stakeholder Supporting") : ''}
                                            onChange={(val) =>
                                                onChange(val.target.name, val.target.value)
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className='m-b-end-05'>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>Due date</Form.Label>
                                        <input
                                            id='dueDate'
                                            disabled={intervention && intervention.archived === true}
                                            className="input-date full-width"
                                            type="date"
                                            name="dueDate"
                                            onChange={(val) =>
                                                convertDateHandler(
                                                    val.target.name,
                                                    val.target.value
                                                )
                                            }
                                            defaultValue={intervention && intervention.due_date ? dateFormatter(intervention.due_date) : ""}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>Due date type</Form.Label>
                                        <DropdownDynamicWithSearch
                                            id='dueDateType'
                                            handleSelect={switchToValue}
                                            initialValue={CONSTANTS.MARKET_ACTIONS_DUE_DATE_TYPE[findInitialValue(null, intervention, "Due Date Type")]}
                                            formVal={CONSTANTS.MARKET_ACTIONS_DUE_DATE_TYPE[form.dueDateType]}
                                            menuItems={dueDateTypesToPass}
                                            className={'dropdown-select'}
                                            fieldName={'dueDateType'}
                                            disabled={intervention && intervention.archived === true}
                                            showDefaultOption={true}
                                            searchable={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className='m-b-end-05'>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>Priority</Form.Label>
                                        <DropdownDynamicWithSearch
                                            id='priority'
                                            handleSelect={switchToValue}
                                            initialValue={CONSTANTS.MARKET_ACTIONS_PRIORITY_VAL_OBJ[findInitialValue(null, intervention, "priority")]}
                                            formVal={CONSTANTS.MARKET_ACTIONS_PRIORITY_VAL_OBJ[form.priority]}
                                            menuItems={CONSTANTS.MARKET_ACTIONS_PRIORITY_ARRAY}
                                            className={'dropdown-select'}
                                            fieldName={'priority'}
                                            disabled={intervention && intervention.archived === true}
                                            showDefaultOption={true}
                                            searchable={false}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>Accuracy</Form.Label>
                                        <DropdownDynamicWithSearch
                                            id='accuracy'
                                            handleSelect={switchToValue}
                                            initialValue={CONSTANTS.MARKET_ACTIONS_ACCURACY_LABEL_OBJ[findInitialValue(null, intervention, "accuracy")]}
                                            formVal={CONSTANTS.MARKET_ACTIONS_ACCURACY_LABEL_OBJ[form.accuracy]}
                                            menuItems={CONSTANTS.MARKET_ACTIONS_ACCURACY_ARRAY}
                                            className={'dropdown-select'}
                                            fieldName={'accuracy'}
                                            disabled={intervention && intervention.archived === true}
                                            showDefaultOption={true}
                                            searchable={false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>HMF category</Form.Label>
                                        <MultiSelectDynamic 
                                            handleSelect={switchToValue}
                                            selectOptionHandler 
                                            initialVals={getInitialLabelVals(findInitialValue(null, intervention, "HMF Category"))} 
                                            options={CONSTANTS.MARKET_ACTIONS_HMF_CATEGORY_ARRAY} 
                                            id='hmfCategory'
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Label className='label-medium'>Prioritised innovation</Form.Label>
                                        <DropdownDynamicWithSearch
                                            id='priorInnovation'
                                            handleSelect={switchToValue}
                                            initialValue={CONSTANTS.MARKET_ACTIONS_REVERSE_PRIOR_INNOVATION[findInitialValue(null, intervention, "Prioritized Innovation")]}
                                            formVal={CONSTANTS.MARKET_ACTIONS_REVERSE_PRIOR_INNOVATION[form.priorInnovation]}
                                            menuItems={CONSTANTS.MARKET_ACTIONS_PRIOR_INNOVATION_ARRAY}
                                            className={'dropdown-select'}
                                            fieldName={'priorInnovation'}
                                            disabled={intervention && intervention.archived === true}
                                            showDefaultOption={true}
                                            searchable={false}
                                        />
                                    </Col>
                                </Row>
                                {
                                    interventionID && interventionID.toString().length > 0 ?
                                        <>
                                            <h4 className='history-header m-b-start-08'>Comments & Event History</h4>
                                            {
                                                intervention && intervention.comments && intervention.comments.comments && intervention.comments.comments.length > 0 ?
                                                    intervention.comments.comments.map((comment, index) => {
                                                        return (
                                                            <>
                                                                <Row key={index} className='p-03 comments body-med'>
                                                                    <Col><p>{comment.commenterEmail}</p></Col>
                                                                    <Col className='comment-date-col'><p>{convertToDate(comment.date)}</p></Col>
                                                                </Row>
                                                                <p className='p-03 body-med'>{comment.comment_text}</p>
                                                            </>
                                                        )
                                                    })

                                                    : <p className='p-03 text-secondary body-med'>No comments yet...</p>
                                            }
                                        </>
                                        : ''
                                }
                                {
                                    interventionID && interventionID.toString().length > 0 ?
                                        <>
                                            <Row className='m-b-end-08 input-comment-container'>
                                                {
                                                    !inputLoading ?
                                                        <input
                                                            className='light-input-med-padding input-underline body-med'
                                                            type="text"
                                                            id='commentInput'
                                                            name="comment_text"
                                                            disabled={intervention && intervention.archived && intervention.archved === true ? true : false}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    submitInterventionComment(e.target, intervention);
                                                                } else {
                                                                    updateInterventionComment(e.target)
                                                                }
                                                            }}
                                                            placeholder={"Enter comment here..."}
                                                        />

                                                        :
                                                        <Spinner animation="border" />
                                                }
                                                <BtnDynamic
                                                    classes={'intervention-comment-btns btn-ghost-lg'}
                                                    content={{ name: 'Cancel' }}
                                                    actionHandler={() => clearCommentInput()}
                                                    loading={intervention && intervention.archived && intervention.archved === true ? true : false}
                                                />
                                                <BtnDynamic
                                                    classes={'surface-primary btn-ghost-border-lg intervention-comment-btns'}
                                                    content={{ name: 'Comment' }}
                                                    actionHandler={() => submitInterventionComment(comments, intervention)}
                                                    loading={intervention && intervention.archived && intervention.archved === true ? true : false}
                                                />
                                            </Row>
                                        </>
                                        : ''
                                }
                                <div className='btn-container'>
                                    {/* why are we passing interventionID back on discard? */}
                                    <Button
                                        onClick={() => handleClose(interventionID ? interventionID : '')}
                                        className="btn-row btn-ghost-border-lg left-btn"
                                    >
                                        Discard changes
                                    </Button>
                                    {
                                        interventionID && interventionID.toString().length > 0 ?
                                            <>
                                                <BtnDynamic
                                                    classes={'btn-row btn-ghost-border-lg m-i-start-03 right-btn'}
                                                    content={archiveBtnContent}
                                                    actionHandler={() => handleShowArchived()}
                                                />
                                            </>
                                            : ''
                                    }
                                    <Button
                                        disabled={posting}
                                        onClick={(e) => onSubmit(e)}
                                        className="surface-brand-primary btn-primary-lg btn-row m-i-start-03 right btn"
                                    >
                                        Save changes
                                    </Button>
                                </div>
                            </>
                        </Form>
                        : <ListSkeletonLoader preview={false} />
                    }
            </dialog>
        </div>
    )
}

export default InterventionViewModal