/* eslint-disable import/no-anonymous-default-export */
import utils from './utils.js'

const sendTrackingInfo = async (artToSend, userAcc, typeOfArticle='cx-linksbridge') => {
    let artTags = artToSend.fields?.tags ? utils.getTagsArray(artToSend.fields.tags) : '';
    let newDate = new Date();

    await utils.upsert('insert_content_log', process.env.REACT_APP_DB_PUBLIC_KEY, 
            {
                user: userAcc.username,
                tags: artTags,
                title: artToSend.fields.title,
                content_id: artToSend.contentID,
                access_date: newDate,
                accessing_site:typeOfArticle
            }
        )
}

export default {
    sendTrackingInfo: sendTrackingInfo
}