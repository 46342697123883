import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Spinner, Button, Table } from 'react-bootstrap';
import utils from '../../utils';
import { Search, BellFill } from 'react-bootstrap-icons';
import Select, { components } from 'react-select';
import BtnDynamic from '../Layout/BtnDynamic';
import ModalDynamic from '../Layout/ModalDynamic';

function SubscribedTagsList({ subscriptionList, onListEdited, tenant, saveChanges, saveUserPrefs }) {
    const [ tagRefList, setTagRefList ] = useState();
    const [ loading, setLoading ] = useState(true);
    const [ show, setShow ] = useState(false);
    const [ selectedTag, setSelectedTag ] = useState('')
    const [ allTagsSelected, setAllTagsSelected ] = useState(false);
    const [ selectedTagsArr, setSelectedTagsArr ] = useState([])
    const [ newPrefToAdd, setNewPrefToAdd ] = useState([]);

    const btnContent = {
        name: 'Unsubscribe',
    }
 
    //closes modal
    const handleClose= (type) => {
        setShow(false);
        setNewPrefToAdd([]);
    }

    //shows checkbox as checked or unchecked
    const isTagChecked = (tag) => {
        if (selectedTagsArr.includes(tag)) {
            return true;
        } else {
            return false;
        }
    }

    //Creates an array of selected unique tag values
    const addAllTagsToStateArr = () => {
        let tempArr = [];

        subscriptionList.forEach((tagItem) => {
                tempArr.push(tagItem.target)
        });

        let uniqueArr = Array.from(new Set(tempArr))
        setSelectedTagsArr(uniqueArr);
    }

    //Funtion to hand full tag subscription(new and intelligence) and select all tag subscription and adds to state
    const tagSelectorHandler = (type, e, tag) => {
        let cpySelectedTagsArr = [...selectedTagsArr]
        //if all are selected
        if (type === 'select-all') {
            setAllTagsSelected(true);
            if(e.target.checked === true){
                addAllTagsToStateArr()
            } else {
                setAllTagsSelected(false);
                setSelectedTagsArr([])
            }
        }
        //if single tag is selected
        if (type === 'single-tag-select') {

            if(e.target.checked === true) {
                cpySelectedTagsArr = [...cpySelectedTagsArr, tag]
                setSelectedTagsArr(cpySelectedTagsArr)
            } else {
                setAllTagsSelected(false);
                let newSelectedArr = cpySelectedTagsArr.filter((tagItem) => tagItem !== tag);
                setSelectedTagsArr(newSelectedArr);
            }
        }
    }

    //Saves new user pref and clears the state and closes the modal
    const handleEdits = async () => {
        for (let i = 0; i < newPrefToAdd.length; i ++) {
            await saveUserPrefs([newPrefToAdd[i]]);
        }
        setNewPrefToAdd([]);
        setShow(false);
    }

    //handles user prefs added in modal 
    const addSubscriptionToState = (e, tag ) => {
        let cpyPrefs = [...newPrefToAdd];
        let beMap = {
            'news':'articles',
            'intelligence':'intelligence'
        };

        // default adds news unless unchecked
        if (e === 'initial-add') {

            tag.for = beMap[tag.for];
            cpyPrefs.push(tag);
            setNewPrefToAdd(cpyPrefs);

        } else {
            //add/deletes prefs selected by user
            let newPrefs = utils.addTagSubscriptionToArray(e, beMap, tag, cpyPrefs)
            setNewPrefToAdd(newPrefs);   

        }
    } 

    // handle user pref changes to add and remove app from subscriptions (table per app selections auto updates API)
    const addRemoveSubscriptionToState = (e, tag, action) => {
        let beMap = {
            'news':'articles',
            'intelligence':'intelligence'
        };
        let editType = e.target.checked === true ? 'ADD' : 'REMOVE';

        onListEdited(editType, {type: 'tag', for: beMap[e.target.name.toLowerCase()], target: tag}, action)
    } 

    // Creates modal body
    const getModalBody = (tag) => {
        return (
            <div className='m-b-end-07'>
                <p className='body-med txt-primary'>{`You're about to subscribe to email notifications for articles and updates related to the tag ${tag}. You'll receive a curated list of content directly to your inbox to keep you informed and engaged.`}</p>
                
                    
                <p className='body-med txt-secondary m-b-start-08'>Select app content of this tag to receive notifications for:</p>
                {
                    utils.subscriptOptArr(tenant).map((app) => {
                        return (
                            <div className='input-checkbox'>
                                <input className='m-i-end-03' type="checkbox" defaultChecked={app === 'news' ? true : false} id={app} name={app} onChange={(e) => addSubscriptionToState(e, tag)}/>
                                <label className='body-large'>{app}</label>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    //Adds icon for BTN
    const addIcon = (name) => {
        return (
            <>
                <BellFill className='btn-icon surface-primary-text' />{name}
            </>
        )
    }

    //Modal Content
    const subscriptionModal = {
        type: selectedTag,
        title: `Subscription to ${selectedTag} notifications`,
        body: getModalBody(selectedTag),
        app: 'search',
        subName: addIcon('Subscribe')
    }

    //custom components for select
    const Placeholder = (props) => {
        return <components.Placeholder {...props} />;
    };

    const SearchDropDownIcon = (props) => {
        return <Button style={{marginBlockEnd: 'inherit'}}><Search style={{color: 'white'}} /></Button>
    }

    const DropdownIndicator = (
        props
      ) => {
        return (
          <components.DropdownIndicator {...props}>
            <SearchDropDownIcon />
          </components.DropdownIndicator>
        );
    };

    const convertTagListToOptions = (ogServerList) => {
        return Object.keys(ogServerList).map( (itemKey) => {
            return { label: itemKey, value: itemKey };
        })
    }

    const getTagList = async () => {
        let res = await fetch(`${process.env.REACT_APP_WEB_API}/api/cms-tags-object`, {
          
        });
        let jsonRes = await res.json();
        setTagRefList(convertTagListToOptions(jsonRes));
      }
    
      useEffect(() => {
        setLoading(true);
        try {
            getTagList();
            setLoading(false);
        } catch {
          setLoading(false);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    //Handler for when user selects a new tag to add to subscriptions from the select
    const tagsChanged = (data) => {
        setSelectedTag(data)
        setShow(true);
        addSubscriptionToState('initial-add', {type: 'tag', for: 'news', target: data})
    }

    const convertToUniqueValues = (rawList) => {
        // returns array of tagnames
        let getTagsList = rawList.map((tag) => tag['target']);
        // create set of only unique values
        let uniqueTags = new Set(getTagsList);
        //turns into an array of tags with unique values and sorts them
        let tagsArr = Array.from(uniqueTags).sort();
        return tagsArr;
    }

    return (
        <Container>
                <Row className='inner-section'>
                <Col><h6>Subscriptions <span className="help-text">You are currently being notified when news and content have been created for the following tags:</span></h6></Col>
            </Row>
            <Row className="space-below">
                <Col>
                    {
                        !loading && tagRefList && tagRefList.length > 0 ?
                            <Select
                                components={{ Placeholder, DropdownIndicator } } 
                                placeholder="Search to add tags to follow" 
                                className='body-small select-filter tag-selector'
                                value=''
                                options={tagRefList}
                                onChange={(e) => tagsChanged(e.value ? e.value : e)}
                            />
                        : 
                        <> <Spinner animation="border" /> Loading Tags For Picker </>
                    }
                </Col>
            </Row>
            <Row className='setting-btns'>
                <Col sm={{span: 2, offset: 10}}>
                    <BtnDynamic
                        loading={loading} 
                        content={btnContent}
                        actionHandler={() => { saveChanges(allTagsSelected, selectedTagsArr) }}
                    />
                </Col>
            </Row>
            <Row >
                <Col>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>
                                    <input 
                                        type='checkbox' 
                                        name='select-all' 
                                        checked={allTagsSelected === true ? true : false} 
                                        onChange={(e) => tagSelectorHandler('select-all', e)}
                                    />
                                </th>
                                <th>Tag Name</th>
                                {
                                    utils.subscriptOptArr(tenant).map((app) => {
                                        return (
                                            <th>{app}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                convertToUniqueValues(subscriptionList).map((tag) => {
                                    return (
                                        <tr>
                                            <td>
                                                <input type='checkbox' name={tag} checked={allTagsSelected === true ? true : isTagChecked(tag)} onChange={(e) => tagSelectorHandler('single-tag-select', e, tag)}/>
                                            </td>
                                            <td>{tag}</td>
                                            {
                                                utils.subscriptOptArr(tenant).map((app) => {
                                                    return (
                                                        <td>
                                                            <input type='checkbox' name={app} defaultChecked={utils.isInArray({ type: 'tag', target: tag, for: app.toLowerCase() === 'news' ? 'articles' : 'intelligence'}, subscriptionList)> -1} onChange={(e) => addRemoveSubscriptionToState(e, tag, 'call-api')}/>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                           
                        </tbody>
                        </Table>
                    </Col>
                </Row>
                <ModalDynamic
                    show={show}
                    content={subscriptionModal}
                    tenant={tenant} 
                    handleClose={handleClose}
                    submitRequest={handleEdits}
                    classes={{modal: `${tenant.tenant.fields.tenantslug}`}}
                />
        </Container>
    )
}

export default SubscribedTagsList