import React from 'react';
import { Pagination, PageItem } from 'react-bootstrap';

function ListPagination({ curPage, articlesPerPage, totalArticles, paginate }) {
  
  let totalPages = Math.ceil(totalArticles / articlesPerPage);

  const createPaginationItem = (i) => {
    return (
      <PageItem
        key={i}
        active={i === curPage}
        onClick={() => paginate(i)}
        activeLabel="" // Override the default activeLabel which is "(current)"
      >
        {i}
      </PageItem>
    );
  };

  const findFuturePageNums = (total, page) => {
    let num = total - page;
    return page + num;
  };

  const paginationItems = [];
  const page = curPage + 4 < totalPages ? curPage + 4 : findFuturePageNums(totalPages, curPage);

  // Create page numbers in the middle
  for (let i = curPage; i <= page; i++) {
    paginationItems.push(createPaginationItem(i));
  }
  
  // Add an ellipsis
  if (page < findFuturePageNums(totalPages, curPage)) {
    // paginationItems.push(<Pagination.Ellipsis onClick={() => paginate(page)} />);
  }

  //TODO: Add First and last and styling

  const morePage = curPage + 4 < totalPages ? curPage + 4 : '';
  const lessPage = curPage - 4 > 0 ? curPage - 3 : '';

  return (
    <Pagination>
      <PageItem
        className='item-prev'
        disabled={curPage <= 1}
        onClick={() => curPage <= 1 ? paginate(curPage) : paginate(curPage - 1)}
      >
        Prev
      </PageItem>
      {
        lessPage && lessPage > 1 ?
          <Pagination.Ellipsis onClick={() => paginate(lessPage)} />
          : ''
      }
      {paginationItems}
      {
        morePage && morePage > 1 ?
          <Pagination.Ellipsis onClick={() => paginate(morePage)} />
          : ''
      }
      <PageItem
        className='item-next'
        disabled={curPage >= totalPages}
        onClick={() => paginate(curPage + 1)}
      >
        Next
      </PageItem>
    </Pagination>
  );
}

export default ListPagination;
