import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import utils from '../../../utils';

function DynamicModal({ content }) {

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
      <Modal 
        className='dynamic-modal'
        show={show}  
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h3>{content.title}</h3>
          <p className='subtitle'>{`${content.modalType} - ${utils.getDateString(content.dateCreated, 'long-day-first')}`}</p>
            <p dangerouslySetInnerHTML={ utils.getHTML(content.body)}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
  )
}

export default DynamicModal