import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import ToolTip from '../Layout/ToolTip';

function LBGanttLabel({ taskDetails, curLength, handleShowIntModal }) {

   const getCrimpedText = (textToCrimp, lngth) => {
       let numChars = 5*lngth;
       return textToCrimp.substring(0, numChars) + '...';
   }

    return (
        <Row className="my-auto">
            <Col xs={8}>
                <Row>
                    <Col xs={12}>
                        <div onClick={()=>handleShowIntModal(taskDetails.id)}>
                            <span>{ getCrimpedText(taskDetails.name, curLength) }</span> <ToolTip tooltip={ curLength < 4 ? taskDetails.name +' Market: '+taskDetails.fromList.name : taskDetails.name} />
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={4} style={{textAlign: 'right', paddingRight: '10px'}}>
                { 
                    curLength > 4 ?
                        <Badge pill variant="primary">{taskDetails.fromList.name}</Badge>
                    : null
                }
            </Col>
        </Row>
    )
}

export default LBGanttLabel