import React, { useState } from 'react';
import { ArrowUp } from 'react-bootstrap-icons';

import Turns from './Turns';
import aiUtils from './aiUtils';
import '../../../styles/layout/_layout.scss';

function ChatHistory({appClass}) {
    const [threadId, setThreadId] = useState();
    const [turns, setTurns] = useState();
    
    const handleSubmit = async () => {
        const turns = await aiUtils.getThread(threadId);
        if (!turns || turns.lenth===0 ){
            window.alert("Failed to load turns!");
            return;
        }
        setTurns(turns);
    }

    return (
        <div className="chat-wrapper p-i-10 p-b-08">
            <div className="chat-container">
                <div className="message-container">
                    <div className="input-wrapper">
                        <input
                            className="message-bar"
                            value={threadId}
                            onChange={(e) => setThreadId(e.target.value)}
                            placeholder={`Enter a thread id`}
                        />
                        <button className="btn-send" onClick={() => handleSubmit()}>
                            <ArrowUp />
                        </button>
                    </div>
                    <Turns turns={turns} appClass={appClass} />
                </div>
            </div>
        </div>
    )
}

export default ChatHistory;