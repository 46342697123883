import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

import CONSTANTS from '../constants';

function SupportModal(props) {

    //receives email of submitter, page that they are on in props, and any addition contextInformation

    const SUPPORT_FORM_API_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/8615684/39b9ec08-2cb4-4fcb-a9e2-b13678336358`;

    let { pathname } = useLocation();

    let [ form, setForm ] = useState({ 
        email: props.userEmail, 
        page_url: props.onPage ? props.onPage : pathname
    });
    let [ errors, setErrors ] = useState({});
    let [ isSubmitted, setIsSubmitted ] = useState(false);
    let [ isSubmitting, setIsSubmitting ] = useState(false);

    useEffect( ()=> {
        //check if isSubmitting is true, if so, push up to the server
        if(isSubmitting === true) {

            setIsSubmitting(false);
            setIsSubmitted(true);
        }
    }, [ isSubmitting ])

    const hsFormSubmitSkeleton = {
        "portalId": 8615684,
        "guid": "39b9ec08-2cb4-4fcb-a9e2-b13678336358",
        "fields": [
          {
            "objectTypeId": "0-1",
            "name": "email",
            "value": ""
          },
          {
            "objectTypeId": "0-1",
            "name": "page_url",
            "value": ""
          },
          {
            "objectTypeId": "0-1",
            "name": "category",
            "value": ""
          },
          {
            "objectTypeId": "0-1",
            "name": "message",
            "value": ""
          }
        ],
        "legalConsentOptions": {
          "consent": { // Include this object when GDPR options are enabled
            "consentToProcess": true,
            "text": "I agree to allow Linksbridge to store and process my personal data.",
            "communications": [
              {
                "value": true,
                "subscriptionTypeId": 999,
                "text": "I agree to receive marketing communications from Linksbridge."
              }
            ]
          }
        }
      };

      const getIndexOfItemInSkeleton = (itemName, skel) => {
          return skel.fields.findIndex( (item) => {
            return item.name.toLowerCase().localeCompare(itemName.toLowerCase()) === 0;
          })
      }

      const mergeFormIntoSkeleton = (frmData, skeleton) => {
        //we don't want to modify the og skeleton
        let skelCopy = {...skeleton};
        Object.keys(frmData).forEach( (thisKey) => {
            let thisItemIndex = getIndexOfItemInSkeleton(thisKey, skeleton);
            if(thisItemIndex > -1){
                skelCopy.fields[thisItemIndex].value = frmData[thisKey];
            }
        });

        return skelCopy;
      }

    const validateForm = (formToCheck) => {
        const { category, message } = formToCheck;
        
        let newErrors = {};

        if(!category || category === ''){
            newErrors.category = 'You must pick a category.';
        }

        if(!message || message === ''){
            newErrors.message = 'Please include message.';
        }

        return newErrors;
    }

    const cancelSubmission = () => {
        setIsSubmitted(false);
        setIsSubmitting(false);
        props.onSubmitted(false);
    }

    const setField = (field, value) => {

        setForm({
          ...form,
          [field]: value
        });
    
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) {
          setErrors({
            ...errors,
            [field]: null
          })
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const formErrors = validateForm(form);


        if (Object.keys(formErrors).length < 1) {
            setIsSubmitting(true);
            fetch(SUPPORT_FORM_API_ENDPOINT, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(mergeFormIntoSkeleton(form, hsFormSubmitSkeleton))
            })
            .then( ()=> {
                setIsSubmitting(false);
                setIsSubmitted(true);
            })
            .catch( (error) => {
                setIsSubmitting(false);
            });  
        } else {
            setErrors(formErrors)
        }
    }

    return (
        <Modal
            {...props}
            show={props.show}
            className="support-modal"
            size="md"
            centered 
            onHide={cancelSubmission} 
            >
            <Modal.Body>
                { !isSubmitted ?
                    !isSubmitting ?
                        <Form onSubmit={(e) => onSubmit(e)}>

                <Modal.Header className="support-modal-header" closeButton >
                    <Modal.Title id="contained-modal-title-vcenter" className="support-modal-title">
                        <Row className="support-modal-title">
                            We'd love to hear your feedback!
                        </Row>
                    </Modal.Title>    
                </Modal.Header>
                            <Row className="support-modal-subtitle">
                                <Col>We'll be reviewing your message and will let you know when it's resolved!</Col>
                            </Row>
                            <Row className='support-email-group'>
                                <Col>
                                    <input type="hidden" name="page_url" value={props.onPage ? props.onPage : 'unknown'} />
                                    <Form.Group className="form_textfield mb-8" controlId="email">
                                        <Form.Label class="col-sm-6 form-label support-email">Your Email</Form.Label>
                                        <Form.Control
                                            plaintext 
                                            defaultValue={props.userEmail} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='support-category-group'>
                                <Col>
                                    <Form.Group className="form_dropdown" controlId="category">
                                    <Form.Label class="col-sm-6 mt-8 form-label support-category">Category</Form.Label>
                                    <Select
                                        id="dropdown-basic-button"
                                        name="category"
                                        className={!!errors.category ? 'error' : ''}
                                        options={CONSTANTS.SUPPORT_FORM.CATEGORIES}
                                        onChange={e => setField('category', e.value)}
                                    />
                                    <div
                                        className="error-message"
                                        style={{
                                        fontSize: '.875em',
                                        color: '#dc3545',
                                        marginTop: '0.25rem'
                                        }}
                                    >
                                        {errors.category}
                                    </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                            <Row className='support-details-group'>
                                <Col>
                                    <label class="col-sm-6 form-label support-details">Details</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <textarea 
                                        class='form-control'
                                        placeholder='Text'
                                        name="details"
                                        style={{width: '100%'}}
                                        rows="1" 
                                        onChange={e => setField('message', e.target.value)}
                                        isInvalid={!!errors.details} />
                                    <div
                                        className="error-message"
                                        style={{
                                        fontSize: '.875em',
                                        color: '#dc3545',
                                        marginTop: '0.25rem',
                                        }}>
                                            {errors.details}
                                    </div>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col>
                                    <label className="support-file-upload">Upload File</label>
                                </Col>
                            </Row>
                            
                            <div className="input-group custom-file-button">
		                        <label className="input-group-text" for="inputGroupFile">Choose File</label>
		                        <input type="file" class="form-control" id="inputGroupFile" />
	                        </div> */}
                            


                            <Row>
                                <Col className='text-end'>
                                    <Button variant="light" onClick={cancelSubmission}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary tertiary-button" type="submit">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                            
                        </Form>
                    : <Spinner anim="border" />
                : 
                    <>
                        <Row>
                            <Modal.Title >
                                <Row className="support-modal-title-submitted">
                                    <Col>Thank you for your submission!</Col>
                                </Row>
                            </Modal.Title>
                                <Row className="support-modal-text-submitted pb-8"></Row>
                                    <Col>
                                        We'll be reviewing your message and will let you know when it's resolved!
                                    </Col>
                                </Row> 
                        
                            <Row>
                                <Col className="text-center">
                                    <Button variant="primary" className="thankyou-close" onClick={cancelSubmission}>Close</Button>
                                </Col>
                            </Row>
                        </>
                    }
            </Modal.Body>
        </Modal>
    )
}

export default SupportModal