import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import filterUtils from '../../filterUtils';

function YearFilter({ yearList, filterChangeHandler, curFilters }) {

    const handleSelect = (key) => {

        let foundArr = yearList.filter((item) => {
            return item.label.toLowerCase() === key.toLowerCase();
        });

        // Creates obj to send to the change handler function
        filterChangeHandler({
            field: 'date_published',
            value: foundArr.length > 0 ? foundArr[0].value : ''
        });

    };

    return (


        <DropdownButton
            className='dropdown-select'
            id="year-filter"
            title={filterUtils.findFilterTitle('date_published', curFilters, yearList, 'Year Published')}
            onSelect={(eventKey) => handleSelect(eventKey)} >
            <Dropdown.Item
                eventKey={'clear-filter-country'}
            >
                Select All
            </Dropdown.Item>
            {
                yearList.map((year, idx) => {
                    return (
                        <Dropdown.Item
                            key={idx}
                            eventKey={year.value}>
                            {year.label}
                        </Dropdown.Item>
                    )
                })
            }
        </DropdownButton>
    )
}

export default YearFilter