import React, { useState, useEffect } from 'react';
import Select from 'react-select';

function TableCellDropDown({ initialValue = null, rowID, column, updateMyData, selectionValues, className=null }) {
    const [myValue, setMyValue] = useState(); 
    const [firstLoad, setFirstLoad] = useState(true); 


    const onChange = selectedOption => {
        setMyValue(selectedOption); 
        updateMyData(rowID, column, (selectedOption ? selectedOption.value : ''));
    };

    const onBlur = () => {
        updateMyData(rowID, column, myValue.value); 
    };


    useEffect(() => {
        if (firstLoad) {
            setMyValue(initialValue || ''); 
            setFirstLoad(false); 
        }
    }, [initialValue, firstLoad]);

    return (
        <Select
            className={className || 'table-dropdown-select'}
            value={myValue}
            onChange={onChange}
            onBlur={onBlur}
            options={selectionValues}
            placeholder="Select..."
            isClearable={true} 
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
        />
    );
}

export default TableCellDropDown;
