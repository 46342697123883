import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';

import StatefulSearchTable from '../StatefulSearchTable';
import ActivityEditor from './ActivityEditor';
import StagingAndProdTransferButtons from '../StagingAndProdTransferButtons';
import utils from '../../../utils';
import '../../../styles/layout/_layout.scss';

// Columns
const metaData=[
  {title:'Delivery ID', dispField:'delivery_id', filterField:'delivery_id', asNumber:true},
  {title:'Activity ID', dispField:'activity_id', filterField:'activity_id'},
  {title:'External Source ID', dispField:'external_source_id', filterField:'external_source_id'},
  {title:'Country', dispField:'country', filterField:'country'}, 
  {title:'Year', dispField:'actual_start_year', filterField:'actual_start_year'},
  {title:'Activity Type', dispField:'activity_type', filterField:'activity_type'}, 
  {title:'Intervention', dispField:'intervention', filterField:'intervention'}, 
  {title:'Disease Group', dispField:'disease_group', filterField:'disease_group'},
  {title:'Main Info Source', dispField:'info_source', filterField:'info_source'},
  {title:'Target Demographic', dispField:'target_demographic', filterField:'target_demographic'},
  {title:'Update Date', dispField:'valid_from', filterField:'valid_from'}
]

const sortData=[
  {title:"Standard", field:'standard_sort', desc:true},
  {title:"Date", field:'update_date'}
]

const fetchData = async(setActivities, apiKey)=> {
  const result = await utils.getData("get_activities", apiKey)
  
  if (result !== null) {
    const formattedResult = result.map((r) => 
      {return {...r, 
        standard_sort:r.delivery_id*1000000000+r.activity_id}
    
      });
    setActivities(formattedResult);
  }
}

function ActivitySearch({apiKey, userName, tooltip, getTooltipContentByKey, editor, appClass=null}) {
  const [activities, setActivities] = useState([]);
  const [activityId, setActivityId] = useState(null);
  const [showEditActivity, setShowEditActivity] = useState(false);
  const [originalInfoSource, setOriginalInfoSource] = useState({ source: null, id: null });


  useEffect(() => {
    if (showEditActivity === false) {
      fetchData(setActivities, apiKey);
    }
  }, [showEditActivity, apiKey])


  useEffect(() => {
    if (activityId && showEditActivity) { // if activityEditor is open
      const fetchOriginalInfoSource = async () => {
        const activityDetails = await utils.getData('get_activity', apiKey, `?activity_id=${activityId}`);
        if (activityDetails && (!originalInfoSource.id || originalInfoSource.id !== activityId)) {
          setOriginalInfoSource({ source: activityDetails.info_source, id: activityId });
        }
      };

      fetchOriginalInfoSource();
    }
  }, [activityId, apiKey, showEditActivity, originalInfoSource.id]);


  const createNew = () => {
    setOriginalInfoSource({ source: null, id: null }); //  reset OriginalInfoSource for new activities
    setShowEditActivity(prev => !prev);
    setActivityId(null);
  };


  const update = (activity) => {
    setShowEditActivity(prev => !prev);
    setActivityId(activity.activity_id);
  };


  // Create New and Clear Filters usually - buttons alongside the Gear icon in the AlchemyToolbar
  const toolbarButtons = () => (
    <>
      <Button className='btn-primary m-i-end-02 surface-primary' onClick={createNew}>Create New <PlusCircle/></Button>
    </>
  );

  const settingsContent = {
    activityButtons: () =>{
      return (
        <StagingAndProdTransferButtons
          userName={userName}
          apiKey={apiKey}
          staging_endpoint='transfer_activities_to_staging'
          prod_endpoint='transfer_activities_to_production'
          module='ce'
          disableRefresh={showEditActivity}
        />
      ) 
    }
  }

  return (
    <div className={appClass}>
      {showEditActivity ? (
        <ActivityEditor
          editorName='Activity'
          activityId={activityId}
          apiKey={apiKey}
          closePopup={() => setShowEditActivity(prev => !prev)}
          userName={userName}
          tooltip={tooltip}
          getTooltipContentByKey={getTooltipContentByKey}
          originalInfoSource={originalInfoSource.source} // Pass just the source part, not the ID
          appClass={appClass}
        />
      ) : null}
      <StatefulSearchTable
        data={activities}
        metaData={metaData}
        sortData={sortData}
        onRowClick={update}
        toolbarButtons={toolbarButtons}
        apiKey={apiKey}
        module='ce'
        rowsPerPage={20}
        editor={editor}
        settingsContent={settingsContent}
        currentEditor='CE'
        appClass={appClass}
      />
    </div>
  );
}

export default ActivitySearch;
