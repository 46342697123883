import React, {useEffect, useState} from 'react'
import { Button} from 'react-bootstrap'

import TherapeuticEditor from './TxEditor'
import StatefulSearchTable from '../StatefulSearchTable';
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';


const sortOptions = [
  {title:'Brand Name', field:'brand_name'},
  {title:'Manufacturer+Brand', field:'manufacturer_brand'},
  {title:'Drug Name', field:'drug_name'},
  {title:'Update Date', field: "update_date"},
]

const metaData = [
  {title:'Brand Name', dispField:'brand_name', filterField:'brand_name',isMulti:true},
  {title:'Alt Brand Names', dispField:'alt_brand_names', filterField:'alt_brand_names'},
  {title:'Manufacturer', dispField:'manufacturer', filterField:'manufacturer',isMulti:true},
  {title:'Drug Name', dispField:'drug_name', filterField:'drug_name',isMulti:true},
  {title:'Include in Visuals', dispField:'include_in_vizs', filterField:'include_in_vizs'},
  {title:'Marketed/Pipeline', dispField:'marketed_pipeline', filterField:'marketed_pipeline'},
  {title:'Updated Date', dispField:'update_date_str'}
]

function TxSearch({apiKey, editor, appClass=null}){
  // Local representation with data augmentations
  const [therapeutics, setTherapeutics] = useState([])
  
  // Product that gets edited when double clicked
  const [txProductId, setTxProductId] = useState(null)
  // A toggle for showing the edit product modal
  const [showEditTherapeutic, setShowEditTherapeutic] = useState(false)
  const [xferMessage, setXferMessage] =useState('')
  // Automatic trigger to refetch the data whenever the user is done editing the data.
  // This is automatically triggered by switching the t/f variable for showing the editing modal to false
  useEffect(() => {
    async function fetchData() {
      if (showEditTherapeutic === false) {
        let result = await utils.getData("get_therapeutics", apiKey)
        if (result !== null) {
          let augmentedResponse = result.map((r) => {
            return {...r, manufacturer_brand: 
              (r.manufacturer?r.manufacturer:'')+
              (r.brand_name?r.brand_name:''),
              update_date: new Date(r.update_date),
              update_date_str: (new Date(r.update_date)).toLocaleDateString(),
              created_date: new Date(r.created_date),
              created_date_str: (new Date(r.created_date)).toLocaleDateString()
            }
          })
          setTherapeutics(augmentedResponse)
        }
      }
    }
    fetchData()
  }, [showEditTherapeutic, apiKey]
  )

  function toggleShowEditTherapeutic(){
    setShowEditTherapeutic(!showEditTherapeutic)
  }

  function createNew(){
    toggleShowEditTherapeutic()
    setTxProductId(null)
  }
  
  function update(tx){
    toggleShowEditTherapeutic()
    setTxProductId(tx.product_id)
  }

  const settingsContent = {
    triggerXfer: async (endpoint) => {
      setXferMessage("Working on transfer");
      const result = await utils.upsert(endpoint, apiKey, '');
      setXferMessage(result === true ? "Done" : "Error");
    },

    settingsButtons: () => (
      <>
        <Button className='btn-primary bg-blue-300 m-b-start-03' onClick={() => settingsContent.triggerXfer('transfer_tx_to_production')}>
          Post to Production
        </Button>{' '}
      </>
    ),
  };


  const toolbarButtons = () => {
    return (
      <>
        <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
      </>
    )
  }

  return (
    <div>
      {showEditTherapeutic ?
        <TherapeuticEditor txId = { txProductId } 
        closePopup = { toggleShowEditTherapeutic }
        show = {showEditTherapeutic}
        apiKey={apiKey}
        appClass={appClass}
        />  
        : null  
      }
      <StatefulSearchTable
        //buttons={therapeuticButtons()}
        data={therapeutics}
        secondRow={<div>{xferMessage}</div>}
        rowsPerPage={20}
        metaData={metaData}
        sortData={sortOptions}
        apiKey={apiKey}
        onRowClick={update}
        module='therapeutic'
        settingsContent={settingsContent}
        settingsButtons={settingsContent.settingsButtons}
        toolbarButtons={toolbarButtons}
        editor={editor}
        appClass={appClass}
      />
        
    </div>
  )
}


export default TxSearch;