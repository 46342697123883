import { Button } from 'react-bootstrap'

import '../../styles/layout/_layout.scss'

function GetAgilityTemplate({contentId}) {
  
  const handleClick = async () =>{
    try {
      let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/cms-content?id=${contentId}`, {});
      let jsonresponse = await response.json();
      if(!jsonresponse.fields||!jsonresponse.fields.file || !jsonresponse.fields.file.url){
        window.alert("This record is missing information. Please contact the sit admin.")
      } else{
        let fileUrl = jsonresponse.fields.file.url;
        window.open(fileUrl);
      }
      
    } catch (error){
      window.alert(`Could not find template! Please contact site admin. Error details: ${error}`);
    }
  }

  return (
    <Button className='padded-row' variant='outline-primary' onClick={handleClick}>Get Template</Button>
  )
}


export default GetAgilityTemplate;