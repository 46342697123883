import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';

function ChartSettingsPanel(props) {
    //this receives functions for onOpen, onClose, show -> we want to leave toggling of the show to the parent of this


    const handleSettingsClick = (e) => {
        if( props.show && props.show === true ) {
            if(props.onClose) { props.onClose() }
        } else {
            if (props.onOpen){ props.onOpen() }
        }
    }

    return (
        <>
            <Row className="settings-panel-header">
                <Col>
                    {props.header}
                </Col>
                <Col className="text-right align-items-right" style={{display: 'flex', justifyContent: 'right'}} >
                    <Button 
                        variant="light" 
                        onClick={handleSettingsClick} 
                        className="toggle-button align-self-end" >
                        
                        <PencilSquare />{ props.show === true ? ' Confirm Settings': ' Edit Settings' }
                    
                    </Button>
                </Col>
            </Row>

            <Row className={props.className + ' settings-panel-children ' + (props.show ? '' : 'hidden')}>
                { props.children }
            </Row>
        </>
    )
}

export default ChartSettingsPanel