import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TopicNodePageWrapper from './TopicNodePageWrapper';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';


function TopicNodePage() {
    
    let [ loading, setLoading ] = useState(true);
    let [ topicToShow, setTopicToShow ] = useState();

    const { topicSlug } = useParams();

    const getSelectedTopic = (topicList, topicSlug) => {

        let picked = topicList.filter( (thisItem) => {
        return thisItem.fields.pageKeyURL.toLowerCase() === topicSlug.toLowerCase();
        });

        if(picked.length > 0) {
            return picked[0];
        }
        return null;
          
    }

    // loads topics from Agility
    const loadTopics = async (topicToPick) => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/cms-content?contenttype=TopicHubPages`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });

        let jsonresponse = await response.json();

        let theTopic = getSelectedTopic(jsonresponse.items, topicToPick);
        setTopicToShow(theTopic);
    }

    useEffect( () => {
        try {
            setLoading(true);
            loadTopics(topicSlug);
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topicSlug]);


    return (

        !loading && topicToShow ?

        <div className="topic-node-full-article">
            <TopicNodePageWrapper 
            rawTopicNodeObj={topicToShow} 
            />
        </div>
        : <ListSkeletonLoader preview={true} />
        
        )
}

export default TopicNodePage