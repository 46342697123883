import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import utils from '../../utils';

function ReactTblDropDownEditCell({ initialValue, row, column, updateMyData, selectionValues, forName, customField=null }) {

    const [ myValue, setMyValue ] = useState('');

    const onChange = value => {
        setMyValue(value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        if(forName === 'hmfCategory'){
            updateMyData(row, column, [myValue]);
        }else{
            updateMyData(row, column, myValue);
        }
        
    };
    
    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        if(initialValue !== myValue){
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, myValue]);

    const findValue = (valToShow, custField) => {

        if((valToShow && valToShow !== null) || `${valToShow}` === '0') {
            let retVal = selectionValues.filter(option => {
                if(custField === true && valToShow && valToShow !== null) {
                    if(typeof valToShow !== 'string') {
                        valToShow = valToShow.toString();
                    }
                    return option && option.label && (`${option.label}`.toLowerCase() === valToShow.toLowerCase());
                } else {
                    return`${option.value}` === `${valToShow}`;
                }
                
            })

            return retVal;
        }
    }


    return (
        <>
            <Select 
                className="table-dropdown select-lg-typography"
                name={`select-${forName ? forName : 'select'}-${row}-${column}`} 
                options={selectionValues}  
                onChange={(val) => onChange(customField ? val.label : val.value)} 
                defaultValue={myValue || initialValue ? findValue(myValue ? myValue : utils.capitalCasing(initialValue), customField) : {label: 'Select...', value: 'Select...'}} 
                onBlur={() => onBlur()}
            />
        </>
    )
}

export default ReactTblDropDownEditCell