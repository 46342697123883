import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Col, Row, Image } from 'react-bootstrap';
import SigninLink from '../components/SigninLink';
import signInImage from '../assets/signin-pg-img.svg';
import SupportModal from '../components/SupportModal';
import { Link } from 'react-router-dom';



import '../styles/main.scss';

function SignedOutPage() {

    let [ supportModalShown, setSupportModalShown ] = useState(false);
    let [ supportContext ] = useState();
    const [windowWidth, setWindowWidth] = useState()

    const setSupportModalIsShown = (newVal) => {
        setSupportModalShown(!!newVal);
    }

    const updateDimensions = () => {
        setWindowWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    // eslint-disable-next-line    
    }, []);

    return (
        <Row>
            <Container className='signin-landing-page'>
                <Col className='signin-col' sm={12} md={12} lg={12} xl={6}>
                    <Row>
                        <h1 className='h1'>Linksbridge Workspace</h1>
                        <h3 className='h3'>Customized platform solutions for global health data and market intelligence</h3>
                        <p className='p1'>Linksbridge Workspace provides end-to-end data management and hosting services to help global health counterparts transform market data into strategic assets.</p>
                        <p className='p2'>We apply our established data infrastructure and management systems to platforms customized for your priorities.</p>
                    </Row>
                    <Row className='welcome-back'>
                        <Card className='surface-primary-background'>
                            <div className='signin-btn'>
                                <h3 className='body-med'>Sign in or sign up</h3>
                                <p>Click button below to proceed. You'll be prompted to authenticate using a Microsoft, Google account, or an organizational email.</p>
                                <SigninLink>
                                    <Button style={{ margin: 'auto', cursor: 'pointer' }}>
                                    Log In / Sign Up
                                    </Button>
                                </SigninLink>
                                <p className='body-med policies-p'>
                                    By signing up or logging in, you acknowledge and agree to Linksbridge Workspace’s 
                                        <Link className='terms-of-use' to={{ pathname: "https://www.iubenda.com/terms-and-conditions/62216506" }} target="_blank">Terms of Use</Link>
                                    and
                                        <Link className='privacy-policy' to={{ pathname: "https://linksbridge.com/privacy-policy" }} target="_blank">Privacy Policy</Link>
                                </p>
                            </div>
                        </Card>
                    </Row>
                </Col>
                {
                    !windowWidth || windowWidth > 1200 ?
                        <Col className='img-col' sm={12} md={12} lg={12} xl={6} >
                            <Image
                                src={signInImage}
                                alt='SignIn-Workspace-img'
                                style={{
                                    width: "100%",
                                
                                }} />
                        </Col>
                    : ''
                }
                <SupportModal
                    userEmail={''}
                    show={supportModalShown}
                    onSubmitted={setSupportModalIsShown}
                    contextInfo={supportContext}
                />
            </Container>
        </Row>
    )
}

export default SignedOutPage