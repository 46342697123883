import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ListSkeletonLoader from '../../components/Layout/ListSkeletonLoader';
import BreadCrumbBar from '../../components/Layout/Nav/BreadCrumbBar';
import CompanyDisplay from '../../components/CompanyProfiles/CompanyDisplay';
import FilteredPagedCoProfList from '../../components/CompanyProfiles/FilteredPagedCoProfList';
import utils from '../../utils';
import CONSTANTS from '../../constants';
import '../../styles/layout/_layout.scss';
import '../../styles/components/_coProApp.scss'
import NavUtils from '../../NavUtils';
import appUtils from '../../appsUtils';


function CoProfileMainPage({ tenant, token, singularCompany=false }) {

    const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES);

    if(!hasAccess) {
        history.push('/');
    }

    let { slug } = useParams();
    let [ profiles, setProfiles ] = useState();
    let [ navDescr, setNavDescr ] = useState();
    let [ loadingProfiles, setLoadingProfiles ] = useState(true);

    let tenantSlug = tenant.tenant.fields.tenantslug;

    let pickedProfile;

    const filterToSlugProfile = (slug, prfls) => {
        let slugToUse = slug;

        if(singularCompany) {
            const profileApp = appUtils.getAppInWkspcListByAppType(tenant.tenant, CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES);
            if(profileApp && profileApp.fields.appSpecificDataField ) {
                slugToUse = profileApp.fields.appSpecificDataField;
            }

        }

        let fndProf = prfls.filter( (thisProf) => {
            return thisProf.fields.slugname.toUpperCase() === slugToUse.toUpperCase();
        });

        if(fndProf.length > 0) {
            return fndProf[0];
        }

        return {}
    }

    const getProfiles = async () => {
        setLoadingProfiles(true);
        let extraParams = `force_id=${tenant.tenant.fields.aDGroupID}`;
        let jsonresponse = await utils.getTenantContent(token, CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES, tenantSlug, tenant.ourUserID, extraParams);

        if( jsonresponse.appFields){
            setNavDescr(jsonresponse.appFields);
        }

        if(singularCompany) {
            const profileApp = appUtils.getAppInWkspcListByAppType(tenant.tenant, CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES);

            //we store what company slug to pull up in the appSpecificDataField of the profile app if it is a singular company profile
            if(profileApp && profileApp.fields.appSpecificDataField ) {
                
                pickedProfile = jsonresponse.content.filter( (thisProf) => {
                    return thisProf.fields.slugname.toUpperCase() === profileApp.fields.appSpecificDataField.toUpperCase();
                });    

            }
            
        }

        setProfiles(jsonresponse.content);

        setLoadingProfiles(false);
    }

    useEffect( () => {
        try{
            setLoadingProfiles(true);
            getProfiles();
        } catch(error) {
            console.log(error);
            setLoadingProfiles(false);
        }
    // eslint-disable-next-line
    },[]);

    const getBreadCrumbName = (tenInfo) => {
        let breadCrumb;

        tenInfo.tenant.fields.apps.forEach((app) => {
            if (app.fields.apptype === "company_profiles") {
                if (app.fields.appAlternateTitle) {
                    breadCrumb = 'my_company_profile';
                } else {
                    breadCrumb = 'company_profiles';
                }
            }
        })
        return breadCrumb;
    }

    return (
        !loadingProfiles && tenant ?
            (slug && slug.length > 0) || singularCompany?
                <>
                    <BreadCrumbBar 
                        appName={getBreadCrumbName(tenant)} 
                        item={pickedProfile && pickedProfile.fields ? {title: pickedProfile.fields.agilityContentModelCompanyName} : null} 
                        displayFieldName="title" />
                    
                    <CompanyDisplay tenant={ tenant } profile={ filterToSlugProfile(slug, profiles) } navs={navDescr} token={token} />
                        
                </>
            :
                // show profiles list
                <FilteredPagedCoProfList 
                        rawList={ profiles } 
                        tenant={tenant}
                    />

        : <ListSkeletonLoader preview={false} />
    )
}

export default CoProfileMainPage;
