import React, { useState } from 'react';
import { ToggleOn, ToggleOff } from 'react-bootstrap-icons';
import { Row, Col } from 'react-bootstrap'

import '../styles/components/_lbtoggle.scss'

function LBToggle({ defaultValue = false, label, name, className, onChange, disabled=false }) {

    const [ myValue, setMyValue ] = useState(defaultValue);

    const handleClick = () => {

        setMyValue(!myValue);

        onChange({ target: name ? name: label, value: !myValue });
    }

    return (
        <Row className={'toggle' + (className ? ' ' + className : '')} onClick={disabled !== true ? handleClick : null }>
            <Col className={`toggle-switch${ disabled ? ' disabled': ''}`}>
                {
                    myValue === true ?
                        <span className='toggle-icon'><ToggleOn className="toggle-itself toggle-on" /></span>
                    :
                        <span className='toggle-icon'><ToggleOff className="toggle-itself toggle-off" /></span>
                }
                
                <span className="toggle-label">{ label }</span>
                
            </Col>
        </Row>
    )
}

export default LBToggle