import React, { useEffect, useState} from 'react'; 

import _ from "lodash" // For making deep copies of objects
import { Table, Button, Modal, ButtonGroup, ButtonToolbar} from 'react-bootstrap'

import EnumDeleter from './EnumDeleter';
import NumericInput from '../NumericInput';
import utils from "../../../utils"
import CONSTANTS from "../../../constants"
import '../../../styles/layout/_layout.scss'

function EnumEditor({id, apiKey, closePopup, enumType}) {
  /*--------------------------State variables---------------------------*/
  const [lbWord, setLbWord] = useState();
  const [errors, setErrors] = useState([]);
  const [showDeleteEnum, setShowDeleteEnum] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if(!enumType){
        return;
      }

      if (id) {
        let res = await utils.getData('get_enum', apiKey, "?enum_id=" + id)
        if (typeof res === 'string') {
          window.alert("Failed to fetch enums.");
          closePopup();
        } else {
          if (res) {
            setLbWord(res);
          }
        }
      } else {
        const baseEnum = _.cloneDeep(CONSTANTS.SHELL_ENUM)
        setLbWord({...baseEnum, enum_type:enumType})
      }
    }
    fetchData();
    // React 18 ready
    return () => { };
  }, [apiKey, id, closePopup, enumType]
  )


  

  /*-------------------------Submit form and new antigen----------------------------------*/
  async function handleSubmit (e, deleted=lbWord.deleted){
    e.preventDefault();
    e.stopPropagation();
    let foundAltEnums = new Set()
    var submissionErrors = []
    lbWord.alt_enums.forEach(
      (ae)=> {
        if(foundAltEnums.has(ae.alt_enum)){
          submissionErrors.push(ae.alt_enum +' exists twice')
        }
      }
    )
    
    setErrors(submissionErrors)
    if (submissionErrors.length > 0)  {
      return
    }
    
    const result = await utils.upsert('upsert_enum', apiKey, {...lbWord, deleted:deleted})
    
    if (typeof result!== 'string') {
      closePopup();
      setShowDeleteEnum(false);
    } else {
      // This is a placeholder for whatever we decide
      window.alert(result)
    }
  }


  /*--------------------------------Manage state variables-----------------------*/
  function addSynonym() {
    const tempAltEnums = lbWord.alt_enums
    tempAltEnums.push({alt_enum: '', partner: null})
    setLbWord({...lbWord, alt_enums:tempAltEnums})
  }

  function setSynonym(synonym, index){
    const synonyms = [...lbWord.alt_enums]
    synonyms[index].alt_enum = synonym
    setLbWord({...lbWord, alt_enums: synonyms})
  }

  function deleteSynonym(index) {
    const tempAltEnums = [...lbWord.alt_enums]
    tempAltEnums.splice(index, 1)
    setLbWord({...lbWord, alt_enums: tempAltEnums})
  }

  function printLbWord(){
    console.log(lbWord)
  }

  function restore(e){
    handleSubmit(e, false);
  }


  return (
    <Modal size='lg' show = {true} onHide={closePopup} className={'alc-app'}>
      <Modal.Header>
        <Modal.Title>LB Word Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showDeleteEnum?
           <EnumDeleter
            apiKey={apiKey}
            lbWord={lbWord}
            onSubmit={handleSubmit}
            closePopup={()=>{
              setShowDeleteEnum(false)
            }}
            setLbWord={setLbWord}
           />
        :null}
        {lbWord?
          <>
          
          <div className='input-text'>Word</div>
        <input type='text'
          className='form-ctrl'
          value={lbWord.enum}
          disabled={lbWord.deleted}
          onChange={(e) => (e) = setLbWord({ ...lbWord, enum: e.target.value })}
        />
          <div className='input-text'>Type</div>
          <input type='text'
            className='form-ctrl'
            disabled
            value={lbWord.enum_type}
            onChange={(e) => (e) = setLbWord({ ...lbWord, enum_type: e.target.value })}
          />
          <div className='input-text'>Sort Order</div>
          <NumericInput
            value={lbWord.sort_order}
            disabled={lbWord.deleted}
            onChange={(e) => setLbWord({...lbWord, sort_order:e})}
            className = 'form-ctrl'
          />
        <div><strong>Synonyms</strong></div>

        <Table>
          <thead className='table-heading'>
            <tr>
              <td>Synonym</td>
              {lbWord.deleted===false &&
                <td><div className='clickable-text'
                  onClick={addSynonym} size="sm">add</div>
                </td>
              }
            </tr>
          </thead>
          <tbody>
            {lbWord.alt_enums.map((ae, i) => {
              return <tr key={i}>
                <td>
                  <input
                    className='form-ctrl'
                    value={ae.alt_enum}
                    onChange={(e) => setSynonym(e.target.value, i)}
                  />
                </td>
                <td className='right-align'>
                  <Button variant="danger"
                    size="sm"
                    disabled={lbWord.deleted}
                    onClick={() => deleteSynonym(i)}>-</Button>
                </td>
              </tr>
            })}
          </tbody>
        </Table>
        {errors.length > 0 ?
          <div>
            <div className="error">Errors:</div>
            {
              errors.map((e, idx) => <li className="error" key={idx}>{e}</li>)
            }
          </div>
          : null}
            <ButtonToolbar className='editor-btn'>
              {
                lbWord.deleted?
                <ButtonGroup>
                  <Button onClick={restore} 
                    variant='success'
                    className='m-i-end-03'>
                      Restore
                  </Button>
                </ButtonGroup>
                :
                <ButtonGroup>
                  <Button onClick={()=>setShowDeleteEnum(true)} variant='danger'className='m-i-end-03'>Delete</Button>
                </ButtonGroup>
              }
              
              <ButtonGroup>
                <Button onClick={closePopup} variant="secondary" className='m-i-end-03'>Close</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick={handleSubmit} variant="primary"  className='m-i-end-03'>Submit</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick={printLbWord} variant="primary">Check Word</Button>
              </ButtonGroup>
            </ButtonToolbar>
            </>
            :null
        }
        
      </Modal.Body>
    </Modal>

  )
}

export default EnumEditor;