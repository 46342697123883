//extracted from campaigneffectiveness.org WP 11-15-2024
const diseaseList = [
    {
        "label": "Anemia",
        "value": "anemia"
    },
    {
        "label": "Chickenpox",
        "value": "Chickenpox"
    },
    {
        "label": "Cholera",
        "value": "Cholera"
    },
    {
        "label": "COVID-19",
        "value": "covid-19"
    },
    {
        "label": "Dengue",
        "value": "Dengue"
    },
    {
        "label": "Deworming",
        "value": "deworming"
    },
    {
        "label": "Diphtheria",
        "value": "Diphtheria"
    },
    {
        "label": "Ebola",
        "value": "Ebola"
    },
    {
        "label": "Hepatitis B",
        "value": "Hepatitis B"
    },
    {
        "label": "Hepatitis E",
        "value": "Hepatitis E"
    },
    {
        "label": "HPV",
        "value": "HPV"
    },
    {
        "label": "Influenza",
        "value": "Influenza"
    },
    {
        "label": "Japanese encephalitis",
        "value": "Japanese encephalitis"
    },
    {
        "label": "Lymphatic Filariasis",
        "value": "Lymphatic Filariasis"
    },
    {
        "label": "Malaria",
        "value": "Malaria"
    },
    {
        "label": "Measles",
        "value": "measles"
    },
    {
        "label": "Meningitis",
        "value": "Meningitis"
    },
    {
        "label": "Monkeypox",
        "value": "Monkeypox"
    },
    {
        "label": "Mumps",
        "value": "Mumps"
    },
    {
        "label": "Onchocerciasis",
        "value": "Onchocerciasis"
    },
    {
        "label": "Pertussis",
        "value": "Pertussis"
    },
    {
        "label": "Pneumococcus",
        "value": "Pneumococcus"
    },
    {
        "label": "Polio",
        "value": "Polio"
    },
    {
        "label": "Rotavirus",
        "value": "Rotavirus"
    },
    {
        "label": "Rubella",
        "value": "Rubella"
    },
    {
        "label": "Schistosomiasis",
        "value": "Schistosomiasis"
    },
    {
        "label": "Soil-Transmitted Helminths",
        "value": "Soil-Transmitted Helminths"
    },
    {
        "label": "Tetanus",
        "value": "Tetanus"
    },
    {
        "label": "Trachoma",
        "value": "Trachoma"
    },
    {
        "label": "Tuberculosis",
        "value": "Tuberculosis"
    },
    {
        "label": "Typhoid",
        "value": "Typhoid"
    },
    {
        "label": "Vitamin A",
        "value": "Vitamin A"
    },
    {
        "label": "Yaws",
        "value": "Yaws"
    },
    {
        "label": "Yellow Fever",
        "value": "Yellow Fever"
    }
];

export default diseaseList;