import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import DynamicIcon from './DynamicIcon';
import Sticky from 'react-stickynode';

import LBGanttRow from './LBGantt/LBGanttRow';
import LBGanttGroupByWrapper from './LBGantt/LBGanttGroupByWrapper';


function LBGantt(props) {
    //takes a list of tasks and displays a gantt with custom columns to the left
    // props:  tasks = [], leftColumnsConfig = [{ header: 'Title of the column', type: 'text|link', accessor: '[fieldName]', label: 'if link, what to display in link', iconName='react-bootstrap icon name' }]
    //adding props.groupedBy (fieldName)


    const getColumns = (taskList, groupByField=null) => {
        if(groupByField && groupByField !== '') {
            //sort items by field
            ///create only as many rows as different values in groupedBy field

            //create grouptitles and sort

            let taskGroups = { notSet: []};

            for(let curTask=0; curTask < taskList.length; curTask++) {
                if(taskGroups.hasOwnProperty(taskList[curTask][groupByField])) {
                    taskGroups[taskList[curTask][groupByField]].push(taskList[curTask]);
                } else {
                    if( !taskList[curTask].hasOwnProperty(groupByField) || taskList[curTask][groupByField] === null || taskList[curTask][groupByField] === '' ) {
                        taskGroups.notSet.push(taskList[curTask]);
                    } else {
                        taskGroups[taskList[curTask][groupByField]] = [taskList[curTask]];
                    }
                }
            }

            //convert to array of arrays
            let retArray =[];
            
            Object.keys(taskGroups).sort().forEach( (thisKey) => {
                retArray.push({groupingKey: thisKey, tasks: taskGroups[thisKey]})
            })

            return retArray;
            
        }
    }

    return (
        <div className={`LBGantt-container ${props.className ? props.className : ''}`}>
            <Sticky enabled={true} top={50}>
                <Row className="LBGantt-tools">
                    <Col className='toolbar-col'>
                    {
                        props.groupByField ?

                            <Col sm={1} className={`LBGantt-col col-0 header intervention-header group-by-field`}>
                                { props.groupByFieldLabel ? props.groupByFieldLabel : props.groupByField }
                            </Col>
                        
                        : props.leftColumnsConfig.map((thisColItem, colIndex) => {
                            return (
                                <Col sm={2} className={`LBGantt-col col-${colIndex} header intervention-header`}>
                                    { thisColItem.header }
                                </Col>
                            )
                        })
                    }
                    </Col>
                    <Col  className="LBGantt-col gantt-col header">
                        {/* if zoom level year, show years at this level, else show month, year */}
                        {/*  if zoom level year, show months at this level, else show weeks */}
                        <LBGanttRow header={true} zoomlevel={props.zoom} viewStart={props.currentView} />
                    </Col>
                </Row>
            </Sticky>
            {
                props.groupByField ?
                    // add an additional row wrapping
                   getColumns(props.tasks, props.groupByField).map( (thisItem, index) => {
                        return ( 
                            <LBGanttGroupByWrapper 
                                handleShowIntModal={props.handleShowIntModal}
                                tasksList={thisItem.tasks} 
                                groupByField={thisItem.groupingKey} 
                                rowIdx={index} 
                                zoom={props.zoom} 
                                currentView={props.currentView} /> 
                        )
                   })
                :
                    props.tasks.map((curTask, rowIdx) => {
    
                        //go through the leftColumnsConfig for each task in the row
                        return (
                            <Row className={`LBGantt-row row-${rowIdx}`}>
                                <>
                                    {
                                        props.leftColumnsConfig.map((thisColItem, colIndex) => {
                                            return (
                                                <Col  className={`LBGantt-col col-${colIndex}`}>
                                                    {
                                                        thisColItem.type === 'text' ?
                                                            <span className="col-text">
                                                                {curTask.hasOwnProperty(thisColItem.accessor) ? curTask[thisColItem.accessor] : ''}
                                                            </span>
                                                            :
                                                            thisColItem.type === 'link' ?
                                                                <Link 
                                                                    className="col-link" 
                                                                    to={curTask.hasOwnProperty(thisColItem.accessor) ? curTask[thisColItem.accessor] : ''}>
                                                                    {
                                                                        <>
                                                                            {
                                                                                thisColItem.label && thisColItem.label !== '' ?
                                                                                    <span className="label">{thisColItem.label}</span>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                thisColItem.iconName && thisColItem.iconName !== '' ?
                                                                                    <DynamicIcon className="link-icon" iconName={thisColItem.iconName} />
                                                                                    : null
                                                                            }
                                                                        </>
                                                                    }
                                                                </Link>
                                                                : null
                                                    }
                                                </Col>
                                            )
                                        })
                                    }
    
                                    <LBGanttRow 
                                        header={false} 
                                        zoomlevel={props.zoom} 
                                        viewStart={props.currentView} 
                                        task={curTask} />
                                </>
                            </Row>
                        )
    
                     })
                }
        </div>
    )
}

export default LBGantt