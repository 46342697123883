import React, { useMemo } from 'react';
import { useTable, useRowSelect, useResizeColumns, useFlexLayout } from 'react-table';
import { Row, Col } from 'react-bootstrap';

import ReactTblDropDownEditCell from '../react-tbl-editable-cells/ReactTblDropDownEditCell';
import ReactTblDatePkrEditCell from '../react-tbl-editable-cells/ReactTblDatePkrEditCell';
import ReactTblMultiTagPickerCell from '../react-tbl-editable-cells/ReactTblMultiTagPickerCell';
import ReactTblSelectEditCell from '../react-tbl-editable-cells/ReactTblSelectEditCell';

import utils from '../../utils';
import CONSTANTS from '../../constants';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import ReactTblCellOnClickModal from '../react-tbl-editable-cells/ReactTblCellOnClickModal';



function MarketActionsTable({ handleShowIntModal, formattedMarketList, onUpdate, memberList, marketIDs, organization, vaccine, tenant }) {

  //let featureflags = utils.getFeatureflags(process.env.REACT_APP_ENV, 'filter-lb-out-market-actions', tenant.tenant.fields.featureflags);

  //need to add styles to the text boxes that make them invisible until onFocus

  //put the assignees into an array of simple strings, taking out null users, and sort by alpha
  let assigneesRaw = memberList.filter((item) => {
      return item.mail !== null;
    }).map((thisPerson) => {
      return thisPerson.mail;
    }).sort();

  //go through interventions and get assignees 
  let additionalAssignees = formattedMarketList.map((theMItem) => {
    return theMItem.assigned_to;
  });


  let the_market_name = formattedMarketList.length > 0 ? formattedMarketList[0].market_name : '';

  let assignees = utils.setMembersListBasedOnMarketName(assigneesRaw, additionalAssignees, the_market_name, true, tenant.tenant); 

  const priorities = [
    {
      label: "Select...",
      value: ""
    },
    {
      label: "High",
      value: 0
    },
    {
      label: "Medium",
      value: 1
    },
    {
      label: "Low",
      value: 2
    }
  ];

  const dueDateTypes = [
    {
      label: "Select...",
      value: ""
    },
    {
      label: "Ongoing",
      value: 0
    },
    {
      label: "Annual",
      value: 1
    },
    {
      label: "N/A",
      value: 2
    },
    {
      label: "Fixed date",
      value: 3
    }
  ];


  const hmfCategory = [
    {
      label: "Demand",
      value: "d9ff9b7b-ec39-49c0-8fe1-5fa26bd6939a"
    },
    {
      label: "Supply",
      value: "8ecdc44e-4b00-4a2a-9afe-6253f4a7ac10"
    },
    {
      label: "Innovation",
      value: "041f197e-dfeb-43c8-9efd-16d5c3768e79"
    }
  ];

  const priorInnovation = [
    {
      label: "Prioritised for interventions",
      value: 0,
    },
    {
      label: "Prioritised for monitoring",
      value: 1,
    },
    {
      label: "Deprioritized",
      value: 2,
    },
    {
      label: "NA",
      value: 3,
    }
  ];

  const accuracy = [
    {
      label: "Select...",
      value: ""
    },
    {
      label: 'Low',
      value: 0,
    },
    {
      label: 'Average',
      value: 1,
    },
    {
      label: 'Exact',
      value: 2,
    }
  ]

  const updatedData = (id, fieldName, value) => {
    onUpdate({ id: id, field: fieldName, value: value });
  }

  const assigneesToPass = utils.getOptions(assignees);

  const getColumnHeaders = (marketIDs) => {
    let retObj;

    if (marketIDs.length > 1) {
      retObj = [
        {
          Header: 'Market',
          accessor: 'market_name',
          className: 'market-column'
        },
        {
          Header: 'Product',
          accessor: rowObj => {
            return (
              <ReactTblSelectEditCell
                initialValue={rowObj.vaccine}
                row={rowObj.id}
                column={'vaccine'}
                updateMyData={updatedData}
                selectionValues={utils.getOptions(vaccine)}
                forName='vaccine' />
            )
          },
        },
        {
          Header: 'Objective',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
                myValue={rowObj.objective}
                handleShowIntModal={handleShowIntModal}
                rowID={rowObj.id}
              />
            )
          },
        },
        {
          Header: 'Target Outcome',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
              myValue={rowObj.target_outcome}
              handleShowIntModal={handleShowIntModal}
              rowID={rowObj.id}
            />
            )
          },
        },
        {
          Header: 'Intervention',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
                myValue={rowObj.task_name}
                handleShowIntModal={handleShowIntModal}
                rowID={rowObj.id}
              />
            )
          },
          className: 'intervention-col',
        },
        {
          Header: 'HMF Category',
          accessor: rowObj => {
            return (
              <ReactTblMultiTagPickerCell
                initialValue={rowObj.hmf_category}
                row={rowObj.id}
                column={'hmfCategory'}
                updateMyData={updatedData}
                selectionValues={hmfCategory}
                customField={true} />
              )
            },
            className: 'hmfCategory',
        },
        {
          Header: 'Supplier',
          accessor: rowObj => {
            return (
              <ReactTblSelectEditCell
                initialValue={rowObj.supplier}
                row={rowObj.id}
                column={'supplier'}
                updateMyData={updatedData}
                selectionValues={utils.getOptions(organization)}
                forName='vaccine'
              />
            )
          },
        },
        {
          Header: 'Prioritized Innovation',
          accessor: rowObj => {
            return (
              <ReactTblDropDownEditCell
                initialValue={rowObj.prior_innovation}
                row={rowObj.id}
                column={'priorInnovation'}
                updateMyData={updatedData}
                selectionValues={priorInnovation}
                forName='priorInnovation' 
                customField={true}/>
            )
          },
        },
        {
          Header: 'Assignee',
          accessor: rowObj => {
            return (
              <ReactTblDropDownEditCell
                initialValue={rowObj.assigned_to}
                row={rowObj.id}
                column={'assignedTo'}
                updateMyData={updatedData}
                selectionValues={assigneesToPass}
                forName='assignedTo'
                customField={true} 
                 />
            )
          },
        },
        {
          Header: 'Accuracy',
          accessor: rowObj => {
            return (
              <ReactTblDropDownEditCell
                initialValue={rowObj.accuracy}
                row={rowObj.id}
                column={'accuracy'}
                updateMyData={updatedData}
                selectionValues={accuracy}
                forName='accuracy' 
                customField={true}/>
            )
          },
        },
        {
          Header: 'Stakeholder Accountable',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
              myValue={rowObj.stakeholder_accountable}
              handleShowIntModal={handleShowIntModal}
              rowID={rowObj.id}
            />
            )
          },
        },
        {
          Header: 'Stakeholder Supporting',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
                myValue={rowObj.stakeholder_supporting}
                handleShowIntModal={handleShowIntModal}
                rowID={rowObj.id}
              />
            )
          },
        },
        {
          Header: 'Priority',
          accessor: rowObj => {
            return (
              <ReactTblDropDownEditCell
                initialValue={rowObj.priority}
                row={rowObj.id}
                column={'priority'}
                updateMyData={updatedData}
                selectionValues={priorities}
                forName='priority' />
            )
          },
        },
        {
          Header: 'Due Date',
          accessor: rowObj => {
            return (
              <ReactTblDatePkrEditCell
                initialValue={rowObj.due_date}
                row={rowObj.id}
                column={'dueDate'}
                updateMyData={updatedData}
              />
            )
          }
        },
        {
          Header: 'Due Date Type',
          accessor: rowObj => {
            return (
              <ReactTblSelectEditCell
                initialValue={rowObj.due_date_type}
                row={rowObj.id}
                column={'dueDateType'}
                updateMyData={updatedData}
                selectionValues={dueDateTypes}
                forName='dueDateTypes' />
            )
          },
        },
        {
          Header: 'Status',
          accessor: rowObj => {
            return (
              <ReactTblDropDownEditCell
                initialValue={rowObj.status}
                row={rowObj.id}
                column={'status'}
                updateMyData={updatedData}
                selectionValues={CONSTANTS.MARKET_ACTIONS_STATUS_SELECT}
                customField={true}
                forName='statuses' />
            )
          },
        },
      ]
    } else {
      retObj = [
        {
          Header: 'Objective',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
              myValue={rowObj.objective}
              handleShowIntModal={handleShowIntModal}
              rowID={rowObj.id}
            />
            )
          },
        },
        {
          Header: 'Target Outcome',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
                myValue={rowObj.target_outcome}
                handleShowIntModal={handleShowIntModal}
                rowID={rowObj.id}
              />
            )
          },
        },
        {
          Header: 'Intervention',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
                myValue={rowObj.task_name}
                handleShowIntModal={handleShowIntModal}
                rowID={rowObj.id}
              />
            )
          },
          className: 'intervention-col',
        },
        {
          Header: 'HMF Category',
          accessor: rowObj => {
            return (
              <ReactTblMultiTagPickerCell
                initialValue={rowObj.hmf_category}
                row={rowObj.id}
                column={'hmfCategory'}
                updateMyData={updatedData}
                className='hmfCategory'
                selectionValues={hmfCategory} 
                customField={true}/>
            )
          },
          className: 'hmfCategory',
        },
        {
          Header: 'Supplier',
          accessor: rowObj => {
            return (
              <ReactTblSelectEditCell
                initialValue={rowObj.supplier}
                row={rowObj.id}
                column={'supplier'}
                updateMyData={updatedData}
                selectionValues={utils.getOptions(organization)}
                forName='vaccine'
              />
            )
          },
        },
        {
          Header: 'Prioritized Innovation',
          accessor: rowObj => {
            return (
              <ReactTblDropDownEditCell
                initialValue={rowObj.prior_innovation}
                row={rowObj.id}
                column={'priorInnovation'}
                updateMyData={updatedData}
                selectionValues={priorInnovation}
                forName='priorInnovation' 
                customField={true}/>
            )
          },
        },
        {
          Header: 'Assignee',
          accessor: rowObj => {
            return (
              <ReactTblDropDownEditCell
                initialValue={rowObj.assigned_to}
                row={rowObj.id}
                column={'assignedTo'}
                updateMyData={updatedData}
                selectionValues={assigneesToPass}
                forName='assignedTo'
                customField={true}/>
            )
          },
        },
        {
          Header: 'Accuracy',
          accessor: rowObj => {
            return (
              <ReactTblDropDownEditCell
                initialValue={rowObj.accuracy}
                row={rowObj.id}
                column={'accuracy'}
                updateMyData={updatedData}
                selectionValues={accuracy}
                forName='accuracy'
                customField={true} />
            )
          },
        },
        {
          Header: 'Stakeholder Accountable',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
                myValue={rowObj.stakeholder_accountable}
                handleShowIntModal={handleShowIntModal}
                rowID={rowObj.id}
              />
            )
          },
        },
        {
          Header: 'Stakeholder Supporting',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
                myValue={rowObj.stakeholder_supporting}
                handleShowIntModal={handleShowIntModal}
                rowID={rowObj.id}
              />
            )
          },
        },
        {
          Header: 'Priority',
          accessor: rowObj => {
            return (
              <ReactTblDropDownEditCell
                initialValue={rowObj.priority}
                row={rowObj.id}
                column={'priority'}
                updateMyData={updatedData}
                selectionValues={priorities}
                forName='priority' 
                customField={true}/>
            )
          },
        },
        {
          Header: 'Due Date',
          accessor: rowObj => {
            return (
              <ReactTblDatePkrEditCell
                initialValue={rowObj.due_date}
                row={rowObj.id}
                column={'dueDate'}
                updateMyData={updatedData}
              />
            )
          }
        },
        {
          Header: 'Due Date Type',
          accessor: rowObj => {
            return (
              <ReactTblSelectEditCell
                initialValue={rowObj.due_date_type}
                row={rowObj.id}
                column={'dueDateType'}
                updateMyData={updatedData}
                selectionValues={dueDateTypes}
                forName='dueDateTypes' />
            )
          },
        },
        {
          Header: 'Status',
          accessor: rowObj => {
            return (
              <ReactTblDropDownEditCell
                initialValue={rowObj.status}
                row={rowObj.id}
                column={'status'}
                updateMyData={updatedData}
                customField={true}
                selectionValues={CONSTANTS.MARKET_ACTIONS_STATUS_SELECT}
                forName='statuses'/>
            )
          },
        },
        {
          Header: 'Notes',
          accessor: rowObj => {
            return (
              <ReactTblCellOnClickModal 
                myValue={rowObj.notes}
                handleShowIntModal={handleShowIntModal}
                rowID={rowObj.id}
              />
            )
          },
          className: 'intervention-col',
        }
      ]
    }
    return retObj;
  }

  // Defining columns for table

  const columns = useMemo(
    () => getColumnHeaders(marketIDs),
    // eslint-disable-next-line
    []
  )

  const defaultColumn = useMemo(
    () => ({
      width: 220,
      minWidth: 41,
      maxWidth: 1000
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable({
    columns,
    data: formattedMarketList.interventions ? formattedMarketList.interventions : formattedMarketList,
    defaultColumn
  },
    useResizeColumns,
    useFlexLayout,
    useRowSelect
  )

  return (
      formattedMarketList ?
        <Row>
          <Col className='table-column' md={12}>
            <div className='table-div'>
              <table {...getTableProps()}>
                <thead>
                  {
                    headerGroups.map((headerGroup, index) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {
                          headerGroup.headers.map((column, index) => (
                            <th {...column.getHeaderProps({
                              className: column.className
                            }
                            )}
                              key={index}
                            >
                              {column.render('Header')}
                              {column.canResize && (
                                <div{...column.getResizerProps()}
                                  className={`resizer ${column.resizing ? 'isResizing' : ''}`}
                                />
                              )}
                            </th>
                          ))}
                      </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {
                    rows.map((row, index) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()} key={index}>
                          {
                            row.cells.map((cell, index) => {
                              return (
                                <td {...cell.getCellProps({
                                  className: cell.column.className
                                })} key={index}>
                                  {cell.render('Cell')}
                                </td>
                              )
                            })
                          }
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>


              <p className='selected-rows'>Selected Rows: {Object.keys(selectedRowIds).length}</p>
              <pre>
                <code>
                  {JSON.stringify(
                    {
                      selectedRowIds: selectedRowIds,
                      'selectedFlatRows[].original': selectedFlatRows.map(
                        d => d.original
                      ),
                    },
                    null,
                    2
                  )}
                </code>
              </pre>
            </div>
          </Col>
        </Row>
        : <ListSkeletonLoader preview={false} />
  )
}

export default MarketActionsTable;