import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { ListUl } from 'react-bootstrap-icons';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import CONSTANTS from '../../constants';
import { LinkContainer } from 'react-router-bootstrap';
import tenantUtils from '../../tenantUtils';
import utils from '../../utils';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';

function MarketActionsListView({ tenant, content, spaceID, onMarketsSelected }) {

  const { instance, accounts } = useMsal();

  const filterOutLBTestMarket = (list, admnMktName) => {
    if(!tenantUtils.isAdminMember(accounts) && accounts[0].username !== 'testlevelq@linksbridgedevtest.onmicrosoft.com') {
      return list.filter( (thisItem) => {
        return thisItem.name.toLowerCase().trim().indexOf(admnMktName.toLowerCase().trim()) < 0;
      })
    }
    return list;
  };

  const [markets] = useState(filterOutLBTestMarket([...content.lists], CONSTANTS.MARKET_ACTIONS_ADMIN_MKT_NAME));
  const [accToken, setAccToken] = useState();
  const [loading, setLoading] = useState();
  let [mktType, setMktType] = useState('');
  const [mktIDList, setmktIDList] = useState([]);

  const isAuthenticated = useIsAuthenticated();



  const request = {
    scopes: ["User.Read"]
  };

  

  const getAccessToken = async (instance, account) => {
    try {
      let theAccToken = await instance.acquireTokenSilent({ ...request, account: account });
      setAccToken(theAccToken);
    } catch (error) {
      console.log(error);
    }
  }


  const setMarketID = (e, mktID, mktType) => {
   
   
    if (e.target.checked === true) {
      
      if(mktType === 'select-all-markets') {
        let tempArr = [];
        setMktType(mktType)
        markets.forEach((market) => {
          tempArr.push(market.id)
          setmktIDList(tempArr)
        })

        onMarketsSelected(tempArr);
      } else {
        setmktIDList([
          ...mktIDList,
          mktID
        ]);
        onMarketsSelected([
          ...mktIDList,
          mktID
        ]);
        mktType ? setMktType(mktType) : setMktType('');
      }
    }
      
    if (e.target.checked === false) {
      if(mktType === 'select-all-markets') {
        setmktIDList([]);
        onMarketsSelected([])
      } else {
        let newMktIdList = mktIDList.filter((id) => id !== mktID)
        setmktIDList(newMktIdList);
        onMarketsSelected(newMktIdList);
      }
    }
  }

  const sortMarkets = (mktArray) => {

    mktArray.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    })
    return mktArray;
  }

  useEffect(() => {
    if (isAuthenticated) {
      try {
        (async () => {
          setLoading(true);
          getAccessToken(instance, accounts[0]);
          setLoading(false);
        }
        )()
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    //eslint-disable-next-line
  }, [isAuthenticated])

  const getNumInterventions = (mktID, mktObj) => {
    let obj = mktObj[mktID]

    //change this a bit to drill into the sub objects
    //obj setup:  { mktID: { interventionID: { dueDateYear|no_due_date: { statusKey: 1 }}}}

    if(obj) {

      let complete = 0;
      let notStarted = 0;
      let inProgress = 0;
      let delayed = 0;
      let deprioritized = 0;
      let num = 0;

      for(const intProp in obj){
        
        let thisObjInt = obj[intProp];

        for(const yrProp in thisObjInt) {

          let yearKey = typeof(yrProp) === 'number'? yrProp.toString() : yrProp;

          complete += thisObjInt[`${yearKey}`].hasOwnProperty('complete')? parseInt(thisObjInt[`${yearKey}`]['complete']) : 0
          notStarted += thisObjInt[`${yearKey}`].hasOwnProperty('not started') ? parseInt(thisObjInt[`${yearKey}`]['not started']) : 0;
          inProgress += thisObjInt[`${yearKey}`].hasOwnProperty('in progress') ? parseInt(thisObjInt[`${yearKey}`]['in progress']) : 0;
          delayed += thisObjInt[`${yearKey}`].hasOwnProperty('delayed') ? parseInt(thisObjInt[`${yearKey}`]['delayed']) : 0;
          deprioritized += thisObjInt[`${yearKey}`].hasOwnProperty('deprioritized') ? parseInt(thisObjInt[`${yearKey}`]['deprioritized']) : 0;
          
        }
      }

      num = complete + notStarted + inProgress + delayed + deprioritized;
      return num;
    } else {
      return 0;
    }
  }

  return (
    markets && accToken && !loading ?
      <>
      <Row>
        <Col className='m-b-end-06 p-i-end'>
          <h4 className='m-b-end'><ListUl /> Markets List</h4>
        </Col>
      </Row>
      <Row className='full-width market-rows'>
        <Row className='cross-mkt-btn-row p-i-end  m-b-end-04'>
          <Col className='all-mkt-check-col' lg={5} xl={6}>
            <input
              className='all-mkt-checkbox m-i-end-03 m-b-start-04'
              type='checkbox'
              name='select-all-markets'
              value=''
              onChange={(e) => setMarketID(e, null, 'select-all-markets')}
            />
            <label className='body-med'>Select All Markets</label>
          </Col>
          <Col lg={7} xl={6} className='p-i-end'>
            <Row>
              <Col lg={7} className='selected-mkt-col'>
                <p className='body-med m-b-start-04'>{`(${mktIDList.length ? mktIDList.length : '0'} Markets Selected)`}</p>
              </Col>
              <Col lg={5} className='p-i-end'>
                <LinkContainer to={`/${utils.findURLPath(tenant.tenant.fields.apps, "market_actions")}/markets/${mktIDList.join()}?mktType=${mktType}`}>
                  <Button disabled={mktIDList.length < 1} className='side-col-btn tertiary-button'>View Cross Market</Button>
                </LinkContainer>
              </Col>
            </Row>
          </Col>

        </Row>
        {
          sortMarkets(markets).map((thisMarket, index) => {
            return (
                <Row className='mkt-row p-i-end p-i-start'>
                  <Col className='checkbox-col'>
                    <input
                      key={index}
                      type='checkbox'
                      checked={mktIDList.includes(thisMarket.id) ? true : false}
                      name={thisMarket.name}
                      value={thisMarket.id}
                      onChange={(e) => setMarketID(e, thisMarket.id, 'cross-markets')}
                    >
                    </input>
                  </Col>
                  <Col className=' p-i-end mkt-name'>
                    <Row>
                      <Col lg={6} xl={8} xxl={9}>
                        <div className='m-b-start-03'>
                          {thisMarket.name}
                        </div>
                      </Col>
                      <Col lg={6} xl={4} xxl={3} className='p-i-end-02'>
                        <Row>
                          <Col className='p-i-start' lg={7}>
                            <p className='mkt-count m-b-start-03'>{getNumInterventions(thisMarket.id, content.summarized)} Interventions</p>
                          </Col>
                          <Col lg={5} className='view-mkt-col'>
                            <LinkContainer to={`/${utils.findURLPath(tenant.tenant.fields.apps, "market_actions")}/markets/${thisMarket.id}`}>
                              <Button className='btn-sm-solid-100 tertiary-button'>View Market</Button>
                            </LinkContainer>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
            )
          }
          )}
        <Row className='end-cross-mkt-btn p-i-end'>
          <Col lg={5} xl={6}>
          </Col>
          <Col lg={7} xl={6} className='p-i-end'>
            <Row>
              <Col lg={7} className='selected-mkt-col'>
                <p className='body-med m-b-start-04'>{mktIDList.length ? mktIDList.length : '0'} Markets Selected</p>
              </Col>
              <Col lg={5} className='p-i-end'>
                <LinkContainer to={`/${utils.findURLPath(tenant.tenant.fields.apps, "market_actions")}/markets/${mktIDList.join()}?mktType=${mktType}`}>
                  <Button disabled={mktIDList.length < 2} className='side-col-btn tertiary-button'>View Cross Market</Button>
                </LinkContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
      </>
      : <ListSkeletonLoader preview={false} />
  )
}

export default MarketActionsListView;