import React, {useEffect, useState} from 'react';
import { Image, Dropdown, Spinner, Row, Col } from 'react-bootstrap';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { ChevronUp, HouseFill, ThreeDotsVertical } from 'react-bootstrap-icons';


function WorkspaceNavDropdown({currentTenant, show}) {

  const [ loading, setLoading ] = useState(true);
  const [ tenantList, setTenantList ] = useState('1');
  const isAuthenticated = useIsAuthenticated();

  const submitSelectedTenantHandler = (e) => {

    if( e !== 'collapse') {
      window.location = `/?tenid=${e}`;
    }
  }

  const { instance, accounts } = useMsal();

  const request = {
    scopes: ["User.Read"]
  };

  const getAccessToken = async (instance, account) => {
    try {
      let theAccToken = await instance.acquireTokenSilent({ ...request, account: account });
      return theAccToken;
    } catch (error) {
      console.log(error);
    }

  }

  const getTenantList = async (accToken) => {
    let res = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-tenants-list`, {
      headers: { "Authorization": "Bearer " + accToken.accessToken }
    });
    let jsonRes = await res.json();
    setTenantList(jsonRes.tenants);
  }

  useEffect(() => {
    if (isAuthenticated) {
      try {
        (async () => {
          setLoading(true);
          let accToken = await getAccessToken(instance, accounts[0]);
          await getTenantList(accToken);
          setLoading(false);
        }
        )()
      }
      catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    !loading && currentTenant ?
      <Dropdown onSelect={(e) => submitSelectedTenantHandler(e)}>
        <Dropdown.Toggle variant="success" id="workspace-selector" className='workspace-selector tertiary-button'>
          {
            currentTenant.tenant && currentTenant.tenant.fields && currentTenant.tenant.fields.logo && currentTenant.tenant.fields.logo.url ?
              <Image src={currentTenant.tenant.fields.logo.url} alt={currentTenant.tenant.fields.name} />
            : <HouseFill  style={{'float':'left'}}/>
          }
          {
          show === true ?
            currentTenant.tenant.fields && currentTenant.tenant.fields.name && currentTenant.tenant.fields.name.length > 17 ? 
              `${currentTenant.tenant.fields.name.slice(0,17)}...` 
                : currentTenant.tenant.fields.name
            : ''
              }
          <ThreeDotsVertical/>
        </Dropdown.Toggle>
        <Dropdown.Menu className='wks-dd-menu'>
          <>
            {
              tenantList.map((ten) => {
                return (
                    <Dropdown.Item eventKey={ten.tenantID}>
                      <Row>
                        <Col sm={4}>
                        {
                          ten && ten.logoSm && ten.logoSm.url ?
                            <Image src={ten.logoSm.url} alt={ten.tenantName} style={{height: '40px'}}/>
                            : ''
                        }
                        </Col>
                        <Col  id={ten.tenantSlug} sm={8}>
                          {ten.tenantName && ten.tenantName.length > 15 ? `${ten.tenantName.slice(0,15)}...`: ten.tenantName}
                        </Col>
                      </Row>
                    </Dropdown.Item>
                )
              })
            }
            <Dropdown.Item eventKey={'collapse'}>
                <Row className='collapse-workspace'>
                  <Col className='hide'>
                    Collapse Workspaces
                  </Col> 
                  <Col>
                    <ChevronUp/>
                  </Col>
                  </Row>
            </Dropdown.Item>
          </>
        </Dropdown.Menu>
      </Dropdown>
    : <Spinner animation='border' />
  )
}

export default WorkspaceNavDropdown