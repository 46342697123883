import React from 'react';
import utils from '../../utils';

function KeyEventsDisplay({ entityToUse, globalDisplay=false }) {

    const eventList = !globalDisplay && entityToUse.fields && entityToUse.fields.keyEvents ? entityToUse.fields.keyEvents : [];

    const printDateInfo = (objFields) => {
        let returnedText = utils.getDateString(objFields.date, 'long');

        if(objFields.hasOwnProperty('dateEnds')) {
            returnedText = returnedText + ' - ' + utils.getDateString(objFields.dateEnds, 'long');
        }

        return returnedText;
    }

    //sort by date newest first
    let sortedEventList = eventList.sort( (firstDate, secDate) => {
        return new Date(secDate.fields.date) - new Date(firstDate.fields.date);
    });

    return (
        <ul className='key-events-list'>
            {
                sortedEventList.map((thisEvent) => {
                    return (
                        <li>
                            <div>
                                <h5>{printDateInfo(thisEvent.fields)}: {thisEvent.fields.title}</h5>
                                { thisEvent.fields.description ?
                                    <div className='event-description' dangerouslySetInnerHTML={{__html: thisEvent.fields.description}} />
                                : null }
                            </div>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default KeyEventsDisplay