import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CheckBoxDropDownSelector from '../MktActionsFilterBarComps/CheckBoxDropDownSelector';

function MarketAndYearFilterPanel({ onSet, marketList, selMkts, selYears }) {

  const pickedAValue = (value) => {

    let retObj = {};
    retObj[value.target.id] = value.target.checked;
    retObj.from = value.target.name;

    if(onSet) {

      onSet(retObj);
    }
  }

  const getMarketList = (mktlist, selectedMkts) => {
    let retObj = {};

    //change to Map object
    
    // if(selectedMkts.hasOwnProperty('Select All')){
    //   //don't let no due date or any years be selected
    //   retObj['Select All'] = selectedMkts['Select All'];
    // } else {
    //   retObj['Select All'] = false;
    // }

    //filter out Test Market
    mktlist = mktlist.filter((thisMkt) => { return thisMkt.name.toLowerCase() !== 'test market'});

    for(let i=0; i< mktlist.length; i++) {
      let thisMktName = mktlist[i].name;

      if(retObj['Select All'] === true) {
        //if select All is checked, check all other markets
        retObj[thisMktName] = true;
      } else {
        
        if(selectedMkts.hasOwnProperty(thisMktName)) {
          retObj[thisMktName] = selectedMkts[thisMktName];
        } else {
          retObj[thisMktName] = false;
        }

      }

      
    }

    return retObj;
  }

  const getMktsSelectedLabel = () => {
    if(selMkts.hasOwnProperty('Select All') || Object.keys(selMkts).length < 1) {
      return "All Markets Selected";
    }

    if(Object.keys(selMkts).length > 0) {
      let theString = Object.keys(selMkts).join(', ');
      if(theString.length > 35) {
        theString = theString.substring(theString, 32) + '...'
      }
      return theString;
    } else {
      return "Select Markets";
    }
    
  }

  const getYearsSelectedLabel = () => {
    if(selYears.hasOwnProperty('Select All') || Object.keys(selYears).length < 1) {
      return "All Years Selected";
    }

    if(Object.keys(selYears).length > 0) {
      let theString = Object.keys(selYears).join(', ');
      if(theString.length > 35) {
        theString = theString.substring(theString, 32) + '...'
      }
      return theString;
    } else {
      return "Select Years";
    }
  }

  const getOptionsForYears = (selectedYrs) => {
    let retObj = {};

    //change to Map object

    // if(selectedYrs.hasOwnProperty('Select All')){
    //   //don't let no due date or any years be selected
    //   retObj['Select All'] = selectedYrs['Select All'];
    // } else {
    //   retObj['Select All'] = false;
    // }
    
    //if select All is checked, uncheck if any other years marked


    if(selectedYrs.hasOwnProperty('No Due Date')) {
      retObj['No Due Date'] = selectedYrs['No Due Date'];
    } else {
      retObj['No Due Date'] = false;
    }
    
    const thisYear = new Date().getFullYear();

    
    for(let curYear = thisYear-2; curYear < thisYear+8; curYear++ ) {
      if(retObj['Select All'] === true) {
        retObj[curYear] = true;
      } else {
        if(selectedYrs && selectedYrs.hasOwnProperty(curYear)) {
          retObj[curYear] = selectedYrs[curYear];
        } else {
           retObj[curYear] = false;
        }
      }
     
    }

    //if selected all, mark all as true
    // if(retObj['Select All'] === true) {
    //   retObj['No Due Date'] = true;
    // }

    return retObj;
  }

  return (
    <>
      <Row className="fitering-panel" style={{paddingTop: '20px', minHeight: '75px'}}>
        <Col>
          <CheckBoxDropDownSelector 
            name="markets-selector"
            labelString={getMktsSelectedLabel()} 
            options={getMarketList(marketList.lists, selMkts)}
            displayOptionHandler={pickedAValue} 
            keepOpen={true} />
        </Col>
        <Col>
          <CheckBoxDropDownSelector 
            name="years-selector"
            labelString={getYearsSelectedLabel()} 
            options={getOptionsForYears(selYears)}
            displayOptionHandler={pickedAValue} 
            keepOpen={true} />
        </Col>
      </Row>
    </>
  )
}

export default MarketAndYearFilterPanel