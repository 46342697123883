import React, { useState, useEffect } from 'react';
import { Dropdown, Row, Col, Button } from 'react-bootstrap';
import CheckBoxDropDownSelector from '../../MktActionsFilterBarComps/CheckBoxDropDownSelector';
import { ChevronDown, PlusCircle } from 'react-bootstrap-icons';
import PMTCONSTANTS from '../pmtConstants';
import PMTListViewManipulator from '../PMTListViewManipulator';
import PMTExcelExport from '../PMTExcelExport';
import BtnDynamic from '../../Layout/BtnDynamic';
import utils from '../../../utils';

function PMTMarketActionsToolbar({
  topList, listViewOption, selectionHandler, handleShowChange,
  listViewHandle, selectedListItems, appName, options,
  filterToListsSelected, toggleArchive, handleShowListItemModal, displayOptionHandler,
  memberList, marketOptions
}) {
    
  const [selectedMarkets, setSelectedMarkets] = useState(selectedListItems || ['Ebola Market']); // Default to 'Ebola Market'
  const [filteredMarketOptions, setFilteredMarketOptions] = useState([]);

  const isDisabled = topList.length === 0;



  // Define listViewObj as it's referenced in the JSX
  const listViewObj = {
    "1": "Table",
    "2": "Kanban",
  };


// Archive button content
  const archiveBtnContent = {
    name: `Archive`,
    icon: utils.getIcon('archive'),
  };



  // Function for market selection
  const handleSelectedListItems = (val, selected, fullList) => {
    let updatedSelection;

    if (val.target.defaultValue === 'select-all') {
      updatedSelection = selected.length === fullList.length ? [] : fullList;
    } else {
      updatedSelection = selected.includes(val.target.defaultValue)
        ? selected.filter(item => item !== val.target.defaultValue)
        : [...selected, val.target.defaultValue];
    }

    setSelectedMarkets(updatedSelection);
    selectionHandler(updatedSelection);
  };



  // Convert options into object format for CheckBoxDropDownSelector
  const getOptionsObj = (list, selected) => {
    let retObj = {};
    list.forEach((key) => {
      retObj[key] = selected.includes(key);
    });
    return retObj;
  };



  // set
  useEffect(() => {
    setFilteredMarketOptions(marketOptions);
  }, [marketOptions, topList]);



  // Handler for filtering options based on search term
  const handleSearchTerm = (searchTerm) => {
    const filteredMarkets = marketOptions.filter(market =>
      market.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMarketOptions(filteredMarkets);
  };



  // When multiple markets are selected, show just the Market name and remove the word "Market"
  const getLabelString = () => {

    if (selectedMarkets.length > 3) {
        return 'Multiple selected';
    }

    if (selectedMarkets.length === Object.keys(marketOptions).length) {
      return 'All markets selected';
    }
    
    // Map through the selected markets and remove "Market" from the label
    const formattedMarkets = selectedMarkets.map((market) => 
      market.replace(' Market', '') // Remove ' Market' from the name
    );
  
    if (formattedMarkets.length > 1) {
      return formattedMarkets.join(', ');
    }
  
    if (formattedMarkets.length === 1) {
      return formattedMarkets[0];
    }
  
    return 'Select...';
  };



  // Return dropdown option for "More Display Options"
  const getDropdownOption = (temp) => {
    const optionsArray = Object.keys(temp);
    let returnString = "";
    let filteredArray = optionsArray.filter((option) => temp[option] === true);
    
    if (filteredArray.length > 0) {
      returnString = filteredArray.map((option, index) => index > 0 ? `${option.slice(0, 3)}...` : option).join(", ");
    } else {
      returnString = 'Select...';
    }
    
    return returnString;
  };

  


  return (
    <Row className="markets-toolbar-responsive">
      <Col className="dropdowns-container" lg={8}>

        {/* View Dropdown */}
        <div className="toolbar-dropdown m-i-end-03">
          <label className="dropdown-label">View</label>
          <Dropdown className="toolbar-standard-dropdown">
            <Dropdown.Toggle className="select-toggle">
              {listViewObj[listViewOption]}
              <ChevronDown className="chevron-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="select-dropdown-menu">
              {Object.entries(listViewObj).map(([key, name], idx) => (
                <Dropdown.Item
                  key={idx}
                  disabled={isDisabled}
                  eventKey={key}
                  active={listViewOption === key}
                  onClick={() => listViewHandle(key)}
                >
                  {name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Markets Dropdown */}
        <div className="toolbar-dropdown m-i-end-03">
          <label className="dropdown-label">{PMTCONSTANTS.PMT_LIST_NAMES[appName.fields.apptype]}</label>
          <CheckBoxDropDownSelector
            className="toolbar-standard-dropdown"
            type="dropdown"
            options={getOptionsObj(filteredMarketOptions, selectedMarkets)}  // Use filtered options
            keepOpen={true}
            displayOptionHandler={(e) => handleSelectedListItems(e, selectedMarkets, filteredMarketOptions)}
            labelString={getLabelString()}
            isSearchable={true}
            selected={getOptionsObj(selectedMarkets, selectedMarkets)}  // Convert selected into object form
            handleSearchTerm={handleSearchTerm}
            selectAll={true}
          />
        </div>

        {/* Grouped By Dropdown */}
        <div className="toolbar-dropdown m-i-end-03">
          <PMTListViewManipulator
            className="toolbar-standard-dropdown"
            disabled={isDisabled}
            whichView={listViewOption}
            type="dropdown"
            onChange={handleShowChange}
            assigneeList={memberList}
            statusList={PMTCONSTANTS.STATUS_LIST}
          />
        </div>

        {/* More Display Options Dropdown */}
        <div className="toolbar-dropdown">
          <label className="dropdown-label">More Display Options</label>
          <CheckBoxDropDownSelector
            className="toolbar-standard-dropdown"
            type="dropdown"
            options={options}
            keepOpen={false}
            displayOptionHandler={displayOptionHandler}
            labelString={getDropdownOption(options)}
          />
        </div>
      </Col>

      {/* Buttons Container */}
      <Col className="buttons-container justify-end">
        <PMTExcelExport
          className="icon-btn btn-primary-lg"
          disabled={isDisabled}
          filteredMarkets={filterToListsSelected(topList, selectedListItems)}
        />
        <BtnDynamic
          classes="icon-btn surface-brand-primary btn-primary-lg m-i-start-03"
          content={archiveBtnContent}
          actionHandler={toggleArchive}
        />
        <Button className="icon-btn surface-brand-primary btn-primary-lg m-i-start-03" onClick={handleShowListItemModal}>
          <PlusCircle />
          Add
        </Button>
      </Col>
    </Row>
  );
}

export default PMTMarketActionsToolbar;
