import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Spinner, Badge } from 'react-bootstrap';
import Select from 'react-select';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useHistory, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { Hash, X } from 'react-bootstrap-icons'; //Archive should be added once the archive report functionality is added
import CreatableSelect from 'react-select/creatable';

import utils from '../../utils';
import BlobUploader from '../BlobUploader';
import FileListFieldDisplay from '../Intelligence/FileListFieldDisplay';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import CONSTANTS from '../../constants';
import ToolTip from '../Layout/ToolTip';
import EyesSelector from './EyesSelector';
import { useToast } from '../ToastProvider';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import ModalDynamic from '../Layout/ModalDynamic';
import BtnDynamic from '../Layout/BtnDynamic';

import NavUtils from '../../NavUtils';

function IntellEditReport({ type, tenant, tooltip}) {

  let history = useHistory();

  let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.INTELLIGENCE);

  if(!hasAccess) {
      history.push('/');
  }

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  let { reportID } = useParams();
  const [form, setForm] = useState(
    {
      informationOwner: tenant.tenant.fields.functionalInformationOwner,
      contentID: reportID,
      ...utils.getDefaultEyesChecked(tenant, CONSTANTS)
    });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [accessToken, setAccessToken] = useState();
  const [tagList, setTagList] = useState([]);
  const [ infoOwner, setInfoOwner ] = useState();
  const [ tagsToShow, setTagsToShow ] = useState([])
  let [showArchive, setShowArchive] = useState(false);
  let [showDelete, setShowDelete] = useState(false);
  let [fireBtnSubmit, setFireBtnSubmit] = useState(false);
  let [submitParams, setSubmitParams] = useState()
  let [initialLoad, setInitialLoad] = useState(true);

  const [ submittedForUpdate, setSubmittedForUpdate ] = useState(false);

  const { addToast } = useToast();
  const request = {
    scopes: ["User.Read"]
  };

  const SIZE_LIMIT = 86400;
  const REF_LIMIT = 10485760;
  const PIC_FOLDER_NAME = "intelligence-attachments";
  const REF_FOLDER_NAME = "intelligence-resources";

  const archiveBtnContent = {
    icon: utils.getIcon('archive'),
    name: form && form.archived === 'true' ? 'Un-archive Report' : 'Archive Report'
  }

  const deleteBtnContent = {
    icon: utils.getIcon('delete'),
    name: 'Delete Report'
  }

  //we want to name these file uploads with tenantslug_uuid.typeoffile
  // const uploadFileNameFn = (fileName) => {
  //   let tenantslug = tenant.tenant.fields.tenantslug;
  //   let newUUID = utils.createUUID();

  //   //get suffix from fileName
  //   let suffixArray = fileName.split('.');
  //   let fileType = suffixArray[suffixArray.length - 1];

  //   return `${tenantslug}_${newUUID}.${fileType}`;

  // }
  

  const fileUploaded = (fileAddedResult) => {
    //if fileAddedResult = { result: 'success', message: '<url to new file>'}, add message to list of files,
    //if fileAddedResult = { result: 'error', message: { error obj}}, show error

    //get attachedImageList field, add item to it, and set field
    if(fileAddedResult.result === 'success') {

      let cpyAttachedImageList = form.attachedImageList ? [...form.attachedImageList] : [];

      cpyAttachedImageList.push(fileAddedResult.message);

      setField('attachedImageList', cpyAttachedImageList);
    }
    

    //put in state under attachedImageList - first, copy the existing field, add the new file to it, and set field
    //the format for the value is file_name.jpg,file_name2.jpg...

  }

  const refFileUploaded = (fileAddedResult) => {
    //if fileAddedResult = { result: 'success', message: '<url to new file>'}, add message to list of files,
    //if fileAddedResult = { result: 'error', message: { error obj}}, show error

    //get attachedImageList field, add item to it, and set field
    if(fileAddedResult.result === 'success') {

      let cpyAttachedFileList = form.attachedRefFileList ? [...form.attachedRefFileList] : [];

      cpyAttachedFileList.push(fileAddedResult.message);

      setField('attachedRefFileList', cpyAttachedFileList);
    }
    

    //put in state under attachedImageList - first, copy the existing field, add the new file to it, and set field
    //the format for the value is file_name.jpg,file_name2.jpg...

  }

  const getAccessToken = async (instance, account) => {
    try {
      let theAccToken = await instance.acquireTokenSilent({ ...request, account: account });
      setAccessToken(theAccToken);
      return theAccToken;
    } catch (err) {
      addToast({
        title: 'Access Denied',
        body: 'Whoops!  something happened.  Please try again.'
      })
    }
  }

  //Adds new tag to state for dropdown and tags display
  const addTagToState = (newTag) => {
    let tempTagsShow = [...tagsToShow];
    let tempTagList = [...tagList];
   
    tempTagsShow.push(newTag);
    tempTagList.push(newTag);

    setTagsToShow(tempTagsShow)
    setTagList(tempTagList)
  }

  //Gets a list of CX specific tags
  const getLimitedCXTags = async () => {
    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-cx-tags`, {
    })
    let jsonResponse = await response.json();
    setTagList(jsonResponse.value)
  }

  //Adds new tag to the backend if it doesn't already exist
  const addCXTag = async (tag) => {
    setLoadingTags(true);
    let accToken = await getAccessToken(instance, accounts[0]);
   
    try {
      let result = await fetch(`${process.env.REACT_APP_WEB_API}/api/add-cx-tag`, {
        method: 'POST', 
        headers: {
          "Authorization": "Bearer " + accToken.accessToken
        },
        body: JSON.stringify({theTag: tag.label})
      })
       
      let tagResult = await result.json()

      await getLimitedCXTags();
        

      setField('tags', tagResult)
      addTagToState({label: tag.label, value: tagResult})
    } catch (err) {
      addToast({
        title: 'Adding Tag Error',
        body: 'Whoops!  something happened while adding tag.  Please try again.'
      })
    }
    
    setLoading(false)    
    setLoadingTags(false);
  }

  const getReport = async (reportID) => {
    setLoading(true);
    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/cms-content?id=${reportID}`, {});

    let jsonresponse = await response.json();
    formatReport(jsonresponse);
    setInfoOwner(utils.convertInfoOwnerNumberToSlug(jsonresponse.fields.informationOwner, CONSTANTS.FUNCTIONAL_INFO_OWNER) )
    setLoading(false)
  }

  const findTagsToDisplay = (data, list) => {
    let tags = data.tags.includes(',') ? data.tags.split(',') : [data.tags];
    let tagArr = [];

    if(tags) {
      list.forEach((tagItem) => {
        tags.forEach((tagID) => {
          if(tagID.toString() === tagItem.value.toString()) {
            tagArr.push(tagItem);
          }
        })
        return tagArr;
      })
      setTagsToShow(tagArr)
      return tagArr;
    }
    return '';
  }

  useEffect(() => {
    if(form && form.tags && tagList) {
      findTagsToDisplay(form, tagList)
    }
  },[form, tagList])

  const formatReport = (report) => {

    if (report && report.fields) {
      let retObj = {
        contentID: report.contentID,
        credibility: report.fields.credibility ? report.fields.credibility : '',
        date: utils.getDateString(new Date(), 'newDate'),
        informationOwner: report.fields.informationOwner ? report.fields.informationOwner : tenant.tenant.fields.functionalInformationOwner,
        internal_comment: report.fields.internalComment ? report.fields.internalComment :'',
        keyContact: report.fields.keyContact ? report.fields.keyContact : '',
        note: report.fields.note ? report.fields.note : '',
        reliability: report.fields.reliability ? report.fields.reliability : '',
        reportDate: report.fields.date ? utils.getDateString(report.fields.date) : '',
        sensitivity: report.fields.sensitivity ? report.fields.sensitivity: '',
        source: report.fields.source ? report.fields.source : '',
        sourceType: report.fields.reportType ? report.fields.reportType : '',
        sourceLink: report.fields.sourceLink ? report.fields.sourceLink : '',
        attachedImageList: report.fields.attachedImageList ? report.fields.attachedImageList.split(',') : [],
        attachedRefFileList: report.fields.attachedRefFileList ? report.fields.attachedRefFileList.split(','): [],
        tags: report.fields.tags_ValueField ? report.fields.tags_ValueField : '',
        title: report.fields.title ? report.fields.title :'',
        reporter: report.fields.reporter ? report.fields.reporter :'',
        gaviEyes: report.fields.gaviEyes === 'true' ? 'on' : 'off',
        bmgfEyes: report.fields.bMGFEyes === 'true' ? 'on' : 'off',
        chaiEyes: report.fields.cHAIEyes === 'true' ? 'on' : 'off',
        unicefEyes: report.fields.uNICEFEyes === 'true' ? 'on' : 'off',
        whoEyes: report.fields.wHOEyes === 'true' ? 'on' : 'off',
        cepiEyes: report.fields.cEPIEyes === 'true' ? 'on' : 'off',
        archived: report.fields.archived ? report.fields.archived : 'false'
      };
      setForm(retObj);
    }
  }

  //Gets the reportID from the URL query params


  useEffect(() => {
    if (isAuthenticated) {
      try {
        (async () => {
          setLoading(true);
          
          if (!accessToken) {
            setAccessToken(await getAccessToken(instance, accounts[0]));
          }
          if (initialLoad === true) {
            getLimitedCXTags();
            if (type ==='edit') {
              await getReport(reportID)
            }
            setInitialLoad(false)
          }
          setLoading(false);
          if(fireBtnSubmit === true){
            setSubmittedForUpdate(true);
            await utils.submitIntelligenceRequest(submitParams, reportID, accessToken, form);
            history.push('/intelligence');
          }

        }
        )()
      } catch (error) {
        addToast({
          title: 'Access Denied',
          body: 'Whoops!  something happened.  Please try again.'
        })
        setLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [reportID, submitParams, form, accessToken])

  const addTag = (temp, tag) => { 
    let tagsArr = temp.tags && temp.tags.includes(',') ? temp.tags.split(',') : [temp.tags];
    if (temp.tags) {
      if (tagsArr.includes(tag)) {
        tagsArr = tagsArr.join(',');
        return tagsArr;
      }else {
        tagsArr.push(tag);
        tagsArr = tagsArr.join(',')
        return tagsArr;
      }
    } else {
      return tag;
    }
  }

  const setField = (field, value) => {
    let newFormObj;
    let temp = {...form}

    switch(field) {
      case'tags':
      newFormObj = {
        ...form, 
        tags: addTag(temp, value.toString())
      }
      break;
      case'bmgfEyes':
      newFormObj = {
        ...form,
        bmgfEyes: form.bmgfEyes === 'on' ? 'off' : 'on',
      }
      break;
      case'chaiEyes':
      newFormObj = {
        ...form,
        chaiEyes: form.chaiEyes === 'on' ? 'off' : 'on',
      }
      break;
      case'gaviEyes':
      newFormObj = {
        ...form,
        gaviEyes: form.gaviEyes === 'on' ? 'off' : 'on',
      }
      break;
      case'unicefEyes':
      newFormObj = {
        ...form,
        unicefEyes: form.unicefEyes === 'on' ? 'off' : 'on',
      }
      break;
      case'whoEyes':
      newFormObj = {
        ...form,
        whoEyes: form.whoEyes === 'on' ? 'off' : 'on',
      }
      break;
      case'cepiEyes':
      newFormObj = {
        ...form,
        cepiEyes: form.cepiEyes === 'on' ? 'off' : 'on',
      }
      break;
      default:
        newFormObj = {
          ...form,
          [field]: value
        }
      break;
    }
    setForm(newFormObj);

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      })
    }
  }


  const validateForm = (formToCheck) => {
    //goes through form state obj - if a field fails, adds to errors
    const { title, sourceType, reportDate, sensitivity, note, tags, reliability, credibility } = formToCheck;

    let newErrors = {}

    if (!title || title === '') {
      newErrors.title = 'Title is Required!';
    }

    if (!sourceType || sourceType === ('' || '-')) {
      newErrors.sourceType = 'Source Type is Required!';
    }

    if (!reportDate || reportDate === 'mm/dd/yyyy') {
      newErrors.reportDate = 'Source Date is Required';
    }

    if (!sensitivity || sensitivity === '') {
      newErrors.sensitivity = 'Sensitivity is Required';
    }

    if (!note || note === '') {
      newErrors.note = 'Summary Notes is Required';
    }
    
    if( !reliability || reliability === '') {
      newErrors.reliability = "Reliability is Required"
    }

    if( !credibility || credibility === ('' || '-')) {
      newErrors.credibility = "Credibility is Required"
    }

    if (!tags || tags === '') {
      newErrors.tags = 'Tags are Required';
    }

    return newErrors;
  }

  const addNewReportToStorage = (newRepID, newRep) => {
    newRep.id = newRepID;
    sessionStorage.clear();
    sessionStorage.setItem("newReport", JSON.stringify(newRep));
    
    history.push(`/intelligence`);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const errorsOnForm = validateForm(form);

    if (Object.keys(errorsOnForm).length < 1) {
      let endpoint = type === 'create' ? 'add-new':'edit-item';
      
      try {
        let res = await fetch(`${process.env.REACT_APP_WEB_API}/api/${endpoint}`, {
          headers: { "Authorization": "Bearer " + accessToken.accessToken },
          method: 'POST',
          body: JSON.stringify(form)
        })
        let resJSON = await res.json();

        addNewReportToStorage(resJSON, form, type);
       
      } catch (err) {
        addToast({
          title: 'Report Submit Error',
          body: 'Whoops!  something happened on this submit.  Please try again.'
        })
      }
    } else {
      setErrors(errorsOnForm);
    }
  } 

  //removes selection from tagList
  const removeTagList = ( tag ) => {
    let tempTags = [...tagsToShow]
    if(tempTags && tempTags.length > 0){
      let tagsArr = tempTags.filter((item) => item.value.toString() !== tag.value.toString())
      setTagsToShow(tagsArr)
    }
  }

  const removeTag = (tag) => {
    let temp = {...form}
    let tags = temp.tags && temp.tags.includes(',') ? temp.tags.split(',') : [temp.tags]

    let formTags = tags.filter((item) => {
      return item !== tag.value.toString()
    })
    if(formTags.length === 1) {
      formTags = formTags[0]
    }
    else if (formTags.length > 1) {
      formTags = formTags.join(',');
    }
    else {
      formTags = '';
    }
    temp.tags = formTags
    setForm(temp)
    removeTagList(tag)
  }

  const tagHandler = (tags) => {
    if (tags && tags.length > 0 ) {
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].__isNew__) {
          addCXTag(tags[i])
        } else {
          setField('tags', tags[i].value )
        }
      }
    }
  }


  const deleteModal = {
    type: 'delete',
    title: utils.getTitle("Confirm file removal"),
    body: "You are about to permanently remove this file. Once removed, it can't be recovered. Are you sure you want to proceed",
    app: 'intelligence',
    subName: 'Confirm'
  }
  
  const archiveModal = {
    type: 'archive',
    title: utils.getTitle(`You're about to ${form && form.archived === 'true' ? 'un-archive' : 'archive'} this report`),
    body: form && form.archived === 'true' ? 'Are you sure you want to continue?' : 'Archiving this report will remove it from other views. Are you sure you want to continue?',
    app: 'intelligence',
    subName: 'Confirm'
  }

  const setLoadingTrue = (state) => {
    setLoading(state);
  }

  const submitRequest = async (params) => {
    setSubmitParams(params);
    setLoadingTrue(true);
    setFireBtnSubmit(true);
  }

  return (
    <div className='cx-app'>
      <BreadCrumbBar appName={'INTELLIGENCE'} />
      <h1 className='page-header h1'>{type === 'create' ? 'Create Report' : 'Edit Report'}</h1>
      { !loading && tagList && tenant ?
        <Form onSubmit={(e) => onSubmit(e)}>
          <input type="hidden" name="informationOwner" value={form.informationOwner ? form.informationOwner : tenant.tenant.fields.functionalInformationOwner} />
          <input type="hidden" name="for_list" value="cx" />
          <input type="hidden" name='contentID' value={form.contentID ? form.contentID : ''} />
          <Row>
            <Col lg={9}>
              <Form.Group className="enter-title" controlId="title">
              <Form.Label className='subtitle-lg label-p0'>Report Title</Form.Label>
                <Form.Control
                  className='body-small input-2px'
                  size="lg"
                  type="text"
                  name="title"
                  onChange={e => setField(e.target.name, e.target.value)}
                  placeholder="Enter Title..."
                  value={form.title ? form.title :''}
                  isInvalid={errors.hasOwnProperty('title')} />
                <Form.Control.Feedback type='invalid'>
                  {errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <p className='m-b-end-06 text-secondary'>Information Owner: {tenant.tenant.fields.tenantslug}</p>

              <Row>
                <Form.Group className="source m-b-end-06" controlId="source">
                  <Form.Label className='subtitle-lg'>Source</Form.Label>
                  <Form.Control
                    className='body-small input-2px'
                    type="text"
                    placeholder="Start typing to add Source."
                    defaultValue={form.source ? form.source : ''}
                    name="source"
                    onChange={e => setField(e.target.name, e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="sourceLink m-b-end-06" controlId="sourceLink">
                  <Form.Label className='subtitle-lg'>Link to source</Form.Label>
                  <Form.Control
                    className='body-small input-2px'
                    type="text"
                    placeholder="Start typing to add link to source."
                    defaultValue={form.sourceLink ? form.sourceLink : ''}
                    name="sourceLink"
                    onChange={e => setField(e.target.name, e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="key-contact m-b-end-06" controlId="key-contact">
                  <Form.Label className='subtitle-lg'>Key Contact(s)</Form.Label>
                  <Form.Control
                    className='body-small input-2px'
                    type="text"
                    placeholder="Start typing to add new members. Use commas or enter to separe key contacts and representatives."
                    name="keyContact"
                    defaultValue={form.keyContact ? form.keyContact : ''}
                    onChange={e => setField(e.target.name, e.target.value)}
                  />
                </Form.Group>
              </Row>
           
              <Row>
                <Form.Group className="summary-notes m-b-end-06" controlId="summary-notes">
                  <Form.Label className='subtitle-lg'>Summary Notes</Form.Label>
                  <Editor 
                    apiKey='y2xksllo6ppxo48k20tdgq92fmqhbbqc82x5y5minextapb2' 
                    value={form.note} 
                    init={{
                      plugins: ['link', 'image'],
                      placeholder: "Summary Notes are visible to anyone with access" 
                    }} 
                    
                    onEditorChange={(newValue, editor) => setField('note', newValue)} />
                  <div
                      className="error-message"
                      style={{
                        fontSize: '.875em',
                        color: '#dc3545',
                        marginTop: '0.25rem'
                      }}
                    >
                      {errors.note}
                    </div>
                </Form.Group>
              </Row>
              {
                tenant.tenant.fields.tenantslug.includes(infoOwner) || tenant.tenant.fields.tenantslug === "LB-Level-Q" ?
                  <Row>
                    <Form.Group className="internal-comment m-b-end-06" controlId="internal-comment">
                      <Form.Label className='subtitle-lg'>Internal Comment</Form.Label>
                      <Editor 
                        className='body-small'
                        apiKey='y2xksllo6ppxo48k20tdgq92fmqhbbqc82x5y5minextapb2' 
                        value={form.internal_comment ? form.internal_comment : ''} 
                        init={{
                          plugins: ['link', 'image'],
                          placeholder: "Internal Comments are visible to workspace owner only"
                        }} 
                        onEditorChange={(newValue, editor) => setField('internal_comment', newValue)} />
                    </Form.Group>
                  </Row>
                  : ''
              }
              <Row className='m-b-end-06'>
                  <Col>
                    <Form.Label className='subtitle-lg'>Upload Images</Form.Label>
                    
                    <h5 className='subtitle-sm'>
                      Max file size is {(SIZE_LIMIT / 1000)}kb.  Supported filetypes are: {CONSTANTS.INTELLIGENCE_FILE_TYPES} 
                    </h5>

                    <BlobUploader 
                      fileTypes={CONSTANTS.INTELLIGENCE_FILE_TYPES}
                      serviceName={"intellattachments"} 
                      sasToken={process.env['REACT_APP_UPLOAD_SAS']}
                      sizeLimit={SIZE_LIMIT} 
                      folderName={PIC_FOLDER_NAME} 
                      onFileAdded={(added) => fileUploaded(added)}
                      form={form} 
                      reportID={reportID}
                      accessToken={accessToken}
                      fileNameGuideFn={null} />

                    <FileListFieldDisplay 
                      fieldValue={form.attachedImageList ? form.attachedImageList : []  }
                      sasToken={process.env['REACT_APP_UPLOAD_SAS']}
                      folderName={PIC_FOLDER_NAME}  
                      serviceName={"intellattachments"} 
                      form={form}
                      onChange={(chng) => { setField('attachedImageList', chng )}} />
                  </Col>
                </Row>
                <Row className='m-b-end-06'>
                  <Col>
                    <Form.Label className='subtitle-lg'>Upload Reference Files</Form.Label>
                    
                    <h5 className='subtitle-sm'>
                      Max file size is {(Math.floor(REF_LIMIT / 1000000))}mb.  Supported filetypes are: {CONSTANTS.INTELLIGENCE_REF_TYPES} 
                    </h5>

                    <BlobUploader 
                      fileTypes={CONSTANTS.INTELLIGENCE_REF_TYPES}
                      sasToken={process.env['REACT_APP_FILE_RESOURCES']}
                      serviceName={"intellattachments"} 
                      sizeLimit={REF_LIMIT} 
                      folderName={REF_FOLDER_NAME} 
                      onFileAdded={(added) => refFileUploaded(added)}
                      form={form} 
                      reportID={reportID}
                      accessToken={accessToken}
                      fileNameGuideFn={null} />

                    <FileListFieldDisplay 
                      fieldValue={form.attachedRefFileList ? form.attachedRefFileList : []  }
                      sasToken={process.env['REACT_APP_FILE_RESOURCES']}
                      folderName={REF_FOLDER_NAME}  
                      serviceName={"intellattachments"} 
                      form={form}
                      onChange={(chng) => { setField('attachedRefFileList', chng )}} />
                  </Col>
                </Row>
              <Row>

              <Form.Group className="m-b-end-04" controlId="tags">
                  <Form.Label className='subtitle-lg'>Tags</Form.Label>
                  <CreatableSelect
                    id='tag-select'
                    className='body-small select-filter'
                    value=''
                    isMulti
                    options={tagList}
                    onChange={(e) => tagHandler(e)}
                    isInvalid={!!errors.tags}
                  />

                  <div
                    className="error-message"
                    style={{
                      fontSize: '.875em',
                      color: '#dc3545',
                      marginTop: '0.25rem'
                    }}
                  >
                    {errors.tags}
                  </div>
                </Form.Group>
              </Row>

              {
                !loadingTags ?
                  tagsToShow && tagsToShow.length > 0?
                    tagsToShow.map((tag) => {
                      return (
                        <Badge className="cx-tag tertiary-button m-i-end-03 m-b-end-06">
                          <Hash className="icon" />
                          {tag.label}
                          <X className='m-i-start-02' onClick={() => removeTag(tag)}/>
                        </Badge>
                      )
                    })
                    :''
                  : <Spinner animation='border' />
              }
            
              <Row className='report-submit'>
                <Col lg={8} xl={9} className='review-message'>
                  <p className='subtitle-lg'>*reports will be reviewed and posted in 2 business days</p>
                </Col>
                <Col lg={4} xl={3} className="submit-cancel-button">
                  <Button href='../' className="btn-cancel secondary-link">cancel</Button>
                  <Button className="btn-submit tertiary-button" disabled={submittedForUpdate === true && type === 'create'} onClick={onSubmit}>{type === 'create' ? 'Create Report' : 'Update Report'}</Button>
                </Col>
              </Row>
            </Col>
            <Col lg={3} className="new-report-checkbox">

              {
                type !== 'create' && form ?
                <>
                  <BtnDynamic
                    classes={'side-col-btn tertiary-button m-b-end-03'}
                    content={archiveBtnContent}
                    actionHandler={() => setShowArchive(!showArchive)}
                  />
                  <ModalDynamic
                    show={showArchive}
                    content={archiveModal}
                    handleClose={() => setShowArchive(!showArchive)}
                    submitRequest={() => submitRequest('ARCHIVE')}
                  />
                  <BtnDynamic
                    classes={'side-col-btn delete-btn'}
                    content={deleteBtnContent}
                    actionHandler={() => setShowDelete(!showDelete)}
                  />
                  <ModalDynamic
                    show={showDelete}
                    content={deleteModal}
                    handleClose={() => setShowDelete(!showDelete)}
                    submitRequest={() => submitRequest('DELETE')}
                  />
                </>
                : ''
              }

              <Form.Group className="source-type m-b-end-06" controlId="source-type">
                <Form.Label className={`subtitle-lg ${type === 'create' ? 'm-b-start-02': 'm-b-start-06'}`}>Source Type</Form.Label>
                <Select
                  id="source-type"
                  name="sourceType"
                  className={!!errors.sourceType ? 'error body-small select-filter' : 'body-small select-filter'}
                  value={CONSTANTS.SOURCE_TYPE.filter((option) => {
                    return (
                    option.value === form.sourceType ? option.label : null
                    )
                  })}
                  options={CONSTANTS.SOURCE_TYPE}
                  onChange={e => setField('sourceType', e.value)}
                />
                <div
                  className="error-message"
                  style={{
                    fontSize: '.875em',
                    color: '#dc3545',
                    marginTop: '0.25rem'
                  }}
                >
                  {errors.sourceType}
                </div>
              </Form.Group>

              <Form.Group className="sensitivity m-b-end-06" controlId="sensitivity">
                <Form.Label className='subtitle-lg'>Sensitivity <ToolTip className='app-tooltip'tooltip={tooltip}/></Form.Label>
                <Select
                   className={!!errors.sourceType ? 'error body-small select-filter' : 'body-small select-filter'}
                  id="sensitivity"
                  name="sensitivity"
                  value={CONSTANTS.SENSITIVITY.filter((option) => {
                    return (
                    option.value === form.sensitivity ? option.label : null
                    )
                  })}
                  options={CONSTANTS.SENSITIVITY}
                  onChange={e => setField('sensitivity', e.value)}
                  isInvalid={!!errors.sensitivity} />
                <div
                  className="error-message"
                  style={{
                    fontSize: '.875em',
                    color: '#dc3545',
                    marginTop: '0.25rem'
                  }}
                >
                  {errors.sensitivity}
                </div>
              </Form.Group>

              <label className='subtitle-lg'>Source Date</label>
              <Form.Control
                className={!!errors.reportDate ? 'error body-small input-2px date-input m-b-end-06' : 'body-small input-2px date-input m-b-end-06'}
                type="date"
                id="reportDate"
                defaultValue={type !== 'create' && form && form.reportDate ? utils.getDashDateString(form.reportDate, 'datePicker') : ''}
                name="reportDate"
                onChange={e => setField(e.target.name, e.target.value)}
                isInvalid={!!errors.reportDate} />
              <Form.Control.Feedback type='invalid'>
                {errors.reportDate}
              </Form.Control.Feedback>

              <Form.Group className="reliability m-b-end-06" controlId="reliability">
                <Form.Label className='subtitle-lg'>Reliability</Form.Label>
                <Select
                  className={!!errors.reliability ? 'body-small select-filter' : 'body-small select-filter'}
                  id="reliability"
                  options={CONSTANTS.RELIABILITY}
                  value={CONSTANTS.RELIABILITY.filter((option) => {
                    return (
                    option.value === form.reliability ? option.label : null
                    )
                  })}
                  name="reliability"
                  onChange={e => setField('reliability', e.value)}
                  isInvalid={!!errors.reliability}
                />
                 <div
                  className="error-message"
                  style={{
                    fontSize: '.875em',
                    color: '#dc3545',
                    marginTop: '0.25rem'
                  }}
                >
                  {errors.reliability}
                </div>
              </Form.Group>
             
              <Form.Group className="credibility m-b-end-06" controlId="credibility">
                <Form.Label className='subtitle-lg'>Credibility</Form.Label>
                <Select
                  className='body-small select-filter'
                  id="credibility"
                  name="credibility"
                  options={CONSTANTS.CREDIBILITY}
                  value={CONSTANTS.CREDIBILITY.filter((option) => {
                    return (
                    option.value === form.credibility ? option.label : null
                    )
                  })}
                  onChange={e => setField('credibility', e.value)}
                  isInvalid={!!errors.credibility}
                />
                <div
                  className="error-message"
                  style={{
                    fontSize: '.875em',
                    color: '#dc3545',
                    marginTop: '0.25rem'
                  }}
                >
                  {errors.credibility}
                </div>
              </Form.Group>
              <EyesSelector
                mode={ tenant.tenant.fields.eyesSelectionLock === 'true' ? 'read': 'write' }
                tenant={tenant} 
                intelReport={form}
                onChange={setField} />
            </Col>
        </Row>
          
        </Form>
        : <ListSkeletonLoader preview={false} />}
    </div>
  )
}


export default IntellEditReport;