
const ganttUtils = {
    getQuarter: (monthNum) => {
        return Math.ceil(monthNum / 4);
    },

    getCurrentQuarter: (dateToLook) => {
        let curMonth = dateToLook.getMonth();
        return Math.floor(curMonth / 4);
    },

    getTaskWithEarlieststart: (tasks) => {
        let sortedTask = tasks.sort( (task1, task2) => {
            if(task1.start_date && task1.start_date !== ''){
                if(task2.start_date && task2.start_date !== ''){
                    let task1Date = new Date(parseInt(task1.start_date));
                    let task2Date = new Date(parseInt(task2.start_date));
    
                    return( task1Date - task2Date );
                } else {
                    return -1;
                }
            }
    
            if(task2.start_date && task2.start_date !== '') {
                return 1;
            }
    
            return 0;
        });
    
        return sortedTask[0];
    },
    

    getTaskWithLatestend: (tasks) => {

        let sortedTask = tasks.sort( (task1, task2) => {
            if(task1.due_date && task1.due_date !== ''){
                if(task2.due_date && task2.due_date !== ''){
                    let task1Date = new Date(parseInt(task1.due_date));
                    let task2Date = new Date(parseInt(task2.due_date));
    
                    return( task2Date - task1Date );
                } else {
                    return -1;
                }
            }
            
            if(task2.due_date && task2.due_date !== '') {
                return 1;
            }
    
            return 0;
        });
    
        return sortedTask[0];
    }
}

export default ganttUtils;