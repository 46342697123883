
import React, { useRef } from 'react';

import { useDebouncedCallback } from 'use-debounce';

function EditableTextCell({ colname, rowId, className=null, initialValue=null, onUpdate }) {


    const ref = useRef(null);

    //only update the value after the user has stopped typing for 1 second
    const debounced = useDebouncedCallback(
        //do what
        (myKey, theValue) => {
            onUpdate({ rowID: rowId, colname: myKey, value: theValue })
        },
        // delay in ms
        1000
    );

    //adds the mode on to the classname so we can style for edit and display modes
    return (
            <textarea 
                ref={ref}
                name={colname}
                style={{width: '100%'}}
                className={className ? `${className} textarea-basic`: "  textarea-basic" } 
                defaultValue={initialValue} 
                onChange={(e) => debounced(e.target.name, e.target.value)} 
            />
    )
}

export default EditableTextCell