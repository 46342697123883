import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LBToggle from '../LBToggle';
import utils from '../../utils';

function WrkspcAppsToggle({ wrkspaceName, workspc, workspacesReference, handleToggle, isSubmitting, superAdminToggled, isSuperAdmin=false }) {

  const wkspcAppToggled = (val) => {
    //add wrkspace key to the toggled app, then pass up
    let submittedVal = {...val, workspace: wrkspaceName};
    handleToggle(submittedVal);
  }

  const getArrayOfApps = (wkspc, masterListOfApps) => {
    let listOfApps = wkspc.appsList.split(',');
    let funcApps = listOfApps.map( (app) => {
      return { name: app, on: true};
    });

    //modify the master list based on
    masterListOfApps.forEach( (thisApp) => {
      if(funcApps.findIndex( thisFuncApp => {
          return thisFuncApp.name === thisApp.app;
        }) > -1) {

          thisApp.on = true;

      }
    });

    return masterListOfApps;
  }

  const getAppsListForEachWorkspace = (thiswrkspaceName, mstrWrkspcList) => {
    //look at mstrWrkspcList and merge user's wkspcObj with it
    let thisWkspc = utils.filterWkspcListToWrkspc(mstrWrkspcList, thiswrkspaceName, 'tenantName');

    if(thisWkspc.appList) {
      return thisWkspc.appList.map( (thisApp) => {
        return { app: thisApp.fields.apptype, on: false, altName: thisApp.fields.appAlternateTitle ? thisApp.fields.appAlternateTitle : thisApp.fields.apptype.replace('_', ' ') };
      })
    }

    //default to news marked on if can't find appslist
    return [ { app: 'news', on: true, altName: 'news'}];
  }

  const getAppsForToggles = (thiswrkspcName, wrkspacesList) => {
    
    let mstrList = getAppsListForEachWorkspace(thiswrkspcName, wrkspacesList);
    let appArrayForWkspc = getArrayOfApps(workspc, mstrList);

    return (
      <>
        { 
          thiswrkspcName === 'Linksbridge Internal Level Q Access' ? 
            <LBToggle
              disabled={isSubmitting} 
              label={'Super Admin (are you sure?)'} 
              name={'super_admin'} 
              defaultValue={isSuperAdmin}
              onChange={superAdminToggled} />
          : null
        }
        { 
          appArrayForWkspc.map( (thisApp) => {
            return (
              <LBToggle
                disabled={isSubmitting} 
                label={thisApp.altName} 
                name={thisApp.app} 
                defaultValue={thisApp.on}
                onChange={wkspcAppToggled} />
              
              )
          })
        }
    </>
    );
  }

  return (
    <>
      <Row className='info_chunk'>
        <Col><strong>{ wrkspaceName }</strong></Col>
      </Row>
      {
        getAppsForToggles(wrkspaceName, workspacesReference)
      }
    </>
  )
}

export default WrkspcAppsToggle