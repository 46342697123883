import React, { useState, useEffect } from 'react';
import utils from '../../utils';
import { Col, Container, Row, Card, Spinner, Badge } from 'react-bootstrap';
import BtnDynamic from './BtnDynamic';
import { Link } from 'react-router-dom';
import SearchUtils from '../SearchUtils';

function RelatedArticleTeaser({tenant, entity, searchTerm=null, entityType, header, tagQuery=false, forceTags=null, shownArticleID=null }) {
  const [cxArticles, setCxArticles] = useState([]);
  const [newsArticles, setNewsArticles] = useState([]);
  const [loading, setLoading] = useState(false);

  let tagsToUse;
  let tenID = utils.getForceTenantValue();


  if(!tenID) {
    //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
    tenID = tenant.tenant.fields.aDGroupID || null;
    utils.setForceTenantValue(tenID);
  }

  const hasIntelligenceAccess = (tenant) => {
      if(tenant.tenant.fields.apps && tenant.tenant.fields.apps.length > 0) {
        return tenant.tenant.fields.apps.findIndex( (thisItem) => { return thisItem.fields.apptype === 'intelligence'}) > -1;
      }

      return false;
  }
   
    if(entity && entity.fields.tags) {
        //iterate through the array and add the tagtext to the string
        tagsToUse = entity.fields.tags.map( (thisTagObj) => {
            return thisTagObj.fields.tagtext;
        })
    } else {
        tagsToUse = forceTags;
    }

    if(forceTags) {
        tagsToUse=forceTags;
    }

    // const convertStringToArr = (storyToConvert) => {
    //     let numwords;

    //     if (storyToConvert && storyToConvert.fields && storyToConvert.fields.body) {
    //         numwords = storyToConvert.fields.body.split(/\s/);
    //     }
    //     if (storyToConvert && storyToConvert.body && storyToConvert.body.body) {
    //         numwords = storyToConvert.body.body.split(/\s/);
    //     }
    //     if (storyToConvert && storyToConvert.fields && storyToConvert.fields.note) {
    //         numwords = storyToConvert.fields.note.split(/\s/);
    //     }
    //     return numwords;
    // }
    
    // const convertToStoryTeaser = (storyToConvert) => {
    //     //figure out reading time based on average reading speed of 200 words per minute.
    //     let timeInMinutes = 0;

    //     let numwords = convertStringToArr(storyToConvert);

    //     if(numwords.length >= 200){
    //         timeInMinutes = Math.round(numwords.length / 200) + (numwords.length < 400 ? " minute" : 'minutes');
    //     } 
    //     else {
    //         timeInMinutes = "less than a minute"
    //     }

    //     storyToConvert.readTime = timeInMinutes;

    //     return timeInMinutes;
    // }

  const filterOutShownArticle = (articleList, articleID, engine) => {
    if (articleID !== null) {
        return articleList.filter( (item) => {
            return item.contentID !== articleID;
        })
    }

    if(engine === 'cxreports') {
        setCxArticles(articleList);
    }
    if(engine === 'linksbridge') {
        setNewsArticles(articleList);
    }
    return articleList;
}

  const loadArticles = async (thisQuery, articleID, engine) => {
    let tagsToQuery = typeof(thisQuery) === 'string' ? thisQuery.split(','): thisQuery;
    let filtersToSet = {}
    
    if (engine === 'cxreports') {
        filtersToSet = {archived: "false"};
        tagQuery = false;
    }

    let response = await SearchUtils.getElasticResultsWithTags(tagsToQuery, tenID, 4, 0, engine, filtersToSet, null, null, 'DATE_DESC');
   
   filterOutShownArticle(response.results, articleID, engine);
}

useEffect( ()=>{
    setLoading(true);
    try {
        loadArticles(searchTerm ? searchTerm : tagsToUse, shownArticleID, 'linksbridge');
        if(hasIntelligenceAccess(tenant)) {
            loadArticles(tagsToUse, shownArticleID, 'cxreports');
        }
    }
    catch(error) {
        //don't do anything.
    }
    setLoading(false)
// eslint-disable-next-line    
},[]);

const createRelatedNewsArr = ( cx, news ) => {

    let retArr = []
    let curArtArray = news.filter((item) => item.contentID.toString() !== entity.contentID.toString())

    retArr[0] = cx && cx.length > 0 ? cx[0] : curArtArray && curArtArray.length > 0 ? curArtArray[0] : null;
    retArr[1] = (cx && cx.length > 0) && (curArtArray && curArtArray.length > 0) ? curArtArray[0] : curArtArray && curArtArray.length > 1 ? curArtArray[1] : null;
    retArr[2] = (cx && cx.length > 0) && (curArtArray && curArtArray.length > 1) ? curArtArray[1] : curArtArray && curArtArray.length > 2 ? curArtArray[2] : null;

    let articleArray = retArr.filter((item) => item !== null)

    return articleArray;
}


  return (
    newsArticles && newsArticles.length > 0 ?
        !loading ?
            utils.isMNCTenant(tenant) ?
                null 
            :
            <Container className="m-b-start-08 p-i-start">
                <Row className='m-b-end-04'>
                    <Col md={9}>
                        <h4>{cxArticles.length > 0 ? header : 'Related News'}</h4>
                    </Col>
                    <Col md={3} className='align-right'>
                        <BtnDynamic 
                            linkTo={`/search/${tagsToUse && tagsToUse[0] ? tagsToUse[0] : searchTerm}`}
                            classes={'btn-primary tertiary-button border-radius-sm'}
                            content={{name: 'View More'}}
                        />
                    </Col>
                </Row>
                <Row>
                    {
                        createRelatedNewsArr(cxArticles, newsArticles).map((article) => {
                            return (
                                <Col md={4}>
                                    <Card className='p-05 related-article-card'>
                                        <Badge className={article.index === "cxreports" ? 'subtitle-lg surface-accent-teal m-b-end-02' : 'subtitle-lg surface-accent-lgt-purple m-b-end-02'}>{article.index === "cxreports" ? "Intelligence" : "News"}</Badge>
                                        <Link className='underline-none h3 m-b-end' to={article.index === "cxreports" ? `/intelligence/reports/${article.contentID}` : `/news/${article.contentID}`}>
                                            <h3 className='p-i-02 m-b-end'>{article.fields.title}</h3>
                                        </Link>
                                        <span className='subtitle-lg p-02'>{utils.getDateString(article.fields.datepublished, 'short')}</span>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        : <Spinner animation='border' />
    : null
  )
}

export default RelatedArticleTeaser;
