import React, { useState, useEffect } from 'react';
import utils from '../utils';
import { LinkContainer } from 'react-router-bootstrap';
import CONSTANTS from '../constants';
import { Col, Row, Button } from 'react-bootstrap';
import IntellFormattedList from './Intelligence/IntellFormattedList';
import NewsTeasers from './News/NewsTeasers';
import FormattedArticleList from './News/FormattedArticleList';
import FilterBarElastic from './Layout/Filters/FilterBarElastic';
import { Link } from 'react-router-dom';
import ListPagination from './Layout/ListPagination';
import CheckBoxDropDownSelector from './MktActionsFilterBarComps/CheckBoxDropDownSelector';
import 'react-loading-skeleton/dist/skeleton.css'
import ListSkeletonLoader from './Layout/ListSkeletonLoader';



function UniversalElasticConnectedList({ pageType = null, reloadLoading = false, handleLoading, tenantSetting, onArticlesRetrieved=null, engineName = null, page, filters=null, searchTerm = null, onTotalPagesReceived, preview = false, tagQuery = false, numPerPage = null, metaData, flatList = true, pollingInterval = null }) {
  //handles listing / filtering on elastic lists

  const [articles, setArticles] = useState([]);
  const [status, setStatus] = useState('ok');
  const [loading, setLoading] = useState(true);
  const [loadingResults, setLoadingResults] = useState(true);
  const [curPage, setCurPage] = useState(1)
  const [curFilters, setCurFilters] = useState({});
  const [clearFilter, setClearFilter] = useState(false)
  const [curTerm, setCurTerm] = useState();
  const [totalNumArticles, setTotalNumArticles] = useState(0);
  let [labelString, setLabelString] = useState('Select...')
  let [options, setOptions] = useState({
    'Show Archived': false,
  });

  const getSearchType = () => {
    let search = window.location.search;

    if (search.includes('type=tag')) {
      return 'tag';
    } else {
      return 'query'
    }
  }

  let tenID = utils.getForceTenantValue();

  if (!tenID) {
    //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
    tenID = tenantSetting.tenant.fields.aDGroupID || null;
    utils.setForceTenantValue(tenID);
  }

  const getResults = async (thisquery, numPerCall, page, engine, tenantID, filters = null, showLoading = true, start, end, sortOrder) => {
    let searchType = getSearchType();

    setLoading(showLoading);

    let searchObj = {
        engine: engine,
        is_boolean: tagQuery && tagQuery === true,
        filters: filters ? filters : {},
        size: numPerCall,
        page: page ? page : 1,
        start_date: start ? start : null,
        end_date: end ? end : null,
        sort_order: sortOrder ? sortOrder : searchType === 'query' && pageType === CONSTANTS.ELASTIC_PAGE_TYPES.SEARCH ? 'SCORE' : 'DATE_DESC',
        tenant_id: tenantID
    };

    if (engine === 'linksbridge') {
      searchObj.exclude_filters = {'category': ['MLPNormal', 'MLPReport']};
   }

    if (searchType && searchType === 'tag') {
        searchObj.tags = [thisquery];
    } else {
        searchObj.query = thisquery;
    }

    let newResponse = await utils.upsert('lb_search', process.env.REACT_APP_DB_PUBLIC_KEY, searchObj);

  

    setArticles(newResponse);
    setTotalNumArticles(newResponse.hit_count);
    onTotalPagesReceived(newResponse.hit_count / numPerCall);
    if (onArticlesRetrieved) {
        onArticlesRetrieved(newResponse.results);
    }
    setLoading(false);
};


  useEffect(() => {

    setLoading(true);

    if (!engineName) {
      return
    }

    try {
      if (reloadLoading === true || loadingResults === true || searchTerm !== curTerm) {
        setArticles([])
        getResults(searchTerm, numPerPage, curPage, engineName, tenID, engineName === 'cxreports' ? { archived: 'false' } : '')
        setStatus('ok');
        setLoadingResults(false);
        if (reloadLoading === true) {
          handleLoading(reloadLoading)
        }
      }

      if (searchTerm !== curTerm) {
        setCurTerm(searchTerm);
      }

    } catch (error) {
      setStatus('error');
      console.log(error)
    }

    setLoading(false);

    //TIM QUESTION: do we need to add pollingInterval back?
    //reload every interval if pollingInterval ! null
    if (pollingInterval !== null) {
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filters, searchTerm, curTerm, engineName, tenID, loadingResults, curFilters, curPage]);

  const approvedReportHandler = (list) => {
    let tempArr = [];

    list.forEach((item) => {
      if (item.fields.approved === 'true') {
        tempArr.push(item);
      }
      return tempArr;
    })
    return tempArr;
  }

  const findFiltersToSet = (list) => {
    let filters = engineName === 'cxreports' ? { archived: options['Show Archived'].toString(), } : {};
    if (list.informationOwner) {
      let tempFilters = { ...filters, informationOwner: list.informationOwner.value, }
      filters = tempFilters;
    }
    if (list.reportType) {
      let tempFilters = { ...filters, reportType: list.reportType.value, }
      filters = tempFilters;
    }
    if (list.sensitivity) {
      let tempFilters = { ...filters, sensitivity: list.sensitivity.value, }
      filters = tempFilters;
    }
    return filters;
  }

  const applyFiltersHandler = (filterList, num) => {

    let filters = findFiltersToSet(filterList)
    let start = filterList.from ? filterList.from : null;
    let end = filterList.to ? filterList.to : null;
    let sortOrder = filterList.sort_by ? filterList.sort_by.value : 'DATE_DESC';
    getResults(searchTerm, numPerPage, num ? num : curPage, engineName, tenID, filters, true, start, end, sortOrder)
  }

  //renders appropriate data based on filters applied
  const filteringDataHandler = (field, filter) => {
    // clears filters and set to original list
    setCurPage(1)
    if (field === 'clear-filters') {
      setClearFilter(true)
      setCurFilters({})
      setLabelString('Select...');
      setOptions({ 'Show Archived': false });
      setLoadingResults(true);
    } else {
      setClearFilter(false)
      if (field === 'date-range') {
        const tempFilters = { ...curFilters };
        if (filter.from) {
          tempFilters['from'] = filter.from;
          setCurFilters(tempFilters);
        }
        if (filter.to) {
          tempFilters['to'] = filter.to;
          setCurFilters(tempFilters);
        }
      } else {
        //makes a copy of the filter obj
        const tempFilters = { ...curFilters }
        //finds the field in the filter dictionary and replaces its value with the new change
        tempFilters[field] = filter
        //sets filters in state
        setCurFilters(tempFilters)
      }
    }
  }

  const paginate = (num) => {
    setLoading(true)
    setCurPage(num);
    applyFiltersHandler(curFilters, num);
  }

  function getDropdownOption(temp) {
    const optionsArray = Object.keys(temp); // get an array/list of all options [Show Archived, Second Option, Third Option]
    let returnString = "";
    for (let i = 0; i < optionsArray.length; i++) {
      if (temp[optionsArray[i]] === true) {
        if (i > 0) {
          returnString = returnString + ", " + optionsArray[i];
        } else {
          returnString = optionsArray[i];
        }
      }
    }
    setLabelString(returnString !== "" ? returnString : 'Select...');
  }

  function selectionHandler(event) {
    let temp = { ...options }
    temp[event.target.id] = event.target.checked
    setOptions(temp)
    getDropdownOption(temp)
  }

  return (
    !loading && !loadingResults ?
      status === 'ok' && articles ?
        engineName && engineName === CONSTANTS.ELASTIC_ENGINES.CX ?
          !preview && articles.results ?
            <>
              <Row>
                <Col lg={9} className='search-content'>
                  {
                    articles.results.length > 0 ?
                      <>
                        <IntellFormattedList
                          list={articles.results}
                          tenant={tenantSetting}
                          engineName={engineName}
                          searchTerm={searchTerm}
                        />
                        <Row className="pagination-row m-b-end-04">
                          {
                            totalNumArticles > numPerPage ?
                              <>
                                <Col>
                                  <ListPagination
                                    articlesPerPage={numPerPage}
                                    totalArticles={totalNumArticles}
                                    paginate={paginate}
                                    curPage={curPage}
                                  />
                                </Col>
                                <Col>
                                  <span className='report-num'>{`${articles.results.length} Reports`}</span>
                                </Col>
                              </>
                              : ''
                          }
                        </Row>
                      </>
                      : <div>No articles available that match your requests</div>
                  }
                </Col>
                <Col lg={3} className='uni-filter-bar m-b-start'>
                  {
                    pageType && (pageType !== CONSTANTS.ELASTIC_PAGE_TYPES.SEARCH) ?
                      <>
                        <LinkContainer to={'/intelligence/create'}>
                          <Button className="side-col-btn tertiary-button m-b-end-06">Add New Report</Button>
                        </LinkContainer>
                        <label className='subtitle-lg m-i-start-04 m-b-end-03'>More Display Settings</label>
                        <CheckBoxDropDownSelector
                          className='standard-dropdown m-i-start-04 m-i-end-03 m-b-end-03 dropdown-text-left body-med'
                          options={options}
                          displayOptionHandler={selectionHandler}
                          labelString={labelString}
                          keepOpen={false}
                        />
                      </>
                      : null
                  }
                  <FilterBarElastic
                    //metaData refers to filter to display based on app
                    metaData={metaData}
                    data={articles.results}
                    filteringDataHandler={filteringDataHandler}
                    rowsPerPage={10}
                    curFilters={curFilters}
                    applyFiltersHandler={applyFiltersHandler}
                    clearFilter={clearFilter}
                    apiName={'intel-filter'}
                  />
                </Col>
              </Row>
            </>
            :
            articles.results && articles.results.length > 0 ?
              approvedReportHandler(articles.results).map((thisItem, indx) => {

                if (indx < 3) {
                  return (
                    <div className="text-left">
                      <Link to={`/intelligence/reports/${thisItem.contentID}`}>
                        <h5>{thisItem.fields.title}</h5>
                        <p>
                          <small style={{ fontWeight: 'bold' }}>
                            {utils.getDateString(thisItem.fields.datepublished, 'short')}
                          </small>
                        </p>
                      </Link>
                    </div>
                  )
                } else {
                  return null;
                }
              })
              : <ListSkeletonLoader preview={preview} />
          :
          engineName === CONSTANTS.ELASTIC_ENGINES.PUBLIC ?
            preview === true && articles.results ?
              <NewsTeasers
                loadingTracker={loading}
                showNumber={2}
                stories={articles.results}
                showTags={false}
                entity={'nah'}
                entityType={'companyProfile'}
                preview={preview}
              />
              :
              articles.results ?
                <>
                  <Row>
                    <Col lg={9} className='search-content'>
                      {
                        articles.results.length > 0 ?
                          <>
                            <FormattedArticleList
                              engineName={engineName}
                              list={articles.results}
                              tenant={tenantSetting}
                              flatList={flatList}
                              searchTerm={searchTerm}
                            />
                            <Row className="pagination-row m-b-end-04">
                              {
                                totalNumArticles > numPerPage ?
                                  <ListPagination
                                    articlesPerPage={numPerPage}
                                    totalArticles={totalNumArticles}
                                    paginate={paginate}
                                    curPage={curPage}
                                  />
                                  : ''
                              }
                            </Row>
                          </>
                          : <div>No articles available that match your requests</div>
                      }
                    </Col>
                    <Col lg={3} className='uni-filter-bar m-b-start'>
                      <FilterBarElastic
                        //metaData refers to filter to display based on app
                        metaData={metaData}
                        data={articles.results}
                        filteringDataHandler={filteringDataHandler}
                        curFilters={curFilters}
                        applyFiltersHandler={applyFiltersHandler}
                        apiName={'news-filter'}
                        clearFilter={clearFilter}
                        className='m-b-start'
                      />
                    </Col>
                  </Row>
                </>
                : <ListSkeletonLoader preview={preview} />
            : null
        : <p>error</p>
      : <ListSkeletonLoader preview={preview} />
  )
}

export default UniversalElasticConnectedList