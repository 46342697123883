import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

function PDFExportButton({ country }) {

    const [ pdfRequested, setPdfRequested ] = useState(false);
    const [ loadingPDF, setLoadingPDF ] = useState(false);
    const [ serverError, setServerError ] = useState(null);
    const [ fileID, setFileID ] = useState(null);

    const downloadPDF = async (fileUrl) => {
        try {
            //weird way to do this, but it works since the link is a downloading link so we stay on the site
            window.location.href = fileUrl;
            setLoadingPDF(false);
        } catch (error) {
            setServerError('Error downloading PDF');
            setLoadingPDF(false);
        }
    }

    const getPDFInitiate = async () => {
        try {
            let resp = await fetch(`${process.env.REACT_APP_WEB_API}/api/export?country=${country}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    values: {
                        country: country
                    }
                })
            });
    
            if(resp.ok) {
                let data = await resp.json();
                setFileID(data.document.id);
            }
        } catch (error) {
            setServerError('Error rqeuesting PDF');
        } 
    }

    const getPDFToDownload = async () => {
        //query the server for the status of the document
        if(fileID) {
            try {
                let resp = await fetch(`https://api.pdfmonkey.io/api/v1/document_cards/${fileID}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_PDF_MONKEY_API_KEY}`
                    }
                });
                let data = await resp.json();

                if(data.document_card.status === 'success') {
                    setLoadingPDF(false);
                    return data.document_card.download_url;
                }

                if(data.document_card.status === 'failure') {
                    setServerError('Error generating PDF: ' + data.document_card.failure_cause );
                    setLoadingPDF(false);
                    return 'error';
                }

                return null;
            } catch (error) {
                setServerError('Error getting PDF');
                setLoadingPDF(false);
                return 'error';
            }
        }
    }

    useEffect(() => {
        if (pdfRequested) {
            setLoadingPDF(true);
            getPDFInitiate();
            setPdfRequested(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfRequested]);

    useEffect(() => {
        if(fileID) {
            //set a timeout to check the status of the document
            //every 3 seconds
            let interval;
            let fileUrlToUse = null;

            const checkStatus = () => {
                //add async iife
                (async () => {
                    fileUrlToUse = await getPDFToDownload();
                }
                )();

                if(fileUrlToUse) {
                    clearInterval(interval);
                    downloadPDF(fileUrlToUse);
                }
            }

            interval = setInterval(checkStatus, 3000);

            return () => clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [fileID]);

    return (
        // sends a request to the server to get a PDF
        // queries the server for status until the PDF is ready
        // downloads the PDF
        serverError !== null ? 
            <p>Sorry - there's been an error with the PDF.  Refresh your browser and if it continues, let know by the Feedback button on the left panel.</p> 
        : 
            <Button 
                onClick={() => setPdfRequested(true)} 
                disabled={loadingPDF === true || pdfRequested === true || serverError !== null }>
                    { loadingPDF ? 'Working on it...' : 'Get PDF' }
            </Button>
    )
}

export default PDFExportButton