import React, { useState, useEffect } from 'react';
import { Container, Spinner, Row, Col, Button } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import SortFilterPageTable from '../SortFilterPageTable';
import utils from '../../utils';

function PendingUsersDisplay({tenID, tenant, updateUserList, getWkspcUsers}) {

    const [loading, setLoading] = useState(true);
    const [pendingUsers, setPendingUsers] = useState([]);

    const { instance, accounts } = useMsal();

    const request = {
        scopes: ["User.Read"]
    };

    const onPendingHandler = (user, action) => {
        //removes user from pendingUser state
        let tempArr = pendingUsers.filter((item) => item === user.id)
        setPendingUsers(tempArr)

        if(action === 'delete') {
            //calls function to delete pending user from endpoint
            manageWkspcUsers('DELETE', user);
        }
        if(action === 'add') {
            //calls function to add pending user to the endpoint
            manageWkspcUsers('POST', user);
            //sends user up to be added to the usersList state in WorkspaceSettingsPanel.js to show on the Workspace User List UI
            updateUserList(user);
        }
    };

    const ulConfig = {
        columns: [
            {
                key: 'name',
                type: 'SIMPLE',
                title: 'Name',
                accessor: 'displayName',
                defaultSort: 'desc'
            },
            {
                key: 'userEmail',
                title: 'Email',
                type: 'SIMPLE',
                accessor: 'mail'
            },
            {
                key: 'actions',
                title: 'Actions',
                type: 'COMPLEX',
                render: (row) => { 
                    return (
                        <span>
                            <Button 
                                onClick={() => { onPendingHandler(row, 'add')}} 
                                variant="light" 
                                size="sm">Accept
                            </Button> | 
                            <Button 
                                onClick={() => { onPendingHandler(row, 'delete')}} 
                                variant="light" 
                                size="sm">Delete
                            </Button>
                        </span>
                    ) 
                }
            }
        ],
        filters: []
    };

    // On page load gets all pending users and sets them in state
    const getPendingUsers = async () => {
        let addedParams = `tenid=${tenID}`;

        setLoading(true);

        let token = await utils.getAccessToken(instance, accounts[0], request);
        

        try {
            let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/pending-users/${tenID}?${addedParams}`, {
                headers: {
                "Authorization": "Bearer " + token.accessToken
                }
            });

            let users = await response.json();

            setPendingUsers(users);
        } catch (error) {
            setPendingUsers([]);
        }

        setLoading(false);
    };

    useEffect( () => {
        getPendingUsers();  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const manageWkspcUsers = async (restMethod, user) => {
        // depending on restMethod will POST or DELETE to the endpoint
        setLoading(true);
        let addedParams = `tenid=${tenID}`;
        let token = await utils.getAccessToken(instance, accounts[0], request);
        
        try {
            let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/pending-users/${user.id}?${addedParams}`, {
                headers: {
                "Authorization": "Bearer " + token.accessToken
                },
                method: restMethod,
                body: JSON.stringify(user)
            })
                if(response.ok) {
                    getPendingUsers();
                    if(restMethod === 'POST'){
                        getWkspcUsers();
                    }
                }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    };

    return (
        <Container>
            { 
               
                    <Row className='inner-section'>
                        <Col>
                            <Row>
                                <Col>
                                    <>
                                        <h6>Access Requests<span className="help-text"> Manage requests from users to access this workspace.</span></h6>
                                        <span className="help-text">* Note: You are seeing this because you are an administrator of this workspace - changes will only affect this workspace.</span>
                                    </>
                                </Col>    
                            </Row>
                            {
                                !loading ?
                                    pendingUsers && pendingUsers.length > 0 ?
                                        <SortFilterPageTable 
                                            data={pendingUsers} 
                                            config={ulConfig} />
                                    : <div className='text-center no-items'>There are no requests at this time.</div>
                                : <Spinner animation='border' />
                            }
                        </Col>
                    </Row>
            }
        </Container>
    )
}

export default PendingUsersDisplay