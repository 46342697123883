import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../../styles/layout/_layout.scss';

function TableHeader(props){
  
  const [localValue, setLocalValue] = useState(null);

  useEffect(()=>{
    setLocalValue(props.value)
  }, [props.value])

  // This seems to be the only way to style Selects
  const customStyles = {
    control: provided => ({
      ...provided,
      border:0,
      indicatorSeparator:0,
      color: 'black',
      background:'transparent'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color:'black'
    }),
    clearIndicator: provided => ({
      ...provided,
      color:'black'
    }),
    placeholder: provided => ({
      ...provided,
      color:'black'
    })
  }
  
  return (
    <th>
      <strong>{props.title}</strong>
      {
        props.options === undefined?null:
          props.isMulti?
            <Select
                options = { props.options }
                isMulti = {true}
                closeMenuOnSelect = {false}
                onChange={(e)=>{
                  if (e.length < localValue.length){
                    props.onChange(e)
                  } else{
                    setLocalValue(e)
                  }
                }
                }
                onMenuClose={() => {
                  props.onChange(localValue)
                }}
                isClearable = {props.isClearable}
                value = {localValue}
                styles={customStyles}
            />
          :
            <Select
                  options = { props.options }
                  isMulti = {props.isMulti}
                  onChange={(e)=>props.onChange(e)}                  
                  isClearable = {props.isClearable}
                  onClear={()=>console.log("clearing")}
                  value = {props.value}
                  styles={customStyles}
              />
      }     
    </th>
  )
}

export default TableHeader;