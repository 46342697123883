import React from 'react';
import { Dropdown } from 'react-bootstrap';
import CONSTANTS from '../../constants';
import { ChevronDown } from 'react-bootstrap-icons';

function DropDownSelector({ indexOfList, opt, handleChange, curSelection, disabled, className=null}) {


    const getOptionsForDropDown = (optLabel) => {
        switch (optLabel) {
            case CONSTANTS.MARKET_ACTIONS_VIEW_OPTIONS.GROUPBY.label:
                return (
                    [
                        { label: 'Status', value: 'status', active: true },
                        { label: 'Priority', value: 'priority', active: false }
                    ]
                )

            default:
                break;
        }
    }

    const displayCurSelection = (curSelect) => {

        switch (curSelect) {
            case 'status':
                return 'Status';
            case 'supplier':
                return'Supplier';
            case 'priority':
                return 'Priority';
            default:
                return 'Status';
        }
    }

    

    return (
        
        <Dropdown
            as={Dropdown}
            className={disabled === true ? `disabled-dropdown ${className}` : 'toolbar-standard-dropdown'}
            key={`market-opts-${indexOfList}`}
            id={`market-opts-${indexOfList}`}
            title={ displayCurSelection(curSelection)}
            onSelect={(e) => handleChange(e)} >
                <Dropdown.Toggle disabled={disabled}>{ displayCurSelection(curSelection)}<ChevronDown /></Dropdown.Toggle>
                
                <Dropdown.Menu>
                { 
                getOptionsForDropDown(opt.label).map((thisOption) => {
                    return (
                        <Dropdown.Item
                            eventKey={thisOption.value}
                            active={curSelection === thisOption.value} >
                            {thisOption.label}
                        </Dropdown.Item>
                    )
                })
            }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default DropDownSelector