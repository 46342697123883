import React, { useEffect } from 'react';
import { Navbar, Container, Row, Col, Nav } from 'react-bootstrap';
import TenantUserBlock from './TenantUserBlock';
import SearchComponent from './SearchComponent';
import '../../../styles/components/_navBar.scss';

function TenantTopNav({ tenant, onQuery, raiseSupport, onWidthChange, windowSize, tenantName=null, resetToken, className=null, showFakes=false}) {

    const updateDimensions = () => {
        onWidthChange(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    // eslint-disable-next-line    
    }, []);

    return (
        <>
            <Navbar className={'top-nav-fixed'} expand='xxl' style={{ padding: '0' }}>
            <Container fluid className="nav-margin shadowed gx-0">
                        <Row className='tenant-top-nav gx-0'>
                            <Col className={tenant && tenant.fields.appname ? 'search-col' : 'search-col add-margin-top'} xs={11} md={11} lg={8} xl={8}>
                                <Nav className={ className ? `${className} nav-app-item` : "nav-app-item"}>
                                    <SearchComponent onQuery={onQuery} tenantSetting={tenant} tenantName={tenantName}/>
                                </Nav>
                            </Col>
                            <Col className={tenant && tenant.fields.appname ? 'signin-col' : 'signin-col add-margin-top'} xs={1} md={1} lg={4} xl={4}>                  
                                <Nav className="text-right">
                                    <TenantUserBlock 
                                        currentTenant={tenant}
                                        raiseSupport={raiseSupport}
                                        windowSize={windowSize}
                                        tenantName={tenantName}
                                        resetToken={resetToken}
                                    />
                                </Nav>                              
                            </Col>
                        </Row>
                    </Container>
            </Navbar>
        </>
    )
}

export default TenantTopNav
