import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';
import DynamicIcon from './DynamicIcon';

import countryList from 'country-list';

function BubbleRow({ itemArray, objToRender }) {

    // itemArray example
    // [
    //     { field: "yearEstablished", type: 'text', label: 'Established' },
    //     { field: "headquarters", type: 'text', label: 'Headquarters' }, 
    //     { field: "numberofCountryOffices", type: 'text', label: 'Country Offices' }, 
    //     { field: "numberofStaff", type: 'text', label: 'Number of Staff'}
    //  ]

  return (
    <>
        <Row sm={1} md={1} lg={3} className="info-bubbles">
            { itemArray.map( (thisItem) => {
                return (
                    <Col> 
                        <Card className="mini-weird-corners">
                            <Row>

                                {
                                    
                                    thisItem.type === 'country' ?
                                        <>
                                        <Col xs={3} className="logo-col">
                                            <ReactCountryFlag
                                            style={{ width: "1.5em", height: 'auto' }}
                                            countryCode={ countryList.getCode(objToRender.fields[thisItem.field]) }
                                            svg />
                                        </Col>
                                        <Col xs={9}>
                                            <div className="label">{ thisItem.label }</div>
                                            { objToRender.fields[thisItem.field] }
                                        </Col>
                                        </>
                                    : 
                                     thisItem.type === 'text' ?
                                        <Col xs={12}>
                                            <div className="label">{ thisItem.label }</div>
                                            { objToRender.fields[thisItem.field] }
                                        </Col>

                                     :
                                        <>
                                            <Col xs={3} className="logo-col">
                                                <DynamicIcon iconName={thisItem.icon} style={{width: "1.5em", height: 'auto'}} />
                                            </Col>
                                            <Col xs={9}>
                                                <div className="label">{ thisItem.label }</div>
                                                { objToRender.fields[thisItem.field] }
                                            </Col>
                                        </>
                                
                                    
                                }
                            </Row>
                        </Card>
                    </Col>
                )
            })}
                            
        </Row>
    </>
  )
}

export default BubbleRow