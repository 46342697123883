import React, {useState, useEffect} from 'react';
import utils from '../../utils';
import { useMsal } from '@azure/msal-react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';

import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import CENewsToElasticAdapter from './CENewsToElasticAdapter';
import constants from '../../constants';
import CEFilterBar from './CEFilterBar';

import diseaseList from './DiseaseList';
import ircUtils from '../IRC/ircUtils';
import PublicationsList from './PublicationsList';

function CENewsApp({ tenantSetting, preview=false }) {
    
    const [countryList, setCountryList] = useState([]);
    const [ APIKey, setAPIKey ] = useState();
    const [ filters, setFilters ] = useState([]);

    let tenID = utils.getForceTenantValue();

    if(!tenID) {
        //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
        tenID = tenantSetting.tenant.fields.aDGroupID || null;
        utils.setForceTenantValue(tenID);
    }

    const request = {
        scopes: ["User.Read"]
    }; 
    
    const { instance, accounts } = useMsal();

    const trackCall = async () => {
        let accToken = await utils.getAccessToken(instance, accounts[0], request);
        if(!preview) {
          //no await on purpose, we don't need to ensure that it returns - just issue it
          utils.trackAppView('ce-news', tenID, accToken);
        }
    }

    const getAPIKey = async() => {
        let accToken = await utils.getAccessToken(instance, accounts[0], request);
        let response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL, {
          headers: {
            "Authorization": "Bearer " + accToken.accessToken
          }
        });

        const responsejson  = await response.json();
       
        setAPIKey(responsejson.value);
        return responsejson.value;
    }

    const getCountriesFromDBAPI = async() => {
        let APIKey = await getAPIKey();

        let response = await utils.getData('get_countries', APIKey,'');

        let trimmedResponse = response.map((thisCountry) => {
            return {
                label: thisCountry.country,
                value: thisCountry.country
            }
        });

        setCountryList(trimmedResponse);
    }

    const filterFlipExistsOnList = (filtername, filterList) => {
        return filterList.findIndex((item) => {
            return item.field.toLowerCase() === filtername.toLowerCase();
        });
    }

    const generateYearList = () => {
        //start at current year and go back 10 years
        let thisyear = new Date().getFullYear();
        let yearList = [];
        
        for (let i = thisyear; i > (thisyear-9); i--) {
          yearList.push({
            label: i.toString(),
            value: i.toString()
          });
          
        }
    
        return yearList;
    }

    const setNewFilters = (prvfilters, newFilter) => {

        //newFilter comes in through higher context
        let filtersCpy = [...prvfilters];

        //handle special case - non-integration cannot be on at the same time as integration, it's either one or none (as that's everything)
        //this is up in this layer bc it's where the state of the filters are managed.

        if(newFilter.field === 'non-integrated') {
            
            //first check if it's already in the list, if so, take off
            let foundAlreadyIndex = filterFlipExistsOnList('non-integrated', filtersCpy);

            if(foundAlreadyIndex > -1) {
                filtersCpy.splice(foundAlreadyIndex, 1);
            } else {
                //not there, so check if integrated is there, if so, take it off
                let foundIntIndex = filtersCpy.findIndex((item) => {
                    return item.field.toLowerCase() === 'integrated';
                });

                if(foundIntIndex > -1) {
                    filtersCpy.splice(foundIntIndex, 1);
                }

                filtersCpy.push(newFilter);
            } 

            return filtersCpy;
        } 
        
        if (newFilter.field === 'integrated') {
            //if it's coming as true, check if non-integration
            
            let foundAlreadyIndex = filterFlipExistsOnList('integrated', filtersCpy);

            if(foundAlreadyIndex > -1) {
                filtersCpy.splice(foundAlreadyIndex, 1);
            } else {
                //not there, so check if integrated is there, if so, take it off
                let foundIntIndex = filtersCpy.findIndex((item) => {
                    return item.field.toLowerCase() === 'non-integrated';
                });

                if(foundIntIndex > -1) {
                    filtersCpy.splice(foundIntIndex, 1);
                }

                filtersCpy.push(newFilter);
            } 

            return filtersCpy;
        } 
        
        // the rest of the filters.
        //does it exists on our list?
        let foundIndex = filtersCpy.findIndex((item) => {
            return item.field.toLowerCase() === newFilter.field.toLowerCase();
        });

        if(foundIndex > -1) {
            //if it is, update it if value is not '' nor false
            if(newFilter.value === ''){
                filtersCpy.splice(foundIndex, 1);
            }

            if(newFilter.value !== null && newFilter.value.length > 0) {
                filtersCpy[foundIndex] = newFilter;
            }
        } else {
            //if not, add it
            filtersCpy.push(newFilter);
        }

        return filtersCpy;
    }

    const filterChanged = (newFilter) => {
        //check if the filter is already in the list
        setFilters(setNewFilters(filters, newFilter));
    }

    useEffect(() => {
        // only track if on prod
        if(process.env.REACT_APP_ENV === 'PROD' ) {
            trackCall();
        }

        //for getting the country and disease list
        if(!APIKey) {
            getCountriesFromDBAPI();
        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <BreadCrumbBar appName={constants.APPS_BY_APPTYPE.CE_NEWS} />
            <h1 className='h1 m-b-start-08 m-b-end-08'>Campaign Effectiveness News</h1>

            <Tab.Container id="ce-news-tabs" defaultActiveKey="news">
                <Nav variant='tabs' className='mb-3'>
                    <Nav.Item key={"news"}>
                        <Nav.Link eventKey={"news"}>News</Nav.Link>
                    </Nav.Item>
                    <Nav.Item key={"publications"}>
                        <Nav.Link eventKey={"publications"}>Publications</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey='news'>
                        <Row>
                            <Col lg={9} className='search-content'>
                                <CENewsToElasticAdapter 
                                    inputArray={filters}
                                    paginationsize={25}
                                    formatForNewsList={ircUtils.formatForNewsList} />
                            </Col>
                            <Col lg={3} className='uni-filter-bar m-b-start'>
                                <CEFilterBar
                                    onFilterChanged={filterChanged}
                                    countryList={countryList}
                                    diseaseList={diseaseList}
                                    yearList={generateYearList()}
                                    curFilters={filters} />
                            </Col>
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey='publications'>
                        <Row>
                            <Col lg={9}>
                                <PublicationsList
                                    titleOfList={''}
                                    listName={'cepublicationlist'} />
                            </Col>
                        </Row>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
        
    )
}

export default CENewsApp
