import React from 'react';
import { useHistory } from 'react-router-dom';
import CONSTANTS from '../../constants';
import NavUtils from '../../NavUtils';
import CENewsApp from '../../components/CENews/CENewsApp';


function CENewsAppPage({ tenant }) {

    const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.CE_NEWS);

    if(!hasAccess) {
        history.push('/');
    }

    return (
        <div className="full-news-app">
          <CENewsApp tenantSetting={tenant} />
        </div>
    )
}

export default CENewsAppPage
