import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import FilteredPagedHorizList from '../../components/FilteredPagedHorizList';
import { useHistory } from 'react-router-dom'

import NavUtils from '../../NavUtils';

import utils from '../../utils';
import CONSTANTS from '../../constants';


function PrtnrProfilesListPage({ tenant, preview = false, numToShow = 3 }) {

    const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.PARTNER_PROFILES);

    if(!hasAccess) {
        history.push('/');
    }
    
    const { instance, accounts } = useMsal();

    let [profiles, setProfiles] = useState();
    let [loading, setLoading] = useState(true);


    const request = {
        scopes: ["User.Read"]
    };

    const gridLayout = [
        {
            type: 'img',
            fieldName: 'logo',
            meta: { className: 'horiz-grid-image' }
        },
        {
            type: 'text',
            fieldName: 'name'
        },
        {
            type: 'item-link',
            fieldName: 'slug',
            path: `/${CONSTANTS.APPS_BY_APPTYPE.PARTNER_PROFILES}`,
            label: 'View Profile'
        }
    ]

    let tenID = utils.getForceTenantValue();

    if (!tenID) {
        //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
        tenID = tenant.tenant.fields.aDGroupID || null;
        utils.setForceTenantValue(tenID);
    }

    const trackCall = async (accToken, appType) => {
        //no await on purpose, we don't need to ensure that it returns - just issue it
        utils.trackAppView(appType, tenID, accToken);
    }

    let tenantSlug = tenant.tenant.fields.tenantSlug

    const getProfiles = async (token) => {
        let extraParams = 'force_id=' + tenant.tenant.fields.aDGroupID;

        let jsonresponse = await utils.getTenantContent(token, CONSTANTS.APPS_BY_APPTYPE.PARTNER_PROFILES, tenantSlug, tenant.ourUserID, extraParams);

        setProfiles(jsonresponse.content);

        setLoading(false);
    }

    useEffect(() => {
        try {
            (async () => {
                let accToken = await utils.getAccessToken(instance, accounts[0], request);
                // only track if on prod
                if(process.env.REACT_APP_ENV === 'PROD' ) {
                    trackCall(accToken, 'partner_profiles');
                }
                getProfiles(accToken);
            })()
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    return (
        !loading && profiles ?
            <>
                {
                    profiles !== null ?
                        preview ?
                            profiles.slice(0, numToShow).map((thisProfile) => {
                                return (
                                    <p>{thisProfile.fields.name}</p>
                                )
                            })
                            : <FilteredPagedHorizList
                                rawList={profiles}
                                filtersToUse={[]}
                                appName={CONSTANTS.APPNAMES_BY_PATH.partner_profiles}
                                numPerPage={5}
                                layoutArray={gridLayout}
                                showFilter={false} />
                        : <Spinner animation="border" />
                }
            </>
            : <div className="text-center"> <Spinner animation="border" /> </div>
    )
}

export default PrtnrProfilesListPage