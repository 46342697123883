import { Button, Table} from 'react-bootstrap'

import '../../../styles/layout/_layout.scss'

function AltNames({product, setProduct, disabled}) {
  /*----------------------State Variables-----------------------------------*/


  function setName(name, i) {
    // Set the organiztion in a row of organizations in the bottom right of the UI
    const altNames = [...product.alt_names];
    if (name === null){
      altNames[i].alt_name = ''
    } else{
      altNames[i].alt_name = name.target.value
    }
    setProduct({
      ...product,
      alt_names:altNames
    })
  }


  function addName(){
    // Creates a new organization row based on organization template
    const altNames = product.alt_names
    altNames.push({
      alt_name:'',
      source:''
    })
    setProduct({
      ...product,
      alt_names:altNames
    })
  }



  function setSource(source, i) {
    // Sets organization role for row i
    const altNames = product.alt_names
    altNames[i].source = source.target.value
    setProduct({
      ...product,
      alt_names:altNames
    })
  }


  function deleteAltName(index) {
    const altNames = [...product.alt_names]
    altNames.splice(index, 1)
    setProduct({...product, alt_names: altNames})
  }
  return  (
    <div>
      <div className='input-text'>Alternative Names</div>
      <Table size = "sm">
        <thead className = "table-heading">
          <tr>
            <th>Source</th>
            <th>Name</th>
            {
              disabled?
              <th></th>:
              <th className="clickable-text"
                onClick={addName}>
                  add
              </th>
            }
            
          </tr>
        </thead>
        <tbody className ="table-body">
          {product.alt_names.map((an, i) => {
            return <tr key = {i}>
              <td>
                <input
                  disabled={disabled}
                  className='form-ctrl'
                  type='text'
                  value={product.alt_names[i].source}
                  onChange={(e) => setSource(e, i)}
                />
              </td>
              <td>
                <input
                  disabled={disabled}
                  className='form-ctrl'
                  type='text'
                  value={product.alt_names[i].alt_name}
                  onChange={(e) => setName(e, i)}
                />
              </td>
              <td className='right-align'>
                {disabled?
                  null:
                  <Button 
                    size="sm"
                    variant="danger" 
                    onClick = {() => deleteAltName(i)}>
                    -
                  </Button>
                }
                
              </td>
            </tr>})
          }
        </tbody>
      </Table>
      
    </div>
  );
}  

export default AltNames;