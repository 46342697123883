import React, {useEffect} from 'react';
import CONSTANTS from '../../constants';
import ElasticAppHarness from '../ElasticAppHarness';
import utils from '../../utils';
import { useMsal } from '@azure/msal-react';

function NewsApp({ tenantSetting, preview=false, forceTags=null }) {
    //if preview, only show small bits
    //if preview, filter to newest news


    let tenID = utils.getForceTenantValue();

    if(!tenID) {
        //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
        tenID = tenantSetting.tenant.fields.aDGroupID || null;
        utils.setForceTenantValue(tenID);
    }

    const request = {
        scopes: ["User.Read"]
    };

    const trackCall = async () => {
        let accToken = await utils.getAccessToken(instance, accounts[0], request);
        if(!preview) {
          //no await on purpose, we don't need to ensure that it returns - just issue it
          utils.trackAppView('news', tenID, accToken);
        }
    }

    const { instance, accounts } = useMsal();
    
    const metaData = [
        //TITLE: What should display on UI
        //DISPLAYFIELD: What is stored in CONSTANTS
        //FILTERFIELD: JSON field to filter the master list on
        {title:'Sort By', displayField: 'sort_by_recent'},
        {title:'Date Range', displayField: 'date_range_filter'}
  
    ]

    // let theEngine = CONSTANTS.ELASTIC_ENGINES.PUBLIC;

    // // //only show GHAF news for GHAF Tenant
    // // if(tenantSetting.tenant.fields.tenantslug === 'GHAF') {
    // //     theEngine = CONSTANTS.ELASTIC_ENGINES.PUB_AND_GHAF;
    // // }

    useEffect(() => {
        // only track if on prod
        if(process.env.REACT_APP_ENV === 'PROD' ) {
            trackCall();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ElasticAppHarness
                headerText='News' 
                tenantSetting={tenantSetting} 
                appType={CONSTANTS.APPS_BY_NAME.NEWS} 
                pageType={CONSTANTS.ELASTIC_PAGE_TYPES.NEWS} 
                preview={preview}
                metaData={metaData} />
        </>
    )
}

export default NewsApp
