import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LBGanttRow from './LBGanttRow';

function LBGanttGroupByWrapper({ tasksList, groupByField, rowIdx, zoom, currentView, handleShowIntModal }) {
  
  return (
    <Row className={`LBGantt-row row-${rowIdx}`}>
        <Col className={`LBGantt-col col-0`}>
            { groupByField }
        </Col>
        <Col>
        {
            tasksList.map( (thisTask, index ) => {
                return (
                <LBGanttRow 
                    header={false} 
                    zoomlevel={zoom} 
                    viewStart={currentView} 
                    task={thisTask} 
                    handleShowIntModal={handleShowIntModal} />
                )
            })
        }
        </Col>
        
    </Row>
  )
}

export default LBGanttGroupByWrapper