import React, {useState} from 'react';
import {Button, Col, Row, Table} from 'react-bootstrap'
import EnumSelector from '../EnumSelector';
import ObjectSelector from '../ObjectSelector';
import PriceDetailEditor from './PriceDetailEditor'
import TableHeader from '../TableHeader';


function SourceDetailEditor(props) {
  const [showPriceDetails, setShowPriceDetails] = useState(false)

  function addPriceDetail(){
    const temp = props.source_detail.price_details
    temp.push({
      year:null,
      price_low:null,
      price_high:null,
      comments:null,
      publish_date:null,
      source_url:null,
      currency:null
    })
    props.onChange({...props.source_detail, price_details:temp})
  }

  function setPriceDetail(price_detail, i){
    const temp = props.source_detail.price_details
    temp[i] = price_detail
    props.onChange({...props.source_detail, price_details:temp})
  }

  function deletePriceDetail(i){
    if(!window.confirm("This will delete a price detail. Are you sure you want to proceeed?")){
      return
    }
    var temp = props.source_detail.price_details
    temp.splice(i, 1)
    props.onChange({...props.source_detail, price_details:temp})
  }

  function setCountry(country){
    if (country===null){
      props.onChange({...props.source_detail, paho_origin_iso:null, paho_origin:null})
    }else{
      props.onChange({...props.source_detail, paho_origin_iso:country.ISO, paho_origin:country})
    }

  }

  return (
    <div className='padded-row'>
      <Row>
        <Col>
          <div className='input-text'>Recipient</div>
          <EnumSelector
            enum_type = 'price_recipient'
            apiKey={props.apiKey}
            value={props.source_detail.recipient}
            onChange={(e)=>props.onChange({...props.source_detail, recipient:e.label})}
          />
        </Col>
        <Col>
          <div className='input-text'>Confidentiality</div>
          <EnumSelector
            enum_type = 'price_confidentiality'
            apiKey={props.apiKey}
            disableCreate={true}
            value={props.source_detail.confidentiality}
            onChange={(e)=>props.onChange({...props.source_detail, confidentiality:e.label})}
          />
        </Col>
        <Col>
          <div className='input-text'>Procurement</div>
          <EnumSelector
            enum_type = 'procurement_mechanism'
            apiKey={props.apiKey}
            disableCreate={true}
            value={props.source_detail.procurement_mechanism}
            onChange={(e)=>props.onChange({...props.source_detail, procurement_mechanism:e.label})}
          />
        </Col>
        <Col>
          <div className='input-text'>PAHO Origin</div>
          <ObjectSelector
            apiKey={props.apiKey}
            obj={props.source_detail.paho_origin}
            data={props.countries}
            isClearable={true}
            onChange={setCountry}
            type='country'
          />
        </Col>
        <Col xs={1}>
          {
            showPriceDetails?
            <Button variant='warning' onClick={()=>setShowPriceDetails(!showPriceDetails)}>-</Button>:
            <Button variant='success' onClick={()=>setShowPriceDetails(!showPriceDetails)}>+</Button>
          }
          
        </Col>
        
      </Row>
      {showPriceDetails?
        <Row className='padded-row'>
          <Table striped size = "sm">
            <thead className="table-heading">
              <TableHeader title='Temple ID'/>
              <TableHeader title='Year'/>
              <TableHeader title='Date'/>
              <TableHeader title='Price Low'/>
              <TableHeader title='Price High'/>
              <TableHeader title='Comment'/>
              <TableHeader title='Source URL'/>
              <th className='clickable-text' onClick={addPriceDetail}>add</th>
            </thead>
            <tbody>
              {props.source_detail.price_details.map((pd, i)=>{
                return <PriceDetailEditor
                  price_detail={pd}
                  onChange={(e)=>setPriceDetail(e, i)}
                  onDelete={()=>deletePriceDetail(i)}
                />
              }
              
              )
              }
            </tbody>
          </Table>
        </Row>
        :''
      }
      
      <Button variant='danger' onClick={props.onDelete}>Delete Source</Button>
    </div>

  )
}

export default SourceDetailEditor;