import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import CompanyTeaserLine from './CompanyTeaserLine';
import FilteredPagedCoProfList from './FilteredPagedCoProfList';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';

function CompanyProfileApp({ tenantSetting, preview=false }) {
    //if preview, only show small bits
    //if preview, only show companies with latest updates

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated(); 

    let [ profiles, setProfiles ] = useState();
    let [ loading, setLoading ] = useState(true);

    const request = {
        scopes: ["User.Read"]
    };

    const getAccessToken = async (instance, account) => {
        try{
            let theAccToken =  await instance.acquireTokenSilent({...request, account: account});
            return theAccToken;
        } catch(error) {
            console.log(error);
        }
    }

    let tenantslug = null;

    let numToShow = preview ? 3 : 15;

    if(tenantSetting){
        tenantslug = tenantSetting.tenant.fields.tenantslug;
    }

    const sortToLatestModified = (list) => {
        //sorts by most recent first
        return list.sort( (firstEl, secondEl) => {
            let firstDate = new Date(firstEl.properties.modified);
            let secondDate = new Date(secondEl.properties.modified);

            if(secondDate > firstDate) {
                return 1;
            }

            if(secondDate < firstDate) {
                return -1;
            }

            return 0;
        })
    };

    const sortExecutives = (companyProfile) => {
        if(companyProfile.fields.companyLeadershipProfiles){
            companyProfile.fields.companyLeadershipProfiles.sort( (item1, item2) => {
                let first = item1.fields.sortOrder ? item1.fields.sortOrder : 0;
                let sec = item2.fields.sortOrder ? item2.fields.sortOrder : 0;

                return sec - first;
            })
        }

        return companyProfile;
    }


    const getProfiles = async (accToken) => {
        setLoading(true);
        let extraParams = `force_id=${tenantSetting.tenant.fields.aDGroupID}${preview === null || preview===false ? '&full_app=true': ''}`;

        let jsonresponse = await utils.getTenantContent(accToken, CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES,tenantslug, tenantSetting.ourUserID, extraParams);

        let listToSet = preview ? sortToLatestModified(jsonresponse.content) : jsonresponse.content;

        setProfiles(listToSet);
        
        setLoading(false);
    }

    useEffect( (tenantslug) => {
        try{
            (async () => {
                setLoading(true);
                let accToken = await getAccessToken(instance, accounts[0]);
                getProfiles(accToken);
            })()
        } catch(error) {
            console.log(error);
            setLoading(false);
        }
    // eslint-disable-next-line
    },[ tenantslug, isAuthenticated ]);

    return (
        <div className="profiles_list">
           {
               !loading && profiles ?
                    preview ? 
                        profiles.slice(0, numToShow).map( (thisProfile) => {
                            return (
                                <CompanyTeaserLine
                                    className="preview" 
                                    profile={sortExecutives(thisProfile)} 
                                    singleCompanyView={utils.isMNCTenant(tenantSetting)} /> )
                            }) 
                    : 
                    <FilteredPagedCoProfList 
                        rawList={ profiles } 
                        tenant={tenantSetting}
                    /> 
                : <ListSkeletonLoader preview={preview} />
           } 
        </div>
    )
}

export default CompanyProfileApp
