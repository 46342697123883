import BasicElasticEngine from "../BasicElasticEngine";

function CENewsToElasticAdapter({ inputArray, paginationsize, formatForNewsList, engineToUse='ce-articles' }) {

    //takes the ce input array and converts it to the format that the BasicElasticEngine expects

    //ce input array is an array of objects with the following structure:
    // {
    //     field: 'country' or 'disease' or 'integration'
    //     value: 'USA'
    // }
    const CE_TO_ELASTIC_MAP = {
        'country': 'post_region_countries',
        'disease': 'post_health_domains'
    }

    const getOutputItem = (itemInFilter) => {
        const itemInFilterName = itemInFilter.field.toLowerCase();

        switch(itemInFilterName) {
            case 'integrated':
                return {
                    field: 'integration',
                    value: "true",
                    type: 'text'
                }
            case 'non-integrated':
                return {
                    field: 'integration',
                    value: "false",
                    type: 'text'
                }
            case 'country':
                return {
                    field: 'post_region_countries',
                    value: itemInFilter.value,
                    type: 'text'
                }
            case 'disease':
                return {
                    field: 'post_health_domains',
                    value: itemInFilter.value,
                    type: 'text'
                }
            case 'date_published':
                return {
                    field: 'date_published',
                    value: itemInFilter.value,
                    type: 'date'
                }
            default:
                return {
                    field: CE_TO_ELASTIC_MAP[itemInFilterName],
                    value: itemInFilter.value,
                    type: 'text'
                }
        }
    }

    const outputFilters = inputArray.map( filter => getOutputItem(filter) );

    return (
        <BasicElasticEngine 
            engineToUse={engineToUse}
            paginationsize={paginationsize}
            formatForNewsList={formatForNewsList}
            filterItems={outputFilters} />
    );
}

export default CENewsToElasticAdapter;