import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import BreadCrumbBar from '../components/Layout/Nav/BreadCrumbBar';
import UniversalElasticConnectedList from '../components/UniversalElasticConnectedList';
import utils from '../utils';
// import SearchResultsNodeWrapper from '../components/News/SearchResultsNodeWrapper';
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import CONSTANTS from '../constants';
import { BellFill } from 'react-bootstrap-icons';

import ListSkeletonLoader from '../components/Layout/ListSkeletonLoader';
import BtnDynamic from '../components/Layout/BtnDynamic';
import ModalDynamic from '../components/Layout/ModalDynamic';

function SearchResultsPage({ tenant, curNav }) {

    // TopicNodes
    // let [ nodeResults, setNodeResults ] = useState();
    let [ currentTerm, setCurrentTerm ] = useState();
    let [ loading, setLoading ] = useState(true);
    let [ currentPage, setCurrentPage ] = useState(1);
    let [ activeKey, setActiveKey ] = useState(curNav && curNav === '/intelligence' ? 'Intelligence' : 'News');
    let [ emailPrefs, setEmailPrefs ] = useState([]);
    let [ loadingPrefs, setLoadingPrefs ] = useState(false);
    let [ reloadLoading, setReloadLoading] = useState(false)
    let [ show, setShow ] = useState(false);

    let featureflags = utils.getFeatureflags(process.env.REACT_APP_ENV, 'subscription-notifications', tenant.tenant.fields.featureflags);

    const metaData = [
        //TITLE: What should display on UI
        //DISPLAYFIELD: What is stored in CONSTANTS
        //FILTERFIELD: JSON field to filter the master list on
        {title:'Sort By', displayField: 'sort_by_relevance'},
    ]

    // let tenID = utils.getForceTenantValue();

    const { instance, accounts } = useMsal();
    let { query } = useParams();

    const request = {
        scopes: ["User.Read"]
    };

    const addSubscriptionToState = (e) => {
        let beMap = {
            'news':'articles',
            'intelligence':'intelligence'
        };

        let cpyPrefs = [...emailPrefs];
        let newPrefs = utils.addTagSubscriptionToArray(e, beMap, query, cpyPrefs)
        setEmailPrefs(newPrefs);   
    } 

    const getModalBody = (searchQuery) => {
        return (
            <div className='m-b-end-07'>
                <p className='body-med txt-primary'>{`You're about to subscribe to email notifications for articles and updates related to the tag ${searchQuery}. You'll receive a curated list of content directly to your inbox to keep you informed and engaged.`}</p>
                
                    
                <p className='body-med txt-secondary m-b-start-08'>Select app content of this tag to receive notifications for:</p>
                {
                    utils.subscriptOptArr(tenant).map((app) => {
                        return (
                            <div className='input-checkbox'>
                                <input className='m-i-end-03' type="checkbox" defaultChecked={utils.isInArray({ type: 'tag', target: searchQuery, for: app.toLowerCase() === 'news' ? 'articles' : 'intelligence'}, emailPrefs)> -1} id={app} name={app} onChange={addSubscriptionToState}/>
                                <label className='body-large'>{app}</label>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const addIcon = (name) => {
        return (
            <>
                <BellFill className='btn-icon surface-primary-text' />{name}
            </>
        )
    }

    const subscriptionModal = {
        type: query,
        title: `Subscription to ${query} notifications`,
        body: getModalBody(query),
        app: 'search',
        subName: addIcon('Subscribe')
    }

    //MAY ADD BACK IN LATER SO LEAVING AS COMMENTED OUT FOR NOW
    // // getTopicNodes gets nodes from ElasticSearch
    // const getTopicNodes = async (page, thisquery) => {

    //     let newResponse = await utils.upsert('lb_search', process.env.REACT_APP_DB_PUBLIC_KEY,{
    //         engine:'topic-nodes',
    //         is_boolean:false,
    //         filters:{},
    //         size:10,
    //         page:page,
    //         query:thisquery,
    //         sort_order:'SCORE',
    //         tenant_id: tenID
    //     }) 
    //     // setNodeResults(newResponse.results)
    // }

    useEffect( ()=> {
        if(query && query.length > 0){
            if(query !== currentTerm) {
                setCurrentPage(1);
                setCurrentTerm(query)
            }
            try {
                setLoading(true);
                setCurrentTerm(query);
                getUserPrefs();
                // getTopicNodes(currentPage, query);  
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, query]);
    
    const handleSelect = (key) => {
        setActiveKey(key.toLowerCase());
        setReloadLoading(true);
    }

    const convertToPrefsList = (objArray) => {
        return objArray.map( (item) => {
            let itemArray = item.rowKey.split('::');
            return { type: itemArray[0], for: itemArray[1], target: itemArray[2]};
        });
    }

    const getUserPrefs = async () => {
        setLoadingPrefs(true);
        let token = await utils.getAccessToken(instance, accounts[0], request);

        try {
            let userPrefResp = await utils.getUserEmailPrefs(token)
            let convertToList = convertToPrefsList(userPrefResp);
            
            setEmailPrefs(convertToList);

        } catch(error) {
            console.log("error: ", error);
        }

        setLoadingPrefs(false);
    }

    const saveUserPrefs = async (userPrefs) => {
        let token = await utils.getAccessToken(instance, accounts[0], request);

        try {
           await utils.saveUserEmailPrefs(userPrefs, token);

        } catch(error) {
            console.log("error: ", error);
        }
    }

    const handleEdits = (dataForEdit) => {
        saveUserPrefs(emailPrefs);
        setShow(false);
    }

    const handleLoading = () => {
        setReloadLoading(false);
    }

    return (
        !loading ?
            <div className='search-page'>
                <BreadCrumbBar appName="SEARCH" />
            
                <h1 className='m-b-end-08 h1'>Search Results For: <span className="secondary-link ">"{currentTerm}"</span></h1>

                {/* LEAVE FOR NOW<SearchResultsNodeWrapper
                    elasticInput={nodeResults}
                    searchTerm={currentTerm}
                />  */}
                <Row className='search-tab-col p-i-start-04'>
                    <Col lg={9} >
                        <Tab.Container
                            defaultActiveKey={activeKey}
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                        {
                            
                            tenant.tenant.fields.apps.map((app) => {
                                return (
                                    CONSTANTS.APPS_FOR_TAB_SEARCH[app.fields.apptype.toUpperCase()] ?
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link 
                                                    className={activeKey === CONSTANTS.APPS_FOR_TAB_SEARCH[app.fields.apptype.toUpperCase()] ? 'active' : ''}
                                                    eventKey={CONSTANTS.APPS_FOR_TAB_SEARCH[app.fields.apptype.toUpperCase()]}
                                                    onClick={(e) => handleSelect(e.target.innerText)}
                                                >
                                                    {CONSTANTS.APPS_FOR_TAB_SEARCH[app.fields.apptype.toUpperCase()]}
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    : ''
                                )
                            })
                        }
                        </Tab.Container>
                    </Col>
                    <Col lg={3}>
                        { 
                            featureflags === 'true' || featureflags === true ?
                            <>
                                <BtnDynamic
                                    classes='surface-brand-primary btn-primary-lg' 
                                    loading={loading}
                                    content={{name: `Get notifications for ${query}`}}
                                    actionHandler={() => setShow(!show)}
                                />
                                <ModalDynamic 
                                    show={show}
                                    content={subscriptionModal}
                                    tenant={tenant}
                                    handleClose={() => setShow(!show)}
                                    submitRequest={handleEdits}
                                    loadingContent={loadingPrefs}
                                />
                            </>

                        : null
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                            {
                                currentTerm && currentTerm.length > 0 ?
                                    <>
                                        <UniversalElasticConnectedList 
                                            engineName={CONSTANTS.ELASTIC_ENGINES_BY_APP[activeKey.toLowerCase()]}
                                            appName={activeKey}
                                            page={0} 
                                            metaData={metaData}
                                            numPerPage={35}
                                            numPerCall={10000}
                                            tenant={tenant} 
                                            preview={false} 
                                            reloadLoading={reloadLoading}
                                            handleLoading={handleLoading}
                                            onTotalPagesReceived={()=> {}} 
                                            searchTerm={currentTerm}
                                            pageType={CONSTANTS.ELASTIC_PAGE_TYPES.SEARCH}
                                        />
                                    </>
                                : <p className="text-center">No criteria specified.  Click the search icon and enter criteria.</p>
                            }
                    </Col>
                </Row>

            </div>
            : <ListSkeletonLoader preview={false} />
    )
}

export default SearchResultsPage