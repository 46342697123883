import React from 'react';
import { Badge, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Tag, X } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

function TagDisplay({ engine=null, tagList, className, flatList=false, handleEdit=null, linksActive=true }) {

    const getInnerWrapped = (item, idx) => {
        if(linksActive === true) {
            return (
                <Link key={idx} to={`/search/${flatList ? item : item.fields.tagtext}?engine=${engine}&type=tag`}>
                    <Badge className="tag-btn tertiary-button border-radius-sm">
                        <Tag className="icon" />
                        { flatList ? item : item.fields.tagtext }
                    </Badge>
                </Link>
            )
        } else {
            return (
                <Badge className="tag-btn tertiary-button border-radius-sm">
                    <Tag className="icon" />
                    { flatList ?  item : item.fields.tagtext }
                    {
                        handleEdit ?
                            <X className="icon close" onClick={() => handleEdit(item)} />
                        : null
                    }
                </Badge>
            )
        }
    }

    return (
        tagList ?
            <div className={(className ? className : '') +" tag-display"}>
                {
                    tagList.map( (thisTag, index) => {
                        return getInnerWrapped(thisTag, index);
                    })
                }
            </div>
        : <Spinner animation='border'/>
    )
}

export default TagDisplay
