import React from 'react';
import { Modal, Image } from 'react-bootstrap';

function ModalWrapper(props) {
    
    return (
        <Modal
            {...props}
            size="lg"
            centered >
            <Modal.Header closeButton>
            <Image className="big-photo" src={props.profile.fields.photo.url} />
                {
                    props.headerText?
                        <Modal.Title id="contained-modal-title-vcenter">
                            {props.headerText}
                        </Modal.Title>
                    : null
                }
            </Modal.Header>
            <Modal.Body>
                {
                    props.children
                }
            </Modal.Body>
    </Modal>
    )
}

export default ModalWrapper
