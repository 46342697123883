import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';

function CountryMap( { country } ) {
    const [coords, setCoords] = useState({lat: null, long: null});
    const [loading, setLoading] = useState(true);
    const acc_tkn = process.env.REACT_APP_MAPBOX_TOKEN;

    const RESP_LAT = 1;
    const RESP_LONG = 0;

    const getCoords = async (countryName) => {
        if(countryName === 'CAR'){
            countryName = 'Central African Republic'
        }
        
        setLoading(true);
        //make a call to the places api to get coords for country map
        try {
            const resp = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${countryName}.json?access_token=${acc_tkn}`);
            const res = await resp.json();

            //make sure there is expected shape of response
            if (res.hasOwnProperty('features') && res.features.length > 0){
                setCoords({
                    lat: res.features[0].bbox[RESP_LAT],
                    long: res.features[0].bbox[RESP_LONG]
                });
            }
            
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
        
    };

    useEffect( () => {
        getCoords(country.country);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[country])
    
    return (
        <div className="mapbox-map-container">
            { 
                !loading ?
                    <img 
                        src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${coords.long},${coords.lat},3.85/600x600?access_token=${acc_tkn}`} 
                        alt="political map of country" />
                
                : <Spinner animation="border" size="lg" />
            }
        </div>
    )
}

export default CountryMap
