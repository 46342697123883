import React from 'react';
import { Container } from 'react-bootstrap';
import Collapse from 'rc-collapse';

import '../../styles/components/_urlDrivenApp.scss';
import CompareCompTabs from './CompareCompTabs';

function CompanyAnalysesPanel({ listOfAnalyses }) {

    return (
        <Container className="company-analyses-panel">
            <Collapse accordion={true}>
                {
                    listOfAnalyses.map((thisAnalysis) => {
                        return (
                            <Collapse.Panel header={thisAnalysis.fields.companyName}  key={thisAnalysis.fields.companyName}>
                                <CompareCompTabs 
                                    analysisRec={thisAnalysis} />
                            </Collapse.Panel>
                        )
                    })
                }
            </Collapse>
        </Container>
    )
}

export default CompanyAnalysesPanel