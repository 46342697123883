import React from 'react'
import { Row, Button } from 'react-bootstrap';
import { ArrowBarLeft, PlusCircle } from 'react-bootstrap-icons';
import utils from '../../utils';
import { LinkContainer } from 'react-router-bootstrap';

function NoIntervention({ marketID, tenant, handleShowIntModal }) {
  return (
    <div>
      <h4 className='text-center m-b-start-13'>There are no interventions in this market</h4>
      <p className='text-center m-b-end-11'>Add interventions below, or go back to Market Actions home page</p>
      <Row md={2} className="add-intervention-col">  
            {
              marketID.length === 1 ?
                <Button className='icon-btn  m-b-end-06' onClick={() => handleShowIntModal()}>
                  <PlusCircle />
                  Add intervention
                </Button>
              :
              <div></div> 
            }
      </Row>
      <Row className="add-intervention-col">
        <LinkContainer to={`/${utils.findURLPath(tenant.tenant.fields.apps, "market_actions")}`}>
          <Button className='go-back-btn'>
            <ArrowBarLeft />
            Go Back
          </Button>
        </LinkContainer>
      </Row>
    </div>
  )
}

export default NoIntervention; 
