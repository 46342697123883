import React, {useEffect, useState} from 'react';
import { Button, Modal, ButtonGroup, ButtonToolbar} from 'react-bootstrap'
import { ArrowRightCircle } from 'react-bootstrap-icons';
import utils from '../../../utils';

import EnumSelector from '../EnumSelector';
import SourceDetailEditor from './SourceDetailEditor';
import ProductEditor from '../Product/ProductEditor';
import ObjectSelector from '../ObjectSelector';
import ConfirmationContent from '../ConfirmationContent';


function PriceEditor({apiKey, productDetailId, closePopup, disableProductEdits, tenantId, 
      visibleConfidentialities, editableConfidentialities, showMSTFields, userName, appClass }) {

  const [productDetail, setProductDetail] = useState(null)
  const [ countries, setCountries ] = useState([])
  const [showEditProduct, setShowEditProduct] = useState(false)
  const [errors, setErrors] = useState([])
  const [ currentView, setCurrentView] = useState('editor');

  // UseEffect: Refetch data whenever a subsequent modal is closed
  useEffect(() => {
    async function fetchData() {
      if (showEditProduct===true){
        return
      }
      let countryResult = await utils.getData('get_countries', apiKey);
      if (countryResult !== null) {
        setCountries(countryResult);
      }
      if (productDetailId === null) {
        setProductDetail({
          'market_segment': null,
          'distributor': null,
          'source_details': [],
          'product_characteristic_id': null,
          'product_characteristic': null,
          'source':null,
          'update_notes': null,
          'update_source': null, // distinct from source, update_source is on confirmation page with update_notes
          'updated_by': userName,
        })
      } else {
        let priceResult = await utils.getData('get_price', apiKey, "?product_detail_id=" + productDetailId)
        if (priceResult !== null) {
          setProductDetail(priceResult)
        }
      }
    }
    fetchData();
  }, [apiKey, productDetailId, showEditProduct, userName]
  )


// -------------------------- preSubmit -------------------------------------------- //
  async function preSubmit(e){
    e.preventDefault();
    e.stopPropagation();
    
    setErrors([]);
    let tempErrors = []
    if (productDetail.market_segment === null){
      tempErrors.push("Market Segment must selected.")
    }
    if (productDetail.product_characteristic_id === null){
      tempErrors.push("Product Characteristic must be selected")
    }
    if (productDetail.source === null){
      tempErrors.push("The source must be selected")
    }
    var foundSourceDets = new Set()
    var sdKey
    for (var i=0; i<productDetail.source_details.length; i++){
      var sd = productDetail.source_details[i];
      if (sd.recipient===null){
        tempErrors.push('The '+utils.makeOrdinal(i+1)+" source detail's recipient must be selected")
      }
      if (sd.confidentiality===null){
        tempErrors.push('The '+utils.makeOrdinal(i+1)+" source detail's confidentiality must be selected")
      }
      if (sd.procurement_mechanism===null){
        tempErrors.push('The '+utils.makeOrdinal(i+1)+" source detail's procurement mechanism must be selected")
      }
      sdKey = sd.recipient+'~'+sd.source+'~'+sd.confidentiality+'~'+sd.procurement_mechanism+'~'+sd.paho_origin_iso
      if (foundSourceDets.has(sdKey)){
        tempErrors.push("The "+utils.makeOrdinal(i+1)+' source detail is a duplicate of another')
      }
      foundSourceDets.add(sdKey)
      for(var j=0; j<sd.price_details.length; j++){
        var pd = sd.price_details[j]
        if (pd.year===null){
          tempErrors.push('The '+utils.makeOrdinal(i+1)+" source detail's " + utils.makeOrdinal(j+1) + " price detail needs a year")
        }
        if (pd.publish_date===null){
          tempErrors.push('The '+utils.makeOrdinal(i+1)+" source detail's " + utils.makeOrdinal(j+1) + " price detail needs a date")
        }
        if (pd.price_low===null){
          tempErrors.push('The '+utils.makeOrdinal(i+1)+" source detail's " + utils.makeOrdinal(j+1) + " price detail needs a low price")
        }
        if (pd.price_high !==null && pd.price_high < pd.price_low){
          tempErrors.push('The '+utils.makeOrdinal(i+1)+" source detail's " + utils.makeOrdinal(j+1) + " LOW price is higher than its HIGH price")
        }
        if (pd.source_url===null){
          tempErrors.push('The '+utils.makeOrdinal(i+1)+" source detail's " + utils.makeOrdinal(j+1) + " price detail needs a source url")
        }
      }
    }


    setErrors(tempErrors)
    if (tempErrors.length === 0){
       // If no errors, switch to the confirmation view
       setCurrentView('confirmation');
    }
  }



// Final Submit - used on Confirmation Page
async function finalSubmit({ updateNotes, updateSource, updatedBy }) {
  const submissionDetail = {
      ...productDetail,
      update_notes: updateNotes, 
      update_source: updateSource,
      updated_by: updatedBy,
  };

  const result = await utils.upsert('upsert_price', apiKey, submissionDetail);
  if (typeof result === 'string' ) {
    window.alert("Error detected: " + result)
  } else{
    closePopup(); 
  }
}




// go back button goes from confirmation page back to the editor view
const handleGoBack = () => {
  setCurrentView('editor');
}


// Confirmation Page Header
function renderProductDetails(productDetail) {
  return (
    <div>
    <div><strong>Product Name: </strong> {productDetail.product_characteristic.product_name}</div>
    <div><strong>Vaccine Subtype: </strong> {productDetail.product_characteristic.vaccine_subtype}</div>
    <div><strong>Manufacturer: </strong> {productDetail.product_characteristic.manufacturer}</div>
    <div><strong>Form: </strong> {productDetail.product_characteristic.form}</div>
    <div><strong>Container: </strong> {productDetail.product_characteristic.container_type}</div>
    <div><strong>Container Quantity: </strong> {productDetail.product_characteristic.container_quantity}</div>
  </div>
  );
}


  function printProductDetail(){
    console.log(productDetail)
  }

  function setDistributor(dist) {
    if (dist ===null){
      setProductDetail({
        ...productDetail,
        distributor_id: null,
        distributor: null
      })
    } else {
      setProductDetail({
        ...productDetail,
        distributor_id: dist.org_id,
        distributor: dist
      })
    }
  }
  function addSourceDetail(){
    const temp = productDetail.source_details
    temp.push({
      'recipient':null,
      'confidentiality':null,
      'procurement_mechanism': null,
      'paho_origin_iso':null,
      'paho_origin':null,
      'price_details':[]
    })
    setProductDetail({...productDetail, source_details:temp})
  }

  function setSourceDetail(sd, i){
    const temp = productDetail.source_details
    temp[i] = sd
    setProductDetail({...productDetail, source_details:temp})
  }

  function deleteSourceDetail(i){
    if(!window.confirm("This will delete a source detail. Are you sure you want to proceed?")){
      return
    }
    const temp = productDetail.source_details
    temp.splice(i, 1)
    setProductDetail({...productDetail, source_details:temp})
  }



 return (
  <Modal dialogClassName="modal-90w" show={true} className={appClass}>
    <Modal.Header>
      <Modal.Title>Price Editor</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {productDetail && currentView === 'editor' && (
        <>
          <div className="input-text">Market Segment</div>
          <EnumSelector
            enum_type="price_market_segment"
            apiKey={apiKey}
            disableCreate={true}
            value={productDetail.market_segment}
            onChange={(e) => setProductDetail({ ...productDetail, market_segment: e.label })}
          />
          <div className="input-text">Target Demographic</div>
          <EnumSelector
            enum_type="price_target_demographic"
            apiKey={apiKey}
            disableCreate={true}
            value={productDetail.target_demographic}
            isClearable={true}
            onChange={(e) => setProductDetail({ ...productDetail, target_demographic: e ? e.label : null })}
          />
          <div className="input-text">Distributor</div>
          <ObjectSelector
            apiKey={apiKey}
            obj={productDetail.distributor}
            onChange={setDistributor}
            isClearable={true}
            type="organization"
          />
          <div className="input-text">Source</div>
          <EnumSelector
            enum_type="price_source"
            apiKey={apiKey}
            disableCreate={true}
            value={productDetail.source}
            onChange={(e) => setProductDetail({ ...productDetail, source: e.label })}
          />
          <div className="input-text">Product Characteristic</div>
          <ObjectSelector
            apiKey={apiKey}
            obj={productDetail.product_characteristic}
            onChange={(e) => setProductDetail({
              ...productDetail,
              product_characteristic_id: e.product_characteristic_id,
              product_characteristic: e,
            })}
            type="product_characteristic"
          />
          {productDetail.product_characteristic && (
            <div className='m-b-start-03'>
              <div><strong>Product Name: </strong> {productDetail.product_characteristic.product_name}</div>
              <div><strong>Vaccine Subtype: </strong> {productDetail.product_characteristic.vaccine_subtype}</div>
              <div><strong>Manufacturer: </strong> {productDetail.product_characteristic.manufacturer}</div>
              <div><strong>Form: </strong> {productDetail.product_characteristic.form}</div>
              <div><strong>Container: </strong> {productDetail.product_characteristic.container_type}</div>
              <div><strong>Container Quantity: </strong> {productDetail.product_characteristic.container_quantity}</div>
            </div>
          )}
          {showEditProduct && (
            <ProductEditor
              productId={productDetail.product_characteristic.product_id}
              closePopup={() => setShowEditProduct(false)}
              show={showEditProduct}
              apiKey={apiKey}
              disableProductEdits={disableProductEdits}
              tenantId={tenantId}
              visibleConfidentialities={visibleConfidentialities}
              editableConfidentialities={editableConfidentialities}
              showMSTFields={showMSTFields}
              userName={userName}
              appClass={appClass}
            />
          )}
          <Button className='btn-primary m-b-start-03 m-i-end-02' onClick={() => setShowEditProduct(!showEditProduct)}>Edit Product</Button>
          
          {productDetail.source_details.map((sd, i) => (
            <SourceDetailEditor
              key={i}
              countries={countries}
              source_detail={sd}
              apiKey={apiKey}
              onChange={(updatedSd) => setSourceDetail(updatedSd, i)}
              onDelete={() => deleteSourceDetail(i)}
            />
          ))}
          <Button className='btn-primary m-b-start-03 m-i-end-02' onClick={addSourceDetail}>Add Source</Button>
      {errors.length > 0 && (
        <div>
          <div className="error">Errors:</div>
          {errors.map((e, index) => (
            <li key={index} className="error">{e}</li>
          ))}
        </div>
      )}
      <ButtonToolbar className="editor-btn">
        <ButtonGroup>
          <Button onClick={closePopup} variant="secondary" className="button-secondary m-i-end-03">Close</Button>
        </ButtonGroup>
        <ButtonGroup>
        <Button onClick={preSubmit} className="icon-btn m-i-end-02">Next Step<ArrowRightCircle className='icon'/></Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button onClick={printProductDetail} variant="primary">Check Price</Button>
        </ButtonGroup>
      </ButtonToolbar>
      </>
      )}
      {productDetail && currentView === 'confirmation' && (
        <ConfirmationContent
          apiKey={apiKey}
          updatedBy={userName}
          updateNotes={productDetail.update_notes}
          updateSource={productDetail.update_source}
          onSubmit={({ updateNotes, updateSource }) => finalSubmit({ updateNotes, updateSource, updatedBy: userName })}
          onGoBack={handleGoBack}
          renderContentSection={() => renderProductDetails(productDetail)}
        />
      )}
    </Modal.Body>
  </Modal>
);
}


export default PriceEditor;