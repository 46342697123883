import React, { useEffect } from 'react';
import SuperAdminMgmtApp from '../../components/SuperAdminMgmtApp/SuperAdminMgmtApp';

function SuperAdminMgmtPage({ tenant }) {

    useEffect(() => {
        document.title = 'Super Admin User View';
    }, [])

    return (
        tenant && tenant.tenant.fields.tenantslug === 'LB-Level-Q' ?
            <SuperAdminMgmtApp />
        : 
            <div>Not authorized.</div>

    )
}

export default SuperAdminMgmtPage