import React, { useState } from 'react';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { PlusCircle, ChevronDown } from 'react-bootstrap-icons';

import InterventionCreateEditPopup from './InterventionCreateEditPopup';
import MarketActionsTableView from './MarketActionsTableView';
import MarketActionsKanbanView from './MarketActionsKanbanView';
import MarketActionsGantView from './MarketActionsGanttView';
import MarketActionsExcelExport from './MarketActionsExcelExport';
import ganttUtils from './ganttUtils';
import CONSTANTS from '../../constants';
import MarketActionsMarketVwManipulator from './MarketActionsMarketVwManipulator';
import utils from '../../utils';
import CheckBoxDropDownSelector from '../MktActionsFilterBarComps/CheckBoxDropDownSelector';

function MarketActionsDisplay({ marketList, spaceID, marketID, handleMarketUpdate, memberList, marketIDs, vaccine, organization, tenant, options, displayOptionHandler, labelString, handleReload, toggleLoading, forceView=null }) {

  const [show, setShow] = useState(false);
  const [intID, setIntID] =useState();

  const getMarketType = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let marketType = getMarketType.mktType;

  const getCustomFieldName = (customFields, fieldName) => {

    let fieldValueArr = customFields.filter((item) => {
      return item.name.toLowerCase() === fieldName.toLowerCase();
    });

    if (fieldValueArr.length > 0) {
      let tempVal;
      if (fieldValueArr[0].hasOwnProperty('value')) {
        tempVal = fieldValueArr[0].value
        return tempVal;
      }
    }

    return null;
  }


  const formatInterventionResults = (list) => {
    if (list && list.length > 0) {
      let retArray = [];
      list.map((thisMarket) => {
        return thisMarket.interventions.map((thisItem) => {
          let retObj =
          {
            id: thisItem.id,
            archived: thisItem.archived,
            date_created: thisItem.date_created,
            last_updated: thisItem.date_updated,
            task_name: thisItem.name ? thisItem.name : '',
            vaccine: getCustomFieldName(thisItem.custom_fields, 'vaccine'),
            vaccine_roadmap: getCustomFieldName(thisItem.custom_fields, 'vaccine roadmap'),
            objective: getCustomFieldName(thisItem.custom_fields, "Objective"),
            target_outcome: getCustomFieldName(thisItem.custom_fields, "Target Outcome"),
            assigned_to: getCustomFieldName(thisItem.custom_fields, "Assigned To"),
            accuracy: getCustomFieldName(thisItem.custom_fields, "accuracy"),
            stakeholder_supporting: getCustomFieldName(thisItem.custom_fields, "Stakeholder Supporting"),
            stakeholder_accountable: getCustomFieldName(thisItem.custom_fields, "Stakeholder Accountable"),
            supplier: getCustomFieldName(thisItem.custom_fields, "Supplier"),
            //adding value if no value exists so the date picker displays
            due_date: thisItem.due_date ? thisItem.due_date : 'noDate',
            due_date_type: getCustomFieldName(thisItem.custom_fields, "Due Date Type"),
            hmf_category: getCustomFieldName(thisItem.custom_fields, "HMF Category"),
            prior_innovation: getCustomFieldName(thisItem.custom_fields, 'Prioritized Innovation'),
            priority: getCustomFieldName(thisItem.custom_fields, "Priority"),
            notes: getCustomFieldName(thisItem.custom_fields, "notes"),
            status: thisItem.status.status ? thisItem.status.status : '',
            start_date: thisItem.start_date ? thisItem.start_date : null,
            market_ID: thisItem.list.id,
            market_name: thisItem.list.name
          }
          retArray.push(retObj);
          return retArray

        });
      });
      return retArray;

    } else {
      return [];
    }
  };


  //putting marketList into state to enable a filtered view of the list "downstream" (ie: Table view, etc)
  let [marketViewOption, setMarketViewOption] = useState(forceView ? forceView : '2'); // '2' is Kanban
  let [sepByField, setSepByField] = useState();

  let [currentView, setCurrentView] = useState({
    'YEAR': new Date().getFullYear(),
    'MONTH': new Date().getMonth() + 1,
    'WEEK': Math.round(new Date().getDate() / 7),
    'QUARTER': ganttUtils.getCurrentQuarter(new Date())
  });

  let [zoom] = useState('QUARTERS');
  let [filteredMarkets, setFilteredMarkets] = useState(formatInterventionResults(marketList));


  const marketViews = [
    { name: 'Table', value: '1' },
    { name: 'Kanban', value: '2' },
    { name: 'Gantt', value: '3' },
  ]

  const marketViewObj = {
    "1": "Table",
    "2": "Kanban",
    "3": "Gantt"
  }


  const resetMarkets = () => {
    setFilteredMarkets(formatInterventionResults(marketList));
  }


  const getLatestFrameStart = (tasks) => {
    let LatestEndTask = ganttUtils.getTaskWithLatestend(tasks);
    
    let myDate = new Date(parseInt(LatestEndTask.due_date));

    return {
      YEAR: myDate.getFullYear() - 1,
      MONTH: myDate.getMonth() + 2,
      QUARTER: Math.ceil(myDate.getMonth() / 4),
      WEEK: (Math.floor(myDate.getDate() / 7) + 1) <= 4 ? Math.round(myDate.getDate() / 7) + 1 : Math.abs((Math.round(myDate.getDate() / 7) + 1)-4)
    }
  }

  const getEarliestFrameStart = (tasks) => {
    let LatestEndTask = ganttUtils.getTaskWithEarlieststart(tasks);

    let myDate = new Date();

    if(LatestEndTask.start_date) {
      myDate = new Date(parseInt(LatestEndTask.start_date));
      return {
        YEAR: myDate.getFullYear(),
        MONTH: myDate.getMonth() + 1,
        QUARTER: Math.floor(myDate.getMonth() / 4),
        WEEK: Math.round(myDate.getDate() / 7)
      }
    }

    return null;
    
  }

  const handleViewManipOptionChange = (srcObj) => {
    let appliedLines;
    resetMarkets();
    switch (srcObj.src) {
      case 'FILTERBY':
        //make sure we have a full filter line before applying it to the list filtering
        appliedLines = filterFullFilterLines(srcObj.data);

        if (appliedLines.length > 0) {
          setFilteredMarkets(filterItemByFilterItems(formatInterventionResults(marketList), appliedLines));
        } else {
          resetMarkets();
        }

        break;
      case 'PAGER':
        if (zoom === 'YEARS') {
          if (srcObj.data === 'rewind') {
            //zoom level, year, rewind - set currentView -1 month

            let newYear = currentView.YEAR - 1;

            let newMonth = 1;

            setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
          }

          if (srcObj.data === 'full-rewind') {
            //zoom level, year, full rewind - set currentView to start year of oldest task
            //TODO - find earliest task and set year to that
            let resp = getEarliestFrameStart(formatInterventionResults(marketList));

            if(resp) {
              setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
            }
          }

          if (srcObj.data === 'forward') {
            //zoom level, year, forward - set currentView +1 month
            let newYear = currentView.YEAR + 1;

            let newMonth = 1;

            setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
          }

          if (srcObj.data === 'full-forward') {
            //zoom level, year, full forward - set currentView to end year of oldest task
            //TODO - set year of last end date

            let resp = getLatestFrameStart(formatInterventionResults(marketList));

            if (isNaN(resp.YEAR)) {
              let newYear = currentView.YEAR + 1;

              let newMonth = 1;

              setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });

            } else {
              setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
            }
          }
        }

        if (zoom === 'QUARTERS') {
          if (srcObj.data === 'rewind') {
            //zoom level, year, rewind - set currentView -1 month

            let newQuarter = currentView.QUARTER - 1;
            let newYear = currentView.YEAR;

            if (newQuarter < 1) {
              newQuarter = 4;
              newYear = currentView.YEAR - 1;
            }


            setCurrentView({ ...currentView, QUARTER: newQuarter, YEAR: newYear });
          }

          if (srcObj.data === 'full-rewind') {
            //zoom level, year, full rewind - set currentView to start year of oldest task
            //TODO - find earliest task and set year to that
            let resp = getEarliestFrameStart(formatInterventionResults(marketList));

            if(resp) {
              setCurrentView({ YEAR: resp.YEAR, QUARTER: ganttUtils.getQuarter(resp.MONTH), MONTH: resp.MONTH, WEEK: resp.WEEK });
            }

            
          }

          if (srcObj.data === 'forward') {
            //zoom level, year, forward - set currentView +1 month
            let newQuarter = currentView.QUARTER + 1;
            let newYear = currentView.YEAR;

            if (newQuarter > 4) {
              newQuarter = 1;
              newYear = currentView.YEAR + 1;
            }


            setCurrentView({ ...currentView, QUARTER: newQuarter, YEAR: newYear });
          }

          if (srcObj.data === 'full-forward') {
            //zoom level, year, full forward - set currentView to end year of oldest task
            //TODO - set year of last end date

            let resp = getLatestFrameStart(formatInterventionResults(marketList));

            if (isNaN(resp.YEAR)) {
              let newYear = currentView.YEAR + 1;

              let newMonth = 1;
              
              setCurrentView({ ...currentView, QUARTER: resp.QUARTER, MONTH: newMonth, YEAR: newYear });

            } else {
              setCurrentView({ YEAR: resp.YEAR, QUARTER: resp.QUARTER, MONTH: resp.MONTH, WEEK: resp.WEEK });
            }

            
          }
        }

        if (zoom === 'YEAR') {
          if (srcObj.data === 'rewind') {
            //zoom level, year, rewind - set currentView -1 month
            let newMonth = currentView.MONTH - 1;
            let newYear = currentView.YEAR;

            if (newMonth < 1) {
              newMonth = 11;
              newYear = newYear - 1;
            }

            setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
          }

          if (srcObj.data === 'full-rewind') {
            //zoom level, year, full rewind - set currentView to start year of oldest task
            //TODO - find earliest task and set year to that
            let resp = getEarliestFrameStart(formatInterventionResults(marketList));

            if(resp) {
              setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
            }
          }

          if (srcObj.data === 'forward') {
            //zoom level, year, forward - set currentView +1 month
            let newMonth = currentView.MONTH + 1;
            let newYear = currentView.YEAR;

            if (newMonth > 12) {
              newMonth = 1;
              newYear = newYear + 1;
            }

            setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
          }

          if (srcObj.data === 'full-forward') {
            //zoom level, year, full forward - set currentView to end year of oldest task
            //TODO - set year of last end date
            setCurrentView({ ...currentView, MONTH: currentView.MONTH + 1 });

            let resp = getLatestFrameStart(formatInterventionResults(marketList));
            if (isNaN(resp.YEAR)) {
              let newMonth = currentView.MONTH + 1;
              let newYear = currentView.YEAR;

              if (newMonth > 12) {
                newMonth = 1;
                newYear = newYear + 1;
              }

              setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });

            } else {
              setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
            }
          }
        }

        if (zoom === 'MONTH') {
          if (srcObj.data === 'rewind') {
            //zoom level, year, rewind - set currentView -1 month
            let newMonth = currentView.MONTH - 1;
            let newYear = currentView.YEAR;

            if (newMonth < 1) {
              newMonth = 11;
              newYear = newYear - 1;
            }

            setCurrentView({ YEAR: newYear, MONTH: newMonth });
          }

          if (srcObj.data === 'full-rewind') {
            //zoom level, year, full rewind - set currentView to start year of oldest task
            let resp = getEarliestFrameStart(formatInterventionResults(marketList));

            if(resp) {
              setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
            }
          }

          if (srcObj.data === 'forward') {
            //zoom level, year, forward - set currentView +1 month
            let newMonth = currentView.MONTH + 1;
            let newYear = currentView.YEAR;

            if (newMonth > 12) {
              newMonth = 1;
              newYear = newYear + 1;
            }

            setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
          }

          if (srcObj.data === 'full-forward') {
            //zoom level, year, full forward - set currentView to end year of oldest task
            setCurrentView({ ...currentView, MONTH: currentView.MONTH + 1 });

            let resp = getLatestFrameStart(formatInterventionResults(marketList));
            if (isNaN(resp.YEAR)) {
              let newMonth = currentView.MONTH + 1;
              let newYear = currentView.YEAR;

              if (newMonth > 12) {
                newMonth = 1;
                newYear = newYear + 1;
              }

              setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
            } else {
              setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
            }

          }

        }
        break;
      default:
        break;
    }

  }

  const filterItemByFilterItems = (list, filterList) => {
    //run the item through each filter
    let runningList = [...list];

    filterList.forEach((item) => {
      runningList = filterByItemFromFilterPopup(runningList, item);
    })

    return runningList;

  }

  const filterByItemFromFilterPopup = (list, item) => {
    //{ fieldForFilter, operator, comparator }

    //filtering is different based on comparator type
    switch (item.fieldForFilter) {

      case "Created":
        return list.filter((listItem) => {
          const itemDate = new Date(parseInt(listItem.date_created));
          const compDate = new Date(item.comparator);

          return dateCompare(itemDate, compDate, item.operator);
        });

      case "Last Updated":
        //return list; 
        return list.filter((listItem) => {
          const itemDate = new Date(parseInt(listItem.last_updated));
          const compDate = new Date(item.comparator);
          return dateCompare(itemDate, compDate, item.operator);
        });

      case "Intervention Title":
        return list.filter((listItem) => {
          return textCompare(listItem.task_name, item.comparator, item.operator);
        });

      case "Assigned To":
        return list.filter((listItem) => {
          let tempValue = '';

          if (listItem.assigned_to !== null) {
            tempValue = listItem.assigned_to;
          }
          let results = tempValue.toLowerCase().includes(item.comparator.toLowerCase());

          if (item.operator === 'is_not_assigned_to') {
            results = !results;
          }

          return results;
        });

      case "HMF Category":
        return list.filter((listItem) => {
          let valToCompare = '';
          if (Array.isArray(listItem.hmf_category)) {
            valToCompare = listItem.hmf_category[0];
          } else {
            valToCompare = listItem.hmf_category;
          }

          let results = valToCompare === item.comparator;

          if (item.operator === 'does_not_equal') {
            results = results === true ? false : true;
          }

          return results;
        });

      case "Status":
        return list.filter((listItem) => {
          return textCompare(listItem.status, CONSTANTS.MARKET_ACTIONS_STATUS[item.comparator], item.operator);
        });
      default:
        return list;
    }
  }


  const dateCompare = (date1, date2, operator) => {
    switch (operator) {
      case "is_on":
        return moment(date1).isSame(date2);
      case "is_before":
        return moment(date1).isBefore(date2);
      case "is_after":
        return moment(date1).isAfter(date2);
      default:
        break;
    }
  }


  const textCompare = (text1, text2, operator) => {
    switch (operator) {
      case "contains":
        return (text1.toLowerCase()).includes(text2.toLowerCase());
      default:
        return ((text1.toLowerCase()).includes(text2.toLowerCase()));
    }
  }

  const filterFullFilterLines = (filterList) => {
    //only return filter lines where the field, operator, and comparator have been specified
    return filterList.filter((filterLine) => {
      return ((filterLine.fieldForFilter !== null && filterLine.fieldForFilter !== '') &&
        (filterLine.operator !== null && filterLine.operator !== '') &&
        (filterLine.comparator !== null && filterLine.comparator !== ''));
    })
  }

  const getStatusList = (listObj) => {
    if (listObj.hasOwnProperty('statuses')) {
      return listObj.statuses.map((listItem) => {
        return listItem.status;
      });
    }

    return [];
  }

  const getListOfLabelValuesFromFieldName = (cfList, fieldName) => {

    let foundObj = utils.getCustomFieldObjByName(cfList, fieldName);

    if (foundObj.hasOwnProperty('type_config')) {
      if (foundObj.type_config.hasOwnProperty('options')) {
        return foundObj.type_config.options.map((thisItem) => {
          return { label: thisItem.label, value: thisItem.id }
        })
      }
    }

    return [];

  }

  const marketViewHandle = (e) => {
    setMarketViewOption(e);
  }

  const handleShowChange = (e) => {
    setSepByField(e.value);
  }

  const getTitleForMarkets = (mktList, type) => {

    if (type === 'select-all-markets') {
      return 'All Markets'
    }
    else if (type === 'cross-markets') {
      let titleArray = mktList.map((mkt) => {
        return mkt.name;
      });

      return 'Cross Markets for ' + titleArray.join(', ');
    }

    let titleArray = mktList.map((mkt) => {
      return mkt.name;
    });

    return titleArray.join(', ');
  }

  const findIfDisabled = (list) => {
    let retArray = [];
    for (let mkt = 0; mkt < list.length; mkt++) {
      if (list[mkt].interventions.length > 0) {
        retArray.push(false);
      }
    }
    return retArray.includes(false) ? false : true;
  }

  let isDisabled = findIfDisabled(marketList)

  
  
  const handleShowIntModal = (id=null) => {
    if(id) {
      setIntID(id)
    } 
    if(show === true) {
      setIntID(null);
    }
    setShow(!show);
  }

  return (

    marketList && marketList.length > 0 && memberList?

      <>
        {/* Market Title */}
        <Row>
          <Col md={6}>
            <h1 className='h1'>{getTitleForMarkets(marketList, marketType)}</h1>
          </Col>
        </Row>


{/* Markets Toolbar */}
<Row className="markets-toolbar-responsive">
  
  {/* Dropdowns Container */}
  <Col className="dropdowns-container" lg={8}>

    {/* View Dropdown */}
    <div className="toolbar-dropdown m-i-end-03">
      <label className='dropdown-label'>View</label>
      <Dropdown className='toolbar-standard-dropdown'>
        <Dropdown.Toggle>
          {marketViewObj[marketViewOption]}
          <ChevronDown />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {
            marketViews.map((opt, idx) => (
              <Dropdown.Item
                key={idx} // Added key for uniqueness
                disabled={isDisabled}
                eventKey={idx}
                id={`opt${idx}`}
                name={opt.value}
                active={marketViewOption === opt.value}
                onClick={(e) => marketViewHandle(e.target.name)}
              >
                {opt.name}
              </Dropdown.Item>
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>

    {/* Filter-By or Grouped-By Dropdown */}
    <div className="toolbar-dropdown m-i-end-03">
      <MarketActionsMarketVwManipulator
        className="toolbar-standard-dropdown"
        disabled={isDisabled}
        whichView={marketViewOption}
        type="dropdown"
        onChange={handleShowChange}
        assigneeList={memberList}
        statusList={getStatusList(marketList)}
        onOptionsChange={handleViewManipOptionChange}
        hmfCategories={getListOfLabelValuesFromFieldName(marketList[0].custom_fields.fields, 'HMF Category')}
      />
    </div>

    {/* Checkbox "More Display Options" Dropdown */}
    <div className="toolbar-dropdown">
      <label className='dropdown-label'>More Display Options</label>
      <CheckBoxDropDownSelector
        className="toolbar-standard-dropdown"
        type="dropdown"
        options={options}
        displayOptionHandler={displayOptionHandler}
        labelString={labelString}
        formatInterventionResults={formatInterventionResults}
        marketList={marketList}
        keepOpen={false}
      />
    </div>
  </Col>

  {/* Buttons Container */}
  <Col className="buttons-container">
    <MarketActionsExcelExport
      className='icon-btn btn-primary-lg'
      disabled={isDisabled}
      filteredMarkets={filteredMarkets}
    />
    {/* Add Intervention Button */}
    {marketID.length >= 1 ? (
      <Button className='icon-btn surface-brand-primary btn-primary-lg m-i-start-03' onClick={() => handleShowIntModal()}>
        <PlusCircle />
        Add
      </Button>
    ) : (
      <div></div>
    )}
  </Col>

</Row>



        
        {/* Intervention Display*/}
        <Row>
          {/* let's put the deep link market routes here - routing on market ids */}
          {
            <Col className={marketList[0].interventions.length < 1 ? `view-display no-interventions ${CONSTANTS.MARKET_ACTIONS_REVERSE_VIEWS[marketViewOption]}` : `view-display ${CONSTANTS.MARKET_ACTIONS_REVERSE_VIEWS[marketViewOption]}`}>
              {

                marketViewOption === CONSTANTS.MARKET_ACTIONS_VIEWS.KANBAN ?
                  <MarketActionsKanbanView
                    marketID={marketID}
                    spaceID={spaceID}
                    marketList={filteredMarkets}
                    onUpdate={handleMarketUpdate}
                    seperateByField={sepByField}
                    statusList={marketList[0].statuses}
                    customFieldsIndex={marketList[0].custom_fields.fields}
                    tenant={tenant}
                    handleReload={handleReload}
                    handleShowIntModal={handleShowIntModal}
                    vaccine={vaccine}
                    organization={organization}
                  />
                  :
                  marketViewOption === CONSTANTS.MARKET_ACTIONS_VIEWS.GANTT ?
                    <MarketActionsGantView
                      marketID={marketID}
                      viewStart={currentView}
                      spaceID={spaceID}
                      marketList={marketList}
                      zoom={zoom}
                      tenant={tenant}
                      handleReload={handleReload}
                      handleShowIntModal={handleShowIntModal}
                      vaccine={vaccine}
                      organization={organization}
                    />
                    :
                    <MarketActionsTableView
                      marketID={marketID}
                      spaceID={spaceID}
                      formattedMarketList={filteredMarkets}
                      onUpdate={handleMarketUpdate}
                      memberList={memberList}
                      customFieldsIndex={marketList[0].custom_fields.fields}
                      marketIDs={marketIDs}
                      vaccine={vaccine}
                      organization={organization}
                      tenant={tenant}
                      handleReload={handleReload}
                      handleShowIntModal={handleShowIntModal}
                    />

              }
            </Col>
          }
        </Row>
        <>
          { show && show === true ?
            <InterventionCreateEditPopup 
              tenant={tenant}  
              show={show} 
              handleShowIntModal={() => setShow(false)}
              handleReload={handleReload}
              marketID={marketID[0]}
              interventionID={intID ? intID : null}
              mode={intID && intID!==null ? 'edit' : 'create'} 
              vaccine={vaccine} 
              toggleLoading={toggleLoading}
              clearIntID={() => setIntID()}
              organization={organization} 
              groupMembers={memberList}
              currentView={marketViewOption} />
            : null }
        </>
      </>
      : null
  )
}

export default MarketActionsDisplay;