import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

function ToolTip({tooltip}) {

  return (
    <>
       <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip placement="top" className="in" id="tooltip-top">
            {/* If tooltip is HTML (usually is, from Agility), use dangerouslySetInnerHTML */}
            {tooltip?.__html ? (
              <div dangerouslySetInnerHTML={tooltip} />
            ) : (
              <div>{tooltip}</div>  // If it's plain text, render as string
            )}
          </Tooltip>
        }
      >
        <InfoCircleFill />
      </OverlayTrigger>
    </>
  )
}

export default ToolTip