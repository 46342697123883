import React from 'react'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

function ListSkeletonLoader({preview=false}) {
  return (
    !preview ?
      <>
        <div className='m-b-end-08'>
          <SkeletonTheme width={'225px'}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
          <SkeletonTheme height={'96px'}>
            <div >
              <Skeleton />
            </div>
          </SkeletonTheme>
        </div>
        <div className='m-b-end-08'>
          <SkeletonTheme width={'225px'}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
          <SkeletonTheme height={'96px'}>
            <div >
              <Skeleton />
            </div>
          </SkeletonTheme>
        </div>
        <div className='m-b-end-08'>
          <SkeletonTheme width={'225px'}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
          <SkeletonTheme height={'96px'}>
            <div >
              <Skeleton />
            </div>
          </SkeletonTheme>
        </div>
        <div className='m-b-end-08'>
          <SkeletonTheme width={'225px'}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
          <SkeletonTheme height={'96px'}>
            <div >
              <Skeleton />
            </div>
          </SkeletonTheme>
        </div>
      </>
    :
    <>
      <SkeletonTheme height={'250px'}>
        <div >
          <Skeleton />
        </div>
      </SkeletonTheme>
    </>
  )
}

export default ListSkeletonLoader