import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { Grid, _ } from 'gridjs-react';
import Pagination from 'rc-pagination';
import localeInfo from '../../locale_en_US';

import utils from '../../utils';

function SignInLogs({ preview = false }) {

    let [loadingSignins, setLoadingSignins] = useState(false);
    let [loadSignins, setLoadSignins] = useState(true);

    let [signins, setSignins] = useState();
    let [curPageSignins, setCurPageSignins] = useState(1);
    let [numPgsSignins, setNumPgsSignins] = useState();

    const { instance, accounts } = useMsal();
    const request = {
        scopes: ["User.Read"]
    };

    const numPerPage = 10;

    const handlePageClick = (itemClicked) => {
        setCurPageSignins(itemClicked);
    }

    const getDateAndTimeString = (dateObj) => {
        let thisDateObj = new Date(dateObj);

        let thisDate = thisDateObj.toLocaleDateString('en-us');
        let thisTime = thisDateObj.toLocaleTimeString('en-us');

        return thisDate + ' ' + thisTime;
    }

    const formatSigninSecCol = (colVal) => {
        //set Security aside for use

        console.log('colval: ', colVal)

        if (colVal) {
            let useVal = colVal.replace('Security: ', '');

            try {
                let thisJSON = JSON.parse(useVal);

                let appsList = thisJSON.appsList.split(',');

                return _(<><strong>Security Level:</strong> {thisJSON.securityLevel}<br /> <strong>Apps:</strong> {appsList.map(thisApp => <li>{thisApp}</li>)}</>);
            } catch (err) {
                return colVal;
            }
        }

    }

    const formattedSignins = (responseArray) => {
        return responseArray.map((thisItem) => {
            return {
                when: thisItem.when.raw ? getDateAndTimeString(thisItem.when.raw) : '',
                what: thisItem.what.raw ? thisItem.what.raw : '',
                server: thisItem.server.raw ? thisItem.server.raw : '',
                where: thisItem.where.raw ? thisItem.where.raw : '',
                who: thisItem.who.raw ? thisItem.who.raw : '',
                was: thisItem.was.raw ? formatSigninSecCol(thisItem.was.raw) : '',
            }
        });
    }

    const loadTheSignins = async (pgNum, accToken) => {
        setLoadingSignins(true);
        let resp = await fetch(`${process.env.REACT_APP_WEB_API}/api/logs/signins${pgNum ? '?page=' + pgNum : ''}`, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + accToken.accessToken
            }
        });

        if (resp.ok) {
            let rspJSON = await resp.json();
            setSignins(rspJSON);
            setNumPgsSignins(rspJSON.meta.page.total_pages);
        }

        setLoadingSignins(false);
        setLoadSignins(false);
    }

    useEffect(() => {
        (async () => {
            // only track if on prod
            if(process.env.REACT_APP_ENV === 'PROD' ) {
                let theAccToken = await utils.getAccessToken(instance, accounts[0], request);
                loadTheSignins(curPageSignins, theAccToken);
            }

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadSignins, curPageSignins]);

    return (
        preview ?
            <div>
                preview mode
            </div>
            :
            <Container>
                <Row>
                    <Col>
                        <h4>Sign-in Logs</h4>
                        {
                            loadingSignins ?
                                <div className='text-center'>
                                    <Spinner animation="border" />
                                </div>
                                :
                                signins && signins.results && signins.results.length > 0 ?
                                    <>
                                        <Grid
                                            className={{
                                                td: 'logtable__cell',
                                                table: 'logtable signins'
                                            }}
                                            data={formattedSignins(signins.results)} />

                                        <div className="text-center">
                                            <Pagination
                                                total={(numPgsSignins * numPerPage)}
                                                onChange={(item) => handlePageClick(item)}
                                                pageSize={numPerPage}
                                                current={curPageSignins}
                                                locale={localeInfo} />
                                        </div>
                                    </>
                                    :
                                    <Row>
                                        <Col>No Sign-in logs found</Col>
                                    </Row>
                        }
                    </Col>
                </Row>
            </Container>

    )
}

export default SignInLogs