import React, { useState, useEffect } from 'react';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import { useMsal } from '@azure/msal-react';
import Pagination from 'rc-pagination';
import localeInfo from '../../locale_en_US';
import utils from '../../utils';
import CONSTANTS from '../../constants';
import UniversalElasticConnectedList from '../UniversalElasticConnectedList';

function FilteredPagedNewsPage({ query, limitFields=null, ghafEnabled=false, tenant }) {
    const numPerPage = 35;
    let [ curPage, setCurPage ] = useState(1);
    let [ numPages, setNumPages ] = useState();
    let [ theEngine ] = useState(ghafEnabled === true ? CONSTANTS.ELASTIC_ENGINES.PUB_AND_GHAF : CONSTANTS.ELASTIC_ENGINES.PUBLIC);

    const { instance, accounts } = useMsal();
    const request = {
        scopes: ["User.Read"]
    };



    const filtersToSet = [
        CONSTANTS.FILTER_TYPES.SORT_BY_RECENT, 
        CONSTANTS.FILTER_TYPES.DATE_RANGE_FILTER
    ];

    if(ghafEnabled === true) {
        filtersToSet.push(CONSTANTS.FILTER_TYPES.CATEGORY)
    };

    const sendTracking = async () => {
        let theAccToken = await utils.getAccessToken(instance, accounts[0], request);

        await fetch(`${process.env.REACT_APP_WEB_API}/api/logs/signins`, {
            method: 'POST',
            headers: {
              "Authorization": "Bearer " + theAccToken.accessToken
                },
            body: JSON.stringify({
                forApp: 'News',
                tenID: tenant.tenant.fields.aDGroupID ? tenant.tenant.fields.aDGroupID : 'unknown'
                })
          });
    }

    const onTotalPagesRecvd = (numberOfPages) => {
        setNumPages(numberOfPages);
    }

    const handlePageClick = (pageNum) => {
        setCurPage(pageNum);
    }
    
    useEffect( () => {
        // only track if on prod
        if(process.env.REACT_APP_ENV === 'PROD' ) {
            sendTracking()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
            <BreadCrumbBar appName="NEWS" />

            <h1 className='h1'>News</h1>
            
            <UniversalElasticConnectedList
                engineName={theEngine}
                numPerPage={numPerPage}
                page={curPage}  
                searchTerm={ghafEnabled === true ? '' : query} 
                onTotalPagesReceived={onTotalPagesRecvd} 
                preview={ false } 
                tagQuery={ limitFields && limitFields !== '' }
                tenant={tenant} />
            <div className="text-center">
                { 
                    numPages && numPages > 0 ?
                        <Pagination 
                            total={(numPages*numPerPage)}
                            onChange={ (item) => handlePageClick(item)}
                            pageSize={numPerPage}
                            current={curPage}
                            locale={localeInfo} />
                    : null
                }
            </div>
        </>
    )
}

export default FilteredPagedNewsPage
