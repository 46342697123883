import React from 'react';
import { Row, Button } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import PMTCONSTANTS from './pmtConstants';

function PMTNoItemInList({header, tenant, handleShowAddItemModal, body, appUrl, appName}) {
    return (
        <div className='no-interventions m-i-end-08 p-b-end-13 full-width'>
          <h2 className='text-center m-b-end-06 p-b-start-13 h2'>{header}</h2>
          <p className='text-center m-b-end-11 body-med'>{body}</p>
          <Row md={2} className="add-intervention-col">  
            <Button className='icon-btn m-b-end-06 surface-brand-primary txt-primary' onClick={() => handleShowAddItemModal()}>
                <PlusCircle />
                {`Add ${PMTCONSTANTS.LIST_ITEM_NAME[appName]}`}
            </Button>
          </Row>

        </div>
      )
}

export default PMTNoItemInList