import React, { useState } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

function CompanySearch({selectCompanyHandler, clearFilter, field}) {
  const [ searchVal, setSearchVal ] = useState('');

  const handleSearch = (key) => {
    key.preventDefault()
    setSearchVal(key.target.value)
    selectCompanyHandler(field, {label: key.target.value, value: key.target.value})
  }

  return (
      <InputGroup.Text className='search-input' id="search-name">
        <Search 
          name={"Search by Name"}
        />
        <FormControl 
          as='input'
          placeholder="Filter by Name"
          aria-label="Filter by Name"
          aria-describedby="basic-addon1"
          onChange={(e) => handleSearch(e)}
          value={clearFilter === true ? '' : searchVal}
        />
      </InputGroup.Text>
  )
}

export default CompanySearch;