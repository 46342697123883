
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CONSTANTS from '../../../constants';

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function SelectFilter({ isMulti=null, options, isSortBy, sortOptions, field, onChange, curFilters, apiName, type }) {
  const [ selection, setSelection ] = useState();
  
  useEffect(()=>{
    if (apiName === 'elastic') {
      // Handle the selection when filters are reset
      if(isEmpty(curFilters)){
        setSelection(isMulti?[]:null)
      }
    }
  }, [curFilters, field, isMulti, apiName])

  function handleChange(e){
    if(isMulti){
      if(e && selection && e.length<selection.length){
        // Deselection feels more natural as an immediate change
        onChange(e)
        setSelection(e)
      } else{
        // If the onChange event happened here, the select would close
        setSelection(e)
      }
    } else{
      onChange(e)
      setSelection(e)
    }
  }

  const findDefaultValue = (filters, fldNme, filType) => {
    if (filters && filters[fldNme]) {
      return filters[fldNme];
    }
    else {
      return filType ? CONSTANTS.FILTER_LISTS[filType.toUpperCase()][0] : 'Select ...';
    }
  }

  return (
    <>
    {
      isMulti?
        <Select
            className='select-filter p-i'
            options = { sortOptions ? sortOptions : options }
            closeMenuOnSelect = {false}
            onChange={handleChange}
            isMulti={true}
            onMenuClose={() => {onChange(selection)}}
            isClearable = {true}
            value = {curFilters && curFilters[field] ? curFilters[field] : ''}
        />
      :
        <Select
            className='select-filter p-i'
            options = { sortOptions ? sortOptions : options  }
            onChange={handleChange}                  
            isClearable = {true}
            value = {findDefaultValue(curFilters, field, type) ? findDefaultValue(curFilters, field, type) : ''}
        />
    }
  </> 
  )
}

export default SelectFilter