import {Row, Col} from 'react-bootstrap';
import React, {useState, useEffect} from 'react';

import TransferButton from './TransferButton';

const disabledStatuses = new Set(['started', 'writing to database', 'working']);

function StagingAndProdTransferButtons({userName, apiKey, staging_endpoint, prod_endpoint, module, disableRefresh}){
  const [ buttonsDisabled, setButtonsDisabled ] = useState(false);
  const [ stagingStatus, setStagingStatus ] = useState('done');
  const [ prodStatus, setProdStatus ] = useState('done');

  useEffect(()=>{
    function isDisabled(){
      if (disabledStatuses.has(stagingStatus) || disabledStatuses.has(prodStatus)){
        setButtonsDisabled(true);
      } else {
        setButtonsDisabled(false);
      }
    }
    isDisabled();
  },[stagingStatus, prodStatus])

  return (
    <Row>
      <Col>
        <TransferButton
          userName={userName}
          apiKey={apiKey}
          disabled={buttonsDisabled}
          endpoint={staging_endpoint}
          module={module}
          environment='staging'
          setStatus={setStagingStatus}
          disableRefresh={disableRefresh}
        />
      </Col>
      <Col>
        <TransferButton
          userName={userName}
          apiKey={apiKey}
          disabled={buttonsDisabled}
          endpoint={prod_endpoint}
          module={module}
          environment='production'
          setStatus={setProdStatus}
          disableRefresh={disableRefresh}
        />
      </Col>
    </Row>
  )
}

export default StagingAndProdTransferButtons;