import React from 'react';
import { Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import utils from '../../utils';
import TagDisplay from '../TagDisplay';

function FormattedArticleList({ list, flatList=true, engineName, searchTerm, showTags=true, useStandardNewsLink=true }) {

    const getBodyHTML = (artItem) => {
      if(artItem.fields && artItem.fields.previewText && artItem.fields.previewText.length > 1) {
        return artItem.fields.previewText;
      }
      
      if (artItem && artItem.highlight && artItem.highlight.length > 0) {
        return artItem.highlight[0];
      }
      else {
        if (artItem.fields && artItem.fields.body) {
          return artItem.fields.body.substr(0, 300)+'...'
        } 
      } 

      return '';
    }

    const getLinkForArticle = (thisItem) => {
      if(useStandardNewsLink && useStandardNewsLink === true) {
        return (
          <LinkContainer to={`/news/${thisItem.contentID}`}>
              <h2 className='add-pointer h2'>{thisItem.fields.title ? thisItem.fields.title : ''}</h2>
          </LinkContainer>
        )
      }

      return (
        thisItem.fields.link && thisItem.fields.link.length > 0 ?
          <Link to={thisItem.fields.link} target="new">
             <h2 className='add-pointer h2'>{thisItem.fields.title ? thisItem.fields.title : ''}</h2>
          </Link>
        : <h3>{thisItem.fields.title ? thisItem.fields.title : ''}</h3>
      )
    }

    return (
        list && list.length > 0 ?
          list.map ( (thisItem, index)=> {

            return (
              <Row className="app-list-item">
                <p className='article-date secondary-link-plain'>{utils.getDateString(thisItem.fields.datepublished, 'long')}</p>
                { getLinkForArticle(thisItem) }
                <p dangerouslySetInnerHTML={{__html: getBodyHTML(thisItem) }}></p>

                { showTags && showTags === true ?
                  <TagDisplay 
                    engine={engineName} 
                    key={index} 
                    tagList={utils.limitTags(thisItem.fields.tags, searchTerm)} 
                    flatList={flatList}
                    searchTerm={searchTerm} /> 
                  : null } 

              </Row>
            )     
          })
        : "No search results available for specified search criteria"
    )

}

export default FormattedArticleList
