import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import filterUtils from '../../filterUtils';

function DiseaseFilter({ diseaseList, filterChangeHandler, curFilters }) {

  const handleSelect = (key) => {

    let foundArr = diseaseList.filter((item) => {
      return item.value.toLowerCase() === key.toLowerCase();
    });

    // Creates obj to send to the change handler function
    filterChangeHandler({
      field: 'disease',
      value: foundArr.length > 0 ? foundArr[0].value : ''
    });
    
  };

  return (
    <DropdownButton
      className='dropdown-select'
      id="disease-filter"
      title={filterUtils.findFilterTitle('Disease', curFilters, diseaseList)}
      onSelect={(eventKey) => handleSelect(eventKey)} >
      <Dropdown.Item
        eventKey={'clear-filter-disease'}
      >
        Select All
      </Dropdown.Item>
      {
        diseaseList.map((disease, idx) => {
          return (
            <Dropdown.Item
              key={idx}
              eventKey={disease.value}>
              {disease.label}
            </Dropdown.Item>
          )
        })
      }
    </DropdownButton>
  )
}

export default DiseaseFilter