import React from 'react';
import { Nav } from 'react-bootstrap';
import Collapse from 'rc-collapse';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

// import 'rc-collapse/assets/index.css';

function AppSideNav({ structure, tenantSettings, appname, navs, apppath, item=null, subItems=null, subUrlVal=null }) {

    // this component takes a tree of nav settings and displays it

    //get where we are at
    let { pathname } = useLocation();

    //get the name of the page -
    let myPageURL = pathname.split('/').pop();

    let findActiveKey = (currentUrl, panelList) => {
        //returns the key of the panel that should be showing

        for(let i=0; i< panelList.length; i++) {

            for(let x=0; x < panelList[i].subNav.length; x++) {
                if(panelList[i].subNav[x].urlForField.toLowerCase() === currentUrl.toLowerCase()) {
                    return i;
                }
            }
        }

        return 0;
    }

    //take out items with groupingTitle of -- as this means they are not part of the navigable struct

    let funcNav = navs.filter( (thisNavItem) => {
        return thisNavItem.groupingTitle !== '--';
    });

    if(item && subItems) {
        let theSubURLVal
        if(subUrlVal) {
            theSubURLVal = subUrlVal;
        } else {
            theSubURLVal = item.fields.slug;
        }
        apppath = apppath + `/${theSubURLVal}`;
    }

    return (
        <Nav className="structure-container flex-column">
            <Collapse accordion={true} defaultActiveKey={findActiveKey(myPageURL, funcNav)}>
            {
                funcNav.map( (highMenuItem, indexKey) => {
                    return (
                        <>
                            <span className='arrow'></span>
                            <Collapse.Panel header={highMenuItem.groupingTitle} headerClass="my-header-class" key={indexKey}>
                                <div>
                                {
                                    highMenuItem.subNav.map( (lowMenuItem) => {

                                        return (
                                            <LinkContainer to={`/${apppath}/${lowMenuItem.urlForField}`}>
                                                <Nav.Item className="sub-link" 
                                                    href={`${apppath}/${lowMenuItem.urlForField}`}>
                                                        { lowMenuItem.linkLabel }
                                                </Nav.Item>
                                            </LinkContainer>
                                        )
                                    })
                                }
                                </div>
                            </Collapse.Panel>
                        </>
                    )
                })
            }
            </Collapse>
        </Nav>
    )
}

export default AppSideNav





