import React, { useState, useRef, useEffect } from 'react';
import * as XLSX from "xlsx";
import { Button, Table} from 'react-bootstrap'

import utils from "../../utils"
import '../../styles/layout/_layout.scss'
import TableHeader from './TableHeader';

function ExcelDataImporter({ apiKey, setErrors, setStatus, columns, endpoint, setExtraContent, refreshData=null }) {
  // Stores the actual excel data as json
  const [xlData, setXlData] = useState([]);
  // For convenience this stores the keys for a single row.
  const [hasErrors, setHasErrors] = useState(false);
  const [apiData, setApiData] = useState([]);
  const ref = useRef();

  function checkForErrors(data){
    // Reset the success message and establish a variable to house errors
    setStatus('Checking for local errors')
    var tempErrors = []
    setErrors([])
    // Check for missing or incorrect types
    // Start by establishing dictionaries to contain the error count by column
    const nullCounts = {}
    const wrongTypeCounts = {}
    const badDates = {}
    // Initialize dictionaries to house errors
    for (const col of columns){
      if (col.mandatory){
        nullCounts[col.xlCol] = 0
      }
      if (col.type === 'number'){
        wrongTypeCounts[col.xlCol] = 0
      }
      if (col.type === 'date'){
        badDates[col.xlCol] = 0
      }
    }
    // Now get the error count
    for (const row of data){
      var apiRow = {}
      for (const col of columns){
        // Capture errors
        if (col.mandatory && !row[col.xlCol]){
          nullCounts[col.xlCol] += 1
        } else {
          if (col.type === 'number' && typeof(row[col.xlCol]) !== 'number'){
            wrongTypeCounts[col.xlCol] += 1
          }
        }
        if (row[col.xlCol] === 'Invalid Date'){
          badDates[col.xlCol] += 1
        }
        // Convert between Excel name and api name
        if (col.type === 'date'){
          // This is the conversion from Excel date to React date. I know it's not legible.
          // I converts to the correct date or "Invalid Date"
          apiRow[col.apiCol] = new Date(Math.round((row[col.xlCol] - 25568) * 864e5))
        } else {
          apiRow[col.apiCol] = row[col.xlCol];
        }
        
      }
    }
    // Check if the error count is greater than 0. If so, capture the error.
    for (const col of columns){
      if (nullCounts[col.xlCol] > 0){
        tempErrors.push("The "+col.xlCol+" column has "+nullCounts[col.xlCol]
          +" row(s) with a null value")
      }
      if (wrongTypeCounts[col.xlCol] > 0) {
        tempErrors.push("The "+col.xlCol+" column has "+wrongTypeCounts[col.xlCol]
          +" row(s) with a non-numeric value")
      }
      if (badDates[col.xlCol] >0){
        tempErrors.push("The " + col.xlCol + " column has " + badDates[col.xlCol]
        +" row(s) in an incorrect date format")
      }
    }
    if (tempErrors.length > 0){
      setHasErrors(true)
    }
    return tempErrors;
  }
  
  function xlToApi(tempXlData) {
    const tempApiData = []
    for (const row of tempXlData) {
      var apiRow = {}
      for (const col of columns) {
        // Convert between Excel name and api name
        if (col.type === 'date') {
          apiRow[col.apiCol] = new Date(row[col.xlCol])
        } else {
          apiRow[col.apiCol] = row[col.xlCol];
        }
      }
      tempApiData.push(apiRow)
    }
    setApiData(tempApiData)
  }

  function readData(e) {
    setErrors([])
    setStatus(null)
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const wsname = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[wsname];
        const json = XLSX.utils.sheet_to_json(worksheet, { defval: null });
        const finalJson = []
        // Just necessary for converting dates
        for (const row of json) {
          const elt = {}
          for (const col of columns) {
            let colName = col.xlCol
            if (col.type === 'date') {
              elt[colName] = new Date(Math.round((row[colName] - 25568) * 864e5))
              elt[colName] = elt[colName].toLocaleDateString()
            } else {
              elt[colName] = row[colName]
            }
          }
          finalJson.push(elt)
        }
        setXlData(finalJson)
        // Get the columns of the first row
        const errors = checkForErrors(finalJson)
        if (errors.length > 0) {
          setErrors(errors)
          setStatus('Local errors found')
        } else {
          xlToApi(finalJson);
          setStatus("No local errors, you can submit")
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      ref.current.value = null;
    }
  }

  function clear(){
    setXlData([])
    setApiData([])
    setErrors([])
    setStatus(null)
    setHasErrors(false)
    ref.current.value=null;
  }

  async function submit(){
    setStatus("Attempting to insert country info")
    
    // Call api to submit
    const result = await utils.upsert(endpoint, apiKey, apiData)
    if (result.length > 0){
      setStatus("Errors found!")
      setErrors(result)
      return
    } else {
      clear()
      if(refreshData){
        refreshData()
      }
      setStatus("Success!")
      
    }
  }

  function handleInputClick(){
    ref.current.click()
  }

  useEffect(()=>{
    if (!setExtraContent){
      return
    }
    if (xlData.length === 0){
      setExtraContent(null)
      return
    }
    const rowCols = Object.keys(xlData[0]);
    
    setExtraContent(
      <div >
        <div>First 5 rows:</div>
        <div className = 'scrollable'>
          <Table size = "sm">
            <thead className="table-heading">
              <tr>
                {rowCols.map(
                    (row) => {return <TableHeader title={row}/>}
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                xlData.slice(0, 5).map((row) =>
                  {return <tr>
                    {rowCols.map((key)=>{
                      return <td>{
                        row[key]
                        }</td>
                    })
                    }
                  </tr>}
                )
              }
            </tbody>
          </Table>
        </div>
        {
          // Disable submit if there are errors.
          !hasErrors?<><Button onClick={submit}>Submit</Button>{' '}</>:null
        }
        <Button onClick={clear}>Clear</Button>
        
      </div>
    )
    // eslint-disable-next-line
  }, [xlData, hasErrors, setExtraContent, apiData])


  return (
    <>
      <Button className='padded-row' variant='outline-secondary' onClick={handleInputClick}>Import Country Info</Button>
      <input ref={ref} type = "file" onChange = {readData} style={{"display":"none"}}/>
    </>
  )
}


export default ExcelDataImporter;