import React, { useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import AppTitleBlock from '../AppTitleBlock';
import ListPagination from '../Layout/ListPagination';

function FilteredPagedMarketsList({ marketsList, tenant }) {
    let [ curPage, setCurPage ] = useState(1);

    const NUMBER_PER_PAGE = 20;

    const getLinkForMarket = (mkt) => {
        let funcLink = '/vaccine_markets/' + mkt.fields.market_slug;
        if(tenant.fields.tenantslug === "LB-Level-Q") {
            funcLink = funcLink + '::' + mkt.fields.tenantView_TextField;
        }

        return funcLink;
    }

    const paginate = (num) => {
        setCurPage(num);
    }

 return (
     <>
         <BreadCrumbBar appName="VACCINE_MARKETS" />
         <div className='vaccine-mkt-page'>

             <AppTitleBlock 
                tenant={tenant} 
                appType={CONSTANTS.APPS_BY_APPTYPE["VACCINE_MARKETS"]} />


            <Row>
                <Col>
                    <div className="list-container">
                        {
                            marketsList && marketsList.length > 0 ?
                                <>
                                    {utils.getPageOfResults(marketsList, curPage, NUMBER_PER_PAGE).map((thisItem) => {

                                        return (

                                        <Card className="app-list-item weird-corners">
                                            <Row>
                                                <Col className="title-body-col" xs={9}>
                                                    <h3>{thisItem.fields.marketName}</h3>
                                                    {
                                                        tenant.fields.tenantslug === "LB-Level-Q" ?
                                                            <span>({ thisItem.fields.tenantView_TextField })</span>
                                                        : null
                                                    }
                                                </Col>
                                                <Col className="link-col" xs={3}>
                                                    <LinkContainer to={ getLinkForMarket(thisItem) }>
                                                        <Button className="surface-brand-primary btn-primary-lg">Read More</Button>
                                                    </LinkContainer>
                                                </Col>
                                            </Row>
                                        </Card>
                                        )
                                    })
                                }
                                    <Row className="pagination-row">
                                        {
                                            marketsList.length > NUMBER_PER_PAGE ?
                                                <ListPagination
                                                    articlesPerPage={NUMBER_PER_PAGE}
                                                    totalArticles={marketsList.length}
                                                    curPage={curPage}
                                                    paginate={paginate}
                                                />
                                            : ''
                                        }
                                    </Row>
                                </>
                                : <div className="text-center">No Results with the Current Filter</div>
                            }
                    </div>
                </Col>
            </Row>

         </div>
     </>
 )
}

export default FilteredPagedMarketsList