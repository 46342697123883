import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';

function FirstTimeUserModal({ userID }) {

    const [ showEditForm, setShowEditForm ] = useState(false);
    const [ formItems, setFormItems ] = useState({});
    const [ formErrors, setFormErrors ] = useState({});
    const [ formReady, setFormReady ] = useState(false);
    const [ formSubmitting, setFormSubmitting ] = useState(false);
    const [ formSubmitted, setFormSubmitted ] = useState(false);
    const [ showVerNoticeModal, setShowVerNoticeModal ] = useState(true);

    const portalId = '8615684';
    const formGuid = '056e402a-48a8-4c3b-aa43-d7e37be477a2';

    const REQ_FORM_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`

    const handleClose = () => {
        setShowVerNoticeModal(false)
    }

    const handleChangeModal = (type) => {
        if(type === 'form') {
            setShowVerNoticeModal(false);
            setShowEditForm(true);
        }
    }

    const checkRequired = (frmToCheck) => {
        let requiredFields = ["email", "lastname", "firstname", "company", "jobtitle", "LEGAL_CONSENT.subscription_type_42084135"];
        let missingFields = {};
        requiredFields.forEach((field) => {
            if(!frmToCheck[field]) {
                missingFields[field] = `${field} is required`;
            }
        });
        if(Object.keys(missingFields).length > 0) {
            setFormErrors(missingFields)
            return false;
        } else {
            setFormErrors({});
            return true;
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        let fieldsPresent = checkRequired(formItems);
        if(fieldsPresent) {
            setFormReady(true);
        } else {
            console.log('missing fields');
        }
    }

    const valueChanged = (e, isCheck=false) => {
        let prevValue = formItems;
        let newValue = e.target.value;
        if(isCheck) {
            newValue = e.target.checked;
        }
        setFormItems({...prevValue, [e.target.name]: newValue});
    }

    const convertToHSFormat = (formMdl) => {
        //hubsport expect { fields: {fieldValues}, except legal consent for subs}

        let retFormat = {
            fields: []
        };

        Object.keys(formMdl).forEach( (thisItem) => {
            if(thisItem !== 'LEGAL_CONSENT.subscription_type_42084135') {
                retFormat.fields.push({
                        "objectTypeId": "0-1",
                        "name": thisItem,
                        "value": formMdl[thisItem]
                })
            }
        })

        retFormat.legalConsentOptions = {
            "consent": { // Include this object when GDPR options are enabled
                "consentToProcess": true,
                "text": "I agree to allow Linksbridge to store and process my personal data.",
                "communications": [
                    {
                    "value": formMdl && formMdl['LEGAL_CONSENT.subscription_type_42084135'] && formMdl['LEGAL_CONSENT.subscription_type_42084135'] === true ? true : false,
                    "subscriptionTypeId": '42084135',
                    "text": "I agree to receive marketing communications from Linksbridge."
                    }
                ]
            }
        }

        return retFormat;

    }

    const submitForm = async () => {
        if(checkRequired(formItems)) {
            try {
                let response = await fetch(REQ_FORM_ENDPOINT, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(convertToHSFormat(formItems))
                });
                await response.json();
            } catch (error) {
                console.log(error);
            }
    }
    }

    //create useEffect that looks for formReady dependency
    //if formReady is true, then setFormSubmitting to true and fetch-post the data in formItems

    useEffect(() => {
        if(formReady && formReady === true) {
            setFormSubmitting(true);
            try {
                submitForm();
                setFormSubmitted(true);
            } catch (error) {
                console.log(error);
            } finally {  
                setFormSubmitting(false);
            }       
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formReady]);

    return (
        <>
        
            <Modal 
                className='user-signup-modal first-signup-modal' 
                show={showVerNoticeModal} 
                onHide={handleClose}
                size="lg"
                backdrop="static"
                keyboard={false} 
                centered >
                
                <Modal.Body>
                    <h3>We can't immediately verify your account.</h3>
                    <p>Linksbridge Workspace is available for verified users only.</p>
                    <p>To request access to the Global Health Markets Hub, please fill out this <button className="link-like-button" onClick={() => handleChangeModal('form')}>form</button>.</p>
                    <p>If you believe you should already have access, please reach out to <a href="mailto:gvmm@linksbridge.com">gvmm@linksbridge.com</a></p>
                    <p>* Please expect a response from our team in 1-3 business days.</p>
                </Modal.Body>
            </Modal>
            <Modal
                show={showEditForm}
                className='user-signup-modal'
                size="lg"
                backdrop="static"
                keyboard={false} 
                centered >

            <Modal.Body>
                    
                    {
                        showEditForm === true ?
                            <>
                                <h3>{formSubmitted === false ? 'Request to fix account' : 'Workspace access request'}</h3>
                                <p>{formSubmitted === false ? 
                                    'This form will allow you to request a change to your account access.*' 
                                        : 'Thank you for submitting your request. Our team will respond within 1-3 business days.'}</p>
                                { formSubmitted === false ?
                                    <Form onSubmit={(e)=> handleSubmit(e)}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label className={formErrors && formErrors.email ? 'error': ''}>Email address*</Form.Label>
                                            <Form.Control
                                                required 
                                                type="email" 
                                                isInvalid={formErrors.hasOwnProperty('email')}
                                                placeholder={formSubmitted === false ? "Enter email" : formItems.email}
                                                name="email" 
                                                disabled={formSubmitted === false ? false : true}
                                                onChange={(e) => valueChanged(e)} />
                                            {
                                                formErrors && formErrors.email ?
                                                    <span className='error-report'>Required Value</span>
                                                : null
                                            }
                                        </Form.Group>
                                        <Form.Group controlId="formFirstName">
                                            <Form.Label className={formErrors && formErrors.firstname ? 'error': ''}>First Name*</Form.Label>
                                            <Form.Control
                                                required 
                                                type="text" 
                                                isInvalid={formErrors.hasOwnProperty('firstname')}
                                                name="firstname" 
                                                disabled={formSubmitted === false ? false : true}
                                                onChange={(e) => valueChanged(e)} />
                                            {
                                                formErrors && formErrors.firstname ?
                                                    <span className='error-report'>Required Value</span>
                                                : null
                                            }
                                        </Form.Group>
                                        <Form.Group controlId="formLastName">
                                            <Form.Label className={formErrors && formErrors.lastname ? 'error': ''}>Last Name*</Form.Label>
                                            <Form.Control
                                                required 
                                                type="text" 
                                                isInvalid={formErrors.hasOwnProperty('lastname')}
                                                name="lastname" 
                                                disabled={formSubmitted === false ? false : true}
                                                onChange={(e) => valueChanged(e)} />
                                            {
                                                formErrors && formErrors.lastname ?
                                                    <span className='error-report'>Required Value</span>
                                                : null
                                            }
                                        </Form.Group>
                                        <Form.Group controlId="formCompany">
                                            <Form.Label className={formErrors && formErrors.company ? 'error': ''}>Company*</Form.Label>
                                            <Form.Control
                                                required 
                                                type="text" 
                                                isInvalid={formErrors.hasOwnProperty('company')}
                                                name="company" 
                                                disabled={formSubmitted === false ? false : true}
                                                onChange={(e) => valueChanged(e)} />
                                            {
                                                formErrors && formErrors.company ?
                                                    <span className='error-report'>Required Value</span>
                                                : null
                                            }
                                        </Form.Group>
                                        <Form.Group controlId="formTitle">
                                            <Form.Label className={formErrors && formErrors.jobtitle ? 'error': ''}>Job Title*</Form.Label>
                                            <Form.Control
                                                required 
                                                type="text" 
                                                isInvalid={formErrors.hasOwnProperty('jobtitle')}
                                                name="jobtitle" 
                                                disabled={formSubmitted === false ? false : true}
                                                onChange={(e) => valueChanged(e)} />
                                            {
                                                formErrors && formErrors.jobtitle ?
                                                    <span className='error-report'>Required Value</span>
                                                : null
                                            }
                                        </Form.Group>
                                        <Form.Group controlId="formPhone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control
                                                type="tel" 
                                                name="phone" 
                                                disabled={formSubmitted === false ? false : true}
                                                onChange={(e) => valueChanged(e)} />
                                        </Form.Group>
                                        
                                        <p>Linksbridge respects your privacy. We'll only use your personal information to provide content you requested, offer necessary updates about our services, and administer your account.</p>
                                        <p>
                                            <input
                                                required 
                                                style={{marginRight: '10px'}} 
                                                type="checkbox" 
                                                name="LEGAL_CONSENT.subscription_type_42084135" 
                                                isInvalid={formErrors.hasOwnProperty('LEGAL_CONSENT.subscription_type_42084135')}
                                                onChange={ (e) => valueChanged(e, true) } 
                                                disabled={formSubmitted === false ? false : true} /> 
                                            <label className={formErrors && formErrors['LEGAL_CONSENT.subscription_type_42084135'] ? 'error': ''}>I would like access to Global Health Markets Hub.*</label>
                                            {
                                                formErrors && formErrors['LEGAL_CONSENT.subscription_type_42084135'] ?
                                                <>
                                                    <br/><span className='error-report'>Required Value</span>
                                                </>
                                                
                                                : null
                                            }
                                        </p>
                                        <p>
                                        By clicking submit below, you consent to allow Linksbridge to store and process the personal information submitted above to provide you the content requested.
                                        </p>
                                        
                                        <button type="submit" className="btn btn-primary" disabled={formSubmitting}>
                                            { formSubmitting ? 'Submitting...' : 'Submit Request' }
                                        </button>
                                            
                                        
                                    </Form>
                                : null }
                            </>
                            
                        : ''
                    }
                </Modal.Body>

            </Modal>
        </>
    )
}

export default FirstTimeUserModal