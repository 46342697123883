import React, { useState, useEffect } from 'react';

import { Spinner, Row, Col, Container, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { CloudDownload, Search, Trash } from 'react-bootstrap-icons';
import { useMsal } from '@azure/msal-react';
import { useToast } from '../ToastProvider';
import utils from '../../utils';
import constants from '../../constants';
import PyramidDataDisplay from './PyramidDataDisplay';
import ListOfMemberWorkspaces from './ListOfMemberWorkspaces';
import SlidingPanel from 'react-sliding-side-panel';
import UserDetails from './UserDetails';
import 'react-sliding-side-panel/lib/index.css';
import InviteUser from '../Settings/InviteUser';
import ModalDynamic from '../Layout/ModalDynamic';
import DataTable from 'react-data-table-component';
import BulkActionsDisplay from './BulkActionsDisplay';
import UserJourneyPanel from './UserJourneyPanel';


function SuperAdminMgmtApp({ tenant }) {

    //go through workspaces - show loading with "loading [workspace name]"
    //for each, get transitive members with extensions
    //check through list of previous members,
    //  if member already in master list, mark the workspaces apps don't add new
    //  if not, add to master list, mark the workspaces apps for said workspace

    const [ masterList, setMasterList ] = useState();
    const [ wkspcList, setWkspcList ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ curWkspcMsg, setCurWkspcMsg ] = useState();
    const [ openPanel, setOpenPanel ] = useState(false);
    const [ showConfirm, setShowConfirm ] = useState(false);
    
    const [ selectedList, setSelectedList ] = useState([]);
    const [ uploadReady, setUploadReady ] = useState(false);

    const [ filterText, setFilterText ] = useState();
    const [ pointedAtServer ] = useState('PROD');

    //states for side panel
    const [ loadingPerson, setLoadingPerson ] = useState(false);
    const [ loadPerson, setLoadPerson ] = useState(false);
    const [ selectedPerson, setSelectedPerson ] = useState();
    const [ executeDelete, setExecuteDelete ] = useState(false); //flag to upload delete request
   
    const [ showBulk, setShowBulk ] = useState(false);
    const [ actionToExec, setActionToExec ] = useState();

    const { instance, accounts } = useMsal();
    const request = {
        scopes: ["User.Read"]
    };

    const { addToast } = useToast();

    let tenID = utils.getForceTenantValue();

    if(tenID === null && tenant) {
        tenID = tenant.tenant.fields.aDGroupID;
    }
    

    document.title = 'Workspace | Super Admin Management Page';

    const getWkspcList = async () => {
        //get list of all workspaces available to this user
        let theAccToken = await utils.getAccessToken(instance, accounts[0], request);
        setCurWkspcMsg(`Loading list of workspaces.`);

        let res = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-tenants-list`, {
          headers: { "Authorization": "Bearer " + theAccToken.accessToken }
        });
        let jsonRes = await res.json();
        
        return jsonRes.tenants; 
    };

    const mergeUsersChangesBackToLiveUserList = (usersToUpdateArray) => {
        //go through the usersArray and delete each user from the masterList
        let newMasterList = Object.assign({}, masterList);
        
        //go through the array of users to merge back in
        //merge matches with existing data so we don't have to port a ton around
        usersToUpdateArray.forEach( (thisUser) => {
            newMasterList[thisUser.user_id] = {...thisUser, ...newMasterList[thisUser.user_id]};
        });

        setMasterList(newMasterList);
    }

    const getUserRecord = (id) => {
        //get user record from masterList
        return {...masterList[id]};
    }

    const wkspcChangeHandler = (e) => {

        let selInfo = e.target.value;

        let selectedArray = selInfo.split('::');

        if(selectedArray.length > 1) {
            //get user that was changed
            let userID = selectedArray[0];
            let newWorkspace = selectedArray[1];

            let thisUserRec = getUserRecord(userID);

            //update the user record with the new workspace
            thisUserRec.defaultWorkspace = newWorkspace;

            //set user for upload
            setSelectedPerson(thisUserRec);
            setUploadReady(true);

            //update the masterList
            mergeUsersChangesBackToLiveUserList([thisUserRec]);
        }  

    };

    const createWkspcDropDown = (curWkspc, list, userID, usersWkspaces) => {
        //create a dropdown of workspaces, value is tenID, value is passed userID + :: + tenName
        //we add the user id so we can pick the correct user to modify
        //default select curWkspc

        let options = [];

        list.forEach((thisItem) => {
            if(Object.keys(usersWkspaces).indexOf(thisItem.tenantID) > -1) {
                options.push( { label: thisItem.tenantSlug, value: userID + '::' + thisItem.tenantID });
            }
        })
        
        let getDefaultIndex = (wkspcPick) => {
            let foundMatch = options.findIndex( (thisItem) => {
                return thisItem.value.includes(wkspcPick);
            });

            if(foundMatch < 0) {
                return null;
            }

            return options[foundMatch].value;

        }

        return (
            <select 
                name={userID} 
                id={userID} 
                defaultValue={ getDefaultIndex(curWkspc)} 
                onChange={wkspcChangeHandler} >
                {
                    options.map( (thisOpt) => {
                        if(getDefaultIndex(curWkspc) === thisOpt.value) {
                            return (<option selected data-tag="allowRowEvents" value={thisOpt.value}>{thisOpt.label}</option>)
                        } else {
                            return (<option data-tag="allowRowEvents" value={thisOpt.value}>{thisOpt.label}</option>)
                        }
                        
                    })
                }
            </select>
        )
    }

    const getAllLogins = async () => {
        let lresp;
        
        let theAccToken = await utils.getAccessToken(instance, accounts[0], request);
        setCurWkspcMsg(`Loading Logins.`);

        try {
            let loginDetails = await fetch(`${process.env.REACT_APP_WEB_API}/api/logs/all-last-logins?server=${convertServerFromProcess(pointedAtServer)}`, {
                method: 'GET',
                headers: {
                    Authorization: theAccToken.accessToken ? theAccToken.accessToken : theAccToken
                }
            });

            lresp = await loginDetails.json();
        } catch (error) {
            console.log('error getting logins: ', error)
            lresp = [];
        }

        return lresp;
    }

    const showUserDetails = () => {
        //render the settings for the selected person
        setLoadPerson(true);
        setOpenPanel(true);
    }

    const convertServerFromProcess = (srvrStr) => {
        if(srvrStr === 'DEV') {
            return 'development';
        }

        return "production";
    }

    const handleRowSelect = (e) => {
        setSelectedList(e.selectedRows);
    }

    const loadPersonDetails = async () => {
        //use the selectedPerson state to get details that we need from aad - last login (using all users group)
        setLoadingPerson(true);
        
        setSelectedPerson(selectedPerson);
        
        setLoadPerson(false);
        setLoadingPerson(false);

    };

    const getAllPyrUsersByRoles = async () => {
        
        let theAccToken = await utils.getAccessToken(instance, accounts[0], request);
        setCurWkspcMsg(`Getting Pyramid Users`);

        let res = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/all-users-by-role/00001`, {
            headers: { "Authorization": "Bearer " + theAccToken.accessToken }
          });
        
        let jsonRes = await res.json();
          
        return jsonRes;
          
    }

    const deleteFromLiveUserList = (usersArray) => {
        //go through the usersArray and delete each user from the masterList
        let newMasterList = Object.assign({}, masterList);
        

        for(let i=0; i< usersArray.length; i++) {
            delete newMasterList[usersArray[i]];
        }

        setMasterList(newMasterList);
        
    }
 
    const createHashFromArray = (arrayToUse, workspaceInfo, isLB) => {
        let retHash = {};

        //correct super_admin set here

        for(let i=0; i<arrayToUse.length; i++) {

            retHash[arrayToUse[i].id] = { 
                displayName: arrayToUse[i].displayName,
                mail: arrayToUse[i].mail,
                securityLevel: arrayToUse[i].securityLevel,
                createdDateTime: arrayToUse[i].createdDateTime,
                defaultWorkspace: arrayToUse[i].defaultWkspc ? arrayToUse[i].defaultWkspc : null,
                wrkspaces: returnWrkSpaceHash(arrayToUse[i], workspaceInfo)
            };

            if(isLB) {
                //super_admin located in here if super_admin
                if(arrayToUse[i].super_admin && arrayToUse[i].super_admin === true) {
                    retHash[arrayToUse[i].id].super_admin = true;
                }
            }
        }
        return retHash;
    }

    const getItemFromArrayByUserID = (uID, checkArray) => {
        let matchArray = checkArray.filter( (thisItem) => {
            return thisItem.rowKey === uID;
        });

        if(matchArray.length > 0) {
            return matchArray[0]
        }

        return null;
    }

    const mergeInLastLogins = (userHash, lloginList) => {
        let newUserHash = Object.assign({}, userHash);

        Object.keys(newUserHash).forEach( (thisKey) => {
            let matchingRec = getItemFromArrayByUserID(thisKey, lloginList);
            if(matchingRec) {
                let dateOfLogin = matchingRec.DateOfLogin;
                newUserHash[thisKey].last_login = dateOfLogin;
            } else {
                newUserHash[thisKey].last_login = new Date('1/1/2016').toISOString();
            }
        });

        return newUserHash;
    }

    const getWorkspaceData = async (tenRec) => {
        //get the transitive members of tenID workspace
        setCurWkspcMsg(`Loading user data for ${tenRec.tenantName}`);

        let theAccToken = await utils.getAccessToken(instance, accounts[0], request);
        let addedParams = `tenid=${tenRec.tenantID}`;

        try {
            let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/group/${tenRec.tenantID}?${addedParams}`, {
                headers: {
                "Authorization": "Bearer " + theAccToken.accessToken
                }
            });
            let users = await response.json();
            
            return createHashFromArray(users, tenRec.tenantID, tenRec.tenantID === constants.LB_WKSPC_ID);
            
        } catch (error) {
            console.log(error);
            return {};
        }
        
    };

    const returnWrkSpaceHash = (item, workspaceInfo) => {
        let newObj = {};

        newObj[workspaceInfo] = { appsList: item.appsList };

        return newObj;
    };
    
    //state is a big hash by user id
    //merge the hashes from state and new hash
    const mergeHashes = (stHash, newHash, newWkspc) => {
        let tempStHash = Object.assign({},stHash);
        Object.keys(newHash).forEach( (thisKey) => {
            if(tempStHash.hasOwnProperty(thisKey)) {
                //user already exists, add the wrkspace apps only
                //add the key to wrkspaces property keyed by the newWkspc
                tempStHash[thisKey].wrkspaces[newWkspc] = { appsList: newHash[thisKey].wrkspaces[newWkspc].appsList ? newHash[thisKey].wrkspaces[newWkspc].appsList : 'news' };
                
            } else {
                //user is not in this list already, add the whole kitten caboodle
                tempStHash[thisKey] = newHash[thisKey];
            }

            if(newWkspc === constants.LB_WKSPC_ID) {
                if(newHash[thisKey].hasOwnProperty('super_admin') && newHash[thisKey].super_admin === true) {
                    //set it
                    tempStHash[thisKey].super_admin = true;
                }
            }
            
        });

        return tempStHash
    }

    const matchUserInArrayByMail = (userObj, arrayToCheck) => {
        let matchingArray = arrayToCheck.filter( (thisItem) => {
            return thisItem.email.toLowerCase().indexOf(userObj.mail.toLowerCase()) > -1;
        });

        if(matchingArray.length > 0) {
            return matchingArray[0];
        }

        return null;
    }

    const mergePyrRoleInfo = (pyrData, userHash) => {
        //go through the user hash, look in pyrData to match mail to users item's and mark what roles they have
        //copy userHash
        let newUserHash = Object.assign({}, userHash);

        Object.keys(newUserHash).forEach( (thisKey) => {

            newUserHash[thisKey].pyramidData = [];
            let roleInfoForThisUser = [];

            for(let i=0; i < pyrData.length; i++) {
                //go through each role
                
                let thisInfoForRole = matchUserInArrayByMail(newUserHash[thisKey], pyrData[i].users.data);

                if(thisInfoForRole) {
                    roleInfoForThisUser.push({ 
                        roleName: pyrData[i].roleName, 
                        id: pyrData[i].id, 
                        ...thisInfoForRole})
                }
            }

            newUserHash[thisKey].pyramidData = roleInfoForThisUser;
        });

        return newUserHash;
    }

    const getAllWorkspaces = async (wkspcList) => {
        //get each workspace in the list
        
        if(wkspcList && wkspcList.length > 0){   
            //get first Wkspc in the list
            let runningCollWkspc = await getWorkspaceData(wkspcList[0]); 

            let curWkspc = 1;
            while(curWkspc < wkspcList.length) {
                let thisWkspc = await getWorkspaceData(wkspcList[curWkspc]);
                
                //merge with runningWrkspcList
                runningCollWkspc = mergeHashes(runningCollWkspc, thisWkspc, wkspcList[curWkspc].tenantID);

                curWkspc++;
            }

            return runningCollWkspc;
        }    
        
    }

    const handleDeleteConfirmClose = () => {
        setShowConfirm(false);
    }

    const handleBulkClose = () => {
        setShowBulk(false);
    }

    const filterByEmailOrUserName = (arrayToFilter) => {
        if(filterText && filterText.length > 0) {
            return arrayToFilter.filter( (thisItem) => {
                return thisItem.displayName.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
                        thisItem.mail.toLowerCase().indexOf(filterText.toLowerCase()) > -1
            });
        }

        return arrayToFilter;
    }

    const renderArrayFromHashObj = (hshObj) => {
        let retArr = [];
        
        Object.keys(hshObj).forEach( (thisKey) => {
            let newObj = hshObj[thisKey];
            newObj.user_id = thisKey;
            retArr.push(newObj);
        });

        retArr.sort( (a, b) => {
            return (a.displayName).localeCompare(b.displayName);
        });

        //if filtering text, filter here
        retArr = filterByEmailOrUserName(retArr);

        return retArr;
    }

    const colConfig = [
        {
            name: 'Name',
            selector: row => row.displayName,
            sortable: true
        },
        {
            name: 'Last Login',
            selector: row => row.last_login,
            format: row => { return ( new Date(row.last_login).getFullYear() > 2021 ? new Date(row.last_login).toLocaleString(): '--' )},
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.mail,
            sortable: true
        },
        {
            name: 'Sec Lvl',
            selector: row => row.securityLevel,
            sortable: true,
            maxWidth: '75px'
        },
        {
            name: 'Pyramid',
            selector: (row) => <PyramidDataDisplay pyrData={row.pyramidData} />,
            maxWidth: '75px',
            sortable: true
        },
        {
            name: 'Def. Wrkspc',
            selector:  (row) => createWkspcDropDown(row.defaultWorkspace, wkspcList, row.user_id, row.wrkspaces)
        },
        {
            name: 'Workspaces',
            selector: (row) => <ListOfMemberWorkspaces rec={row} allWkspcsList={wkspcList} />,
            grow: 2
        }
    
    ];


    const getNameOfWkspc = (wkspcID, wkspcList) => {
        let indexOfWk = wkspcList.findIndex( (thisWk) => { return thisWk.tenantID === wkspcID});

        if(indexOfWk > -1) {
            return wkspcList[indexOfWk].tenantSlug;
        }

        return wkspcID;
    }

    const getWorkspaceList = (usrWkspcList, wkspcList) => {
        //returns a object with a prperty for each workspace,
        //if user has apps in that workspace, the property is an list of apps,
        //if not, shows ''
        let retObj = {};

        wkspcList.forEach( (thisWkspc) => {
            retObj[thisWkspc.tenantSlug] = '';
            if(usrWkspcList.hasOwnProperty(thisWkspc.tenantID)) {
                retObj[thisWkspc.tenantSlug] = usrWkspcList[thisWkspc.tenantID].appsList;
            }
        });

        return retObj;
    }


    const exportToExcel = () => {
        let dataToExport = renderArrayFromHashObj(masterList);
        let formatted = dataToExport.map( (row) => {
            return {
                UserName: row.displayName,
                LastLogin: row.last_login,
                Email: row.mail,
                SecurityLevel: row.securityLevel,
                Pyramid: row.pyramidData.length > 0 ? row.pyramidData[0].roleName : "None",
                DefaultWorkspace: getNameOfWkspc(row.defaultWorkspace,wkspcList),
                ...getWorkspaceList(row.wrkspaces, wkspcList)
            }
        });

        utils.exportExcel(formatted, 'super-admin-export');
    }

    const getUserInfoFromIDInList = (listToPullFrom, id) => {
        let thisPerson = listToPullFrom[id];

        return thisPerson ? thisPerson.mail : '';
    }

    const customSort = (rows, selector, direction) => {
        return rows.sort((rowA, rowB) => {
         // use the selector function to resolve your field names by passing the sort comparitors
            let aField, bField
            if(selector === 'last_login') {
                aField = new Date(selector(rowA))
                bField = new Date(selector(rowB))
            } else {
                aField = selector(rowA)
                bField = selector(rowB)
            }

            let comparison = 0;
        
            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }
        
            return direction === 'desc' ? comparison * -1 : comparison;
        })
    };


    const getDeleteBody = () => {
        return (
            <div className='m-b-end-07'>
                <p className='body-med txt-primary'>
                    Are you sure you want to delete the following users?  They will be permanently deleted. 
                    <ul>
                        {
                            selectedList.map( (thisItem) => {
                                return (
                                    <li>{ getUserInfoFromIDInList(masterList, thisItem.user_id) }</li>
                                )
                            })
                        }
                    </ul>
                </p>
            </div>
        )
        
    }

    const getBulkBody = () => {
        return (
            <BulkActionsDisplay
                usersToActOn={ selectedList } 
                actionToTake={ actionToExec }  
                wkspcList={wkspcList} 
                tenID={tenID} />
        )
    }

    const bulkModal = {
        title: `Bulk Actions`,
        body: getBulkBody(),
        subName: 'Finished / Close'
    }

    const confirmDeleteModal = {
        title: `Are you sure you want to delete the selected users?`,
        body: getDeleteBody(),
        subName: 'Yes, Delete'
    }

    const getDeleteResults = (statusArr) => {
        let retStr = '';

        for(let statusLine in statusArr) {
            retStr = retStr + statusLine.user + ' result: ' + statusLine.status === 'ok' ? 'deleted.  ' : 'not able to be deleted.  Message: ' + statusLine.message + '.  ';
        }

        return retStr;
    }

    const uploadChangedUser = async (selUser) => {
        //send the user to the api to update
        let theAccToken = await utils.getAccessToken(instance, accounts[0], request);

        let userToUpload = {
            id: selUser.user_id,
            defaultWkspc: selUser.defaultWorkspace
        }


        try {
            await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/elevate-users/${selUser.defaultWorkspace}?tenid=${selUser.defaultWorkspace}`, {
                headers: {
                    "Authorization": "Bearer " + theAccToken.accessToken,
                    "Content-Type": "application/json"
                },
                method: 'POST',
                body: JSON.stringify([userToUpload])
            });

            // let jsonRes = await response.json();
            mergeUsersChangesBackToLiveUserList([selUser]);
            addToast({
                title: 'User Update',
                body: 'Updated ' + selUser.displayName + ' default wkspc'
            });
        } catch (error) {
            console.log(error);
            addToast({
                title: 'User Update',
                body: 'Error updating user: ' + error
            });
        }

    }

    const deleteUsers = async () => {
        //go through usersSelected and send delete
        let statusOfDeletions = [];

        if(selectedList && selectedList.length > 0) {
            let curUser = 0;
            let numToDelete = selectedList.length;

            let theAccToken = await utils.getAccessToken(instance, accounts[0], request);

            while(curUser < numToDelete) {
                let thisUser = selectedList[curUser].user_id;

                let addedParams = `tenid=${constants.GHMH_WKSPC_ID}`;  //delete them from ghmh, which perm deletes them.

                try {
                    await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/current-user/${thisUser}?${addedParams}`, {
                        headers: {
                            "Authorization": "Bearer " + theAccToken.accessToken
                        },
                        method: 'DELETE',
                    });

                    statusOfDeletions.push({ status: 'ok', user: thisUser, message: 'deleted successfully'});
                    
                } catch (error) {
                    console.log(error);
                    statusOfDeletions.push({ status: 'fail', user: thisUser, message: error });
                }

                curUser = curUser + 1;
            }

            //filter status responses to just successful ones, then map an array of just userids
            let usersToDelete = statusOfDeletions.filter( (thisRes) => {
                    return thisRes.status === 'ok';
                })
                .map( (thisSuccRes) => {
                    return thisSuccRes.user;
                });

            //delete all users from the FE list
            deleteFromLiveUserList(usersToDelete);
        }

        //notify user of operation results
        addToast({
            title: 'Delete Result',
            body: getDeleteResults(statusOfDeletions)
        });

        return statusOfDeletions;
    }

    const handleDeleteExec = () => {
        if(selectedList && selectedList.length > 0) {
            //delete all users selected
            setExecuteDelete(true); //cue up the api call
        }

        setShowConfirm(false);
    }

    const showDeleteConfirm = () => {
        setShowConfirm(true);
    }

    const handleRowClick = (row) => {
        //returns the whole row of data
        setSelectedPerson(row);
        showUserDetails();
    }

    const handleFilterText = (e) => {
        //if backspace, remove 1 from filterText
        e.preventDefault();
        if ( e.code === "Backspace") {
            let newVal = filterText.slice(0, -1);
            setFilterText(newVal)
        } else {   
            //else add character to 
            setFilterText(e.target.value)
        }
        
    }

    const handleActionsSel = (actionReq) => {
        //set the action to execute
        setActionToExec(actionReq);
        //show the bulk modal
        setShowBulk(true);
    }

    useEffect( () => {
        const getTheData = async () => {
            setLoading(true);
            let wrkspaces = await getWkspcList();
            let pyrUsers = await getAllPyrUsersByRoles();
            let runningWrk = await getAllWorkspaces(wrkspaces);
            let mrgedRunning = mergePyrRoleInfo(pyrUsers, runningWrk);
            let lastLogins = await getAllLogins();
            let loginsMrged = mergeInLastLogins(mrgedRunning, lastLogins);
            setWkspcList(wrkspaces);
            setMasterList(loginsMrged);
            setCurWkspcMsg('');
            setLoading(false);
        }
        
        getTheData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ pointedAtServer ]);

    useEffect( () => {
        if(loadPerson === true) {
            loadPersonDetails();
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadPerson] )

    useEffect( () => {
        if(executeDelete === true) {
            deleteUsers();
            setExecuteDelete(false);
            setSelectedList([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[executeDelete] )   

    useEffect( () => {
        if(uploadReady === true) {
            uploadChangedUser(selectedPerson);
            setUploadReady(false);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[uploadReady] )    

    const getDisabled = () => {
        let hasSelections = selectedList.length > 0;

        return !hasSelections;
    }

    return (
        <Container className="super_admin_page">
            { loading === false && masterList && wkspcList?
                <>
                 
                    <Row>
                        <Col style={{marginBlockEnd: '30px'}}>
                            <h4 style={{display: 'inline-block', marginInlineEnd: '16px'}}> Super Admin User View:  Production</h4>
                        </Col>
                    </Row>
                    <Row>
                    <Row>
                        <Col xs={12} md={{ span: 5, offset: 0 }}>
                            <span id="search-icon"><Search /></span><input id="filter-text" type="search" placeholder="Search for Email or Username to filter" style={{width: '80%'}} onChange={handleFilterText} />
                        </Col>
                        <Col xs={12} md={{ span: 2, offset: 0 }}>
                            <DropdownButton 
                                variant="light"
                                title="... More Actions"
                                disabled={getDisabled()} >
                                <Dropdown.Item as={Button} onClick={() => handleActionsSel(constants.USERS_ACTIONS.DEFAULT_WORKSPACE_CHANGE)} name="defWkspaceChng">Change Default Workspace</Dropdown.Item>
                                <Dropdown.Item as={Button} onClick={() => handleActionsSel(constants.USERS_ACTIONS.ADD_TO_WORKSPACE)} name="addWorkspcChng">Add To Workspace</Dropdown.Item>
                                <Dropdown.Item as={Button} onClick={() => handleActionsSel(constants.USERS_ACTIONS.SECURITY_LEVEL)} name="secLevelChng">Change Security Level</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <Col  xs={12} md={{ span: 2, offset: 0 }}>
                            <Button onClick={showDeleteConfirm} disabled={getDisabled()}>Delete User <Trash /></Button>
                        </Col>
                        <Col xs={12} md={{ span: 2, offset: 0 }}>
                            <InviteUser
                                tenID={''}
                                usersList={renderArrayFromHashObj(masterList)}
                                workspaceName={''}
                                superAdmin={true}
                                wrkspcMasterList={wkspcList} />
                        </Col>
                        <Col>
                            <Button variant="light" onClick={exportToExcel} ><CloudDownload /></Button>
                        </Col>
                    </Row>
                    
                        <Col>
                            <DataTable
                                data={renderArrayFromHashObj(masterList)}
                                sortFunction={customSort}
                                columns={colConfig} 
                                paginationPerPage={30} 
                                pagination 
                                fixedHeader
                                fixedHeaderScrollHeight="600px"
                                selectableRows
                                onSelectedRowsChange={ handleRowSelect } 
                                onRowClicked={ handleRowClick } 
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <UserJourneyPanel
                                userRecords={renderArrayFromHashObj(masterList)} />
                        </Col>
                    </Row>
                    <SlidingPanel
                        type={'right'}
                        isOpen={openPanel}
                        size={30} >

                        <div>
                            { loadingPerson ? 
                                <Spinner animation='border' />
                            :
                                <>
                                    <div>
                                        {
                                            selectedPerson ?
                                                <UserDetails 
                                                    userToShow={selectedPerson} 
                                                    masterWrkSpaceList={wkspcList}
                                                    onClosePanel={setOpenPanel} />
                                            :
                                                <div className='align-center'>No Person Selected.</div>
                                        }
                                    </div>
                                    
                                </>
                            }
                        </div>

                    </SlidingPanel>
                    <ModalDynamic
                        show={showConfirm}
                        content={confirmDeleteModal}
                        handleClose={handleDeleteConfirmClose}
                        submitRequest={handleDeleteExec}
                    />
                    <ModalDynamic
                        show={showBulk}
                        content={bulkModal}
                        handleClose={handleBulkClose}
                        submitRequest={handleBulkClose}
                    />
                </>
            
            :   <Row>
                    <Col className='text-center'>
                        <Spinner animation="border" /> { curWkspcMsg }
                    </Col>
                </Row>
                }
        </Container>
    )
}

export default SuperAdminMgmtApp