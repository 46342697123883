import React, { useState, useEffect } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import { ChevronDown } from 'react-bootstrap-icons';

function MultiSelectDynamic({ options, id=null, handleSelect, initialVals=[], className=null, disabled=false}) {

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [initialState, setIntitialState] = useState(true)

    useEffect(() => {
        if(initialState === true && initialVals && initialVals.length > 0) {
            setSelectedOptions(initialVals)
            setIntitialState(false)
        }
    },[initialState, initialVals])
    
    const filterOutValue = (arr, val) => {
        let curOptions = arr.filter((item) => item !== val);
        if(curOptions && curOptions.length > 0) {
            setSelectedOptions(curOptions);
            handleSelect(id, curOptions);
        } else {
            setSelectedOptions([]);
            handleSelect(id, [])
        }
    }

    const toggleLang = (option) => {
        let tempSelected = selectedOptions && selectedOptions.length > 0 ? [...selectedOptions] : [];

        if (tempSelected && tempSelected.length > 0 && tempSelected.includes(option)) {
            filterOutValue(tempSelected, option);
        } else {
            tempSelected.push(option)
            setSelectedOptions(tempSelected);
            handleSelect(id, tempSelected)
        }
    };
    
    return (
        <Dropdown className={`${className ? className : ''} dropdown-multi-select`}>
            <Dropdown.Toggle 
                id={id}
                className={
                    (selectedOptions && selectedOptions.length > 0) || (initialVals && initialVals.length > 0) ?
                    'remove-padding' 
                    : 'add-padding'} 
            >
                {
                    selectedOptions && selectedOptions.length > 0 ?
                        selectedOptions.map((option, idx) => {
                            return <Badge className={`selection_${idx}`}>{option}</Badge>
                        })
                        : 'Select...'
                }
                <ChevronDown />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {options.map((option, index) => (
                    <Dropdown.Item
                        key={index}
                        disabled={disabled}
                        onClick={() => toggleLang(option)}
                    >
                        {option}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default MultiSelectDynamic;