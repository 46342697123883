import React from 'react';
import { Row, Col } from 'react-bootstrap';

function AppAccessPicker({ appList, userInfo, onChange }) {

    // creates array of apps from the string
    let userChecks = userInfo.appsList ? userInfo.appsList.split(','): [];

    //Finds the apps that user has access to and displays checkbox as 'on'
    const appIsOn = (appType, appChecks) => {
        return appChecks.indexOf(appType.toLowerCase()) > -1 ;
    }    

    //apps object
    const APPNAMES_BY_KEY = {
        NEWS: 'News',
        COMPANY_PROFILES: 'Company Profiles',
        PARTNER_PROFILES: 'Partner Profiles',
        INDUSTRY_ANALYSIS: 'Industry Analysis',
        VACCINE_MARKETS: 'Vaccine Markets',
        ADDRESSABLE_POP: 'Addressable Pop. Estimator',
        INTELLIGENCE: 'Intelligence',
        MARKET_ACTIONS: 'Market Actions',
        MARKET_ANALYSIS: 'Market Analysis',
        GLBL_HLTH_ALCHEMY: 'Global Health Alchemy',
        META: 'META'
    }

    //sorts order apps are displayed in
    appList.sort((app1, app2) => {
        if(app1.fields.apptype > app2.fields.apptype) {
            return 1
        }

        if(app2.fields.apptype > app1.fields.apptype) {
            return -1;
        }

        return 0;
    })

    //sends change up to WorkspaceUserList.js to be sent to backend
    const addAppPermissions = (change) => {
       onChange(change, userInfo)
    }

    return (
        appList && userInfo && userChecks.length > 0 ?
            appList.map( (thisApp, idx) => {
                return (
                    <Row key={idx}>
                        <Col xs={2}>
                            <input 
                                type="checkbox" 
                                name={thisApp.fields.apptype} 
                                onChange={(e) => { addAppPermissions(e.target.name)}} 
                                defaultChecked={appIsOn(thisApp.fields.apptype, userChecks)} />
                        </Col>
                        <Col xs={10}>
                            { thisApp.fields.appAlternateTitle ? thisApp.fields.appAlternateTitle : APPNAMES_BY_KEY[thisApp.fields.apptype.toUpperCase()] }
                        </Col>
                    </Row>
                )
            })
        :
        null
    )
}

export default AppAccessPicker