import React from 'react'
import DynamicToast from './DynamicToast'
import DynamicModal from './DynamicModal'

function Notification({ notificationType, content }) {

  return (
    notificationType === 'toast' ?
    <DynamicToast
      content={content}
    />
    : notificationType === 'modal' ?
    <DynamicModal 
      content={content}
    />
    : ''
  )
}

export default Notification