import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../../styles/layout/_layout.scss';

function ColumnSelector({ data, columnData, onChange  }){
  const [ selection, setSelection ] = useState();
  const [ options, setOptions ] = useState();
  
  useEffect(()=>{
    const temp = data?.map((d)=> {return {label:d.title, value:d}})
    setOptions(temp);
  }, [data])

  useEffect(()=>{
    if(!columnData){
      return 
    }
    setSelection(columnData.map((cd)=>{return {value:cd, label: cd.title}}))
  }, [columnData])

  function handleChange(e){
    onChange(e.map((elt)=>{return elt.value}))
  }

  return (
    <>
    <label className='input-text'>Show Columns</label>
    <Select
      className='select-lg m-b-end-03'
      options = { options }
      closeMenuOnSelect = {false}
      onChange={handleChange}
      isMulti={true}
      isClearable = {true}
      value = {selection}
    /> 
    </>
  )
}

export default ColumnSelector;