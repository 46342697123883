import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { Grid } from 'gridjs-react';
import Pagination from 'rc-pagination';
import localeInfo from '../../locale_en_US';
// import DataFilter from '../Alchemy/DataFilter';

import utils from '../../utils';

function DataChangesLogs({ preview=false }) {

  let [loadingChanges, setLoadingChanges] = useState(false);
  let [loadChanges, setLoadChanges] = useState(true);
  let [numPgsChanges, setNumPgsChanges] = useState();

  let [changeLogs, setChangeLogs] = useState();
  let [curPageChanges, setCurPageChanges] = useState(1);

  const { instance, accounts } = useMsal();
  const request = {
    scopes: ["User.Read"]
  };

  const numPerPage = 10;

  const handlePageClick = (itemClicked) => {
    setCurPageChanges(itemClicked);
  }

  const formattedLogs = (responseArray) => {
    return responseArray.map( (thisItem) => {
        return {
            when: thisItem.when.raw ? getDateAndTimeString(thisItem.when.raw): '',
            what: thisItem.what.raw ? thisItem.what.raw: '',
            where: thisItem.where.raw ? thisItem.where.raw: '',
            who:  thisItem.who.raw ? thisItem.who.raw: '',
            was: thisItem.was.raw ? thisItem.was.raw: '',
            willBe: thisItem.will_be.raw ?thisItem.will_be.raw : ''
        }
    });
};

  const getDateAndTimeString = (dateObj) => {
    let thisDateObj = new Date(dateObj);

    let thisDate = thisDateObj.toLocaleDateString('en-us');
    let thisTime = thisDateObj.toLocaleTimeString('en-us');

    return thisDate + ' ' + thisTime;
  }

  const loadTheChanges = async (pgNum, accToken) => {
    setLoadingChanges(true);

    let resp = await fetch(`${process.env.REACT_APP_WEB_API}/api/logs/changes${pgNum ? '?page=' + pgNum : ''}`, {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + accToken.accessToken
      }
    });

    if (resp.ok) {
      let rspJSON = await resp.json();
      setChangeLogs(rspJSON);
      setNumPgsChanges(rspJSON.meta.page.total_pages);
    } 

    setLoadingChanges(false);
    setLoadChanges(false);
  }


  useEffect(() => {
    (async () => {
      // only track if on prod
      if(process.env.REACT_APP_ENV === 'PROD' ) {
        let theAccToken = await utils.getAccessToken(instance, accounts[0], request);
        loadTheChanges(curPageChanges, theAccToken);
      }

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadChanges, curPageChanges]);

  return (
    preview ?
      <div>
        preview mode
      </div>
      :
      <Container>
        <Row>
          <Col>
            <h4>Change Logs</h4>
            {
              loadingChanges ?
                <div className='text-center'>
                  <Spinner animation="border" />
                </div>
                :

                changeLogs && changeLogs.results && changeLogs.results.length > 1 ?
                  <>
                    <Grid
                      className={{
                        td: 'logtable__cell',
                        table: 'logtable changes'
                      }}
                      data={formattedLogs(changeLogs.results)} />

                    <div className="text-center">
                      <Pagination
                        total={(numPgsChanges * numPerPage)}
                        onChange={(item) => handlePageClick(item)}
                        pageSize={numPerPage}
                        current={curPageChanges}
                        locale={localeInfo} />
                    </div>
                  </>
                  :
                  <Row>
                    <Col>No Changelogs found</Col>
                  </Row>
            }
          </Col>
        </Row>
      </Container>
  )
}

export default DataChangesLogs