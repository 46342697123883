import React, { useState, useEffect } from 'react';
import Board, { moveCard } from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import { useIsAuthenticated } from '@azure/msal-react';
import NoIntervention from './NoIntervention';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import LBKanbanCard from '../KanbanSpecific/LBKanbanCard';

function MarketActionsKanbanView({ marketID, seperateByField, marketList, customFieldsIndex, statusList, onUpdate, tenant, handleReload, handleShowIntModal }) {
  const isAuthenticated = useIsAuthenticated();

  const [board, setBoard] = useState();
  const [loading, setLoading] = useState(true);
  const [boardLoading, setBoardLoading ] = useState(true);

  const abbreviateText = (text, lengthToAbbrev) => {
    let newText = text.length >= (lengthToAbbrev - 3) ? (text.substring(0, lengthToAbbrev-3) + '...' ) : text;
    return newText;
  }

  if(!seperateByField || seperateByField === ''){
    seperateByField = 'status';
  }


  const getMarketInfo = (marketList, seperateByField) => {
    let newBoard;

    if(marketList && marketList.length >= 1) {
      marketList.forEach((market) => {
     
          newBoard = { columns: []};
          newBoard.columns = getBoardColumns(market, seperateByField, statusList, customFieldsIndex);
      });


      newBoard.columns = putCardsIntoArrayUnderField(newBoard.columns, marketList, seperateByField);
  
      setBoard(newBoard);
    }
    
  }

  const getCustomFieldValuesForColumns = (listOfFields, customFldName ) => {
    
    if(listOfFields.length < 1){
      return [];
    }
    
    const cfObj = utils.getCustomFieldObjByName(listOfFields, customFldName);

    if(cfObj === null){
      return [];
    }

    if(cfObj.hasOwnProperty('type_config') && cfObj.type_config.hasOwnProperty('options')){
      return cfObj.type_config.options.map( (thisOption) => {
        let theValToUse = cfObj.type === 'labels' ? thisOption.label : thisOption.name;
        let theIDtoUse = cfObj.type === 'labels' ? thisOption.id : thisOption.name;
        return ({ id: theIDtoUse, title: theValToUse.toUpperCase(), cards: [] });
      })
    } else {
      return [];
    }
  }

  const updatedData = (id, fieldName, value) => {
    let turnValToArr = [];
    if(fieldName === 'hmfCategory') {
      turnValToArr.push(value)
      value = turnValToArr
    }

    onUpdate({ id: id, field: fieldName, value: value });
  }

  const getOptionsForLabelTypes = (customFieldListItem) => {
    let retObj ={};
    customFieldListItem.type_config.options.forEach( (thisOpt) => {  
      if(thisOpt.label) {
        retObj[thisOpt.id] = thisOpt.label.toLowerCase();
      }

      if(thisOpt.name) {
        retObj[thisOpt.id] = thisOpt.name.toLowerCase();
      }
      
    });

    return retObj;
  }

  const getAllPossibleValuesInIntsForColumnsByFieldName = (intList, fldName) => {
    let valuesNameList = [];

    intList.forEach( (thisInt) => {
      const customFieldsList = thisInt.hasOwnProperty('custom_fields') ? thisInt.custom_fields : [];

      if(customFieldsList.length > 0){
        const cfObj = utils.getCustomFieldObjByName(customFieldsList, fldName);

        if( cfObj !== null && cfObj.hasOwnProperty('value')){
          valuesNameList.push(cfObj.value);
        } 
      }
    });

    if(valuesNameList.length < 1){
      return [];
    }

    return Array.from(new Set(valuesNameList));
  }

  const getBoardColumns = (markets, fieldToSeparate, statusList, customFieldIdx) => {
    //this gets the list of possible iterations of a particular field, default is "status", but could be technically any field on intervention
    let returnedArray = [{ id: 'notSpecified', title: 'Not specified', cards: []}];
    if(!fieldToSeparate || fieldToSeparate === 'status') {
      //get market.statuses
        statusList.forEach((status) => {
          returnedArray.push({ id: status.status, title: utils.capitalCasing(status.status), cards: [] });
        })
 
    } else {
      //get a list of all possible values under the specific field - look in the first intervention custom fields if not available on market.
      let theRetArr;
      switch (fieldToSeparate) {
        case 'priority':
          theRetArr = getCustomFieldValuesForColumns(customFieldIdx, 'Priority');
          returnedArray = returnedArray.concat(theRetArr);
          break;
        case 'hmfCategory':
          theRetArr = getCustomFieldValuesForColumns(customFieldIdx, 'HMF Category');
          returnedArray = returnedArray.concat(theRetArr);
          break;
        case 'priorInnovation':
          theRetArr = getCustomFieldValuesForColumns(customFieldIdx, 'Prioritized Innovation');
          returnedArray = returnedArray.concat(theRetArr);
          break;
        case 'supplier':
          //this is a different case, we have to get a set of all the values in the interventions lsit
          let arrOfValues = getAllPossibleValuesInIntsForColumnsByFieldName(customFieldIdx, 'Supplier');
          returnedArray = returnedArray.concat(arrOfValues.map( (thisCol) => {
            return ({ id: thisCol, title: thisCol.toUpperCase(), cards: [] });
            }));
          break;
        default: 
          break;
      }
    }

    return returnedArray;

  }

  const getHashMapOfFieldValuesToIndexesInColArray = (brdColArray) => {
    //this creates a hashmap for fast lookup on the indexes of the various field values (statuses, usually)
    //ie: goes from [{ status: 'not started' },...] to {'not started' : 0,...}
    let tempObj = {};
  
    for(let x=0; x < brdColArray.length; x++) {
      tempObj[brdColArray[x].title.toLowerCase()] = x;
    }

    return tempObj;
  }

  const putCardsIntoArrayUnderField = (colArrayToAddTo, interventionList, fieldToSeparate) => {
    //go through the interventions List, and put the card into the appropriate cards list

    let fieldNameToUse = fieldToSeparate;
    let labelsHashMap;

    let tempHashMap = getHashMapOfFieldValuesToIndexesInColArray(colArrayToAddTo);

    if(fieldToSeparate === 'priority'){
      fieldNameToUse = 'Priority';
    }

    if(fieldToSeparate === 'hmfCategory') {
      fieldNameToUse = 'HMF Category';
      labelsHashMap = getOptionsForLabelTypes(utils.getCustomFieldObjByName(customFieldsIndex, fieldNameToUse));
    }

    if(fieldToSeparate === 'priorInnovation') {
      fieldNameToUse = 'Prioritized Innovation';
      labelsHashMap = getOptionsForLabelTypes(utils.getCustomFieldObjByName(customFieldsIndex, fieldNameToUse));
    }

    interventionList.forEach( (thisInt) => {
      //status is weird because it has a status inside of status object in intervention, so special

      let valueToUse;
      if(fieldNameToUse === 'status'){
        valueToUse = thisInt.status;
      } else {
        if(thisInt.hasOwnProperty(fieldNameToUse) && fieldNameToUse !== 'Priority'){ //there's a priority field in the main obj we don't want to use
          valueToUse = thisInt[fieldNameToUse];
        } else {
          if(thisInt.hasOwnProperty(CONSTANTS.MARKET_ACTIONS_KANBAN_CUSTOM_TO_FLAT[fieldNameToUse])) {
              valueToUse = thisInt[CONSTANTS.MARKET_ACTIONS_KANBAN_CUSTOM_TO_FLAT[fieldNameToUse]];
              
            } else {
              valueToUse = utils.getCustomFieldObjByName(thisInt.custom_fields, fieldNameToUse).hasOwnProperty('value') ? utils.getCustomFieldObjByName(thisInt.custom_fields,fieldNameToUse).value : 'No Value Specified';
            }
            
        }
      }

      if(fieldNameToUse === 'Priority' && valueToUse !== null){
        
        //reverse match the label for priority value
        let newValue = CONSTANTS.MARKET_ACTIONS_REVERSE_PRIORITY[valueToUse.toString()];
        valueToUse = newValue;

      }

      if(fieldNameToUse === 'HMF Category' && valueToUse !== null){
        //reverse match the label for priority value
        let newVal = valueToUse;
        valueToUse = labelsHashMap[newVal];
      }

      if(fieldNameToUse === 'Prioritized Innovation' && valueToUse !==null ) {
        valueToUse = CONSTANTS.MARKET_ACTIONS_REVERSE_PRIOR_INNOVATION[valueToUse].toLowerCase();
      }

      if(tempHashMap.hasOwnProperty(valueToUse)) {
        colArrayToAddTo[tempHashMap[valueToUse]].cards.push({
          id: thisInt.id,
          due_date: thisInt.due_date,
          title: abbreviateText(thisInt.task_name, 150),
          description: thisInt.description ? abbreviateText(thisInt.description, 200) : '',
          market_name: thisInt.market_name,
          tooltip: thisInt.task_name,
          hmf_category: thisInt.hmf_category,
          assigned_to: thisInt.assigned_to,
          archived: thisInt.archived,
        });
      } else {
        colArrayToAddTo[0].cards.push({
          id: thisInt.id,
          due_date: thisInt.due_date,
          title: abbreviateText(thisInt.task_name, 150),
          description: thisInt.description ? abbreviateText(thisInt.description, 200) : '',
          market_name: thisInt.market_name,
          tooltip: thisInt.task_name,
          assigned_to: thisInt.assigned_to,
          archived: thisInt.archived,
          hmf_category: thisInt.hmf_category,
        });
      }
    })
    setBoardLoading(false);
    return colArrayToAddTo;
  }

  const setIDByField = (fieldPicked, idToSet) => {
    let idReturned;

    switch( fieldPicked ) {
      case "priority" :
        idReturned = CONSTANTS.MARKET_ACTIONS_PRIORITY_UPPERCASE[idToSet.toUpperCase()];
        break;
      default:
        idReturned = idToSet;
        break;
    }

    return idReturned;
  }

  const updateMarketList = (list, card, to, from, field) => {
    let tempList = [...list];
    let fieldName =CONSTANTS.MARKET_ACTIONS_GROUP_BY[field]

    tempList.map((int) => {
      if (int.id === card.id) {
        //problem here - the ID set is the text value, not
          int[fieldName] = setIDByField(fieldName, to.toColumnId.toLowerCase());
          return tempList
      
      }
      return tempList;
    })
    
  }

  const onBoardChange = (_card, destination) => {

    //sets the field to change based on the field that we specify to columnize and the value to change to based on the column ID
    let theVal = destination.toColumnId;

    if(seperateByField.toLowerCase() === 'priority'){
      theVal = CONSTANTS.MARKET_ACTIONS_PRIORITY[destination.toColumnId];
    }

    if(seperateByField.toLowerCase() === 'priorinnovation') {
      theVal = CONSTANTS.MARKET_ACTIONS_PRIOR_INNOVATION[destination.toColumnId]
    }

    let changeObject = { taskId: _card.id};
    
    if(destination.toColumnId === 'notspecified'){
      changeObject[seperateByField] = null;
    }else{
      changeObject[seperateByField] = theVal;
    }
    //raise the update to the parent
    updatedData(_card.id, seperateByField, theVal);

  }

  function handleCardMove(_card, source, destination) {
    
    onBoardChange(_card, destination);
    
    const updatedBoard = moveCard(board, source, destination);
    
    setBoard(updatedBoard);
    updateMarketList(marketList, _card, destination, source, seperateByField)
  }

  useEffect(() => {
    if (isAuthenticated) {
      try {
        (async () => { 
          setLoading(true);
          getMarketInfo(marketList, seperateByField);
          setLoading(false);
        }
        )()
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    //eslint-disable-next-line
  }, [isAuthenticated, seperateByField, marketList])

  return (
    <>
      { 
      !loading && marketList && marketList.length > 0 ?
        !boardLoading && board ?
          <>
            <Board
              onCardDragEnd={handleCardMove}
              disableColumnDrag
              allowRemoveLane 
              renderCard={(card, { removeCard, dragging }) => (
                <LBKanbanCard
                  id={card.id} 
                  title={card.title}
                  description={card.description} 
                  market_name={card.market_name} 
                  tenant={tenant}
                  tooltip={card.tooltip}
                  assigned_to={card.assigned_to}
                  archived={card.archived}
                  hmfCategory={card.hmf_category}
                  handleReload={handleReload}
                  marketID={marketID}
                  handleShowIntModal={handleShowIntModal}
                  marketList={marketList}
                /> 
              )}>
              {board}
            </Board>
          </>
        : <p>Crunching Board items - one minute</p>
      : 
      <NoIntervention 
        marketID={marketID}
        tenant={tenant}
        handleShowIntModal={handleShowIntModal}
      />
      }
      </>
  );
}

export default MarketActionsKanbanView;