import React, { useEffect, useState } from 'react';
import { Button} from 'react-bootstrap'
import Select from 'react-select';

import '../../../styles/layout/_layout.scss'
import ObjectSelector from '../ObjectSelector'
import CONSTANTS from '../../../constants';
import utils from '../../../utils';
import EnumSelector from '../EnumSelector';

function ProductEditorDrugSubstanceRow({product, setProduct, apiKey, organizations, disableProductEdits, addDrugSubstance, index}) {
    const [currOrg, setCurrOrg] = useState([])
    const [currProdLoc, setCurrProdLoc] = useState([])

    useEffect(() => {
        async function getOrganization() {
            let id = product.drug_substance[index].org_id
            if (id === null) {
              setCurrOrg(CONSTANTS.SHELL_ORGANIZATION)
            } else {
              const result = await utils.getData("get_organization", apiKey, "?organization_id=" + id)
              if (result !== null) {
                setCurrOrg(result)
                setCurrProdLoc(result.production_locations.find(x => x.production_location_id === product.drug_substance[index].production_location_id))
              } else {
                alert("Data failed to load")
                setCurrOrg(CONSTANTS.SHELL_ORGANIZATION)
              }
        }}
        getOrganization()
    }, [product, apiKey, index])

    function setRowDrugSubstance(org, i) {
        // Set the organiztion in a row of organizations in the bottom right of the UI
        const drug_substance = product.drug_substance

        if (org === null){         
            drug_substance[i].org_id = null
        } else{
            drug_substance[i].org_id = org.org_id
        }
        drug_substance[i].product_id = product.product_id
        drug_substance[i].production_location_id = null
        setProduct({
            ...product,
            drug_substance:drug_substance
        })
        setCurrOrg(org)
        setCurrProdLoc(null)
    }

    function setRowProductionLocation(prodLoc, i) {
        setCurrProdLoc(prodLoc)
        // set the production location in a row of drugSubstances
        const drug_substance = product?.drug_substance
        if (prodLoc === null) {
            drug_substance[i].production_location_id = null
        } else {
            drug_substance[i].production_location_id = prodLoc.value
        }
        setProduct({
            ...product,
            drug_substance:drug_substance
        })
    }
    
    function deleteDrugSubstance(index) {
        const drug_substance = [...product.drug_substance]
        drug_substance.splice(index, 1)
        setProduct({...product, drug_substance: drug_substance})
    }

    return (
        <tr key={index}>
            <td className='width-45'>
                <ObjectSelector
                    apiKey = { apiKey }
                    data={organizations}
                    obj={currOrg}
                    onChange={(e) => setRowDrugSubstance(e, index)}
                    isClearable={true}
                    type='organization'
                    disabled={disableProductEdits}
                />
            </td>
            <td>
                <Select 
                    options={currOrg?.production_locations?.map((c) => {return {
                        value: c.production_location_id,
                        label: c.location_name
                        }})}
                    isClearable={true}
                    onChange={(e) => {setRowProductionLocation(e, index)}}
                    value={{value: currProdLoc?.production_location_id,
                            label: currProdLoc?.location_name
                    }}
                />
            </td>
            <td>
                <EnumSelector
                    enum_type={'antigen'}
                    apiKey={apiKey}
                    value={product.drug_substance[index].antigens?.map((e) => {return e.antigen})}
                    isMulti={true}
                    isClearable={true}
                    disableCreate={true}
                    onChange={((e) => {
                        let drug_substance = [...product.drug_substance]
                        drug_substance[index].antigens = e.map((a) => {return {
                            drug_substance_detail_id: drug_substance[index].drug_substance_detail_id,
                            antigen: a.label
                        }})
                        setProduct({...product, drug_substance: drug_substance})
                    })}
                />
            </td>
            <td className='right-align'>
                {disableProductEdits? null:
                    <Button 
                        className='delete-btn'
                        size="sm"
                        variant="danger" 
                        onClick = {() => deleteDrugSubstance(index)}>
                        -
                    </Button>
                }
            </td>
        </tr>
    )
}

export default ProductEditorDrugSubstanceRow