import React from 'react';
import CONSTANTS from '../constants';

function AppTitleBlock({ tenant, appType }) {

    //look up app information from tenant.fields.apps by appType

    let filteredAppArray = tenant.fields.apps.filter( (thisApp) => {
        return thisApp.fields.apptype === appType;
    });

    let appBlock = filteredAppArray.length > 0 ? filteredAppArray[0] : { fields: {apptype: '', appDescription: null} };

    return (
        <>
            <h1 className='h1 m-b-start-08 m-b-end-08'>{ appBlock.fields.appAlternateTitle ? appBlock.fields.appAlternateTitle : CONSTANTS.APPNAMES_BY_PATH[appBlock.fields.apptype] }</h1>
            {
                appBlock.fields.appDescription ?
                    <div dangerouslySetInnerHTML={{__html: appBlock.fields.appDescription }} />
                : null
            }
        </>
    )
}

export default AppTitleBlock