import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import PMTtableConfig from './PMTtableConfig';

function PMTTable({tableHeaderConfig, appName, formattedList, handleShowModal, handleListUpdate, apiKey, configType, organization, assigneesList, priorities, hmfCategory=null, vaccineRoadmap=null, uniqueObjectives=null}) {

    let [ listToShow, setListToShow ] = useState(formattedList);

    // a little cringy, but this is the only way to get the list to update when the formattedList changes
    useEffect(() => { 
        setListToShow(formattedList);
    
    }, [formattedList]);

    return (
        <Row>
            <Col className='table-column' md={12}>
                <div className='table-div'>
                    <table>
                        <thead>
                            <tr>
                                {
                                    tableHeaderConfig.map((column, idx) => (
                                        <th key={idx} className={column.className} >{column.header}</th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listToShow.map((item, idx) => {
                                    return (
                                        <tr key={idx}>
                                            {
                                                PMTtableConfig[configType](appName, handleShowModal, handleListUpdate, organization, assigneesList, priorities, apiKey, hmfCategory, vaccineRoadmap, uniqueObjectives).map((cell, idx) => {
                                                    return (
                                                        <td key={idx} className={cell.className}>{cell.accessor(item)}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Col>
        </Row>
  )
}

export default PMTTable