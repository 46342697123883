import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import utils from '../../utils';


function MarketActionsSummaryPanel({ markets, marketSelections = [], yearsSelections = [] }) {
    //strips the summarized object off the "config call" (called markets)
    //filter out testMarket
    const TEST_MKT_ID = '176457807';

    const withoutTestMarket = (marketsToStrip) => {
        let retObj = {};

        Object.keys(marketsToStrip).forEach((thisMktKey) => {
            if (thisMktKey !== TEST_MKT_ID) {
                retObj[thisMktKey] = marketsToStrip[thisMktKey];
            }
        })

        return retObj;
    }

    let mktsWOTest = withoutTestMarket(markets.summarized);

    const statusKeys = [
        'not started',
        'in progress',
        'delayed',
        'deprioritized',
        'complete'
    ]

    const colorsToStatus = {
        'not started': '#7B8186',
        'in progress': '#0D6EFD',
        'delayed': '#FFC107',
        'deprioritized': '#985FF6',
        'complete': '#479F76'
    };

    const getColorsForPie = item => colorsToStatus[item.id.toLowerCase()];

    const getMarketNameByID = (mktID, marketSrc) => {

        let foundMktArray = marketSrc.filter((thisMkt) => {
            return thisMkt.id === mktID;
        });

        if (foundMktArray.length > 0) {
            return foundMktArray[0].name;
        }

        return `${mktID}`;
    }

    const getInterventionsWithSpecifiedDueDateYears = (mktObj, due_date_array) => {
        //{ interventionID: { due_date_year|no_due_date: { status: count } }}
        //due_date_array example = ['2020', '2021', 'no_due_date']

        let returnedObj = {}

        //return { interventionID: { status: count }}

        for (const intrvntion in mktObj) {
            let thisIntValue = intrvntion;

            for (const dueYear in mktObj[intrvntion]) {
                let thisYearVal = dueYear === 'no_due_date' ? dueYear : parseInt(dueYear);

                if (due_date_array === null || due_date_array.length < 1) {
                    returnedObj[thisIntValue] = mktObj[intrvntion][dueYear];
                } else {
                    
                    if (due_date_array.indexOf(thisYearVal.toString()) > -1) {
                        returnedObj[thisIntValue] = mktObj[intrvntion][dueYear]
                    }
                }
            }
        }

        return returnedObj;
    }

    const capitalizeKeys = (arr) => {

        let firstLetterCapArr = arr.map((item) => {
            return utils.capitalCasing(item);
        })
        return firstLetterCapArr
    }

    //pie chart depends on what markets have been selected
    const getPieChartData = (mktSummaries, mktsToUse, yearsToUse) => {
        //iterate through all the markets and total according to status
        let retData = [];
        let totalledObj = {};

        let filteredMktsKeys;

        if (mktsToUse.length > 0 && !mktsToUse.includes('Select All')) {
            //if they've checked markets/years to compare, only use those in computation
            filteredMktsKeys = Object.keys(mktSummaries).filter((thisKey) => {
                return mktsToUse.indexOf(thisKey) > -1;
            });

        } else {
            //use all if none selected
            filteredMktsKeys = Object.keys(mktSummaries);
        }

        //go through statuses and go through markets summary - if cur market has that status, add the count to totalledObj
        statusKeys.forEach((thisStatusKey) => {
            filteredMktsKeys.forEach((thismktKey) => {

                let fndInts = getInterventionsWithSpecifiedDueDateYears(mktSummaries[thismktKey], yearsToUse);
                

                const testStatus = `${thisStatusKey}`;

                Object.keys(fndInts).forEach( (thisFndInt) => {
                    
                    if (fndInts[thisFndInt].hasOwnProperty(testStatus)) {
   
                        if (totalledObj.hasOwnProperty(testStatus)) {
                            totalledObj[testStatus]++; //increment the counter for this
                        } else {
                            totalledObj[testStatus] = 1; //status not recorded yet, add ti.
                        }
                    }
                });

               
                
            })
        });

        //turn totalledObj into an array of status and count
        retData = Object.keys(totalledObj).map((thisStatusItem) => {
            return {
                id: utils.capitalCasing(thisStatusItem),
                label: utils.capitalCasing(thisStatusItem),
                value: totalledObj[thisStatusItem]
            }
        });

        return retData;

    }

    //bar chart is based on all markets
    const getBarChartDataForCross = (mktsToUse, mktsReference, yearsSelected) => {
        let retData = [];
        let theRetObj = {};

        let filteredMktsKeys;

        if (mktsToUse.length > 0 && !mktsToUse.includes('Select All')) {
            //if they've checked markets/years to compare, only use those in computation
            filteredMktsKeys = Object.keys(mktsReference).filter((thisKey) => {
                return mktsToUse.indexOf(thisKey) > -1;
            });

        } else {
            //use all if none selected
            filteredMktsKeys = Object.keys(mktsReference);
        }

        filteredMktsKeys.forEach( (mktKey) => {
            let fndInts = getInterventionsWithSpecifiedDueDateYears(mktsReference[mktKey], yearsSelected);
            
            let vaccineFromMarketName = getMarketNameByID(mktKey, markets.lists).split(' Market')[0];

            theRetObj = { vaccine: vaccineFromMarketName }
            
            statusKeys.forEach( (thisStatus) => {
                const testStatus = `${thisStatus}`;
                theRetObj[testStatus] = 0;
            })

            //get the data to put on the vaccine obj
            statusKeys.forEach( (thisStatusKey) => {
                const testStatus = `${thisStatusKey}`;

                let totalledObj = {};

                Object.keys(fndInts).forEach( (thisFndInt) => {
                    if (fndInts[thisFndInt].hasOwnProperty(testStatus)) {
   
                        if (totalledObj.hasOwnProperty(utils.capitalCasing(testStatus))) {
                            
                            totalledObj[utils.capitalCasing(testStatus)]++; //increment the counter for this
                        } else {
                            totalledObj[utils.capitalCasing(testStatus)] = 1; //status not recorded yet, add ti.
                        }
                    }
                });

                theRetObj = {...theRetObj, ...totalledObj};
            })

            retData.push(theRetObj);
        })
        
       return retData;
        
    }


    const getChartTitle = (marketSelections, marketSrc, yrsSelections) => {
        let mktsPortion, yearsPortion;
        
        if(marketSelections.includes('Select All') || marketSelections.length < 1) {
            mktsPortion = "All";
        } else {
            mktsPortion = marketSelections.length;
        }

        if(yrsSelections.includes('Select All') || yrsSelections.length < 1) {
            yearsPortion = "All";
        } else {
            yearsPortion = yrsSelections.length;
        }

        return `${mktsPortion} Markets, ${yearsPortion} Years Selected`
    }


    return (
        <>
            <Row className="m-b-end-08">
                <Col style={{ height: '400px' }} className="ma-compilation-panel responsive-pie" sm={6}>
                    {/* pie chart of selected markets */}
                    <h4 style={{ textAlign: 'center' }}>{ getChartTitle(marketSelections, markets, yearsSelections) }</h4>
                    <ResponsivePie
                        data={getPieChartData(mktsWOTest, marketSelections, yearsSelections)}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        colors={ getColorsForPie }
                        borderWidth={2}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}
                        legends={[]} />
                </Col>

                <Col style={{ height: '400px' }} className="ma-compilation-panel responsive-bar" sm={6}>
                    {/* bar chart for cross market */}
                    <h4 style={{ textAlign: 'center' }}>Cross Market Summary : { getChartTitle(marketSelections, markets, yearsSelections) }</h4>
                    <ResponsiveBar
                        data={getBarChartDataForCross(marketSelections, mktsWOTest, yearsSelections)}
                        keys={capitalizeKeys(statusKeys)}
                        groupMode="stacked"
                        indexBy="vaccine"
                        margin={{ top: 0, right: 135, bottom: 80, left: 100 }}
                        padding={0.1}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={ getColorsForPie }
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 2,
                            tickPadding: 2,
                            tickRotation: 30,
                            legend: 'Vaccine',
                            legendPosition: 'middle',
                            legendOffset: 50,
                            truncateTickAt: 25
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: 0
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]} />
                </Col>
            </Row>
        </>
    )
}

export default MarketActionsSummaryPanel