import CONSTANTS from "../../constants";
import { getCode, getName } from 'country-list';
import getCountryISO2 from "country-iso-3-to-2";


const ircUtils = {

    formatForNewsList : (rawList) => {

        return rawList.map((thisArticle) => {
            return (
                {
                    fields: {
                        title: thisArticle.title.raw,
                        body: thisArticle.body.raw,
                        link: '/ce_news/'+thisArticle.id.raw,
                        datepublished: thisArticle.date_published.raw
                    }
                })
        });

    },

    getThreeLetter: (ctryName, countriesList) => {
        let adjCtryName = ctryName;
    
        if( CONSTANTS.COUNTRY_ADAPTER.toCE.hasOwnProperty(ctryName) ) {
            adjCtryName = CONSTANTS.COUNTRY_ADAPTER.toCE[ctryName];
        }
    
        return countriesList.find( (itemInList) => {
            //add transform back to our three letters here, ie: DRC / CAF / Cote d'Ivoire
            
            return itemInList.country === adjCtryName;
        });
    },


    createJVector: (countryList) => {
        let retObj = {};
    
        countryList.forEach( (itemInList) => {
    
            let countryName = itemInList.country;
    
            //check our adapter to see if this is a mismatched case
            if(CONSTANTS.COUNTRY_ADAPTER.toJvector.hasOwnProperty(itemInList.country)){
                countryName = CONSTANTS.COUNTRY_ADAPTER.toJvector[itemInList.country];
            } 
    
           retObj[getCode(countryName)] = 1;
        })
    
        return retObj;
    },

    stripNewsArticles : (articleArray) => {
        const regex = /<(?!a|\/a|p|\/p).*?>/gi;

        articleArray.forEach((thisArticle) => {
            thisArticle.content.rendered.replace(regex, '');
        });

        return articleArray;
    },

    getNewsFromWP : async (iso) => {

        let TwodigitCountryISO = getCountryISO2(iso);
        let countryName = getName(TwodigitCountryISO);

        try {
            let resp = await fetch(`${process.env.REACT_APP_WEB_API}/api/newscontentbytag?newssource=CE&tag=${countryName}`,
                {
                    headers: {
                        "x-functions-key": process.env.REACT_APP_NEWS_CONTENT_TAG
                    }
                });

            let data = await resp.json();
            return ircUtils.stripNewsArticles(data);

        } catch (error) {
            console.error('Error fetching news articles: ', error);
            return [];
        }
    }

}

export default ircUtils;