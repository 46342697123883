import React, {useState, useEffect, useRef} from 'react';
import { Button, Row, Col, Spinner } from 'react-bootstrap'
import { ArrowUp } from 'react-bootstrap-icons';
import Select from 'react-select';

import utils from '../../../utils';
import Turns from './Turns';
import aiUtils from './aiUtils';
import '../../../styles/layout/_layout.scss';

const LLM_SERVICE_URL = process.env.REACT_APP_LLM_SERVICE_URL
const LLM_SERVICE_API_KEY = process.env.REACT_APP_LLM_SERVICE_API_KEY


const headers = {
  'Content-Type': 'application/json',
  'access_token': LLM_SERVICE_API_KEY
}

const postRequestOptions = {
  method: 'POST',
  headers:headers
}

const getRequestOptions = {
  method: 'GET',
  headers:headers
}

function OpenAIService({apiKey, userName, assistantId, assistantName, welcomeMessage, description, appClass}) {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [turns, setTurns] = useState([]);
  const [threadId, setThreadId] = useState();
  const [rating, setRating] = useState('');
  const [notes,setNotes] = useState('');




  const chatEndRef = useRef(null);

  // Scrolls the chat end into view
  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  }

  // Scroll to bottom whenever a new message is added (turns array changes)
  useEffect(() => {
    scrollToBottom();
  }, [turns]);



  async function createNewThread(){
    const threadId = await fetch(`${LLM_SERVICE_URL}create_thread`, getRequestOptions)
    let threadIdJson = await threadId.json()
    setThreadId(threadIdJson);
  }


  useEffect(()=>{
    createNewThread();
  },[]);
  
  async function insertLog(){
    const log = {
      thread_id:threadId,
      user:userName,
      rating:rating,
      notes:notes
    }
    const result = await utils.upsert('insert_ai_log', apiKey, log);
    if(!result){
      window.alert('Failed to insert log!');
    } else{
      createNewThread();
      setTurns([]);
      setQuery('');
      setRating('');
      setNotes('');
    }
  }

  async function getLLMResponse() {
    setLoading(true);
    // Create a run. This is basically sending a new message to the ai assistant.
    const message = {message:query, thread_id: threadId, assistant_id:assistantId}
    const postReqOpts = {...postRequestOptions, body:JSON.stringify(message)}
    let runId = await fetch(`${LLM_SERVICE_URL}send_message`, postReqOpts)
    let runIdJson = await runId.json();
    // Now that the query is used in the API call, reset it
    setQuery('');

    // Check in on the status of the run
    let runStatus = await fetch(`${LLM_SERVICE_URL}get_run_status?run_id=${runIdJson}&thread_id=${threadId}`, getRequestOptions);
    let runStatusJson = await runStatus.json();

    // Now check in again every second until the run is finished
    while (runStatusJson === "in_progress" || runStatusJson === "queued") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      runStatus = await fetch(`${LLM_SERVICE_URL}get_run_status?run_id=${runIdJson}&thread_id=${threadId}`, getRequestOptions);
      runStatusJson = await runStatus.json();
    }
    
    const localTurns = await aiUtils.getThread(threadId);
    if (localTurns && localTurns.length > 0){
      setTurns(localTurns)
    }
    
    setLoading(false);    
  }

  function handleQueryChange(e){
    // Let the user type a query, but ignore the enter character if typed.
    let cleanQuery = e.target.value.replace(/(\r\n|\n|\r)/gm, "");
    setQuery(cleanQuery);
  }


  async function handleEnterPress(e){
    // Handle the user pressing enter by getting the LLM Response
    let code = e.keyCode || e.which;
    if (code === 13){
      await getLLMResponse();
    }
  }

 


  return (
    <div className="chat-wrapper p-i-10 p-b-08">
      <div className="chat-container">
        {Array.isArray(turns) && turns.length === 0 && ( // hide intro if conversation has started
          <div className="intro-section">
            <div className='header p-b-end-06'>
              <h1>{welcomeMessage}</h1>
            </div>
            <div className='intro'>
              {description}
            </div>
          </div>
        )}

        {/* Chat History Display */}
        <Turns turns={turns} appClass={appClass} />
        {/* Loading Spinner */}
        {loading && (
          <Row className="extra-padded-row m-b-end-04">
            <Col>
              <span className="loading-message label-medium">
                <Spinner animation="border" />
                {`${assistantName} is busy researching...`}
              </span>
            </Col>
          </Row>
        )}

        {/* Message input bar */}
        <div className="message-container">
          <div className="input-wrapper">
            <input
              className="message-bar"
              value={query}
              onChange={handleQueryChange}
              onKeyDown={handleEnterPress}
              placeholder={`Message ${assistantName}`}
            />
            <button className="btn-send" onClick={getLLMResponse}>
              <ArrowUp />
            </button>
          </div>
          {/* "Can make mistakes" line */}
          <div className="mistake-message">
            <Row>
              <p className="label-medium align-center">{`${assistantName} can make mistakes.  Please check important information.`}</p>
            </Row>
          </div>
        </div>
      </div>

      {/* Feedback Section */}
      {turns.length > 1 && (
        <>
          <Row>
            <Col>
              <div className="input-text">Please rate this response:</div>
              <Select
                options={[
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                ]}
                value={{ label: rating, value: rating }}
                onChange={(e) => setRating(e.value)}
              />
            </Col>
            <Col>
              <div className="input-text">Do you have anything to share about this response?</div>
              <textarea
                className="textarea"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </Col>
          </Row>
          <Row className="p-b-start-04">
            <Col>
              <Button className="btn-primary" onClick={insertLog} disabled={!rating}>
                Submit Feedback
              </Button>
            </Col>
          </Row>
        </>
      )}

    </div>
  );

}


export default OpenAIService;