import React, {useState, useEffect} from 'react';
import PopupModal from './PopupModal';
import utils from '../utils';

function AlertWrapper({tenant, token}) {

  const [loading, setLoading] = useState(false);
  const [pendingUsers, setPendingUsers] = useState();
  let tenID = utils.getForceTenantValue();

  

      // On page load gets all pending users and sets them in state
      const getPendingUsers = async () => {

        if(tenID === null && tenant) {
          tenID = tenant.tenant.fields.aDGroupID;
        }

        let addedParams = `tenid=${tenID}`;

        setLoading(true);

        try {
            let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/pending-users/${tenID}?${addedParams}`, {
                headers: {
                "Authorization": "Bearer " + token.accessToken
                }
            });


            if(response.ok) {
              let users = await response.json();

              setPendingUsers(users);
            } else {
              setPendingUsers([]);
            }
            
        } catch (error) {
            setPendingUsers([]);
        }

        setLoading(false);
    };

    useEffect( () => {
      try {
          (async () => {
              await getPendingUsers();
          })()
      } catch (err) {
          console.log(err)
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFromPendingUser = (user) => {
    let newList = Array.from(pendingUsers);
    newList = newList.filter((item) => item.id !== user.id);
    setPendingUsers(newList);
  }

  const addUserHandler = async (user) => {
    removeFromPendingUser(user);

    if(tenID === null && tenant) {
      tenID = tenant.tenant.fields.aDGroupID;
    }

    let addedParams = `tenid=${tenID}`;
    
    try {
        await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/pending-users/${user.id}?${addedParams}`, {
            headers: {
            "Authorization": "Bearer " + token.accessToken
            },
            method: 'POST',
            body: JSON.stringify(user)
        })
    } catch (error) {
        console.log(error)
    }
};

  return (
    !loading && pendingUsers && pendingUsers.length > 0 ?

      pendingUsers.map((item, idx) => {
        return (
          <PopupModal
            num={pendingUsers.length} 
            idx={idx} 
            specialParams={item} 
            showModal={true} 
            title={'Workspace Access Request'} 
            body={'has requested to access this workspace'} 
            bodyDiv={'You can view this request in the'} 
            buttonNo={'Ignore Message'} 
            buttonYes={'Approve User'} 
            hideBtnNo={true} 
            hideBtnYes={true} 
            navRoute={'/settings'} 
            navPage={'settings'} 
            submitHandler={addUserHandler}
          />
        )
      })
    : null
  )
}

export default AlertWrapper