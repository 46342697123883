import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Row } from 'react-bootstrap';
import TagDisplay from './../TagDisplay';
import CONSTANTS from '../../constants';
import utils from '../../utils';

function IntellFormattedList({ engineName, list=null, searchTerm=null }) {

  const displaySourceType = (srcType) => {

    if (srcType) {
      CONSTANTS.SOURCE_TYPE.filter((item) => {
        if(srcType === item.value) {
          srcType = item.label
        }
        return srcType;
      })
      return srcType;
    } else {
      return '';
    }
  }

  return (
    list ?
      list.map((thisItem, index) => {
        
        return (
          <>
                <Row className="app-list-item approved-false">
                  <p className='article-date secondary-link-plain'>{utils.getDateString(thisItem.fields.datepublished, 'long')}</p>
                    <LinkContainer to={`/intelligence/reports/${thisItem.contentID}`}>
                      <h2 className='add-pointer h2' >
                      {
                        thisItem.fields.archived && thisItem.fields.archived === 'true' ?
                          `*Archived* ${thisItem.fields.title ? thisItem.fields.title : ''}`
                        :
                          thisItem.fields.approved && thisItem.fields.approved === "false" ?
                            `*Pending Approval* ${thisItem.fields.title ? thisItem.fields.title : ''}`
                          :
                            thisItem.fields.title ? thisItem.fields.title : ''
                      }
                      </h2>
                    </LinkContainer>
                  <p className='source-type'>{ displaySourceType(thisItem.fields.report_type)}</p> 
                  {/* <p dangerouslySetInnerHTML={{__html: getBodyHTML(thisItem.fields.body) }}></p> */}
                  <TagDisplay 
                    engine={engineName}
                    key={index} 
                    tagList={utils.limitTags(thisItem.fields.tags, searchTerm)} 
                    flatList={true} 
                    searchTerm={searchTerm} />
                </Row>
          </>
        )
      })
      : <div>Sorry, no results.</div>
  )
}

export default IntellFormattedList;
