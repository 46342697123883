import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';

import utils from '../../utils';
import OrganizationEditor from './Organization/OrganizationEditor';
import VaccineEditor from './Vaccine/VaccineEditor';
import DrugEditor from './Drug/DrugEditor';
import IndicationEditor from './Tx/IndicationEditor';

const settings = {
  // repr:(probably pythonic) way that the object is shown in the dropdown
  // Editor: the editor to open when someone clicks edit or new
  // pkField: the primary key of the object being requrested
  undefined: {
    repr: (e)=>{return "error"},
    Editor:null,
    pkField:null,
    endpoint:null
  },
  'organization': {
    repr: (o)=>{ return o.org_name }, 
    Editor:OrganizationEditor, 
    pkField:'org_id', 
    endpoint:'get_organizations'},
  'vaccine': {
    repr: (v)=>{return v.vaccine_subtype}, 
    Editor:VaccineEditor, 
    pkField:'vaccine_id', 
    endpoint:'get_vaccines'},
  'geography': {
    repr: (g)=>{return g.geography_name}, 
    Editor:null, 
    pkField:null, 
    endpoint:'get_geographies'},
  'country': {
    repr: (c)=>{return c.country}, 
    Editor:null, 
    pkField:null, 
    endpoint:'get_countries'},
  'product_characteristic': {
    repr: (pc)=>{return (pc.vaccine_subtype+'-'+ (pc.product_name===null?'-':pc.product_name)+'-'+ 
      (pc.manufacturer===null?null:pc.manufacturer)+ '-'+
      (pc.container_type===null?null:pc.container_type)+'-'+
      (pc.container_quantity===null?null:pc.container_quantity))},
    Editor:null,
    pkField:null,
    endpoint:'get_product_characteristics'
  },
  // Should be CLOSE to how to implement a drug selector.
  'drug': {
    repr: (d)=>{return d.global_data_drug_id+'-'+d.drug_name}, 
    Editor: DrugEditor,   
    pkField:'drug_id', 
    endpoint:'get_drugs'},
  // TODO: Add indication information. I think the repr can just be the indiation_name for now.
  'indications': {
    repr: (ind) => {return ind.indication_name},
    Editor: IndicationEditor,
    pkField: 'indication_id',
    endpoint: 'get_indications'
  },
  'interventions':{
    repr: (i) =>{return i.intervention},
    Editor:null,
    pkField:null,
    endpoint: 'get_interventions'
  }
}


function ObjectSelector({ obj, disabled, data=null, fetchData=true, type, onChange, onSubmit=null, 
                          isClearable=null, className=null, appClass=null, apiKey, isMulti=false}) {
  //obj is the full object structure
  //data is list of options to display in select dropdown
  //type is name of what the selector is used for
  
  const [options, setOptions] = useState([]);
  const [selection, setSelection] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showNew, setShowNew] = useState(false);
  
  const repr = settings[type].repr;
  const Editor = settings[type].Editor;
  const pkField = settings[type].pkField;
  const endpoint = settings[type].endpoint;

  useEffect(() => {
    async function setData(){
      if((!data&&!apiKey)||!repr||!endpoint){
        return
      }
      var localData=[];
      if (data){
        localData = data;
      } else {
        if (fetchData){
          localData = await utils.getData(endpoint, apiKey);
        }
      }
      // This is added to the backend where applicable as well.
      // By having the filter here, it just safeguards to ensure that deleted items don't come through.
      const filteredData = localData.filter((d) => (d.deleted === false || !d.hasOwnProperty("deleted")));
      const dataOptions = filteredData.map((d) => { return { label: repr(d), value: d } });
      dataOptions.sort((a, b) => utils.createStrSortOrder(a.label, b.label));
      setOptions(dataOptions);
    }
    setData()
  }, [data, apiKey, endpoint, repr, fetchData])




  // Sets the initial value of the select
  useEffect(()=>{
    if (obj && repr){
      if (isMulti) {
        setSelection(obj.map((e) =>{return {label: repr(e), value: e}}))
      } else {
        setSelection({label: repr(obj), value: obj})
      }
    }
  // eslint-disable-next-line
  }, [obj])

  const handleChange = (e) => {
    if (e){
      setSelection(e)
      if (isMulti) {
        onChange(e.map((r) => {return r.value}))
      } else {
        onChange(e.value)
      }
    } else {
      setSelection(null)
      onChange(null)
    }
  }
  
  const handleSubmit = (item) => {
    //adds newly created or edited select item as select default value

      if (item) {
        setSelection({label: repr(item), value: item})
      }
    
    setShowEdit(false)
    setShowNew(false)
    onChange(item)

    if(onSubmit){
      onSubmit()
    }
  }

  const closePopup = () => {
    setShowEdit(false)
    setShowNew(false)
  }

  return (
    <div>
      <Row>
        <Col>
          <Select
            className={ className === undefined?"select":className}
            isMulti={isMulti === true}
            options={options}
            onChange={handleChange}
            value={selection}
            isClearable={isClearable===true}
            isDisabled={disabled}
          />
        </Col>
        {Editor&&!disabled?
          <Col xs={2}>
          {
            selection&&!isMulti?
            <Form.Text className = "clickable-text m-i-end-02" 
              onClick = {()=>setShowEdit(true)}
              size = "sm">
              Edit
            </Form.Text>
            :null
          }
          {!isMulti?
            <Form.Text className = "clickable-text" 
              onClick = {()=>setShowNew(true)}
              size = "sm">New</Form.Text>
            : null
          }
          </Col>
          :null
        }
      </Row>
     
     {obj && obj.deleted ?
      <Row className='error'>
        <div className='input-text'>
          <p><strong>Warning:</strong>This {type} has been deleted, please change it.</p>
        </div>
      </Row>
      : null
      }
      {showEdit||showNew?
        <Editor 
          apiKey = {apiKey} 
          id = {showNew?null:obj[pkField]}
          onSubmit={handleSubmit}
          closePopup={closePopup}
          appClass={appClass}
        />:null
      }
    </div> 
  );
}

export default ObjectSelector;