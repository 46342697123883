import React, {useState} from 'react';
import {Button, Table, Row, Col} from 'react-bootstrap'
import EnumSelector from '../EnumSelector';
import { PlusCircle, Trash, ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import Collapse from 'react-bootstrap/Collapse';

function ExternalID({externalIds, onChange, apiKey, errors}) {
  const [open, setOpen] = useState(true);



  function addExternalId(){
    const temp = [...externalIds]
    temp.push({external_data_source:null,external_source_id:null})
    onChange(temp)
  }
  
  function handleChange(value, field, i){
    const temp = [...externalIds]
    temp[i][field] = value
    onChange(temp)
  }

  function handleDelete(i){
    const temp = [...externalIds];
    temp.splice(i, 1);
    onChange(temp)
  }




  return (
    <>
      <Row className='m-b-start-10'>  
        <Col>
          <div className='input-text body-large'><strong>External IDs</strong></div>
        </Col>
      </Row>
      <Table size='sm'>
              <thead className='table-heading'>
                <tr>
                  <th><strong>External Source *</strong></th>
                  <th><strong>External ID * </strong></th>
                  <th className="align-right">
                    <Button onClick={() => setOpen(!open)}
                      aria-controls="collapse-table"
                      aria-expanded={open}
                      className='btn-sm-invisible-dark'
                    >
                {open ? <ChevronUp /> : <ChevronDown />}
              </Button>
            </th>
                </tr>
              </thead>
              <Collapse in={open}>
              <tbody className='table-body'>
                {externalIds?externalIds.map((ei, i) =>{
                    return<tr>
                       <td>
                          <EnumSelector
                            enum_type='ce_external_data_source'
                            onChange={(e) => handleChange(e.label, 'external_data_source', i)}
                            value={ei.external_data_source}
                            apiKey={apiKey}
                          />
                        </td>
                        <td>
                          <textarea
                            className='textarea'
                            value={ei.external_data_source_id}
                            onChange={(e) => handleChange(e.target.value, 'external_data_source_id', i)}
                          ></textarea>
                        </td>
                        
                        <td className='align-right'>
                          <Button className='cancel-btn'
                             onClick={() => handleDelete(i)}
                          >
                            Delete External ID <Trash />
                          </Button>
                        </td>
                      </tr> 
                  })
                  :null
                }
              </tbody>
              </Collapse>
              {open && (
          <tfoot>
            <tr>
              <td colSpan="3" className="align-right">
                <Button onClick={addExternalId}>Add External ID<PlusCircle /></Button>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
      {errors?.map((error, index) => (
        <div key={index} className="error">{error}</div>
      ))}
    </>
  )
}

export default ExternalID;