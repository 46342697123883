import React, { useEffect, useState } from 'react'
import { Row, } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import TagDisplay from './../TagDisplay';
import CONSTANTS from '../../constants';

import utils from '../../utils';

function IntellMockNewReport({article, searchTerm=null, engineName}) {

  const [ tagList, setTagList ] = useState();
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    if(loading) {
      getLimitedCXTags();
      setLoading(false);
    }
  },[loading]);

  const displaySourceType = (srcType) => {

    if (srcType) {
      CONSTANTS.SOURCE_TYPE.filter((item) => {
        if(srcType === item.value) {
          srcType = item.label
        }
        return srcType;
      })
      return srcType;
    } else {
      return '';
    }
  }

  const getLimitedCXTags = async () => {
    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-cx-tags`, {
    })
    let tagsList = await response.json();
    setTagList(tagsList);
  }

  const getTagsToSend = (tags, listOfTags) => {

    let tagsToFilter = tags.split(',');

    let tagsToReturn = listOfTags.value.filter((n) => {
      return tagsToFilter.indexOf(n.value.toString()) !== -1;
    }).map((tag) => {
      return tag.label;
    })

    let tagsToSend = utils.limitTags(tagsToReturn)
    return tagsToSend;
  }

  return (

    article && tagList?
          <>
            <Row className="app-list-item approved-false">
              <p className='article-date secondary-link-plain'>{utils.getDateString(article.reportDate, 'long')}</p>
                <LinkContainer to={`/intelligence/reports/${article.id}`}>
                  <h3 className='add-pointer' >{ `*Pending Approval* ${article.title ? article.title : ''}`}</h3>
                </LinkContainer>
              <p className='source-type'>{ displaySourceType(article.sourceType)}</p> 
              <TagDisplay 
                engine={engineName}
                tagList={getTagsToSend(article.tags, tagList)} 
                flatList={true} 
                searchTerm={searchTerm} />
            </Row>
          </>
      : <div>Sorry, no results.</div>
  )
}

export default IntellMockNewReport