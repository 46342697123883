import React from 'react';
import FieldDisplay from '../FieldDisplay';
import utils from '../../utils';

function CountryProfileFieldWrapper({ tenant,showGenPageTitle, cntryInfo, fields, fieldsDescr }) {

    //this component replaces items in the navDescr with the content from the record

    const countryTokenData = {
        'curYear': new Date().getFullYear(),
        'curYear-1': new Date().getFullYear() - 1,
        'curYear+1': new Date().getFullYear() + 1,
        'Country': cntryInfo.country,
        'countryName': cntryInfo.country,
        'country_name': cntryInfo.country,
    }

    if(fieldsDescr && fieldsDescr.length > 0) {
        if(fieldsDescr[0].hasOwnProperty('subNav') && fieldsDescr[0].subNav.length > 0) {
            //we need to iterate through the subNav items and replace {{fieldName}} and **fieldValue** with the fieldName from the record
            // not ideal as it mismatches the other replacement structure of [[item]] but to prevent full meltdown, we will do it this way
            fieldsDescr[0].subNav.forEach((navItem) => {
                if(navItem.hasOwnProperty('pageItems')) {
                    navItem.pageItems.forEach((pageItem) => {
                        if(pageItem.hasOwnProperty('itemTitle')) {
                            pageItem.itemTitle = utils.replaceTokensInField(pageItem.itemTitle, countryTokenData);
                        }
                        if(pageItem.hasOwnProperty('pBI_Filter') && pageItem.pBI_Filter.length > 0) {
                            pageItem.pBI_Filter.forEach((filterItem) => {
                                if(filterItem.fields && filterItem.fields.filterValues) {
                                    filterItem.fields.filterValues = utils.replaceTokensInFilter(filterItem.fields.filterValues, countryTokenData);
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    if(fields.fields) {
        Object.keys(fields.fields).forEach((key) => {
            if(fields.fields[key]) {
                fields.fields[key] = utils.replaceTokensInField(fields.fields[key], countryTokenData);
            }
        });
    }

    return (
        <FieldDisplay
            tenant={tenant}
            showGenPageTitle={showGenPageTitle}
            fields={fields}
            fieldsDescr={fieldsDescr} 
            isForCountry={cntryInfo} />
    )
}

export default CountryProfileFieldWrapper