import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import UrlDrivenAppDisplay from '../../components/UrlDrivenAppDisplay';

import NavUtils from '../../NavUtils';

function PrtnrProfilesAppPage({ tenant }) {

  const history = useHistory();
  let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.PARTNER_PROFILES);

  if(!hasAccess) {
    history.push('/');
  }

  let [ contentModel, setContentModel ] = useState();
  let [ navDescr, setNavDescr ] = useState();
  let [ loading, setLoading ] = useState(true);
  const { instance, accounts } = useMsal();

  let tenantSlug = tenant.tenant.fields.tenantslug;

  let { slug } = useParams();

  const request = {
      scopes: ["User.Read"]
  };

  const bubbleFieldItems = [
    { field: "yearEstablished", type: 'text', label: 'Established' },
    { field: "headquarters", type: 'text', label: 'Headquarters' }, 
    { field: "numberofCountryOffices", type: 'text', label: 'Country Offices' }, 
    { field: "numberofStaff", type: 'text', label: 'Number of Staff'}
  ];

  const getItemFromList = (itemValToFind, keyToUse, listToLook) => {
    let theFoundArray = listToLook.filter( (thisItem) => {
      return thisItem.fields[keyToUse] === itemValToFind;
    });

    if(theFoundArray.length > 0) {
      return theFoundArray[0]
    }

    return {};
  }

  const getContentModel = async (token) => {
    let extraParams = 'force_id=' + tenant.tenant.fields.aDGroupID;

      let jsonresponse = await utils.getTenantContent(token, CONSTANTS.APPS_BY_APPTYPE.PARTNER_PROFILES, tenantSlug, tenant.ourUserID, extraParams);

      if( jsonresponse.appFields){
          setNavDescr(jsonresponse.appFields);
      }

      let foundItem = getItemFromList(slug, 'slug', jsonresponse.content);

      setContentModel(foundItem);

      setLoading(false);
  }

  useEffect( () => {
      try{
          (async () => {
              let accToken = await utils.getAccessToken(instance, accounts[0], request);
              getContentModel(accToken);
          })()
      } catch(error) {
          console.log(error);
          setLoading(false);
      }
  // eslint-disable-next-line
  },[]);

  return (
    !loading ?
      contentModel ?
        <div className='url-driven-app'>
          <UrlDrivenAppDisplay 
            tenant={tenant}
            logoFieldToShow="logo" 
            content={contentModel}
            navs={navDescr}
            appType={'PARTNER_PROFILES'}
            defaultPage="facts-at-a-glance" 
            displayFieldName={"name"} 
            showRelatedNewsField={slug} 
            bubbleFields={bubbleFieldItems} 
            hasSubItems={true} />
        </div>
      : <div>Content Model Not Found</div>
    : <Spinner animation="border" />

  )
}

export default PrtnrProfilesAppPage;
