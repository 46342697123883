import React from 'react';
import ModalWrapper from '../ModalWrapper';

import utils from '../../utils';

function LeadershipProfileDetailsModal({ profile, show, onHide }) {

    return (
        profile !== null && show === true ?
            <ModalWrapper
                className="profile-popup"
                show={show}
                onHide={() => onHide()}
                profile={profile} >
                <h5>{profile.fields.name}</h5>
                {
                    profile.fields.position ?
                        <h6>{profile.fields.position}</h6>
                        : null
                }
                <div className="leadership-profile-bio" dangerouslySetInnerHTML={utils.getHTML(profile.fields.bio)} />
            </ModalWrapper>
            : null
    )
}

export default LeadershipProfileDetailsModal
