import React from 'react'
import SelectFilter from './SelectFilter'
import { Button, Col, Container, Row } from 'react-bootstrap'
import CONSTANTS from '../../../constants';
import CountryFilter from './CountryFilter';
import SearchFilter from '../../Layout/Filters/SearchFilter';
import DateRangeFilter from './DateRangeFilter';
import { X } from 'react-bootstrap-icons';


function FilterBarElastic({ className=null, apiName, data,clearFilter, metaData, rowsPerPage=10, applyFiltersHandler, filteringDataHandler, curFilters }) {

  //filter handler takes a filter and field 
  function handleFilterChange(field, filter){
  
    //resets filters and send 'clear-filters' up level to reset data
    if ( field === 'clear-filters' ) {
      filteringDataHandler('clear-filters', true);
    } 
    else {
      filteringDataHandler(field, filter, false)
    }
  }
  
  //Finds correct label name to display on the UI
  const findFilterTitle = (thisFilterDropdown) => {
    if (thisFilterDropdown.displayField.toUpperCase() === 'SORT_BY_RELEVANCE' || thisFilterDropdown.displayField.toUpperCase() === 'SORT_BY_RECENT') {
        return CONSTANTS.FILTER_TITLES.SORT_BY_RECENT;
    } else {
        return CONSTANTS.FILTER_TITLES[thisFilterDropdown.displayField.toUpperCase()];
    }
  }

  const submitFilters = (filters, list) => {
    //if content comes from agility needs to send filtered list
    if (apiName === CONSTANTS.FILTER_BAR_API_NAMES.AGILITY) {
      filteringDataHandler(list)
      // if content comes from elastic it needs to send list of filters
    } else{
      applyFiltersHandler(filters)
    }
  }
  
  return (
    <Container className={`filter-bar ${ className ? className :apiName}`}>
          {
            //Loops through a list of app specific filters to display
              metaData.map((thisFilterDropdown, index) => {
                  return (
                    <Row key={index} className="filter-box">
                      {
                        thisFilterDropdown.displayField.toUpperCase() === 'SORT_BY_RELEVANCE' || 
                          thisFilterDropdown.displayField.toUpperCase() === 'SORT_BY_RECENT' ||
                            CONSTANTS.FILTER_TITLES[thisFilterDropdown.displayField.toUpperCase()] ?
                              <span className="filter-select-title">
                                  {findFilterTitle(thisFilterDropdown)}
                              </span>
                              : ''
                      }
                       {
                          CONSTANTS.FILTER_TYPES[thisFilterDropdown.displayField.toUpperCase()] === CONSTANTS.FILTER_TYPES.COUNTRY_FILTER ?
                              <CountryFilter 
                                selectHandler={handleFilterChange}
                                //send a boolean to decipher if a default value exists and should display
                                clearFilter={clearFilter}
                                //JSON field to look for to filter data
                                field={thisFilterDropdown.filterField}
                                curFilters={curFilters}
                              />
                              :
                              CONSTANTS.FILTER_TYPES[thisFilterDropdown.displayField.toUpperCase()] === CONSTANTS.FILTER_TYPES.NAME_FILTER ?
                                  <SearchFilter 
                                    selectCompanyHandler={handleFilterChange} 
                                    //JSON field to look for to filter data
                                    field={thisFilterDropdown.filterField}
                                    //send a boolean to decipher if a default value exists and should display
                                    clearFilter={clearFilter}
                                  />
                                  :
                                  //TODO:
                                  CONSTANTS.FILTER_TYPES[thisFilterDropdown.displayField.toUpperCase()] === CONSTANTS.FILTER_TYPES.DATE_RANGE_FILTER ?
                                      <DateRangeFilter 
                                          dateRangeHandler={handleFilterChange} 
                                          curFilters={curFilters}
                                      />
                                      : 
                                      CONSTANTS.FILTER_TYPES[thisFilterDropdown.displayField.toUpperCase()] === CONSTANTS.FILTER_TYPES.SORT_BY_RECENT 
                                        || CONSTANTS.FILTER_TYPES[thisFilterDropdown.displayField.toUpperCase()] === CONSTANTS.FILTER_TYPES.SORT_BY_RELEVANCE ?
                                          <SelectFilter 
                                            isSortBy={true}
                                            sortOptions={CONSTANTS.FILTER_LISTS[CONSTANTS.FILTER_TYPES[thisFilterDropdown.displayField.toUpperCase()]]}
                                            curFilters={curFilters}
                                            filteredData={data}
                                            type={CONSTANTS.FILTER_TYPES[thisFilterDropdown.displayField.toUpperCase()]} 
                                            //JSON field to look for to filter data
                                            field={'sort_by'}
                                            onChange={(e)=>handleFilterChange('sort_by', e)}
                                          /> 
                                          :
                                            <SelectFilter 
                                              isSortBy={false}
                                              labelDict={CONSTANTS.FILTER_OPTIONS[thisFilterDropdown.displayField.toUpperCase()] ? CONSTANTS.FILTER_OPTIONS[thisFilterDropdown.displayField.toUpperCase()] : null}
                                              curFilters={curFilters}
                                              options={CONSTANTS.FILTER_LISTS[thisFilterDropdown.displayField.toUpperCase()]}
                                              //JSON field to look for to filter data
                                              field={thisFilterDropdown.filterField}
                                              isMulti={thisFilterDropdown.isMulti ? thisFilterDropdown.isMulti : null} 
                                              onChange={(e)=>handleFilterChange(thisFilterDropdown.filterField, e)}
                                            />
                       }
                    </Row>
                )
              })
          }
        <Row className="filter-btn">
          <Col className='clear-filter' > 
            <Button className='btn-ghost-sm' onClick={() =>  handleFilterChange('clear-filters')}>
              <span><X/></span> 
              Clear Filters
            </Button>
          </Col>
          <Col className='p-i-end-0'>
            <Button className='btn-primary side-col-btn tertiary-button' onClick={() => submitFilters(curFilters, data)}>Apply Filters</Button>
          </Col>
        </Row>
    
    </Container>

  )
}

export default FilterBarElastic;