
const copyPhase = (phase) => {
    const newPhase = {...phase};
    newPhase.phase_id = null;
    newPhase.activity_id = null;
    for (let tg of newPhase.target_geographies){
        tg.phase_id = null;
        tg.target_geography_id = null;
    }
    return newPhase;
}

const copyActivity = (activity) => {
    const newActivity = {...activity};
    newActivity.activity_id = null;
    newActivity.dose_sequences = newActivity.dose_sequences.map((ds) => {
        return {
            ...ds,
            dose_sequence_id: null,
            activity_id: null,
            phases: ds.phases.map(phase => copyPhase(phase))
        }
    });
    newActivity.coverage = newActivity.coverage.map(coverage => {
        coverage.coverage_id = null;
        return coverage;
    });
    return newActivity;
}


async function deleteActivity(activity, apiKey, setShowWarning, closePopup, upsertFunction) {
    const updatedActivity = { ...activity, deleted: true };

    try {
        const result = await upsertFunction('upsert_activity', apiKey, updatedActivity);
        if (result !== null) {
            setShowWarning(false);
            closePopup();
        } else {
            throw new Error("Failed to update the activity.");
        }
    } catch (error) {
        console.error("Error in deleteActivity:", error);
    }
}

export {copyActivity, copyPhase, deleteActivity};