import React, { useEffect } from 'react';
import LogsApp from '../../components/LogsApp/LogsApp';

function LogsAppPage({ tenant }) {

    useEffect(() => {
        document.title = 'Logs';
    }, [])

    return (
        tenant && tenant.tenant.fields.tenantslug === 'LB-Level-Q' ?
            <LogsApp preview={false} />
        : 
            <div>Not authorized.</div>

    )
}

export default LogsAppPage