import React from 'react';
import { Badge } from 'react-bootstrap';

function ListOfMemberWorkspaces({ rec, allWkspcsList }) {

    const getWkspBubbles = (myWkSpcObj, allWkspcsList) => {
        return Object.keys(myWkSpcObj).map( (thisKey) => {
            return (<><Badge pill variant="primary" style={{backgroundColor: '#2d579e'}}>{ allWkspcsList[allWkspcsList.findIndex( (thisWk) => { return thisWk.tenantID === thisKey})].tenantSlug }</Badge>{' '}</>)
        })
    }

    return getWkspBubbles( rec.wrkspaces, allWkspcsList );
    
}

export default ListOfMemberWorkspaces