import React, { useEffect, useState } from 'react';
import Select from 'react-select'; 


function ReactTblSelectEditCell({ initialValue, rowID, column, updateMyData, selectionValues, forName, className = null, customField = null }) {
    const [myValue, setMyValue] = useState();

    useEffect(() => {
        setMyValue(initialValue)
    },[initialValue])

    const onChange = selectedOption => {
        setMyValue(selectedOption);
        updateMyData(rowID, column, selectedOption ? selectedOption.value : '');
    };

    const onBlur = () => {
        if (forName === 'hmfCategory') {
            updateMyData(rowID, column, myValue ? [myValue.value] : []);
        } else {
            updateMyData(rowID, column, myValue ? myValue.value : '');
        }
    };




    return (
        <Select
            className={className || 'table-dropdown-select'}
            value={myValue}
            customField={customField}
            onChange={onChange}
            options={selectionValues}
            placeholder="Select..."
            isClearable={true} 
            onBlur={onBlur}  
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
        />
    );
}

export default ReactTblSelectEditCell;
