import React from 'react';
import DynamicIcon from '../DynamicIcon';

function AzureStorageFileDisplayList({ contentArray, svcName, folderName }) {

    const getFileIcon = (fileName) => {
        let fileExtension = fileName.split('.').pop();

        switch(fileExtension) {
            case 'pdf':
                return <DynamicIcon iconName='FilePdfFill' className='file-icon' />;
            case 'doc':
            case 'docx':
                return <DynamicIcon iconName='FileEarmarkWordFill' className='file-icon' />;
            case 'xls':
            case 'xlsx':
                return <DynamicIcon iconName='FileExcelFill' className='file-icon' />;
            case 'ppt':
            case 'pptx':
                return <DynamicIcon iconName='FileEarmarkPptFill' className='file-icon' />;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return <DynamicIcon iconName='FileEarmarkImageFill' className='file-icon' />;
            default:
                return <DynamicIcon iconName='FileEarmarkFill' className='file-icon' />;
        }

    }

    const getFileNameOnly = (url) => {
        const myBlobNameArray = url.split('/');

        return myBlobNameArray[myBlobNameArray.length -1];
    }

    const getFullUrl = (fileName, svcName, folderName) => {
        if(fileName.indexOf('https://') < 0){
            return `https://${svcName}.blob.core.windows.net/${folderName}/${fileName}`;
        } else {
            return fileName
        }
        
    }

    return (
        <ul className='file-display-list'>
            {
                contentArray.length > 0 ?
                    contentArray.map((thisFile, ind) => {
                        return (
                            <li key={ind}>
                                <a href={getFullUrl(thisFile, svcName, folderName)} target='_blank' rel='noreferrer' className='file-link'>
                                    { getFileIcon(thisFile) } {getFileNameOnly(thisFile)}
                                </a>
                            </li>
                        )
                    })
                : <p>No files attached</p>
            }
        </ul>
    )
}

export default AzureStorageFileDisplayList