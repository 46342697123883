import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import FilteredPagedMarketsList from '../../components/VaccineMarkets/FilteredPagedMarketsList';
import { useHistory } from 'react-router';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import ListSkeletonLoader from '../../components/Layout/ListSkeletonLoader';
import NavUtils from '../../NavUtils';


function VaccineMarketsListPage({ tenant}) {

    const history = useHistory();
    let hasAccesstoVM = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.VACCINE_MARKETS);
    let hasAccesstoVA = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.VACCINE_ALMANAC);

    if(!hasAccesstoVA && !hasAccesstoVM) {
        history.push('/');
    }

    let [ markets, setMarkets ] = useState();
    let [ loading, setLoading ] = useState(true);
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    let tenantSlug = tenant.tenant.fields.tenantslug;
    let forceTen = 'force_id=' + tenant.tenant.fields.aDGroupID;

    const request = {
        scopes: ["User.Read"]
    };

    let tenID = utils.getForceTenantValue();

    if(!tenID) {
        //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
        tenID = tenant.tenant.fields.aDGroupID || null;
        utils.setForceTenantValue(tenID);
    }

  
    const trackCall = async () => {
        let accToken = await utils.getAccessToken(instance, accounts[0], request);
          //no await on purpose, we don't need to ensure that it returns - just issue it
          utils.trackAppView('vaccine_markets', tenID, accToken);
    }

    const getAccessToken = async (instance, account) => {
        try{
            let theAccToken =  await instance.acquireTokenSilent({...request, account: account});
            return theAccToken;
        } catch(error) {
            console.log(error);
        }
    }

    const getMarkets = async (token) => {
        setLoading(true);
        
        let jsonresponse = await utils.getTenantContent(token, CONSTANTS.APPS_BY_APPTYPE.VACCINE_MARKETS,tenantSlug, tenant.ourUserID, forceTen);

        setMarkets(jsonresponse.content);

        setLoading(false);
    }

    useEffect( (tenantSlug) => {
        try{
            (async () => {
                setLoading(true);
                // only track if on prod
                if(process.env.REACT_APP_ENV === 'PROD' ) {
                    trackCall();
                }
                let accToken = await getAccessToken(instance, accounts[0]);
                await getMarkets(accToken);
            })()
        } catch(error) {
            console.log(error);
            setLoading(false);
        }
    // eslint-disable-next-line
    },[ tenantSlug, isAuthenticated ]);

    return (
        !loading && tenant ?
            <>
                {
                    !loading ?
                        markets !== null ?
                            <FilteredPagedMarketsList marketsList={markets} tenant={tenant.tenant} />

                        : <div>No Markets Found</div>
                    : <ListSkeletonLoader preview={false} />
                }
            </>
        : <ListSkeletonLoader preview={false} />
    )
}

export default VaccineMarketsListPage;
