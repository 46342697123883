import React, {useEffect, useState} from 'react';
import { Table, Form } from 'react-bootstrap';


function ProductCapacityProbability({onChange, confidentiality, capacityProbabilities, characteristics,
                                    disabled}){
  const [capacityRow, setCapacityRow] = useState(null);
  const [indexRow, setIndexRow] = useState(null);
  const [pqYear, setPqYear] = useState(null);
  const [licensureYear, setLicensureYear] = useState(null);
  useEffect(() => {
    const findRowValues = (list) => {
      list.forEach((item, index) => {
        if(item.confidentiality_type === confidentiality ) {
            setCapacityRow(item);
            setIndexRow(index);
            return;
          }
      })
    }

    const setYears = (list) => {
      var minPQ = null;
      var minLicensure = null;
      var pqYear=null;
      var licYear=null;
      list.forEach((elt)=>{
        pqYear = elt.pq_date?new Date(elt.pq_date).getFullYear():null;
        licYear = elt.lic_date?new Date(elt.lic_date).getFullYear():null;
        if (pqYear && (!minPQ || pqYear <minPQ)){
          minPQ = pqYear;
        }
        if (licYear && (!minLicensure || licYear <minLicensure)){
          minLicensure = licYear;
        }
      })
      setPqYear(minPQ);
      setLicensureYear(minLicensure);
    }
    findRowValues(capacityProbabilities);
    setYears(characteristics);
  }, [capacityProbabilities, characteristics, confidentiality])



  const handleChange = (field, value)=>{
    const new_cap_probs = [...capacityProbabilities];
    value = value===''?null:value;
    if (capacityRow===null){
      const tempRow = {'confidentiality_type':confidentiality}
      tempRow[field]=value;
      new_cap_probs.push(tempRow);

    } else {
      capacityRow[field] =value;
      new_cap_probs[indexRow] = capacityRow;
    }
    onChange(new_cap_probs);
  }


  return (
    <Table size='sm' className='probability-thead'>
      <thead className='table-heading'>
        <tr>
          <th></th>
          <th>Internal Comment</th>
          <th>Year</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Licensure</td>
          <td>
            <textarea
              className='comments'
              type='text'
              value={capacityRow ? capacityRow.licensure_comments : ''}
              onChange={(e)=>handleChange('licensure_comments', e.target.value)}
              disabled={disabled}
            />
          </td>          
          <td>
            <Form.Control                 
              className='capacity-cells'
              type='text'
              value={licensureYear?licensureYear:capacityRow ? capacityRow.licensure_year : ''}
              disabled={licensureYear!==null||disabled}
              onChange={(e)=>handleChange('licensure_year', e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td>PQ</td>
          <td>
            <textarea
              className='comments'
              type='text'
              value={capacityRow ? capacityRow.pq_comments : ''}
              onChange={(e)=>handleChange('pq_comments', e.target.value)}
              disabled={disabled}
            />
          </td>
          
          <td>
            <Form.Control                 
              className='capacity-cells'
              type='text'
              value={pqYear?pqYear:capacityRow ? capacityRow.pq_year : ''}
              disabled={pqYear!==null||disabled}
              onChange={(e)=>handleChange('pq_year', e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td>Gavi Exit</td>
          <td>
            <textarea
              className='comments'
              type='text'
              value={capacityRow ? capacityRow.gavi_exit_comments : ''}
              onChange={(e)=>handleChange('gavi_exit_comments', e.target.value)}
              disabled={disabled}
            />
          </td>
          <td>
            <Form.Control                 
              className='capacity-cells'
              type='text'
              min={0}
              max={20}
              value={capacityRow ? capacityRow.gavi_exit_year : ''}
              onChange={(e)=>handleChange('gavi_exit_year', e.target.value)}
              disabled={disabled}
            />
          </td>
        </tr>
        <tr>
          <td>Total Exit</td>
          <td>
            <textarea
              className='comments'
              type='text'
              value={capacityRow ? capacityRow.total_exit_comments : ''}
              onChange={(e)=>handleChange('total_exit_comments', e.target.value)}
              disabled={disabled}
            />
          </td>
          <td>
            <Form.Control                 
              className='capacity-cells'
              type='text'
              min={0}
              max={20}
              value={capacityRow ? capacityRow.total_exit_year : ''}
              onChange={(e)=>handleChange('total_exit_year', e.target.value)}
              disabled={disabled}
            />
          </td>
        </tr>
      </tbody>
    </Table>
    )


}

export default ProductCapacityProbability;