import React from 'react'; 
import { Row } from 'react-bootstrap';



function DateRangeFilter({ dateRangeHandler, curFilters }) {

  return (
    <>
      <Row className='date-from date-range'>
        <label className='filter-select-title' htmlFor='start'>From</label>
        <input
          className='date-input'
          type="date"
          id="start"
          name="start-date"
          value={curFilters['from']}
          onChange={(item) => dateRangeHandler('from', item.target.value)}
        />
      </Row>
      <Row className='date-to date-range'>
        <label className='filter-select-title' htmlFor='end'>To</label>
        <input
          className='date-input'
          type="date"
          id="end"
          value={curFilters['to']}
          name="end-date"
          onChange={(item) => dateRangeHandler('to', item.target.value)}
        />
      </Row>
    </>
  );
};



export default DateRangeFilter;