import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import constants from '../../constants';
import { Button } from 'react-bootstrap';
import { PersonVcardFill, ChevronDoubleRight } from 'react-bootstrap-icons';
import UserUtils from '../../UserUtils';
import utils from '../../utils';
import BulkResultsTable from './BulkResultsTable';

function BulkActionsDisplay({ usersToActOn, actionToTake, wkspcList, tenID }) {
    // gets user list and actionTotake, then set targetData (which represents the new data to be updated on the user's account)

    const [ usersForOperation, setUsersForOperation ] = useState(usersToActOn);
    const [ targetData, setTargetData ] = useState(); 
    const { procResults, setProcResults } = useState([]);
    const [ isWorking, setIsWorking ] = useState(false);
    const [ readyForAction, setReadyForAction ] = useState(false);

    const { instance, accounts } = useMsal();
    const request = {
        scopes: ["User.Read"]
    };

    const getToken = async () => {
        return await utils.getAccessToken(instance, accounts[0], request);
    }

    const handleAction = async (actionToTake) => {
        const usersToProcess = [...usersForOperation];
        let report;

        let token = await getToken();

        switch(actionToTake){
            case constants.USERS_ACTIONS.ADD_TO_WORKSPACE:
                
                report = await UserUtils.suAddToWorkspaceForUsers(usersToProcess, targetData, tenID, token); //sudo add to workspace
                break;
            
            case constants.USERS_ACTIONS.DEFAULT_WORKSPACE_CHANGE:
                report = await UserUtils.updateUsers(usersToProcess, { defaultWorkspace: targetData }, token); //update default workspace
                break;

            default: //constants.USERS_ACTIONS.SECURITY_LEVEL:
                report = await UserUtils.updateUsers(usersToProcess, { securityLevel: targetData }, tenID, token); //update security level
                break;
        }

        setProcResults(report);
    }

    const showPickerBasedOnAction = (actionToTake) => {
        switch(actionToTake){
            case constants.USERS_ACTIONS.ADD_TO_WORKSPACE:
                //show list of workspaces
                return (
                    <select 
                        name='workspace-picker' 
                        onChange={(e) => { setTargetData(e.target.value) }}>
                        {
                            wkspcList.map( (wkspc, idx) => {
                                return (
                                    <option key={'wkspc-'+idx} value={wkspc.tenantID}>{ wkspc.tenantSlug }</option>
                                )
                            })
                        }
                    </select>
                    
                )
            case constants.USERS_ACTIONS.DEFAULT_WORKSPACE_CHANGE:
                //show list of workspaces
                return (
                    <select 
                        name='workspace-picker' 
                        onChange={(e) => { setTargetData(e.target.value) }}>
                        {
                            wkspcList.map( (wkspc, idx) => {
                                return (
                                    <option key={'wkspc-'+idx} value={wkspc.tenantID}>{ wkspc.tenantSlug }</option>
                                )
                            })
                        }
                    </select>
                    
                )

            default: //constants.USERS_ACTIONS.SECURITY_LEVEL:
                //show secLevel picker
                return ( <select 
                    name='secLevel-picker' 
                    onChange={(e) => { setTargetData(e.target.value) }}>
                    {
                        [ 1, 2, 3, 4, 5, 6 ].map( (thisNum, idx) => {
                            return (
                                <option key={'secLevel-'+idx} value={thisNum}>{ thisNum }</option>
                            )
                        })
                    }
                </select>)
        }    


    }

    const getSlugBasedOnID = (tenantID) => {
        return wkspcList.filter( wkspc => wkspc.tenantID === tenantID)[0].tenantSlug;
    }

    const isInUserList = (user, usersForOperation) => {
        return (usersForOperation.filter( u => u.user_id === user.user_id).length > 0);
    }

    const getActionExplanation = (actionToTake, userInfo) => {
        switch(actionToTake){
            case constants.USERS_ACTIONS.ADD_TO_WORKSPACE:
                return 'Add Selected Users to a Workspace'
            case constants.USERS_ACTIONS.DEFAULT_WORKSPACE_CHANGE:
                return 'Change Default Workspaces for Selected Users'

            default: //constants.USERS_ACTIONS.SECURITY_LEVEL:
                return 'Change Sec. Level for Selected Users'
        }
    }

    const showExistingBasedOnAction = (actionToTake, userInfo) => {
        switch(actionToTake){
            case constants.USERS_ACTIONS.ADD_TO_WORKSPACE:
                return 'N/A'
            case constants.USERS_ACTIONS.DEFAULT_WORKSPACE_CHANGE:
                return getSlugBasedOnID(userInfo['defaultWorkspace'])

            default: //constants.USERS_ACTIONS.SECURITY_LEVEL:
                return userInfo['securityLevel']
        }
    }

    const showNewBasedOnAction = (actionToTake, target) => {
        switch(actionToTake){
            case constants.USERS_ACTIONS.ADD_TO_WORKSPACE:
                return target ? getSlugBasedOnID(target) : 'Pick Above'
            case constants.USERS_ACTIONS.DEFAULT_WORKSPACE_CHANGE:
                return target ? getSlugBasedOnID(target) : 'Pick Above'

            default: //constants.USERS_ACTIONS.SECURITY_LEVEL:
                return target ? target : 'Pick Above';
        }
    }

    const handleCheck = (e, userID) => {
        // if marked as false, remove from list
        if(e.target.checked === false){
            setUsersForOperation(usersForOperation.filter( user => user.user_id !== userID))
        } else {
            // if marked as true, add to list
            if(!isInUserList(userID, usersForOperation)){
                //get user from usersToActOn
                let user = usersToActOn.filter( u => u.user_id === userID)[0];
                setUsersForOperation([...usersForOperation, user]);
            }
        }
    }

    useEffect( () => {
        if(readyForAction === true) {
            setIsWorking(true);
            handleAction(actionToTake);
            //clear out users picked
            setUsersForOperation([]);
            setIsWorking(false);
            setReadyForAction(false);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ readyForAction ]);

    return (
         // table with User name, existing data, switch to enable and new data to be applied
        <>
            <h3><PersonVcardFill /> Bulk: { getActionExplanation(actionToTake) } </h3>
            
            <table className='small-table'>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Existing</th>
                        <th>Apply</th>
                        <th>New - { showPickerBasedOnAction(actionToTake) }</th>
                    </tr>
                </thead>
                <tbody className='scroll-pane'>
                    {
                        usersToActOn.map( (user, idx) => {
                            return (
                                <tr key={'user-'+idx}>
                                    <td>{ user.mail }</td>
                                    <td style={{textAlign: 'center'}}>{ showExistingBasedOnAction(actionToTake, user) }</td>
                                    <td style={{textAlign: 'center'}}>
                                        <input 
                                            type='checkbox' 
                                            name='enforce-change' 
                                            defaultChecked={ true } 
                                            onChange={(e) => { handleCheck(e, user.user_id)}} />
                                    </td>
                                    <td>{ showNewBasedOnAction(actionToTake, targetData) }</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <Button 
                className='btn-sm-solid-100 buttonSmall tertiary-button' 
                onClick={(e) => { handleAction(actionToTake) }}
                disabled={isWorking || usersForOperation.length < 1 || (!targetData || targetData.length < 1)}>
                <span>Execute Actions ({ usersForOperation.length })</span> <ChevronDoubleRight />
            </Button>
            <div className='operation-result small-table'>
                <BulkResultsTable
                    results={procResults}
                    actionToTake={actionToTake} />
            </div>
        </>    

    )
}

export default BulkActionsDisplay