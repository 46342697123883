import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import CONSTANTS from '../../../constants';
import '../../../styles/layout/_layout.scss';

function BreadCrumbBar({appName, item, displayFieldName, noListLink=null, appAltTitle=null, displayChildValue=null}){

    let app_Name = CONSTANTS.APPNAMES_BY_PATH[CONSTANTS.APPS_BY_APPTYPE[appName.toUpperCase()]];

    return (
        <Breadcrumb>
            <LinkContainer to="/">
                <Breadcrumb.Item active={!appName && !item ? true : false} className={appName ? 'not-active' : "secondary-link"}>Home</Breadcrumb.Item>
            </LinkContainer>
            { app_Name && (noListLink===null || noListLink===false)?
                <LinkContainer to={`/${CONSTANTS.APPS_BY_APPTYPE[appName.toUpperCase()]}/`} className="breadcrumb-custom">
                    <Breadcrumb.Item active={appName && !item ? true : false} className={appName && item ? 'secondary-link not-active' : 'custom-breadcrumb-style'}>{ appAltTitle ? appAltTitle : app_Name }</Breadcrumb.Item>
                </LinkContainer>
            : null }
            {
                item ?
                    displayFieldName && (item.hasOwnProperty(displayFieldName) || item.fields.hasOwnProperty(displayFieldName)) ?
                        <Breadcrumb.Item active={item ? true : false} className="secondary-link">{ item[displayFieldName] ? item[displayFieldName] :item.fields[displayFieldName] }</Breadcrumb.Item>
                    : null
                : null
            }


        </Breadcrumb>
    )
}

export default BreadCrumbBar
