import React, { useState, useEffect } from 'react';
import { Row, Col, InputGroup, Form, Spinner } from 'react-bootstrap';
import _ from 'lodash';

import { useToast } from '../../ToastProvider';
import ProductCapacityTable from './ProductCapacityTable';
import ProductCapacityProbability from './ProductCapacityProbability'
import SimpleSelect from '../SimpleSelect'
import utils from "../../../utils"

function ProductCapacity({ apiKey, productCharacteristics, capacityDetails, setCapacityDetails,
  visibleConfidentialities, editableConfidentialities, capacityProbabilities, setCapacityProbabilities,
      productId, tenantId, closePopup}) {
  const [showHistory, setShowHistory] = useState(false)
  const [columnYears, setColumnYears] = useState([])
  const [capTypes, setCapTypes] = useState([])
  const [allRecipients, setAllRecipients] = useState([])
  const [confidentiality, setConfidentiality] = useState(null)
  const [recipients, setRecipients] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const { addToast } = useToast();
  // Set the disabled status
  useEffect(() => {
    // If the selected confidentiality is NOT in the list of editable confidentialities, disable
    const tempDisabled = editableConfidentialities.indexOf(confidentiality) > -1;
    setDisabled(!tempDisabled);
    // React 18 ready
    return () => { };
  }, [editableConfidentialities, confidentiality])


  useEffect(() => {
    const handleConfidentialityChange = async () => {
      if (!productId || !tenantId || !confidentiality) {
        return
      }
      let capacityResult = await utils.getData('get_capacities', apiKey,
        `?product_id=${productId}&tenant_id=${tenantId}&confidentiality_type=${confidentiality}`)

      if (capacityResult && capacityResult.length > 0) {
        setCapacityDetails(capacityResult)
      }

    }
    handleConfidentialityChange()

    // React 18 ready
    return () => { };
  }, [confidentiality, apiKey, productId, tenantId, setCapacityDetails])



  useEffect(() => {
    if (visibleConfidentialities && visibleConfidentialities.length === 1) {
      const tempConf = visibleConfidentialities[0]
      setConfidentiality(tempConf)
    }
    // React 18 ready
    return () => { };
  }, [visibleConfidentialities])


  useEffect(() => {
    if (!capacityDetails || !confidentiality) {
      return
    }
    let capacitySet = new Set()
    capacityDetails.filter((listItem) => listItem.confidentiality_type === confidentiality).forEach((item) => {
      capacitySet.add(item.capacity_type)
    })
    let newArray = Array.from(capacitySet)
    setRecipients(newArray);
    // React 18 ready
    return () => { };
  }, [capacityDetails, confidentiality])

  // This one just gets the initial immutable list of recipients
  useEffect(() => {
    async function fetchData() {
      let recipientResult = await utils.getData('get_enums', apiKey, "?enum_type=capacity_type&include_deleted=false")
      if (typeof recipientResult === 'string'){
        addToast({
          title: 'Failed to load data. Error code 1-c.',
          body: recipientResult
        })
        closePopup();
      }
      let recipients = recipientResult.map((r)=>r.enum)
      setAllRecipients(recipients)
    }
    fetchData()
  },[apiKey, addToast, closePopup])

  // This one adjusts the columnYears if the show history button changes or the years change
  useEffect(() => {
    const currentYear = new Date().getFullYear()
    if (showHistory) {
      setColumnYears(_.range(2000, 2041, 1))
    } else {
      setColumnYears(_.range(currentYear, 2041, 1))
    }
    // React 18 ready
    return () => { };
  }, [showHistory])

  // This one re-filters the recipients as recipients get added or removed, or the full list of recipients changes
  useEffect(() => {
    async function filterRecipients() {
      const finalResult = allRecipients.filter((r) => !recipients.includes(r)).sort()
      setCapTypes(finalResult);
    }
    filterRecipients();
    // React 18 ready
    return () => { };
  }, [apiKey, recipients, allRecipients]
  )


  const onChange = (row, idx) => {
    // Deep copy of the details is necessary to get the useEffects to trigger properly
    const cap = [...capacityDetails];
    cap[parseInt(idx)] = row;
    setCapacityDetails(cap)
  }

  const onDelete = async (index) => {
    // This hard-deletes a capacity row by id
    const capId = capacityDetails[index].capacity_detail_id
    const newCapDetails = capacityDetails;
    newCapDetails.splice(index, 1);
    const deleteResult = await utils.delete('delete_capacity_details', apiKey, [capId])

    if (typeof deleteResult !== 'string'){
      setCapacityDetails(newCapDetails);
    } else {
      addToast({
        title: 'Failed to delete capacity data. Error code 1-d.',
        body: deleteResult
      })
      closePopup();
    }
  }

  const onNew = (newCapRow) => {
    let newCapDetails = [...capacityDetails];
    newCapDetails.push(newCapRow);
    setCapacityDetails(newCapDetails);
  }


  const onDeleteRecipient = async (recipient) => {
    // This hard-deletes all capapacity rows for a recipient.
    let newCapDetails = capacityDetails.filter((cd) => cd.capacity_type !== recipient);
    const capsToDelete = capacityDetails.filter((cd) => cd.capacity_type === recipient);
    const capIdsToDelete = capsToDelete.map((cd) => { return cd.capacity_detail_id })
    const deleteResult = await utils.delete('delete_capacity_details', apiKey, capIdsToDelete);
    if (deleteResult) {
      setCapacityDetails(newCapDetails);
    }
  }

  const addRecipient = (recipient) => {
    onNew({
      confidentiality_type: confidentiality,
      capacity_type: recipient,
      capacity_estimate_category: 'Base',
      capacities: []
    })
  }

  return (
    capacityDetails ?
      <div className='capacity'>
        {
          !confidentiality ?
            <Row>
              <Col>
                <label>Confidentiality</label>
                <SimpleSelect
                  className="select"
                  options={visibleConfidentialities}
                  onChange={setConfidentiality}
                />
              </Col>
            </Row>
            :
            <>
              <Row>
                <Col xs={3}>
                  <div className='input-text'>New Recipient:</div>
                  <SimpleSelect
                    className='select-lg'
                    isClearable={false}
                    clearAfterSelect={true}
                    options={capTypes}
                    value={null}
                    onChange={(e) => addRecipient(e)}
                    disabled={disabled}
                  />
                  <InputGroup className='low-check'>
                    <Form.Check
                      label='Show History'
                      name='Low'
                      checked={showHistory}
                      onChange={() => setShowHistory(!showHistory)}
                      disabled={disabled}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className='capacity-table'>
                {
                  recipients.filter(item => item !== 'Total').map((recipient, index) => {
                    return (
                      <>
                        <ProductCapacityTable
                          key={index}
                          capacityDetails={capacityDetails}
                          confidentiality={confidentiality}
                          recipient={recipient}
                          onChange={onChange}
                          onDelete={onDelete}
                          onNew={onNew}
                          onDeleteRecipient={onDeleteRecipient}
                          columnYears={columnYears}
                          disabled={disabled}
                        />

                      </>
                    )
                  })
                }
                {
                  // Only display the total table if total is a recipient
                  recipients.includes('Total') ?
                    <ProductCapacityTable
                      capacityDetails={capacityDetails}
                      confidentiality={confidentiality}
                      recipient={'Total'}
                      onChange={onChange}
                      onDelete={onDelete}
                      onNew={onNew}
                      onDeleteRecipient={onDeleteRecipient}
                      columnYears={columnYears}
                      disabled={disabled}
                    /> : null
                }
              </Row>
              {
                recipients.includes('Total') ? null :
                  <label><strong>Note: </strong> Gavi estimates will be used for total</label>
              }
              <Row>
                <Col className='col-left'>
                  <h5>Probability</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProductCapacityProbability
                    capacityProbabilities={capacityProbabilities}
                    setCapacityProbabilities={setCapacityProbabilities}
                    confidentiality={confidentiality}
                    onChange={setCapacityProbabilities}
                    characteristics={productCharacteristics}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </>
        }
      </div> : <Spinner animation='border' />
  )

}

export default ProductCapacity;