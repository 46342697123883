import React from 'react';
import { Badge, Row, Col } from 'react-bootstrap';
import { PersonFill } from 'react-bootstrap-icons';
import ToolTip from '../Layout/ToolTip';
import CONSTANTS from '../../constants';

function LBKanbanCard(props) {

  const findClassForAccentColor = (idx) => {
    if (idx === 0) {
      return 'surface-accent-teal';
    }
    if (idx === 1) {
      return 'surface-accent-lgt-purple'
    }
    if (idx === 2) {
      return 'surface-accent-peach';
    }
  }
  return (
    <>
      <div id={props.id} className={props.archived === true ? "lb-'kanban'-card archived" : "lb-kanban-card"} onClick={() => props.handleShowIntModal(props.id)}>
        <Row>
          <Col xs={11}>
            <h4>
            {props.archived && props.archived === true ?
              `* Archived * ${props.title ? props.title.slice(0, 65) : '' }`
            :
            props.title ? props.title.slice(0, 65) + ' ...' : ''
            }
            </h4>
          </Col>
          <Col xs={1} className='tool-tip'>
          <ToolTip tooltip={props.tooltip}/>
          </Col>
        </Row>
          { props.description && props.description.length > 0 ?
            <p>
                { props.description }
            </p>
          : null }
          <Badge className='mkt-name-badge badge-radius-lg surface-brand-primary'>{props.market_name}</Badge>
          {
            props.hmfCategory && props.hmfCategory.length > 0 ?
              props.hmfCategory.map((category, idx) => {
                return <Badge className={`${findClassForAccentColor(idx)} m-i-start-03 badge-radius-lg`}>{CONSTANTS.MARKET_ACTIONS_REVERSE_HMF_CATEGORY[category]}</Badge>
              })
            : null
          }
          <Badge className='archived-badge badge-radius-lg'>{props.archived === true ? 'Archived': ''}</Badge>
          <Row className='m-b-start-04'>
            <Col xs={1}>
              <PersonFill />
            </Col>
            <Col className="person-responsible">
              <span>{props.assigned_to}</span>
            </Col>
          </Row>
      </div>
    </>
  )
}

export default LBKanbanCard