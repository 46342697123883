import React, { useEffect } from 'react';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import { useMsal } from '@azure/msal-react';
import ElasticAppHarness from '../ElasticAppHarness';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';

function IntelligenceApp({ tenantSetting, preview=false }) {

    const metaData = [
      //TITLE: What should display on UI
      //DISPLAYFIELD: What is stored in CONSTANTS
      //FILTERFIELD: JSON field to filter the master list on
      {title:'Sort By', displayField: 'sort_by_recent'},
      {title:'Information Owner', displayField:'owner_filter', filterField: 'informationOwner'},
      {title:'Sensitivity', displayField:'sensitivity_filter', filterField: 'sensitivity'},
      {title:'Source Type', displayField:'source_type_filter', filterField: 'reportType'},
      {title:'Date Range', displayField: 'date_range_filter'}

  ]

  let tenID = utils.getForceTenantValue();

  if(!tenID) {
    //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
    tenID = tenantSetting.tenant.fields.aDGroupID || null;
    utils.setForceTenantValue(tenID);
  }

  const request = {
    scopes: ["User.Read"]
  };

  const { instance, accounts } = useMsal();
  
  // let localStorageArr = JSON.parse(localStorage.getItem('intelligence-filters'));

  const trackCall = async () => {
    let accToken = await utils.getAccessToken(instance, accounts[0], request);
    if(!preview) {
      //no await on purpose, we don't need to ensure that it returns - just issue it
      utils.trackAppView('intelligence', tenID, accToken);
    }
  }

  useEffect(() => {
  // only track if on prod
    if(process.env.REACT_APP_ENV === 'PROD' ) {
      trackCall();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="intelligence-display">
      { 
        tenantSetting ?
            <>
              <ElasticAppHarness
                headerText='View All Reports' 
                tenantSetting={tenantSetting} 
                appType={CONSTANTS.APPS_BY_NAME.INTELLIGENCE} 
                pageType={CONSTANTS.ELASTIC_PAGE_TYPES.INTELLIGENCE} 
                preview={preview}
                metaData={metaData} 
                pollingInterval={5*1000} />
            </>
        : <ListSkeletonLoader preview={false} />
      }
    </div>
  )
}

export default IntelligenceApp;