import React from 'react';
import { useMsal } from '@azure/msal-react';


function SigninLink(props) {

    const { instance } = useMsal();

    const loginRequest = {
        scopes: ["User.Read"]
    };

    const showLoginPopup = () => {
        try {
            instance.loginPopup(loginRequest).catch( error => {
                  console.log(error);
              });
        } catch (error) {
            console.log(error);
        }
        
    };

    return (
        <span onClick={() => { showLoginPopup() }}>
            { props.children }
        </span>
    )
}

export default SigninLink
