import React, { useState, useEffect } from 'react'; 
import { Form, FormControl, Button, ButtonGroup, Modal, Spinner} from 'react-bootstrap'

import utils from "../../../utils"
import EnumSelector from '../EnumSelector'
import '../../../styles/layout/_layout.scss'

function IndicationEditor(props) {

  /*--------------------------State variables---------------------------*/
  const [indication, setIndication] = useState()
  const [errors, setErrors] = useState([])

  useEffect(() => {
    const getIndication = async () => {
      const result = await utils.getData("get_indication", props.apiKey, "?indication_id="+props.id)
      if (result !== null) {
        setIndication(result)
      }
    }
    getIndication();
  }, [props])

  /*-------------------------Submit form and new antigen----------------------------------*/
  async function handleSubmit (e){
    e.preventDefault();
    e.stopPropagation();
    setErrors([])
    const submissionErrors = []
    if (indication.indication_name === null){
      submissionErrors.push("The indication NAME can't be blank")
    }
    if (indication.indication_type === null){
      submissionErrors.push("The indication TYPE can't be blank")
    }
    setErrors(submissionErrors)
    if (submissionErrors.length > 0){
      return
    }
    const data = indication
    const result = await utils.upsert('upsert_indication', props.apiKey, data)
    if (result !== null) {
      props.handleSubmit(result)
      props.closePopup()
    }
  }

  /*--------------------------------Manage state variables-----------------------*/
  function printIndication() {
    console.log(indication)
  }


  return (
    indication ?
    <Modal className='indication-editor' show={true}>
      <Modal.Header>
        <Modal.Title>Indication Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
        <div className='input-text m-b-start-03'>Indication Name</div>
          <FormControl type='text'
            value={indication.indication_name}
            onChange={(e) => (e) = setIndication({ ...indication, indication_name: e.target.value })}
          ></FormControl>
          <div className='input-text m-b-start-03'>Indication Type</div>
          <EnumSelector type='text'
            apiKey={props.apiKey}
            enum_type='indication_type'
            value={indication.indication_type}
            onChange={(e) => (e) = setIndication({ ...indication, indication_type: e.label })}
          />
            <ButtonGroup className='editor-btn m-b-start-03'>         
              <Button onClick={props.closePopup} variant="secondary" className='button-secondary m-i-end-03'>Close</Button>
              <Button type="submit" variant="primary" className='btn-primary m-i-end-03'>Submit</Button>
              <Button onClick= { printIndication } variant = "primary">Check Indication</Button>
            </ButtonGroup> 
          {
            errors.length >0?
              <>
                <div className='error'>Errors:</div>
                <ul>
                  {errors.map((e, i)=>{
                    return <li className='error' key = {i}>{e}</li>
                  })}
                </ul>
              </>
            :null
          }
        </Form>
      </Modal.Body>
    </Modal>
    : <Spinner animation='border' />

  )
}

export default IndicationEditor;