import React, { useState, useEffect } from 'react'

import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useToast } from '../ToastProvider';
import utils from '../../utils';
import InterventionViewModal from './InterventionViewModal';
import ModalDynamic from '../Layout/ModalDynamic';

function InterventionCreateEditPopup({ organization, clearIntID, vaccine, groupMembers, show=false, handleShowIntModal, tenant, interventionID=null, handleReload, marketID=null, mode='create', toggleLoading, currentView='2' }) {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { addToast } = useToast();

  let [intervention, setIntervention] = useState({});
  let [loading, setLoading] = useState(true);
  const [form, setForm] = useState({ listID: marketID });
  const [ formReadyForSubmit, setFormReadyForSubmit ] = useState(false);
  const [ formSubmitting, setFormSubmitting ] = useState(false);
  const [inputLoading, setInputLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [comments, setComments] = useState('');
  const [showArchiveModal, setShowArchiveModal] = useState(false)

  let featureflags = utils.getFeatureflags(process.env.REACT_APP_ENV, 'filter-lb-out-market-actions', tenant.tenant.fields.featureflags);

  const request = {
    scopes: ["User.Read"]
  };

  const getAccessToken = async (instance, account) => {
    try {
      let theAccToken = await instance.acquireTokenSilent({ ...request, account: account });
      return theAccToken;
    } catch (error) {
      console.log(error);
    }
  }

  const getMarketActionsSpace = (ten) => {
    let ma_space_id;

    let actionsApp = ten.fields.apps.filter((item) => {
      return item.fields.apptype === 'market_actions';
    });

    if (actionsApp.length > 0) {
      ma_space_id = actionsApp[0].fields.appSpecificDataField ? actionsApp[0].fields.appSpecificDataField : null;
    }

    return ma_space_id;
  }

  const getInterventionByID = async (accToken, interventionID) => {
    setLoading(true)
    let param = `uid=${tenant.ourUserID}&on=int&id=${interventionID}&spc_id=${getMarketActionsSpace(tenant.tenant)}`;
    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/market-actions?${param}`, {
      headers: {
        "Authorization": "Bearer " + accToken.accessToken
      }
    });

    let jsonresponse = await response.json();
    setIntervention(jsonresponse);
    addIntToForm(jsonresponse);
    setLoading(false)
  }

  const addIntToForm = (int) => {
    if (int && int.customFields) {
      int.custom_fields.forEach((item) => {
        if (item.name === "Vaccine") {
          setForm({
            date_updated: new Date(),
            name: int.name,
            vaccine: item.value
          })
        }
      })
    } else {
      setForm({
        date_updated: new Date()
      })
    }
  }

  const handleAbort = () => {
    setForm({ listID: marketID });
    setIntervention();
    handleShowIntModal();
    clearIntID()
  }

  const toggleArchived = () => {
    //copying state object
    let cpyState = {...intervention};

    //modify cpyState to set archived to value
    cpyState['archived'] = !!cpyState.archived === true ? false : true
    //set back into state
    setIntervention(cpyState);
    submitArchive(cpyState);
  }

  const assigneesToPass = utils.setMembersListBasedOnMarketName(groupMembers, [], '', featureflags.FILTER_LB_OUT_MARKET_ACTIONS, tenant.tenant);

  const validateForm = (formToCheck) => {
    const { taskName, vaccine } = formToCheck;

    let newErrors = {};

    if (!taskName || taskName === '') {
      newErrors.taskName = 'Task Name is Required';
    }

    if (!vaccine || vaccine === '' || vaccine === '-') {
      newErrors.vaccine = 'Product selection is required';
    }

    return newErrors;
  }

  const archiveModal = {
    type: 'archive',
    title: utils.getTitle("You're about to archive this intervention"),
    body: 'Archiving this intervention will remove it from other views. Are you sure you want to continue?',
    app: 'market actions',
    subName: 'Confirm',
}

const onChange = (field, value) => {
    setForm({
      ...form,
      [field]: value === 'none' ? '' : value
    });
};

  //Adds comments to intervention
  const submitInterventionComment = (event, int) => {
    if(comments && comments.name) {

    setInputLoading(true);
    
    let curAccessToken;
    //put the updated field to said intervention
    let theBody = {};
    theBody.taskId = int.id;
    theBody[event.name] = event.value;

    event.value = '';

    let param = `uid=${tenant.ourUserID}&on=cmt&id=${int.id}&spc_id=${getMarketActionsSpace(tenant.tenant)}`;

    // first, get access token
    getAccessToken(instance, accounts[0])
      .then((res) => { curAccessToken = res })
      .then(() => {
        //call the put endpoint with the updated intervention field
        fetch(`${process.env.REACT_APP_WEB_API}/api/market-actions?${param}`, {
          method: 'POST',
          headers: {
            "Authorization": "Bearer " + curAccessToken.accessToken
          },
          body: JSON.stringify(theBody)
        })
          .then((resJSON) => {
            if (resJSON.ok) {
              return resJSON.json();
            } else {

              //TODO: Switch out to toast stuff
              throw Error('Server error', resJSON.statusText);
            }
          })
          .then(resp => {
            setInputLoading(false)
            setIntervention(resp);
          })
          .catch((err) => {
            console.log('error: ', err);
            //we need to force a refresh of the table
            setIntervention(intervention);
          })
      });
    }
  }

  //updates a comment on an intervention
  const updateInterventionComment = (event) => {
    setComments(event);
  }

  const submitForm = async () => {
    setFormSubmitting(true);
    
    let accToken = await getAccessToken(instance, accounts[0]);

    let idToSend = interventionID ? interventionID : '';
    
    let param = `uid=${tenant.ourUserID}&on=int&id=${idToSend}&spc_id=${getMarketActionsSpace(tenant.tenant)}`;
    let frmResponse;

    try {
      frmResponse = await fetch(`${process.env.REACT_APP_WEB_API}/api/market-actions?${param}`, {
        headers: {
          "Authorization": "Bearer " + accToken.accessToken
        },
        method: interventionID ? 'PUT' : 'POST',
        body: JSON.stringify(form)
      });

      if(!frmResponse.ok) {
        throw new Error('error on server');
      }

    } catch (error) {
      addToast({
        title: 'Error Updating Intervention',
        body: error.message + ' - try submitting again.'
      });
    }
      setFormSubmitting(false)
  }

  const onSubmit = (e) => {
    e.preventDefault();

    let copy = {...form};
    if (mode!=='create' && intervention && !form.vaccine) {
      let vaccineVal = intervention.custom_fields.filter((customField) => {
        if(customField.name && customField.name === "Vaccine") {
          return customField;
        }
        return null;
      })

      copy.vaccine = vaccineVal[0].value
    }

    if (intervention && !form.taskName) {
      copy.taskName = intervention.name;
    }

    
    const errorsOnForm = validateForm(copy);

    if (Object.keys(errorsOnForm).length < 1) {
      setForm(copy);
      setFormReadyForSubmit(true);
      
    } else {
      setErrors(errorsOnForm);
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      try {
        (async () => {
          setLoading(true);
          if(formReadyForSubmit && formReadyForSubmit === true) {
              await submitForm();
              await handleReload(null, currentView);
          }
          let accToken = await getAccessToken(instance, accounts[0]);
          
          if(mode === 'edit') {
            getInterventionByID(accToken, interventionID);
          }
        
          setLoading(false);
        }
        )()
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    //eslint-disable-next-line
  }, [isAuthenticated, interventionID, groupMembers, formReadyForSubmit])


  //Archives or unarchives interventions
  const submitArchive = (formBody) => {
    toggleLoading()
    let curAccessToken;
    let theBody = {};
    theBody.taskId = formBody.id;
    theBody['archived'] = formBody.archived;

    let param = `uid=${tenant.ourUserID}&on=int&id=${formBody.id}&spc_id=${getMarketActionsSpace(tenant.tenant)}`;
    
    //first, get access token
    getAccessToken(instance, accounts[0])
    .then( (res) => { curAccessToken = res})
    .then(() => {
      //call the put endpoint with the updated intervention field
      fetch(`${process.env.REACT_APP_WEB_API}/api/market-actions?${param}`, {
        method: 'PUT',
        headers: {
          "Authorization": "Bearer " + curAccessToken.accessToken
        },
        body: JSON.stringify(theBody)
      })
      .then( (resJSON) => {
        if(resJSON.ok) {  
          return resJSON.json();
          } else {
            addToast({
              title: 'Error Updating Intervention',
              body: resJSON.statusText + ' - try submitting again.'
          })
        }  
      })
          .then( () => {
            setLoading(false);
            handleReload();
      })
      .catch( (err) => {
        console.log('error: ', err);
        addToast({
          title: 'Error Updating Intervention',
          body: err + ' - try submitting again.'
        })
        
      })
    });
    } 
     
    const clearCommentInput = () => {
      document.getElementById('commentInput').value = ''
      setComments();
    } 
  
  return (
    groupMembers && assigneesToPass && vaccine && organization ?
      <>
        <InterventionViewModal 
          toggleArchived={toggleArchived}
          show={show}
          intervention={intervention}
          handleClose={handleAbort}
          onSubmit={onSubmit}
          errors={errors}
          assigneesToPass={assigneesToPass}
          vaccinesToPass={vaccine}
          suppliersToPass={organization}
          interventionID={interventionID}
          marketID={marketID} 
          submitInterventionComment={submitInterventionComment}
          updateInterventionComment={updateInterventionComment}
          clearCommentInput={clearCommentInput}
          comments={comments}
          handleArchived={() => setShowArchiveModal(!showArchiveModal)}
          tenant={tenant}
          inputLoading={inputLoading}
          onChange={onChange}
          loading={loading}
          posting={formSubmitting} 
          mode={mode}
          form={form}
        />
      :
      {/* Modal that popsup to confirm Achive action */}
        <ModalDynamic
          classes={'archive-confirm-modal'}
          id='archive-confirm-modal'
          show={showArchiveModal}
          content={archiveModal}
          tenant={tenant.tenant}
          handleClose={handleAbort}
          animation={false}
          submitRequest={toggleArchived} />
      </> 
    : null
  )
}

export default InterventionCreateEditPopup