import React, {useEffect, useState} from 'react';
//dependency imports
import { Image, Tabs, Tab } from 'react-bootstrap';

//file imports
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import marketActionsPreviewImage from '../../assets/market-actions-cover.png';
import PMTDisplay from './PMTDisplay';
import utils from '../../utils';
import CONSTANTS from '../../constants';
import MarketActionsSummaryView from './PMTChartDashboard/MarketActionsSummaryView';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import PMTCONSTANTS from './pmtConstants';
import DemandHealthDisplay from '../DemandHealth/DemandHealthDisplay';



function PMTApp({ listOptions, selectionHandler, selectedListItems, tenantSetting, appTitle=null, suppliers, triggerLoading, listOptionHandler, handleShowArchived, handleLoadList, handleReload, preview=false, apiKey, updateTopList, appName=null, archived, options, displayOptionHandler, parentPMTList, updateListItemForItemChange, loading=false, memberList }) {
    const [vaccineRoadmap, setVaccineRoadmap] = useState([])
    const [hmfCategory, setHMFCategory] = useState([])
    
    let breadCrumbName = utils.findURLPath(tenantSetting.tenant.fields.apps, appTitle)

    const handleLoadArchived = (archived = null, setView = 1) => {
        handleShowArchived(true)
        handleLoadList();
    }

    

const stripOutEnum = (list) => {
    let enumNames = list.map((item) => {
        return {
            label: item.enum, 
            value: item.enum
        }
    })
    return enumNames
}


 // calls /get_enums and hmf_category_ma and holds in enumData.  enumData is passed down to EnumSelector.
 // if EnumSelector sees enumData, it will use that. If not, it will call the API.
 // this prevents the EnumSelector API from being called on every table row
 useEffect(() => {
    // Function to fetch enum data for HMF Category and Vaccine Roadmap from the API
    const fetchEnumData = async () => {
        try {
            // Fetch enum data for 'hmf_category_ma' and 'vaccine_roadmap_ma' including deleted enums
            const hmfResponse = await utils.getData('get_enums', apiKey, '?enum_type=hmf_category_ma&include_deleted=true');
            const vaccineResponse = await utils.getData('get_enums', apiKey, '?enum_type=vaccine_roadmap_ma&include_deleted=true');

            // Set the state variables with the fetched data
            setHMFCategory(stripOutEnum(hmfResponse));
            setVaccineRoadmap(stripOutEnum(vaccineResponse));


        } catch (error) {
            console.error('Error fetching enum data:', error);
        }
    };

    fetchEnumData();
}, [apiKey]); // Empty dependency array ensures this only runs once when the component mounts, preventing the enums from being called for every table row.




    return (
        <div className="market-actions-display">
            {
                !preview ?
                    <>
                        <BreadCrumbBar
                            appName={breadCrumbName}
                        />
                        <h1 className='h1 m-b-start-08 m-b-end-08'>{CONSTANTS.APPS_BY_NAME[breadCrumbName.toUpperCase()]}</h1>
                        <Tabs
                            defaultActiveKey={'summary'}
                            id='pmt-user-tabs'
                            className='app-tabs m-b-end-08'
                        >
                            <Tab
                                eventKey={'summary'}
                                title='Summary'
                            >
                                {
                                    !loading  && tenantSetting ? 
                                        <MarketActionsSummaryView 
                                            appName={appName.fields.apptype}
                                            reportID={PMTCONSTANTS[appName.fields.apptype].DASH_PBI.reportID}
                                            datasetID={PMTCONSTANTS[appName.fields.apptype].DASH_PBI.datasetID}
                                            
                                        />
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                            <Tab
                                eventKey={'detailed-view'}
                                title='Detailed View'
                                >
                                {
                                    parentPMTList && parentPMTList.length > 0 ?
                                        PMTCONSTANTS.APP_NAMES[appName.fields.apptype] === 'Market Actions' ?
                                            <PMTDisplay 
                                                tenant={tenantSetting}
                                                memberList={memberList}
                                                suppliers={suppliers}
                                                options={options}
                                                displayOptionHandler={displayOptionHandler}
                                                apiKey={apiKey}
                                                appName={appName}
                                                handleReload={handleReload}
                                                updateTopList={updateTopList}
                                                topList={parentPMTList}
                                                loading={loading}
                                                handleListUpdate={updateListItemForItemChange}
                                                handleLoadArchived={handleLoadArchived}
                                                listOptionHandler={listOptionHandler}
                                                listOptions={listOptions}
                                                selectionHandler={selectionHandler}
                                                selectedListItems={selectedListItems}
                                                triggerLoading={triggerLoading}
                                                hmfCategory={hmfCategory}
                                                vaccineRoadmap={vaccineRoadmap}
                                            />
                                        : 
                                        PMTCONSTANTS.APP_NAMES[appName.fields.apptype] === "pmt_demand"?
                                            <DemandHealthDisplay
                                                tenant={tenantSetting}
                                                memberList={memberList}
                                                apiKey={apiKey}
                                                appName={appName}
                                                topList={parentPMTList}
                                                loading={loading}
                                                handleListUpdate={updateListItemForItemChange}
                                            />
                                        : ''
                                    : <ListSkeletonLoader preview={false} />
                                }
                            </Tab>
                        </Tabs>
                    </>
                :
                <div className='preview-img-div'>
                    <Image className='preview-card-img' src={appName && appName.fields && appName.fields.previewImage && appName.fields.previewImage.url ? appName.fields.previewImage.url : marketActionsPreviewImage} />
                </div>
            }
        </div>
    )
}

export default PMTApp;