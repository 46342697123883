import React, {useEffect, useState} from 'react';
import { Button } from 'react-bootstrap';

import OrganizationEditor from './OrganizationEditor'
import StatefulSearchTable from '../StatefulSearchTable';
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';

const metaData = [
  {title:'Organization Name', dispField:'org_name', filterField:'org_name'},
  {title:'Country', dispField:'country', filterField:'country'},
  {title:'Gavi Visible', dispField:'gavi_visible', filterField:'gavi_visible'},
  {title:'Licensure Experience', dispField:'licensure_experience'},
  {title:'PQ Experience', dispField:'pq_experience'}
]
const sortOptions=[
  {title:'Organization Name', field:'org_name'},
  {title:'Country', field:'country'}
]

function OrganizationSearch(props) {
  // Raw api response
  // Local representation with data augmentations
  const [organizations, setOrganizations] = useState([])
  // Product that gets edited when double clicked
  const [organization, setOrganization] = useState(null)
  // A toggle for showing the edit product modal
  const [showEditOrg, setShowEditOrg] = useState(false)


  // Automatic trigger to refetch the data whenever the user is done editing the data.
  // This is automatically triggered by switching the t/f variable for showing the editing modal to false
  useEffect(() => {
    if(showEditOrg === false){
      async function fetchData() {
        let result = await utils.getData("get_organizations", props.apiKey)
        let augmentedResult = result.map((r)=>{return {...r,
          country:r.country?r.country.country:''
        }})
        if (result !== null) {
          setOrganizations(augmentedResult);
        }
      }
      fetchData()
    }
    // eslint-disable-next-line
  },[showEditOrg]
  )


  function toggleShowEditOrg(){
    setShowEditOrg(!showEditOrg)
  };

  function createNew(){
    toggleShowEditOrg()
    setOrganization(null)
  }
  
  function update(org){
    toggleShowEditOrg()
    setOrganization(org)
  }

  const toolbarButtons = () => {
    return ( 
    <>
      <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
    </>
    )
  }


  return (
    <div>
      {showEditOrg ?
        <OrganizationEditor 
          id = { organization!==null?organization.org_id:null}
          closePopup = { toggleShowEditOrg }
          apiKey ={props.apiKey}
          show = {showEditOrg}
          onSubmit = {() => setShowEditOrg(false)}
          appClass={props.appClass}
        />  
        : null  
      }
      <StatefulSearchTable
        toolbarButtons={toolbarButtons}
        data={organizations}
        metaData={metaData}
        apiKey={props.apiKey}
        onRowClick={update}
        sortData={sortOptions}
        module='organization'
        editor={props.editor}
        appClass={props.appClass}
      />
    </div>
    
  )
}

export default OrganizationSearch;