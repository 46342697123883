import '../../../styles/layout/_layout.scss';

function AIContentItem({message, scrollToBottom}){

  // Handle image onLoad to trigger scroll after image is rendered
  const handleImageLoad = () => {
    if (scrollToBottom) {
      scrollToBottom(); // Trigger scroll once the image is fully loaded
    }
  };
    
    return <>
        {message.text &&
            <div>
                {message.text} 
            </div>
        }
        {
            message.file_contents &&
                <img 
                    alt='Not found' 
                    className='ai-picture' 
                    src={`data:image/png;base64, ${message.file_contents}`}
                    onLoad={handleImageLoad} // Scroll after image is loaded
                    />
        }
        </>
}

export default AIContentItem;