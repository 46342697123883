import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import {X} from 'react-bootstrap-icons';
import WrkspcAppsToggle from './WrkspcAppsToggle';

import utils from '../../utils';

function UserDetails({ userToShow, masterWrkSpaceList, onClosePanel }) {

    const [ userInfoReady, setUserInfoReady ] = useState(false);
    const [ userToSubmit, setUserToSubmit ] = useState();
    const [ submitting, setSubmitting ] = useState(false);
    const [ superAdminPriv, setSuperAdminPriv ] = useState(false);
    const [ saReady, setSaReady ] = useState(false)

    const { instance, accounts } = useMsal();

    const request = {
        scopes: ["User.Read"]
    };

    const getPyramidSecValue = (userRec) => {
        //array of pyramid roles, get the highest

        if(userRec.pyramidData.length < 1) {
            return 'Not in Pyramid';
        }
        
        if(userRec.pyramidData.length === 1) {
            return userRec.pyramidData[0].roleName;
        }

        if(userRec.pyramidData.length > 1) {
            
            //sort with highest first
            userRec.pyramidData.sort( (a, b) => {
                let nameA = a.roleName.toLowerCase();
                let nameB = b.roleName.toLowerCase();

                if (nameB < nameA) {
                    return -1;
                  }
                  if (nameB > nameA) {
                    return 1;
                  }
                
                  // names must be equal
                  return 0;
            });

            //return first item.
            return userRec.pyramidData[0];
        }

    }

    const getWrkspaceTitleFromList = (wkID, masterList) => {
        let fndWrkspace = masterList.filter( (thisWkspc) => {
            return thisWkspc.tenantID.toLowerCase() === wkID.toLowerCase();
        });

        if(fndWrkspace.length > 0) {
            return fndWrkspace[0].tenantName;
        }

        return '';
    }

    const getIDFromWrkSpcTitle = (wkName, masterList) => {
        let fndWrkspace = masterList.filter( (thisWkspc) => {
            return thisWkspc.tenantName.toLowerCase() === wkName.toLowerCase();
        });

        if(fndWrkspace.length > 0) {
            return fndWrkspace[0].tenantID;
        }

        return '';
    }

    const displayFriendlyDate = (dateVal) => {
        if(isNaN(Date.parse(dateVal))) {
            return dateVal;
        }

        return new Date(dateVal).toLocaleString();

    }

    const toggleAppAccess = (wkspcObj) => {

        let submittedUser = {
            id: userToShow.user_id,
            displayName: userToShow.displayName,
            mail: userToShow.mail,
            securityLevel: userToShow.securityLevel,
            appsList: '',
            forWorkspace: getIDFromWrkSpcTitle(wkspcObj.workspace, masterWrkSpaceList)
        };
        
        
        let wkspcToSearch = Object.keys(userToShow.wrkspaces).map( (thisWk) => {
            return {
                id: thisWk,
                appsList: userToShow.wrkspaces && userToShow.wrkspaces[thisWk].appsList ? userToShow.wrkspaces[thisWk].appsList.split(',') : 'news'
            };
        });


        //find the workspace and extract for submission
        let thisWkspc = utils.filterWkspcListToWrkspc(wkspcToSearch, submittedUser.forWorkspace, 'id');
        
        //let's add / remove the checked / unchecked item from apps list
        if(thisWkspc.hasOwnProperty('id')) {
            if(wkspcObj.value === false) {
                //remove from list
                let itemIndex = thisWkspc.appsList.indexOf( wkspcObj.target );

                if(itemIndex > -1) {
                    thisWkspc.appsList.splice(itemIndex, 1);
                    submittedUser.appsList = thisWkspc.appsList.join(',');
                }
            }

            if(wkspcObj.value === true) {
                //add to list
                // let itemIndex = thisWkspc.appsList.indexOf( wkspcObj.target );

                thisWkspc.appsList.push(wkspcObj.target)
                submittedUser.appsList = thisWkspc.appsList.join(',');
            }

            return submittedUser;
        }

        return null;
        
    }

    const handleAppToggle = (info) => {
        //set the user info in state
        //mark the userInfoReady in state to true

        let userToSubmit = toggleAppAccess(info);

        if(userToSubmit) {
            setUserToSubmit(userToSubmit);
            setUserInfoReady(true);
        }
    }

    const superAdminToggled = (e) => {
        setSuperAdminPriv(e.value);
        setSaReady(true);
    }

    const updateWkspcUser = async (changedUser) => {
        setSubmitting(true);

        let addedParams = `tenid=${changedUser.forWorkspace}`;
        let token = await utils.getAccessToken(instance, accounts[0], request);

        // expects body in this format
        // [
        //     {
        //         "id": "14ac0b8d-d721-48ad-ac94-ad62f3918845",
        //         "displayName": "Aaron Milner",
        //         "mail": "aaron.milner@sassenach.co",
        //         "securityLevel": "6",
        //         "appsList": "news,intelligence,market_actions,vaccine_markets,addressable_pop,company_profiles,glbl_hlth_alchemy,industry_analysis"
        //     }
        // ]

        
        
        try {
            await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/elevate-users/${changedUser.forWorkspace}?${addedParams}`, {
                headers: {
                    "Authorization": "Bearer " + token.accessToken
                },
                method: 'POST',
                body: JSON.stringify([changedUser])
            });
        } catch (error) {
            console.log(error)
        }
        setSubmitting(false);
    };

    const updateSuperAdmin = async (userID, saVal) => {
        setSubmitting(true);

        // console.log('user: ', user, ' saVal: ', saVal)
        let bdyObj = {super_admin: saVal};
        let token = await utils.getAccessToken(instance, accounts[0], request);

        try {
            await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/set-super-admin/${userID}`, {
                headers: {
                    "Authorization": "Bearer " + token.accessToken
                },
                method: 'POST',
                body: JSON.stringify(bdyObj)
            });
        } catch (error) {
            console.log(error)
        }
        setSubmitting(false);
    }

    useEffect( ()=> {
        if(userInfoReady === true) {
            updateWkspcUser(userToSubmit);
            setUserInfoReady(false);
        }

        if(saReady === true) {
            updateSuperAdmin(userToShow.user_id, superAdminPriv);
            setSaReady(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ userInfoReady, saReady ])

    return (
        <div style={{overflowY: 'scroll'}}>
            
            
            <Row>
                <Col>
                    <div className='user_title'>{userToShow.displayName}</div>
                    {userToShow.mail}
                </Col>
                <Col className="align-right">
                    <Button variant="light" onClick={() => onClosePanel(false)}><X /></Button>
                </Col>
            </Row>
            <Row className='info_chunk'>
                <Col><h5>Basic Information</h5></Col>
            </Row>
            <Row>
                <Col>
                    Date Created:
                </Col>
                <Col>
                    {displayFriendlyDate(userToShow.createdDateTime)}
                </Col>
            </Row>
            <Row>
                <Col>
                    Last Login:
                </Col>
                <Col>
                    { displayFriendlyDate(userToShow.last_login) }
                </Col>
            </Row>
            <Row>
                <Col>
                    Security Level:
                </Col>
                <Col>
                    { parseInt(userToShow.securityLevel) === 6 ? 'Q': userToShow.securityLevel }
                </Col>
            </Row>
            <Row>
                <Col>
                    Pyramid Level:
                </Col>
                <Col>
                    { getPyramidSecValue(userToShow) }
                </Col>
            </Row>
            <div className='wrkspc_container'>
                {
                    Object.keys(userToShow.wrkspaces).map( (thisWrkspaceKey) => {
                        return (
                            <WrkspcAppsToggle 
                                wrkspaceName={getWrkspaceTitleFromList(thisWrkspaceKey, masterWrkSpaceList)}
                                workspacesReference={masterWrkSpaceList}
                                isSubmitting={submitting} 
                                workspc={userToShow.wrkspaces[thisWrkspaceKey]}
                                handleToggle={handleAppToggle}
                                superAdminToggled={superAdminToggled}
                                isSuperAdmin={userToShow.super_admin} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default UserDetails