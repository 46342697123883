import React from 'react';
import CONSTANTS from '../../constants';

function EyesSelector({ mode, tenant, intelReport, onChange, className=null }) {

    const getThisValue = ( keyOfObj, formToCheck) => {
        //BMGF -> bMGFEEyes.value
        let theFormKey = CONSTANTS.TENANT_SLUG_TO_EYES[keyOfObj];

        return formToCheck.hasOwnProperty(theFormKey) && formToCheck[theFormKey] === 'on';
    }

    const isLocked = ( keyOfObj) => {
        if (mode === 'read') {
            return true;
        }

        if(tenant.tenant.fields.eyesSelectionLock && tenant.tenant.fields.eyesSelectionLock === 'true') {
            return CONSTANTS.EYES_FOR_TENANT_VIEW[tenant.tenant.fields.tenantslug].indexOf(CONSTANTS.TENANT_SLUG_TO_EYES[keyOfObj]) > -1;
        }

        return false;
    }


    const eyesListToShow =  
        Object.keys(CONSTANTS.TENANT_SLUG_TO_EYES).filter((thisItem) => {
            if (tenant.tenant.fields.tenantslug === "LB-Level-Q") {
                return CONSTANTS.TENANT_SLUG_TO_EYES;
            } else {
                return CONSTANTS.EYES_FOR_TENANT_VIEW[tenant.tenant.fields.tenantslug].indexOf(CONSTANTS.TENANT_SLUG_TO_EYES[thisItem]) < 0;
            }
        });
    
        

    return (
        <>
            <label className={className ? `subtitle-lg ${className}` : 'subtitle-lg'}>Sharing</label>
            <p className='subtitle-sm'>* These orgs have access to this intelligence report</p>
            {
                eyesListToShow.map( (thisKey) => {
                        return (
                            <div className='eyes-checkbox'>
                                <input 
                                    type='checkbox'
                                    name={CONSTANTS.TENANT_SLUG_TO_EYES[thisKey]}
                                    disabled={isLocked(thisKey) } 
                                    defaultChecked={ getThisValue(thisKey, intelReport) } 
                                    onChange={e => onChange(e.target.name, e.target.value)}
                                    className='checkbox'
                                    id={CONSTANTS.TENANT_SLUG_TO_EYES[thisKey]}
                                />
                                <span className='body-large'>{thisKey} Eyes</span>
                            </div>
                        )
                })
            }
        </>
    )
}

export default EyesSelector