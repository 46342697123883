import React, { useState, useEffect } from 'react';

function TableDatePicerCell({ initialValue, rowID, column, updateMyData }) {

    const [ myValue, setMyValue ] = useState();
    const [ firstLoad, setFirstLoad ] = useState(true);

    const onChange = e => {
        let newDate = e.target.value.split('T')
        newDate = newDate[0]
        setMyValue(newDate);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(rowID, column, myValue);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        if (firstLoad === true && initialValue) {
            let dateVal;
            if(initialValue.includes('T')) {
                let val = initialValue.split('T');
                dateVal = val[0];
            } else {
                 dateVal = initialValue;
            }
            setMyValue(dateVal);
            setFirstLoad(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, firstLoad ]);

    return (
        <>
            {
                <input
                    type="date"
                    name={`due-date-${rowID}`}
                    onChange={(e) => onChange(e)}
                    onBlur={(e) => onBlur(e)}
                    value={myValue ? myValue : null}
                />
            }
        </>
    )
}

export default TableDatePicerCell;