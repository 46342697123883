import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import utils from '../../utils';
import { PlusCircle } from 'react-bootstrap-icons';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import WorkspaceCheckList from '../SuperAdminMgmtApp/WorkspaceCheckList';

function InviteUser({ tenID, usersList, workspaceName, superAdmin=false, wrkspcMasterList=null, refreshUserList }) {
    //this component pops up the invite user modal and sends the request

    let [ userToSubmit, setUserToSubmit ] = useState({});
    let [ wkspcsToAddTo, setWkspcsToAddTo ] = useState([]);
    let [ multWkspcAddingMsg, setMultWkspcAddingMsg ] = useState();
    let [ formErrors, setFormErrors ] = useState({});
    let [ sendToServer, setSendToServer ] = useState(false);
    let [ sent, setSent ] = useState(false);
    let [ show, setShow ] = useState(false);
    let [ sending, setSending ] = useState(false);

    const { instance, accounts } = useMsal();

    const request = {
        scopes: ["User.Read"]
    };

    const validateEmail = (emailAddress) => {
        const emailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailFormatRegex.test(emailAddress)
    }

    const isNewUser = (emailAddress) => {
        let checkExist = usersList.filter( (thisUser) => {
            return thisUser.mail.toLowerCase().indexOf(emailAddress.toLowerCase()) > -1
        });

        return checkExist.length < 1;
    }

    const handleWorkspaceForInvite = (e) => {
        let cpyWrkspaces = [...wkspcsToAddTo];
        let idOfWrkspace = e.target.id;
        let checked = e.target.checked;

        if(checked === true) {
            cpyWrkspaces.push(idOfWrkspace);
        } else {
            let idxOfthisWrkspace = cpyWrkspaces.indexOf(idOfWrkspace);

            if(idxOfthisWrkspace > -1) {
                cpyWrkspaces.splice(idxOfthisWrkspace, 1);
            }
        }

        setWkspcsToAddTo(cpyWrkspaces);
    }

    const validateForm = (formObj) => {
        //if invalid or missing, set formErrors and return false
        //else return true
        let newFormErrors = {};
        

        if(!formObj.hasOwnProperty('firstName') || formObj.firstName === '') {
            newFormErrors.firstName = "* Please add first name";
        }

        if(!formObj.hasOwnProperty('lastName') || formObj.lastName === '') {
            newFormErrors.lastName = "* Please add last name";
        }

        if(!formObj.hasOwnProperty('emailAddress') || formObj.emailAddress === '') {
            newFormErrors.emailAddress = "* Please add email";

        }

        //additionally, check that another user with this email does not already exist
        if(formObj.hasOwnProperty('emailAddress')) {


            if(validateEmail(formObj.emailAddress) === false) {
                newFormErrors.emailAddress = "* Please enter a valid email address";
            }

            if(isNewUser(formObj.emailAddress) === false) {
                newFormErrors.emailAddress = "* A user with that email already exists in this group."
            }

            if(formObj.emailAddress.toLowerCase().indexOf('yahoo.com') > -1 || formObj.emailAddress.toLowerCase().indexOf('aol.com') > -1) {
                newFormErrors.emailAddress = "* We do not support that email domain at this time. We prefer Google and Microsoft accounts";
            }
        }

        // setFormErrors(newFormErrors);
        return newFormErrors;

    }

    const inviteUser = async (usr) => {
        let errorsOnForm = validateForm(usr);
        
        if(Object.keys(errorsOnForm).length < 1) {
            let theAccToken =  await utils.getAccessToken(instance, accounts[0], request);
            
            usr.userName = usr.firstName + ' ' + usr.lastName;

            setSending(true);

            let res = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/invite-user/${tenID}?tenid=${tenID}`,{
                method:'POST',
                headers: {
                    authorization: 'Bearer ' + theAccToken.accessToken
                },
                body: JSON.stringify(usr)
            });

            if(res.ok) {
                setSent(true);
                setFormErrors({});
            } else {
                setFormErrors({...formErrors, sendError: '* Something went wrong.  Please try again.'})
            }

            setSendToServer(false);
            setSending(false);
        } else {
            setFormErrors(errorsOnForm);
        }
    };

    const inviteAndAddToSelectedWorkspaces = async (usr, wrkspaces) => {
        
        let errorsOnForm = validateForm(usr);
        
        if(Object.keys(errorsOnForm).length < 1) {
            let theAccToken =  await utils.getAccessToken(instance, accounts[0], request);
            setFormErrors({});
            
            usr.userName = usr.firstName + ' ' + usr.lastName;

            setSending(true);

            let curWrkspace = 0;

            console.log('wrkspaces: ', wrkspaces)

            while(curWrkspace < wrkspaces.length && Object.keys(formErrors).length < 1 && show === true) {
                setMultWkspcAddingMsg('Adding to ' + wrkspaces[curWrkspace] + ` (${curWrkspace+1} of ${wrkspaces.length})`);
                let res = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/invite-user/${wrkspaces[curWrkspace]}?tenid=${wrkspaces[curWrkspace]}`,{
                    method:'POST',
                    headers: {
                        authorization: 'Bearer ' + theAccToken.accessToken
                    },
                    body: JSON.stringify(usr)
                });
    
                if(!res.ok) {
                    setFormErrors({...formErrors, sendError: '* Something went wrong.  Please try again.'})
                } else {
                    setFormErrors({})
                }

                curWrkspace = curWrkspace + 1;
            }

            setSent(true);
            
            setSendToServer(false);
            setWkspcsToAddTo([]);
            setMultWkspcAddingMsg('');
            setSending(false);

        } else {
            setFormErrors(errorsOnForm);
        }
    }


    useEffect(() => {
        if(sendToServer === true) {
            //send to invite
            if(superAdmin && superAdmin === true) {
                inviteAndAddToSelectedWorkspaces(userToSubmit, wkspcsToAddTo);
            } else {
                inviteUser(userToSubmit);
            } 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendToServer]);

    const resetSpace = () => {
        setUserToSubmit({});
        setWkspcsToAddTo([]);
        setSendToServer(false);
        setFormErrors({});
        setSent(false);
        setSending(false);
        
        if(refreshUserList && typeof refreshUserList === 'function'){
            refreshUserList();
        }
        
    }

    const handleClose = () => { 
        setShow(false);
        resetSpace();
    };
    
    const handleClick = (which) => {
        switch(which.toLowerCase()) {
            case 'submit':
                setSendToServer(true);
                break;
            case 'close':
                handleClose();
                break;
            case 'another':
                resetSpace();
                break;
            default:
                break;
        }
    }



    const handleChange = (e) => {
        let cpyUsr = {...userToSubmit};
        let cpyErrors = {...formErrors};

        //clear change if in formerrors;

        cpyUsr[e.target.name] = e.target.value;
        if(e.target.name === 'emailAddress') {
            if(validateEmail(e.target.value) && isNewUser(e.target.value)) {
                delete cpyErrors[e.target.name]; 
            }
        } else if (formErrors.hasOwnProperty(e.target.name) && e.target.value.length > 0){
            delete cpyErrors[e.target.name];   
        }

        setFormErrors(cpyErrors);
        setUserToSubmit(cpyUsr);
    }

    return (
        <>
            <Button variant="primary" onClick={() => {setShow(true)}}>Add Users <PlusCircle /></Button>
            
            
            <Modal className="invite-modal" show={show} onHide={handleClose}>
                
                <Modal.Header>
                    <Modal.Title className='h4'>Invite People to {workspaceName} Workspace</Modal.Title>
                </Modal.Header>
                <Modal.Body className='m-i-end-06'>
                <Form>
                    {
                        sending === true ?
                            <Row><Col className="text-center"><Spinner animation="border" /> Inviting User...</Col></Row>
                        :
                            <fieldset disabled={sent || sending}>
                                <Row>
                                    <Col>
                                        <Form.Label 
                                            htmlFor="firstName" 
                                            className={formErrors && formErrors.firstName ? 'error subtitle-lg' : 'subtitle-lg'}>
                                                First Name *
                                        </Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="firstName"
                                            className='input-2px' 
                                            onChange={(e) => {handleChange(e)}} />
                                        <span className='error-report error'>{formErrors && formErrors.firstName ? formErrors.firstName : null}</span>
                                    </Col>
                                    <Col>
                                        <Form.Label 
                                            htmlFor="lastName" 
                                            className={formErrors && formErrors.lastName ? 'error subtitle-lg' : 'subtitle-lg'}>
                                                Last Name *
                                        </Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="lastName" 
                                            className='input-2px' 
                                            onChange={(e) => {handleChange(e)}} />
                                        <span className='error-report error'>{formErrors && formErrors.lastName ? formErrors.lastName : null}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label 
                                            htmlFor="emailAddress" 
                                            className={formErrors && formErrors.emailAddress ? 'error subtitle-lg' : 'subtitle-lg'}>
                                                Email *
                                        </Form.Label>
                                        <Form.Control 
                                            type="email" 
                                            name="emailAddress"
                                            className='input-2px'  
                                            onChange={(e) => {handleChange(e)}} 
                                            onBlur={() => validateForm(userToSubmit)}/>
                                        <span className='error-report error'>{formErrors && formErrors.emailAddress ? formErrors.emailAddress : null}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="error-report error">{formErrors && formErrors.sendError ? formErrors.sendError : null}</span>
                                    </Col>
                                </Row>
                            </fieldset>
                    }
                    {
                        superAdmin === true && wrkspcMasterList && wrkspcMasterList.length > 0 ?
                            <WorkspaceCheckList 
                                masterList={ wrkspcMasterList } 
                                changeHandler={ handleWorkspaceForInvite } 
                                wrkMessage={multWkspcAddingMsg} />
                        : null
                    }
                </Form>
                </Modal.Body>
                <Modal.Footer className="right-align">
                    <Button className='btn-sm-invisible' onClick={sent ? () => handleClick('another') : () => handleClick('close')}>
                        { sent ? 'Send Another Invite' : 'Cancel'}
                    </Button>
                    <Button className='btn-sm-solid' onClick={sent ? () => handleClick('close') : () => handleClick('submit')}>
                        { sent ? 'Close' : 'Send Invite'}
                    </Button>
                </Modal.Footer>
            </Modal>
                
            
        </>
    )
}

export default InviteUser