import utils from '../utils';

const SearchUtils = {
    getElasticResultsWithTags: async (tagsArray, tenantID, numPerCall=4, page=0, engine='linksbridge', filters={archived: "false"}, start=null, end=null, sortOrder='DATE_DESC') => {

        let searchObj = {
            engine:engine,
            filters:filters,
            size:numPerCall,
            page:page,
            tags: tagsArray,
            start_date: start,
            end_date: end,
            sort_order: sortOrder,
            tenant_id: tenantID
        };

        return await utils.upsert('lb_search', process.env.REACT_APP_DB_PUBLIC_KEY, searchObj)
    }
}

export default SearchUtils;