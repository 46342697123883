import React, {useState} from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';

// to use: wrap the content to be expanded/collapsed like so:
//   <ExpandCollapse>
//         <div>
//             content
//         </div>
//     </ExpandCollapse>


// optional prop 'editorName' is a string defining the editor to be expanded/collapsed.  Example: editorName='Activity'


const ExpandCollapse = ({editorName=null, children, showWarningModal }) => {
    const [isOpen, setIsOpen] = useState(true);

    // Function to toggle expand/collapse
    const toggleExpandCollapse = () => {
        setIsOpen(!isOpen);
    };


    return (
    <>  
        <Row className='m-b-start-10'>
            <Col>

                <div className="d-inline expand-title body-large"><strong>{editorName ? `${editorName} Level Information` : null}</strong></div>
                    {editorName === "Activity"?
                    <Button variant="danger" onClick={showWarningModal} className='btn-primary m-i-end-03'>Delete Activity</Button>
                    : ''
                    }
                    <Button className='button-secondary button-small' onClick={toggleExpandCollapse}>
                        {isOpen ? `Collapse View` : `Expand View`}
                        {isOpen ? <ChevronUp /> : <ChevronDown />}
                    </Button>
             </Col>
        </Row>
        <div className={isOpen?  'full-width': ''}>
            {isOpen ? children : null}
        </div>
    </>
    );
};



export default ExpandCollapse;