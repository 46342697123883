import React, {useState, useEffect} from 'react';
import {Table, Button, Row} from 'react-bootstrap';
import ActivityEditor from './ActivityEditor';
import utils from '../../../utils';
import { ChevronUp, ChevronDown, PlusCircle } from 'react-bootstrap-icons';
import Collapse from 'react-bootstrap/Collapse';
import ToolTip from '../../Layout/ToolTip';

function Codeliveries({apiKey, userName, parentActivity, tooltip, getTooltipContentByKey, appClass}) {
  const [showEditActivity, setShowEditActivity] = useState(false);
  const [activityId, setActivityId] = useState(null);
  const [activityToCopy, setActivityToCopy] = useState(null);
  const [codeliveries, setCodeliveries] = useState([]);
  const [open, setOpen] = useState(true);



// Destructure activity_id from parentActivity. If parentActivity is undefined or null, default to empty object to avoid destructuring errors.
const { activity_id } = parentActivity || {};

// This useEffect is triggered only whenever activity_id, showEditActivity, or apiKey changes.
useEffect(() => {

    async function fetchData() {
        // If no activity_id or if the edit activity UI is currently shown.
        // True ? clear any existing codelivery data and exit the function to prevent unnecessary API calls.
        if (!activity_id || showEditActivity) {
            setCodeliveries([]);
            return;
        }
        const res = await utils.getData('get_codeliveries_by_activity_id', apiKey, `?activity_id=${activity_id}`);
        // Update the state 
        setCodeliveries(res);
    }

    
    fetchData();
}, [activity_id, showEditActivity, apiKey]);




  
  function addCodelivery(){
    setActivityId(null);
    setActivityToCopy(parentActivity);
    setShowEditActivity(true)
  }



  function editCodelivery(e){
    setActivityId(e.activity_id);
    setActivityToCopy(null);
    setShowEditActivity(true);
  }

  return (
    <>
    <Row className='m-b-start-10'>
      <div className='input-text body-large'><strong>Co-Delivered Activities </strong>  <ToolTip className='app-tooltip'tooltip={getTooltipContentByKey(tooltip, 'ce_codelivery')}/></div>
    </Row>
      <Table size='sm'>
        <thead className='table-heading'>
          <tr>
            <th><strong>Activity ID</strong></th>
            <th><strong>Disease</strong></th>
            <th className="align-right">
              <Button onClick={() => setOpen(!open)}
                  aria-controls="collapse-table"
                  aria-expanded={open}
                  className='btn-sm-invisible-dark'
                  >
                {open ? <ChevronUp /> : <ChevronDown />}
              </Button>
            </th>
          </tr>
        </thead>
        <Collapse in={open}>
          <tbody className='table-body'>
            {codeliveries ? codeliveries.map((c, i) => (
              <tr key={i} onDoubleClick={() => editCodelivery(c)}>
                <td>{c.activity_id}</td>
                <td>{c.intervention}</td>
              </tr>
            )) : null}
          </tbody>
        </Collapse>
        {open && (
          <tfoot>
            <tr>
              <td colSpan="3" className="align-right">
                <Button onClick={addCodelivery}>Add Codelivery <PlusCircle /></Button>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
      {showEditActivity ? (
        <ActivityEditor
          apiKey={apiKey}
          userName={userName}
          closePopup={() => setShowEditActivity(false)}
          deliveryId={parentActivity?parentActivity.delivery_id:null}
          activityId={activityId}
          activityToCopy={activityToCopy}
          tooltip={tooltip}
          getTooltipContentByKey={getTooltipContentByKey}
          appClass={appClass}
        />
      ) : null}
    </>
  );
      }

export default Codeliveries;