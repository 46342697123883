import React from 'react';
import { Button } from 'react-bootstrap';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import moment from 'moment';
import { FileArrowDown } from 'react-bootstrap-icons';

import CONSTANTS from '../../constants';
function PMTExcelExport({filteredMarkets, disabled}) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Get the constants as dictionaries
    var hmfCategories = {}
    CONSTANTS.MARKET_ACTIONS_HMF_CATEGORY_SELECT.forEach((cs)=>{
        hmfCategories[cs.value] = cs.label
    })

    var priorities = {}
    CONSTANTS.MARKET_ACTIONS_PRIORITY_SELECT.forEach((ps)=>{
        priorities[ps.value]=ps.label
    })

    var accuracies = {}
    CONSTANTS.MARKET_ACTIONS_ACCURACY_SELECT.forEach((as)=>{
        accuracies[as.value] = as.label
    })

    var due_date_types = {}
    CONSTANTS.MARKET_ACTIONS_DUE_DATE_TYPE_SELECT.forEach((dd)=>{
        due_date_types[dd.value] = dd.label
    })

    // Process the filteredMarkets data into a flat data structure that's Excel friendly
    function processMarketList(){
        
        return filteredMarkets.map((fm)=>{
            const date = new Date(parseInt(fm.due_date))
            const dateStr = moment(date).format('MM/DD/YYYY');
            return {
                'Product': fm.vaccine,
                'HMF Category':fm.hmf_category?
                    fm.hmf_category.map((hc)=>
                        hmfCategories[hc]
                    ).join('; '):null,
                'Objective': fm.objective,
                'Target Outcome': fm.target_outcome,
                'Intervention': fm.task_name,
                'Supplier': fm.supplier,
                'Prioritized Innovation': fm.prioritized_innovation?'Yes':'No',
                'Asignee':fm.assigned_to,
                'Accuracy':fm.accuracy===null?null:accuracies[fm.accuracy],
                'Stakeholder Accountable':fm.stakeholder_accountable,
                'Stakeholder Supporting':fm.stakeholder_supporting,
                'Due Date': fm.due_date?dateStr:null,
                'Due Date Type': fm.due_date_type===null?
                    null:
                    due_date_types[fm.due_date_type],
                'Priority':fm.priority===null?null:priorities[fm.priority],
                'Status':fm.status
            }
        })
    }

    // Do the actual export
    function exportToCSV(){
        const fileName='MarketActionsExport'+moment(new Date()).format('YYYYMMDD')
        const marketData=processMarketList()
        if(!marketData){
            return
        }
        const ws = XLSX.utils.json_to_sheet(marketData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };


    return (
        <Button disabled={disabled} className='icon-btn surface-brand-primary btn-primary-lg' onClick={exportToCSV}>
            <FileArrowDown />
            Export
        </Button>

    )
}

export default PMTExcelExport