import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

function CountriesDropDown({ countryChangeHandler, selectedCountry, ctryList }) {

    const convertCountriesToList = (rawList) => {
        let returnedObj = {};
        
        rawList.forEach(element => {
            let isoCode = element.ISO;
            
            returnedObj[isoCode] = element.country;
        });

        return returnedObj;
    }

    const getCountryTitle = () => {
        let runningTitle ='Select Country';
        if(selectedCountry) {
            return renderedList[selectedCountry];
        } 

        return runningTitle;
    };

    const handleCountryChng = (eventKey) => {
        let retCntryObj = { country: renderedList[eventKey], ISO: eventKey};
        countryChangeHandler(retCntryObj);
    }

    let renderedList;

    if(ctryList.length > 0){
        renderedList = convertCountriesToList(ctryList);
    }

    return (
        <DropdownButton 
            id="dropdown-country" 
            title={getCountryTitle()}
            onSelect={(eventKey) => handleCountryChng(eventKey)} >
            {
                renderedList ? 
                    Object.keys(renderedList).map( (thisItem) => {
                        if (selectedCountry === thisItem) {
                            return <Dropdown.Item 
                                        key={thisItem} 
                                        eventKey={thisItem} 
                                        active >
                                            {renderedList[thisItem]}
                                    </Dropdown.Item>;
                        }

                        return <Dropdown.Item 
                                    key={thisItem} 
                                    eventKey={thisItem}>
                                        {renderedList[thisItem]}
                                </Dropdown.Item>;
                    })
                : null
            }

        </DropdownButton>
    )
}

export default CountriesDropDown
