import React  from 'react';
import { Row, Col, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import Select from 'react-select';
import { XCircle, ChevronDown} from 'react-bootstrap-icons';
import CONSTANTS from '../../constants';
import PMTCONSTANTS from '../PMT/pmtConstants';

function FilterByFilterLine({ filterItem, fieldList, onSet, onDeleteLine, deps }) {

    //field config uses the type of field to provide options for operator and comparator
    const fieldConfig = {};

    fieldConfig[CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.DATE_TYPE] = ['is before', 'is after'];
    fieldConfig[CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.TEXT_TYPE] = [ 'contains', 'does not contain'];
    fieldConfig[CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.NUMERICAL_TYPE] = ['equals', 'does not equal', 'is less than', 'is greater than'];
    fieldConfig[CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.ASSIGNEE_TYPE] = ['is assigned to', 'is not assigned to'];
    fieldConfig[CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.STATUS_TYPE] = ['equals', 'does not equal'];
    fieldConfig[CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.BOOLEAN_TYPE] = ['equals', 'does not equal'];
    fieldConfig[CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.LABEL_LIST_TYPE] = ['equals', 'does not equal'];

    const createOptionsForAssignee = (listToUse) => {
        return listToUse.map( (thisItem) => {
            return { label: thisItem.mail, value: thisItem.mail }
        })
    }

    const listOfAssignees = createOptionsForAssignee(deps.assigneeList);

    const alignForFilterType = (fieldName) => {
        let arrMatch = fieldList.filter( (fieldItem) => {
            return fieldItem.label === fieldName;
        })

        if(arrMatch.length > 0) {
            return arrMatch[0].type;
        } 

        return CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.TEXT_TYPE;
    }

    const getOptionsForOperator = (fieldName) => {
        let typeToUse = CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.TEXT_TYPE;
        
        let arrMatch = fieldList.filter( (fieldItem) => {
            return fieldItem.label === fieldName;
        })

        if(arrMatch.length > 0) {
            typeToUse = arrMatch[0].type;
        } 
        
        return fieldConfig[typeToUse].map( (item) => {
            return { label: item, value: item.replaceAll(' ', '_').toLowerCase() };
        })
    }

    const getLabelForItem = (itemVal, list) => {
        let idxOfItem = list.findIndex( (item) => {
            return item.value = itemVal;
        })

        return list[idxOfItem].label;
    }

    return (
        <div className='m-i-start-06 m-b-end-04'>
            <Row className='filter-row-1'>
                <Col sm={3}>
                    <Form.Label>Where</Form.Label>
                </Col>
                <Col sm={7}>


                    <input type="hidden" value={filterItem.id} name="id" />
                    <Dropdown
                        className='standard-dropdown'
                        onSelect={(e) => onSet({ id: filterItem.id, field: 'fieldForFilter', value: e })}
                        name="fieldForFilter"
                        title={filterItem.fieldForFilter !== null ? filterItem.fieldForFilter : 'Field To Check'} > 
                        <Dropdown.Toggle>{filterItem.fieldForFilter !== null ? filterItem.fieldForFilter : 'Field To Check'} <ChevronDown /></Dropdown.Toggle>

                         <Dropdown.Menu>
                        {
                            fieldList.map((thisField) => {
                                return (
                                    <Dropdown.Item
                                        eventKey={thisField.label}
                                        active={thisField.fieldName === filterItem.fieldForFilter}>
                                        {thisField.label} 
                                    </Dropdown.Item>)
                            })

                        }
                       </Dropdown.Menu> 
                    </Dropdown>
                    
                </Col>
                <Col sm={2} className='svg-col'>
                    <XCircle onClick={() => { onDeleteLine(filterItem.id) }} />
                </Col>
            </Row>
            {
                filterItem.fieldForFilter && filterItem.fieldForFilter !== '' ?
                <Row className='filter-row-2 m-i-end-04 m-b-start-04'>
                    <Col sm={3}>
                        <DropdownButton
                            name="operator"
                            onSelect={(e) => onSet({ id: filterItem.id, field: 'operator', value: e })}
                            title={filterItem.operator !== null ? getLabelForItem(filterItem.operator, getOptionsForOperator(filterItem.fieldForFilter)) : 'Operation'}  >
                            {
                                getOptionsForOperator(filterItem.fieldForFilter).map( (opItem) => {
                                    return (
                                        <Dropdown.Item
                                            eventKey={opItem.value} 
                                            active={filterItem.operator === opItem.value }>
                                            { opItem.label }
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        </DropdownButton>
                    </Col>
                    <Col sm={9}>
                        {
                            alignForFilterType(filterItem.fieldForFilter) === CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.DATE_TYPE ?
                                <Form.Control
                                    type="date"
                                    name="comparator"
                                    placeholder="What To Compare"
                                    value={filterItem.comparator}
                                    onChange={(e) => { onSet({ id: filterItem.id, field: 'comparator', value: e.target.value }) }} /> :

                            alignForFilterType(filterItem.fieldForFilter) === CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.TEXT_TYPE ?
                                <Form.Control
                                    type="text"
                                    name="comparator"
                                    placeholder="What To Compare"
                                    value={filterItem.comparator}
                                    onChange={(e) => { onSet({ id: filterItem.id, field: 'comparator', value: e.target.value }) }} /> :
                        
                            alignForFilterType(filterItem.fieldForFilter) === CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.NUMERICAL_TYPE ?
                                <Form.Control
                                    type="number"
                                    name="comparator"
                                    placeholder="What To Compare"
                                    value={filterItem.comparator}
                                    onChange={(e) => { onSet({ id: filterItem.id, field: 'comparator', value: e.target.value }) }} /> :

                            alignForFilterType(filterItem.fieldForFilter) === CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.ASSIGNEE_TYPE ?
                                <Select 
                                    className="table-dropdown"
                                    name={'assignees'} 
                                    options={ listOfAssignees }  
                                    defaultValue={
                                        listOfAssignees.filter( option => {
                                            return option.value === filterItem.comparator })[0]
                                        } 
                                    onChange={(e) => { onSet({ id: filterItem.id, field: 'comparator', value: e.value }) }} /> :

                            alignForFilterType(filterItem.fieldForFilter) === CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.STATUS_TYPE ?
                                <Select 
                                    className="table-dropdown"
                                    name="Status" 
                                    options={PMTCONSTANTS.STATUS_SELECT} 
                                    defaultValue={
                                        deps.statusList.filter( thisoption => {
                                            return thisoption === filterItem.comparator })[0]
                                        } 
                                    onChange={(e) => { onSet({ id: filterItem.id, field: 'comparator', value: e.value }) }} /> : 
                                   
                            
                            alignForFilterType(filterItem.fieldForFilter) === CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.LABEL_LIST_TYPE ?
                                <Select 
                                    className="table-dropdown"
                                    name={filterItem.fieldForFilter}
                                    options={ deps.hmfCategories } 
                                    defaultValue={
                                        deps.hmfCategories.filter( thisoption => {
                                            return thisoption.value === filterItem.comparator })[0]
                                        } 
                                    onChange={(e) => {  onSet({ id: filterItem.id, field: 'comparator', value: e.value }) }} /> : 
                            null
                        }

                    </Col>
                </Row>
                : null }
        </div>
    )
}

export default FilterByFilterLine