import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import '../../styles/layout/_layout.scss';
import utils from '../../utils';

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function setLabel(theString, labelDict){
  /* Sets the label based on the value and the dictionary passed in.
   If the value is not in the dictionary, it uses the value; otherwise the label in the dictionary
   So if the dictionary is {1:'BMGF', 2:'CEPI'} and you attempted to retrieve 1 it would be 'BMGF'
   If you attempted to retrieve 4, it would return 4 since it's undefined.
   If the returned value from the dictionary is null, or the string is null and there is no dictionary value for null,
   the label will be "<None>"
  */
   let temp
  if (labelDict && labelDict[theString]){
    temp = labelDict[theString]
  } else {
    temp = theString
  }
  // null and undefined should map to <None> false should map to 'false'
  const label = temp!==null&&temp!==undefined?temp:'<None>'
  return label.toString()
}

function DataFilter({ isMulti, filteredData, field, onChange, filters, asNumber=false, labelDict=null}){
  const [ selection, setSelection ] = useState()
  const [ options, setOptions ] = useState()

  useEffect(()=>{
    // Handle the selection when filters are reset
    if(isEmpty(filters)){
      setSelection(isMulti?[]:null)
    }
  }, [filters, field, isMulti])

  useEffect(()=>{
    if(!field){
      return
    }
    // Get just the values from this one field in the data and flatten them into a list
    var temp = filteredData.map((d) => {
      return d[field]
    }).flat()
    // dedupe
    temp = [...new Set(temp)]
    // convert to select options
    temp = temp.map((tl) => {return {value: tl, label: setLabel(tl, labelDict)}})
    if (asNumber===true){
      temp.sort((a,b) => a.value - b.value)
    } else{
      // sort alphabetically
      temp.sort((a, b)=> utils.createStrSortOrder(a.label, b.label))
    }

    // and set the options to that
    setOptions(temp)
  }, [filteredData, field, labelDict, asNumber])

  // This seems to be the only way to style Selects
  const customStyles = {
    control: provided => ({
      ...provided,
      border:0,
      indicatorSeparator:0,
      color: 'black',
      background:'transparent'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color:'black'
    }),
    clearIndicator: provided => ({
      ...provided,
      color:'black'
    }),
    placeholder: provided => ({
      ...provided,
      color:'black'
    })
  }

  function handleChange(e){
    if(isMulti){
      if(e.length<selection.length){
        // Deselection feels more natural as an immediate change
        onChange(e)
        setSelection(e)
      } else{
        // If the onChange event happened here, the select would close
        setSelection(e)
      }
    } else{
      onChange(e)
      setSelection(e)
    }
  }

  return (
    <>
      {
        isMulti?
          <Select
              options = { options }
              closeMenuOnSelect = {false}
              onChange={handleChange}
              isMulti={true}
              onMenuClose={() => {
                onChange(selection)
              }}
              isClearable = {true}
              value = {selection}
              styles={customStyles}
          />
        :
          <Select
                options = { options }
                onChange={handleChange}                  
                isClearable = {true}
                value = { selection }
                styles={customStyles}
            />
      }
    </> 
  )
}

export default DataFilter;