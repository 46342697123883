import React from 'react';

import '../../../styles/layout/_layout.scss'

function UptakeCell(props) {
  return (
    <input
      className={props.value===null?'form-ctrl':props.value > 10? 'ad-hoc':'override'}
      type='number'
      size='sm'
      placeholder={props.default}
      onChange={(e)=> props.onChange(e)}
      value={props.value}
    />
  )
}

export default UptakeCell;