import React, { useState } from 'react';
import { Toast, Container } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import utils from '../../../utils';

function DynamicToast({ content }) {
  
  const [ showToast, setShowToast ] = useState(true);

  const showToastHandler = () => {
    setShowToast(false)
  }

  return (

    <Container className="position-static dynamic-toast-container">
      <Toast show={showToast} onClose={showToastHandler}>
        <Toast.Header>
          <div className="toast-message"><InfoCircleFill />{`${content.title}:`}<span dangerouslySetInnerHTML={ utils.getHTML(content.body)}/></div>
        </Toast.Header>
      </Toast>
    </Container>
  );
}

export default DynamicToast;