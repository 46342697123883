import React from 'react';
import DataChangesLogs from './DataChangesLogs';
import SignInLogs from './SignInLogs'; 

function LogsApp({ preview }) {

    return (
        <>
        <DataChangesLogs preview={preview} />
        <SignInLogs preview={preview} />
       </>
    )
}

export default LogsApp