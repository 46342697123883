import React, {useState, useEffect} from 'react';
import { Row, Col, Card, Spinner } from 'react-bootstrap';
import PendingUsersDisplay from './PendingUsersDisplay';
import WorkspaceUserList from './WorkspaceUserList';
import { useMsal } from '@azure/msal-react';
import utils from '../../utils';
import WorkspaceRequestList from './WorkspaceRequestList';

function WorkspaceSettingsPanel({ tenant }) {

    const [usersList, setUsersList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [workspaceAccess, setWorkspaceAccess] = useState([]);
    const [reloadWkspUser, setReloadWkspUser] = useState(true);
    
  
    let tenID = utils.getForceTenantValue();

    const { instance, accounts } = useMsal();

    const request = {
        scopes: ["User.Read"]
    };

    if (!tenID) {
        if (tenant.tenant && tenant.tenant.fields && tenant.tenant.fields.aDGroupID) {
            tenID = tenant.tenant.fields.aDGroupID;
        }
    }

    

    // Calls endpoint to get users and sets them in state
    const getWkspcUsers = async () => {
        setLoading(true);

        let addedParams = `tenid=${tenID}`;  //&page_num=1&page_size=50
        let theAccToken =  await utils.getAccessToken(instance, accounts[0], request);

        try {
            let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/group/${tenID}?${addedParams}`, {
                headers: {
                "Authorization": "Bearer " + theAccToken.accessToken
                }
            });

            if(reloadWkspUser !== true) {
                utils.trackAppView('settings', tenID, theAccToken.accessToken);
            }

            let users = await response.json();
            //sort users by name in alphabetical order
            users = users.sort((a, b)=> utils.createStrSortOrder(a.displayName, b.displayName))

            setUsersList(users);
        } catch (error) {
            setUsersList([]);
        }
        setLoading(false);
    };

        //sends changed user to endpoint to update
        const updateWkspcUsers = async (changedUsers) => {
            setLoading(true);

            let addedParams = `tenid=${tenID}`;
            let token = await utils.getAccessToken(instance, accounts[0], request);
            
            try {
                await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/elevate-users/${tenID}?${addedParams}`, {
                    headers: {
                        "Authorization": "Bearer " + token.accessToken
                    },
                    method: 'POST',
                    body: JSON.stringify(changedUsers)
                });
                setReloadWkspUser(true)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        };

    const deleteWkspcUsers = async (user) => {
        setLoading(true)

        let addedParams = `tenid=${tenID}`;
        let token = await utils.getAccessToken(instance, accounts[0], request);
        
        try {
            let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/current-user/${user.id}?${addedParams}`, {
                headers: {
                    "Authorization": "Bearer " + token.accessToken
                },
                method: 'DELETE',
            })
            if(response.ok) {
                await getWkspcUsers();
            }
        } catch (error) {
            console.log(error)
        }
        setReloadWkspUser(true)
        setLoading(false)
    }

    const getWkspcAccess = async () => {
        setLoading(true);

        let theAccToken =  await utils.getAccessToken(instance, accounts[0], request);

        try {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/eligible-groups/${tenant.ourUserID}?tenid=${tenID}`, {
            headers: {
                "Authorization": "Bearer " + theAccToken.accessToken
            }
        });
        let wkspc = await response.json();
        setWorkspaceAccess(wkspc);
        } catch (error) {
            console.log('error ', error)
            setWorkspaceAccess([])
        }
        setLoading(false);
    }

    const accessRequestHandler = (wkspc) => {
        let tempArr = workspaceAccess.filter((item) => item.id === wkspc.id)
        setWorkspaceAccess(tempArr)
        requestWksAccess(wkspc);
        
    }

    const requestWksAccess = async (wkspc) => {
        setLoading(true)
        let addedParams = `tenid=${tenID}`;
        let token = await utils.getAccessToken(instance, accounts[0], request);

        try {
            await fetch(`${process.env.REACT_APP_WEB_API}/api/user-service/request-access/${wkspc.id}?${addedParams}`, {
                headers: {
                "Authorization": "Bearer " + token.accessToken
                },
                method: 'POST',
                body: JSON.stringify(wkspc)
            });
            setLoading(false)       
        } catch (error) {
            console.log(error)
        } 
    }

    useEffect(() => {
  
        setLoading(true);
        getWkspcUsers();
        getWkspcAccess();
        setLoading(false)
        setReloadWkspUser(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reloadWkspUser])

    //updates usersList in state with changedUsers to show in UI
    const updateUserList = (userVal, action) => {
        setLoading(true);
        let users = Array.from(usersList);

        if (action ==='update-user') {
            for(let i = 0; i < users.length; i++) {
                for(let j = 0; j < userVal.length; j ++) {
                    if(users[i].id === userVal[j]) {
                        users[i] = userVal[j];
                    }
                }
            }
        } 
        if (action === 'delete-user') {
            users = users.filter((val) => {
                return userVal.indexOf(val) === -1;
            })
        } 
        setLoading(false);
    }

    //adds accepted user from pendingUsers into usersList and updates in state
    const addUserToList = (user) => {
        setLoading(true)
        let users = Array.from(usersList);
        users.push(user);
        setUsersList(users);
        setLoading(false)
    }

    const refreshUserList = () => {
        setReloadWkspUser(true)
    }

    return (
        tenant && usersList && workspaceAccess ?
        <>
            <Card className='settings'>
                <Card.Title>
                    Workspace Settings
                </Card.Title>
            {
                utils.isAUserManager(tenant.tenant, accounts[0]) ? 
                    <>
                        
                        <Row>
                            <Col>
                                <PendingUsersDisplay 
                                    tenID={tenID}
                                    tenant={tenant} 
                                    usersList={usersList}
                                    updateUserList={addUserToList}
                                    loading={loading}
                                    getWkspcUsers={getWkspcUsers}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                
                                <WorkspaceUserList
                                    tenID={tenID}
                                    tenant={tenant} 
                                    usersList={usersList}
                                    updateUserList={updateUserList}
                                    getWkspcUsers={getWkspcUsers}
                                    deleteWkspcUsers={deleteWkspcUsers}
                                    loading={loading}
                                    refreshUserList={refreshUserList}
                                    updateWkspcUsers={updateWkspcUsers}
                                />
                            </Col>
                        </Row>
                        
                    </>
                : null
            }
            <Row>
                <Col>
                    <WorkspaceRequestList 
                        tenID={tenID}
                        tenant={tenant}
                        wrkspAccessList={workspaceAccess}
                        loading={loading}
                        accessRequestHandler={accessRequestHandler}
                    />
                </Col>
            </Row>
        </Card>
        </>
        : <Spinner animation='border' />  
    )
}

export default WorkspaceSettingsPanel