import React from 'react'
import { Modal, Row, Col, Button, Spinner } from 'react-bootstrap';


function ModalDynamic({ classes, content, loadingContent, show, handleClose, submitRequest, id, animation }) {
  return (
    <>
      <Modal 
        id={id}
        show={show} 
        onHide={handleClose} 
        className={`${classes && classes.modal ? classes.modal : ''} confirm-modal`}
        centered
        animation={animation}
      >
        <Modal.Header closeButton>
          <Modal.Title className='title'>{content.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='m-i-start-06'>{ loadingContent ? <div className="text-center"><Spinner animation="border" /></div>: content.body}</Modal.Body>
        <Modal.Footer>
          <Row>
            <Col className='close'>
              <Button className='btn-sm-invisible-100 buttonSmall secondary-link' onClick={(e) => handleClose(e)}>
                Close
              </Button>
            </Col>
            <Col className='confirm'> 
              <Button className='btn-sm-solid-100 buttonSmall tertiary-button' id='modal-submit' onClick={(e) => submitRequest(e)}>
                {content.subName ? content.subName : content.name}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalDynamic