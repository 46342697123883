import React from 'react'
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';


function BtnDynamic({ linkTo=null, classes, actionHandler, content, loading=false, idName}) {

  return (
    linkTo ?
      <LinkContainer to={linkTo}>
        <Button 
          className={classes}
          id={idName}
        >
          {content.icon ? content.icon : ''}{content.name}
        </Button>
      </LinkContainer>
      :
      <Button
        id={idName}
        className={classes}
        disabled={loading}
        onClick={!loading ? actionHandler : null}
      >
      {content.icon ? content.icon : ''}{loading ? 'Loading...' : content.name}
      </Button>
  )
}

export default BtnDynamic