import { Row } from 'react-bootstrap'

import '../../../styles/layout/_layout.scss'
import ProductEditorTechTransferRow from '../Product/ProductEditorTechTransferRow'

function ProductEditorTechTransfer({ product, setProduct, apiKey, organizations, disableProductEdits }) {
    /*----------------------State Variables-----------------------------------*/

    function addTechTransfer() {
        // Creates a new tech transfer row based on tech transfer template
        const tech_transfer = product?.tech_transfer ? product.tech_transfer : []
        tech_transfer.push({
            tech_transfer_id: null,
            product_id: null,
            tech_transfer_type: null,
            year: null,
            originator_id: null,
            description: null,
            source: null,
            status: null,
            is_confidential: false
        })
        setProduct({
            ...product,
            tech_transfer: tech_transfer
        })
    }

    return (
        <div>
            {disableProductEdits ? <div></div> :
                <Row>
                    <button className='clickable-text full-width align-right'
                        onClick={addTechTransfer}>
                            Add
                    </button>
                </Row>
            }
            {product.tech_transfer?.map((o, i) => {
                return <ProductEditorTechTransferRow
                    product={product}
                    setProduct={setProduct}
                    apiKey={apiKey}
                    organizations={organizations}
                    disableProductEdits={disableProductEdits}
                    index={i}
                />
            })}
        </div>
    )
}

export default ProductEditorTechTransfer