import Analytics from 'analytics';
//import hubSpotPlugin from '@analytics/hubspot';
import googleAnalytics from '@analytics/google-analytics';
import { stripHtml } from "string-strip-html";
import Cookies from 'js-cookie';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import moment from 'moment';
import { Archive, Trash3, InfoCircleFill } from 'react-bootstrap-icons';
import withExpiry from './withExpiry';

import CONSTANTS from './constants';

const utils = {

    analytics: null,

    getHTML : (fieldObj) => {
        return { __html: fieldObj }
    },

    formatForInfo : (cmField, countryData, ojbFieldsToFormat=null) => {
        let formattedData;

        //don't modify the actual source field
        let cpiedCMField = `${cmField}`;

        //replace [[colname]] with actual data by that name
        //originally was {{colname}} but changed to [[colname]] to avoid conflict with react rendering
        formattedData = cpiedCMField.replace(/\[\[(.*?)\]\]/g, (match, p1) => {
            let theval = countryData[p1] || '';
            if(ojbFieldsToFormat && ojbFieldsToFormat.hasOwnProperty(p1)) {
                //if formatting is number, format it
                if(ojbFieldsToFormat[p1] === 'number') {
                    theval = new Intl.NumberFormat().format(theval);
                }
            }

            return theval;
        });

        return formattedData;
    },

    convertTextToJSON : (textField) => {
        //first, strip the returns and tabs out.
        let fixedField = textField.replace(/[\r\n\t]/g, "");
        return JSON.parse(fixedField);
    },

    replaceTokensInField : (cmField, dataForReplace) => {
        let formattedData = cmField;

        if(cmField.indexOf('{{') > -1) {
            //replace {{fieldValName}} with actual data by that name
            formattedData = cmField.replace(/{{(.*?)}}/g, (match, p1) => {
                return dataForReplace[p1] || '';
            });
        }


        return formattedData;
    },

    replaceTokensInFilter : (cmField, dataForReplace) => {
        let formattedData = cmField;

        if(cmField.indexOf('**') > -1) {
            //replace {{fieldValName}} with actual data by that name
            formattedData = cmField.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
                return dataForReplace[p1] || '';
            });
        }

        return formattedData;
    },

    convertArrayToObject : ( arrayToConvert ) => {
        let retObj = {};

        arrayToConvert.forEach( (thisItem) => {
            if(thisItem.fields.key) {
                retObj[thisItem.fields.key] = thisItem.fields.htmlvalue;
            }
        });

        return retObj;
    },

    
    capitalCasing: (str) => {

        if (isNaN(str) && str.length >0 && (!str.includes('@') || !str.includes('.com'))) {
            let theTitleArray = str.split('');
            let capitaledFirstLetter = theTitleArray[0].toUpperCase();
            let restOfString = theTitleArray.slice(1, theTitleArray.length)
            let capitalizedString = `${capitaledFirstLetter}${restOfString.join('')}`
     
            return capitalizedString;
        } else {
            return str;
        }
    },

    getOptions : (list, selectName) => {

        if (list) {
            if (selectName === 'status') {
                return list.map((item) => {
                    return {
                      label: item,
                      value: item
                    }
                  })
            } else {
                let tempArr = [''].concat(list)
                return tempArr.map((item) => {
                  return {
                    label: item,
                    value: item
                  }
                })
            }
          }
    },

    subscriptOptArr : (userSettings) => {
        let subscriptionsArr = [];

        userSettings.tenant.fields.apps.forEach((app) => {
            if(app.fields.apptype === 'news' || app.fields.apptype === 'intelligence') {
                subscriptionsArr.push(app.fields.apptype);
            }
        })
        return subscriptionsArr;
    },

    isInArray : (item, arrayToLookIn) => {
        return arrayToLookIn.findIndex( (thisItem) => {
            return thisItem.target === item.target && thisItem.for === item.for;
        });
    },

    filterWkspcListToWrkspc: (wrkspcs, itemToLookupVal, attrtolookup) => {
        //workspaceList that comes from get-tenants-list
        let fndWrkspc = wrkspcs.filter( (thisWkspc) => {
            return thisWkspc[attrtolookup].toLowerCase() === itemToLookupVal.toLowerCase();
        });

        if(fndWrkspc.length > 0) {
            return fndWrkspc[0];
        }

        return {};
    },

    addTagSubscriptionToArray : (e, beMap, subItem, cpyPrefs) => {
        let whatBox = beMap[e.target.name];
        let isChecked = e.target.checked;
        let copy = [...cpyPrefs]

        let newSub = { type: 'tag', for: whatBox, target: subItem.toLowerCase()};

        let alreadyIn = utils.isInArray(newSub, copy);
        
        if(isChecked) {
            //if checked = on, add to our state by name
            if(alreadyIn < 0) {
                copy = [...copy, newSub]
            }
        } else {
            //if checked = off, remove from our state by name
            if(alreadyIn > -1) {
                copy.splice(newSub, 1);
            }
        }
        return copy;
    },

    getKeyOfObjectFromValue : ( obj, value ) => {

        let retArry = Object.keys(obj).filter( (key) => {
            let thisKeyVal = obj[key];
            return thisKeyVal === value.toString()
        });

        return retArry.length > 0 ? retArry[0] : null
    },

    getFeatureflags : (serverLoc, featureType, flags) => {
  
        let sevLoc = serverLoc === 'DEV' ? 'onForDevelopment' : 'onForProduction';
        let retVal = 'false';
        
        for( let i=0; i < flags.length; i++ ) {
            if(flags[i].fields.flagKey === featureType) {
                retVal = flags[i].fields[sevLoc];
            }
        }
        return retVal;
    },

    getKeyValObjFromRichAppSettings : ( itemFieldName, AppSettingsContentObj ) => {
        if (AppSettingsContentObj) {
            let returnedItem = AppSettingsContentObj.fields.richcontentfields.find( (thisItem) => {
                return thisItem.fields.key === itemFieldName;
            });

            if(returnedItem) {
                return returnedItem;
            }
        }

        return '';
    },

    getTagsArray : (tagsList) => {
        let retArr = [];

        tagsList.forEach((tag) => {
            retArr.push(tag.fields.tagtext)
        })
        return retArr;
    },

    setAccessTokenForPyramid : async (authToken) => {
        //request access token from Pyramid using current location hostname and set it to cookie

        let resp = await fetch(`${process.env.REACT_APP_WEB_API}/api/py-auth-token?domain=${window.location.hostname}`, {
            headers: { "Authorization": "Bearer " + authToken.accessToken }
        });

        let accCookieValue = await resp.text();

        Cookies.set('PyramidEmbeddedAuth', accCookieValue);

    },

    isDateValid: (dateToCheck) => {
        return !isNaN(new Date(dateToCheck));
    },

    setForceTenantValue : ( newValue ) => {
        //use localstorage to save the forcevalue
        localStorage.setItem('forceTenantID', newValue);
    },

    removeForceTenantValue : () => {
        localStorage.removeItem('forceTenantID');
    },

    getForceTenantValue : () => {
        //get the tenID if exists, returns null if not set
        return localStorage.getItem('forceTenantID');
    },

    fieldByFieldNameHasContent : ( entity, fieldName ) => {
        return entity.fields.hasOwnProperty(fieldName) && entity.fields[fieldName].length > 0;
    },

    getFieldNameByUrl : ( contentList, urlStr ) => {

        let theFieldObj = contentList.find( (thisItem) => {
            return thisItem.linkLabel === urlStr;
        });

        if(theFieldObj){
            return theFieldObj.fieldName;
        }

        return null;
    },

    getKeyValObjFromAppSettings : ( itemFieldName, AppSettingsContentObj ) => {
        if (AppSettingsContentObj) {
            let returnedItem = AppSettingsContentObj.fields.keyvalues.find( (thisItem) => {
                return thisItem.fields.key === itemFieldName;
            });
    
            if(returnedItem) {
                return returnedItem;
            }
        }
        
        return '';
    },

        //this function takes in a url and looks to see if the template link exists and if so replaces it with the right url
        removeURLPlaceholder: (link, type) => {
            if (type === 'news') {
               return link.replace(/{{news_page_link}}/g, 'cx.linksbridge.com//news/')
            } 
            if (type === 'topics') {
                return link.replace(/{{topic_page_link}}/g, 'cx.linksbridge.com/topics/')
            }
        },

    limitTags : (tagsList, term) => {
        // make copy of state list
        let copyList = [...tagsList]
 
        if(term) {
            //convert to string inorder to lower case array values
            let convertToStrToLowerCase = copyList.toString().toLowerCase()
            //convert back to an array
            let convertToArr = convertToStrToLowerCase.split(',')

            if (convertToArr.includes(term.toLowerCase())) {
                //find the index of the tag that is being searched for
                let idxOfTag = convertToArr.indexOf(term.toLowerCase())
                // create a copy of the tag
                let tagToMove = copyList[idxOfTag]
                // //add tag to be the first value in the array
                copyList.unshift(tagToMove);
                //remove the repeat tag from the array
                copyList.splice(idxOfTag+1, idxOfTag+1);
            } else {
                // shorten the taglist to be only the first 5 tags
                copyList = copyList.slice(0,5);
            }
        }
        // shorten the taglist to be only the first 5 tags
        copyList = copyList.slice(0,5);
        return copyList;
    },
    
    findURLPath : (apps, urlPath) => {
        if(apps) {
            let pathToReturn;
            for (let i = 0; i < apps.length; i ++){
                if (apps[i].fields.apptype === urlPath) {
                    if(apps[i].fields.customctalink && !apps[i].fields.customCTALinkName) {
                        pathToReturn = apps[i].fields.customctalink;
                    } 
                    else if(apps[i].fields.customCTALinkName) {
                        pathToReturn = apps[i].fields.customCTALinkName;
                    }
                    else {
                        pathToReturn = apps[i].fields.apptype;
                    }
                    return pathToReturn.toString();
                }
            }
        }
    },

    findUserInfo: (tenUserID, membersList) => {
        let user = membersList.filter((member) => {
            return member.id === tenUserID;
          })
          return user;
    },

    getUserEmailPrefs: async (token) => {

        let response =  await fetch(`${process.env.REACT_APP_WEB_API}/api/preferences`, {
            headers: {
                "Authorization": "Bearer " + token.accessToken
            }
        });

        let res = await response.json();
        return res;
    },

    saveUserEmailPrefs: async (prefToUpdate, token) => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/preferences`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + token.accessToken
            },
            body: JSON.stringify(prefToUpdate)
        });
        let res = await response.json();
        return res;
    },

    deleteUserEmailPrefs: async (userPrefs, token) => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/preferences`, {
            method: 'DELETE',
            headers: {
                "Authorization": "Bearer " + token.accessToken
            },
            body: JSON.stringify(userPrefs)
        });
        let res = await response.json();
        return res;
    },

    getAppName: (appKey) => {

        if(CONSTANTS.APPNAMES_BY_PATH.hasOwnProperty(appKey.toLowerCase())){
            return CONSTANTS.APPNAMES_BY_PATH[appKey.toLowerCase()];
        }
        if(CONSTANTS.APPS_BY_NAME.hasOwnProperty(appKey.toUpperCase())){
            return CONSTANTS.APPS_BY_NAME[appKey.toUpperCase()];
        }

        return null;
    },

    getAccessToken: async (instance, account, request) => {
        try{
            let theAccToken = await instance.acquireTokenSilent({...request, account: account});
            return theAccToken;
        } catch(error) {
            if (error.name === "InteractionRequiredAuthError") {
                try {
                    return await instance.acquireTokenPopup({...request, account: account});
                } catch (err) {
                    console.log('error: ', err);
                }
                  
              } else {
                console.error(error);
              }
            //if expired, force popup
            console.log('error', error);
            if (error.name === "InteractionRequiredAuthError" || error.name === "ClientConfigurationError") {
                try {
                    let theAccToken = await instance.acquireTokenPopup({...request, account: account});
                    return theAccToken;
                } catch (err) {
                    console.log('error: ', err);
                }
                  
              } else {
                console.error(error);
              }
        }
    },

    isAUserManager : (tnt, userAcct) => {
        if(tnt.fields.userManagers) {
            let isMgr = tnt.fields.userManagers.findIndex( (thisTnt) => { return userAcct && userAcct.username ? thisTnt.fields.emailAddress.toLowerCase().indexOf(userAcct.username.toLowerCase()) > -1 : '' });
      
            return isMgr > -1;
        }

        return false;
        
    },

    getTenantContent: async (token, app, userCGs, uid, extraParams) => {
        let addedParams = '';

        if(extraParams && extraParams.length > 0) {
            addedParams = `&${extraParams}`;
        }

       
        //use the app fields test list
        if(process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.toLowerCase() === 'dev') {
            addedParams = addedParams + '&use_test_list=true';
        }

        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-tenant-content?app=${app}&userCGs=${userCGs}&uid=${uid}${addedParams}`, {
            headers: {
              "Authorization": "Bearer " + token.accessToken
            }
        });

        let jsonResp = await response.json();

        return jsonResp;
    },

    createUUID: () => {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          // eslint-disable-next-line no-mixed-operators
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        )
    },

    initializeTracking: function(){
        this.analytics = Analytics({
            app: 'Linksbridge Platform',
            version: 100,
            plugins: [
              googleAnalytics({
                trackingId: 'UA-128191977-2',
              }),
            //   hubSpotPlugin({
            //     portalId: '8615684'
            //   })
            ]
          });
    },

    // initializeGA: () => {
    //     ReactGA.initialize('UA-128191977-2');
    // },

    gaPageView: function(pageName){
        // ReactGA.pageview(pageName);
        this.analytics.page();
    },

    gaEvent: function( eventObj ) {
        // ReactGA.event({       
        //     category: eventObj.categoryName,  // Required
        //     action: eventObj.eventName,       // Required
        //     label: eventObj.label,       
        //     ...eventObj     
        // });
        this.analytics.track(eventObj.categoryName, {
            action: eventObj.eventName,
            ...eventObj
        })
    },

    trackAppView: async (appName, tenID, userToken) => {
        //does a post to logging service with token 
        // body: { forApp: appName, tenID: tenantID }

        //first, let's see if they have accessed this app within the last 5 min, 
        //if so, don't send tracking
        // if not, make the call and set the expiring item

        let identifierKey = tenID.toLowerCase() + '-' + appName.toLowerCase();
        let isFound = withExpiry.getWithExpiry(identifierKey); //returns null if not found

        if(isFound === null) {
            await fetch(`${process.env.REACT_APP_WEB_API}/api/logs/signins`, {
                method: 'POST',
                headers: {
                    Authorization: userToken.accessToken ? userToken.accessToken : userToken
                },
                body: JSON.stringify({
                    forApp: appName,
                    tenID: tenID
                 })
            });

            withExpiry.setWithExpiry(identifierKey, 'tracked', 5*60*1000 ); //5 min in ms
        }


        

    },

    getPreviewTextFromHTML: function( fieldToConvert, numChar ) {
        let strippedText = stripHtml(fieldToConvert).result;
        if(strippedText.length > numChar ) {
            return strippedText.substr(0,numChar) + '...'
        }

        return strippedText;
    },

    getShortDateName: function(date, type) {
        const MONTHSNAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let dateArr = date.split('/')
        //Returns just the month
        if (type === 'month') {
            let monthNum = parseInt(dateArr[0])
            return MONTHSNAMES[monthNum -1];
        }
        //Returns just the day num
        if (type === 'day') {
            return dateArr[1];
        }
        //Returns the year in full
        if (type === 'year') {
            return dateArr[2];
        }
    },

    getIndexOfValueInArrayOfObjectsByProp: function(propName, value, listToCheck) {
        const lenListToCheck = listToCheck.length;

        if(lenListToCheck < 1) {
            return undefined;
        }

        for(let i=0; i<lenListToCheck; i++) {
            if(listToCheck[i][propName] === value){
                return i;
            }
        }

        //iterated through the list and didn't see it
        return undefined;
    },

    sortByDate: function(list, dateFieldName, direction) {
        
        if(list.length > 0) {
            return list.sort( (firstElem, secondElem) => {
                let firstDate;
                let secDate;
                if( dateFieldName === "datepublished"){
                    firstDate = new Date(firstElem.fields.SORT_BY_RECENT[dateFieldName]);
                    secDate = new Date(secondElem.fields.SORT_BY_RECENT[dateFieldName]);
                } else {
                    firstDate = new Date(firstElem.dateposted.raw);
                    secDate = new Date(secondElem.dateposted.raw);
                }
                if (firstDate < secDate) {
                    return direction.toUpperCase() === 'ASC';
                }

                if (firstDate > secDate) {
                    return direction.toUpperCase() === 'ASC';
                }

                return 0;
                
            })
        }
    },

    filterToDateRange: function(list, dateFieldName, range) {
        let backDate = new Date();
        backDate.setHours(backDate.getHours()-range);

        return list.filter( (thisItem) => {
            return new Date(thisItem.fields.dateFieldName) > backDate;
        })
    },

    filterToCountry: function(list, countryFieldName) {
        return list.filter( (thisItem) => {
            if(thisItem.fields.headquartersCountry) {
                return thisItem.fields.headquartersCountry.toLowerCase().trim().indexOf(countryFieldName.toLowerCase().trim()) > -1;
            } else {
                return false;
            }
        })
    },

    filterToCompanyType: function(list, companyType) {
        return list.filter((thisItem) => {
            if (thisItem.fields.companyType) {
                return thisItem.fields.companyType.toLowerCase().trim().indexOf(companyType.toLowerCase().trim()) > -1;
            } else {
                return false;
            }
        })
    },

    containsAFilter: function(list, nameOfFilter) {
        return list.some( (thisElem) => {
            return thisElem.name === nameOfFilter && thisElem.value !== '';
        });
    },

    tagTracker: async (tagToSend) => {
        await fetch(`${process.env.REACT_APP_WEB_API}/api/tag-tracking?tag=${tagToSend}`);
    },

    convertTagListToString: (tagList) => {
        let accumeArray = [];
        tagList.forEach((item) =>
        accumeArray.push(item.fields.tagtext)
        )
        return accumeArray.join(',')
    },

    getRelatedTags: (tagList) => {
        let tagArray = tagList.slice(0, 2);
        return tagArray;
    },

    getValueForDate: function(list) {
        for(let i=0; i<list.length; i++) {
            if(list[i].name === 'Date Range') {
                 switch (list[i].value) {
                     case 'this-month':
                         return CONSTANTS.DATE_RANGES.THIS_MONTH;
                     case 'this-week':
                        return CONSTANTS.DATE_RANGES.THIS_WEEK;
                    default:
                        return CONSTANTS.DATE_RANGES.TODAY;
                 }
            }
        }
    },

    getValueForSortBy: function(list) {
        for(let i=0; i<list.length; i++) {
            if(list[i].name === 'Sort By') {
                switch (list[i].value) {
                    case 'relevance':
                        return CONSTANTS.SORT_BY_ITEMS.RELEVANCE;
                    case 'most-recent-date':
                        return CONSTANTS.SORT_BY_ITEMS.MOST_RECENT;
                    case 'oldest-date':
                        return CONSTANTS.SORT_BY_ITEMS.OLDEST;
                   default:
                       return '';
                }
            }
        }
    },

    getValueForCountry: function(list) {
        for(let i=0; i<list.length; i++) {
            if(list[i].name === CONSTANTS.FILTER_TYPES.COUNTRY_FILTER) {
               return list[i].value;
            }
        }
    },

    getValueforCompanyType: function(list) {
        for(let i=0; i<list.length; i++) {
            if(list[i].name === CONSTANTS.FILTER_TITLES.COMPANY_TYPE_FILTER) {
               return list[i].value;
            }
        }
    },

    addFilters: function(existingFilter, filterVal) {
        if(existingFilter !== '') {
            filterVal = existingFilter + ',' + filterVal;
            return filterVal;
        } else {
            filterVal = '&filters=' + filterVal;
            return filterVal;
        }
    },

    getValueForName: function(list) {
        for(let i=0; i<list.length; i++) {
            if(list[i].name === CONSTANTS.FILTER_TYPES.NAME_FILTER) {
               return list[i].value;
            }
        }
    },

    getValueOfCurrentFilterByFilterName: function (list, nameOfFilter) {

        let pickedFilter = list.filter( (listItem) => {
            return listItem.name.toLowerCase().indexOf(nameOfFilter.toLowerCase()) > -1;
        });

        if(pickedFilter.length > 0) {
            return pickedFilter[0].value;
        } else {
            return undefined;
        }

    },

    addTargetToLinksInHtml: function(linkFields) { 
        //this function takes our "links" field from agility, and puts a 'target="_blank" in each
        return linkFields.replace(/<a href=/g, '<a target="_blank" rel="noreferrer" href=');
    },

    cleanUpNavs: function (navs, profile) {

        let useNavs = [];

        //check each field at nav level, take off nav obj if no object in profile - helper functions
        const getPageItemsWithContent = (pageItemsArray, profile) => {

            let retArray = [];

            pageItemsArray.forEach( (thisPItem) => {
                if( thisPItem.fieldAPIName === 'news' || thisPItem.fieldAPIName === 'ircNews' ||
                    (profile.fields.hasOwnProperty(thisPItem.fieldAPIName) && 
                        (profile.fields[thisPItem.fieldAPIName] !== '' && profile.fields[thisPItem.fieldAPIName] !== null)) ) {
                    retArray.push(thisPItem);
                }
            });

            return retArray;
        };

        const getSubNavsWithContent = (subNavArray, profile) => {
            let retArray = [];
            
            //get a subNav array
            subNavArray.forEach( (thisItem) =>{
                let tempObj = {...thisItem, pageItems: getPageItemsWithContent(thisItem.pageItems, profile)}
                if(tempObj.pageItems.length > 0) {
                    retArray.push(tempObj);
                }
            });
    
            return retArray;
        };

        if(navs && navs.length > 0) {
            navs.forEach( (thisNavItem) => {
                let tempObj = {};
                tempObj = {...thisNavItem, subNav: getSubNavsWithContent(thisNavItem.subNav, profile)};
                if(tempObj.subNav.length > 0){
                    useNavs.push(tempObj);
                }
            });
        }

        return useNavs;

    },

    submitIntelligenceRequest: async (action, reportID, accessToken, report) => {
        let params = action.toLowerCase() === "archived" ? 'archived=true' : action.toLowerCase() === "delete" ? 'del=true' : '';
        let bodyToSend;
      
        if (action === 'ARCHIVED') {
        //updates the form.archive state - toggles to current val
         bodyToSend = utils.updateArchiveState(action, report);
       } else {
         bodyToSend = {
           contentID: reportID
         }
       }
      
        await fetch(`${process.env.REACT_APP_WEB_API}/api/edit-item?${params}`, {
          headers: { "Authorization": "Bearer " + accessToken.accessToken },
          method: 'POST',
          body: JSON.stringify(bodyToSend)
        })
      
      },
      
    //toggles intervention form.archive state
    updateArchiveState: (action, temp) => {
        let tempArchive = temp.archived === 'true' ? 'false' : 'true';
      
        if (action === 'ARCHIVED') {
          temp.archived =  tempArchive;
        }
        return temp;
      },

    getPageOfResults: function( originalList, pageNumber, numberInPage ) {
        let startOfSlice = (pageNumber-1) * numberInPage;
        let endOfSlice = startOfSlice + numberInPage;
        let slicedList = originalList.slice(startOfSlice, endOfSlice);
        return slicedList;
    },

    getListOfTags: function(tagList) {
        let retField = [];

        //iterate through the tags list, and create a comma separated list of tags
        for(let i=0; i<tagList.length; i++) {
            if(tagList[i].fields && tagList[i].fields.tagtext){
                retField.push(tagList[i].fields.tagtext);
            }
        }

        return retField.join(',');
    },

 

    getDefaultNavItem: function(navlist) {
        //get the first item in the array that doesn't have -- for groupingtitle
        for(let i=0; i<navlist.length; i++) {
            if(navlist[i].groupingTitle.trim() !== '--') {
                return navlist[i].subNav[0].urlForField;
            }
        }

        return null;
    },

    //Displays date in yyyy-mm-dd format
    getDashDateString : (dateStr) => {
        if(!dateStr){
            return null
        }
        let dateArr = dateStr.split('/')
        return `${dateArr[2]}-${dateArr[0] && (parseInt(dateArr[0]) < 10) ? '0' + dateArr[0]: dateArr[0]}-${dateArr[1]}`
    },

    //Depending on desired format displays date string
    getDateString : (dateStr, format) => {
        const longMonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let datePcs;
        let mnthShown;

        //formats new date()
        if(format === 'newDate' || format === 'newDateDash') {
            let dateArr = dateStr.toString().split(' ')

            for (let i = 0; i < shortMonthNames.length; i++) {
                if (shortMonthNames[i] === dateArr[1]) {
                    if (format === 'newDateDash') {
                        //formats for days 1-9 and adds a 0
                        if (i < 9) {
                        
                            return `${dateArr[3]}-0${i + 1}-${dateArr[2]}`
                        } else {
                            //fomats for days 10-31
                            return `${dateArr[3]}-${i + 1}-${dateArr[2]}`
                        }  
                    }
                    //formats for days 1-9 and adds a 0
                    if (i < 9) {
                        return `0${i + 1}/${dateArr[2]}/${dateArr[3]}`
                    } else {
                        //fomats for days 10-31
                        return `${i + 1}/${dateArr[2]}/${dateArr[3]}`
                    }
                }
            }
        } else {

            let cutDate = dateStr.split('T')[0];
            datePcs = cutDate.split('-');
            mnthShown = parseInt(datePcs[1]);
            //Displays long month format January 05, 2023
            if(format === 'long') {
                return `${longMonthNames[mnthShown-1]} ${datePcs[2]}, ${datePcs[0]}`;
            } 
            //diplays short month format Jan 05, 2023
            if(format === 'short') {
                return `${shortMonthNames[mnthShown-1]} ${datePcs[2]}, ${datePcs[0]}`;
            } 
            //displays long DD Month YYYY
            if (format === 'long-day-first') {
                return `${datePcs[2]} ${longMonthNames[mnthShown-1]}, ${datePcs[0]}`
            }
            //Diplays yyyy/mm/dd
            return `${datePcs[1]}/${datePcs[2]}/${datePcs[0]}`;
        }

    },

    formatDateForPicker: (dtToFormat) => {
        let dtDate;

        if(typeof(dtToFormat) === 'string' || typeof(dtToFormat) === 'number') {
            dtDate = new Date(dtToFormat);
        
        } else {    
            dtDate = dtToFormat;
        }
        
        if(!isNaN(dtDate)) {
            let year = dtDate.getFullYear();
            let mon = dtDate.getMonth()+1;
            let dy = dtDate.getDate();

            if(mon < 10) {
                mon = '0' + mon;
            }

            if(dy < 10) {
                dy = '0' + dy;
            }

            return `${year}-${mon}-${dy}`
        }
        

        return '';
    },

    getMonthAbbrvName: (monthNum) => {
        const mnths = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        
        if(monthNum > 0 && monthNum < 13) {
            let actMnth = monthNum - 1;
            return mnths[actMnth];
        } else {
            return undefined;
        }
    },

    //market actions related stuff
    setMembersListBasedOnMarketName: (memberList, existAssigneesList, marketname, shouldFilter=true, ten) => {

        const market_names_for_LB_users = ['test market'];

        const filterOutLBUsers = (list) => {
            
            if(!list[0].hasOwnProperty('mail')) {
                return list.filter( (thisItem) => {
                    return thisItem &&
                            thisItem.toLowerCase().indexOf('@linksbridge.com') < 0 &&
                            thisItem.toLowerCase().indexOf('@sassenach.co') < 0 &&
                            thisItem.toLowerCase().indexOf('@linksbridgedevtest.onmicrosoft.com') < 0
                });
            }

            //filter the linksbridge / sassenach / linksbridgedevtest users 
            return list.filter( (thisItem) => {
                return thisItem &&
                        thisItem.mail.toLowerCase().indexOf('@linksbridge.com') < 0 &&
                        thisItem.mail.toLowerCase().indexOf('@sassenach.co') < 0 &&
                        thisItem.mail.toLowerCase().indexOf('@linksbridgedevtest.onmicrosoft.com') < 0
            });
        };

        //put the assignees into an array of simple strings, taking out null users, and sort by alpha
        let assigneesRaw = memberList.filter((item) => {
            return item !== null;
            }).map((thisPerson) => {
            return thisPerson;
            }).sort();


        let assignees = Array.from(new Set(assigneesRaw.concat(existAssigneesList)));

        if(market_names_for_LB_users.indexOf(marketname) < 0 && shouldFilter === true) {
            if(ten.fields.tenantslug === 'LB-Level-Q') {
                return assigneesRaw;
            }
            return filterOutLBUsers(assignees);
        }

        return assignees;

    },

    snakeToTitleCase: function(s){
        return s.replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
         .replace (/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
    },

    getData: async function (endPoint, apiKey, query){

        const headers= {
            'Content-Type': 'application/json',
            'access_token': apiKey
        }
        try{
            const result = await fetch(process.env.REACT_APP_DB_API_URL+endPoint+
                (query!==undefined?query:''), 
                {headers:headers})
            if (result['status'] === 403) {
                return "Invalid API key, access denied"
            }
            return result.json()

        } catch(error){
            return "Failed to fetch data. Please contact an administrator";
        }
        
    },
    
    delete: async function (endPoint, apiKey, data){

        const headers = {
            'Content-Type': 'application/json',
            'access_token': apiKey
        }
        const requestOptions = {
          method: 'DELETE',
          headers:headers,
          body: JSON.stringify(data)
        }
        let result = await fetch(process.env.REACT_APP_DB_API_URL+endPoint, requestOptions)
        let resultJson = await result.json()
        if (result['status'] === 403 ) {
          return "Invalid API key, access denied"
        }
        if (result['status'] === 422) {
          // Basic response parsing for known errors, so far only duplicate key errors
          if (resultJson['detail'].includes("not allow nulls")){
            return "There is a field in this record that should not be null"
          }else if (JSON.stringify(resultJson['detail']).includes("is not a valid")){
            return "There is an incorrect type in a field"
          }else{
            // Fall back case. Try to get the user to send the detailed error report.
            return "Unable to delete for an unknown reason"
          }
        }
        return resultJson
    },


    upsert: async function (endPoint, apiKey, data){
        try{
            const headers = {
                'Content-Type': 'application/json',
                'access_token': apiKey
            }
            const requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            }
            let result = await fetch(process.env.REACT_APP_DB_API_URL + endPoint, requestOptions)
            let resultJson = await result.json()
            if (result['status'] === 403) {
                return "Invalid API key, access denied"
            }
            if (result['status'] === 422) {
                // Basic response parsing for known errors, so far only duplicate key errors
                if (resultJson['detail'].includes("duplicate key")) {
                    return "This record already exists in the database. Please find and update that record."
                }
                else if (resultJson['detail'].includes("not allow nulls")) {
                    return "There is a field in this record that should not be null"
                } else if (JSON.stringify(resultJson['detail']).includes("is not a valid")) {
                    return "There is an incorrect type in a field"
                } else {
                    // Fall back case. Try to get the user to send the detailed error report.
                    return "Unable to create or update for an unknown reason"
                }
            }
            if (result['status'] === 500){
                return 'An unexpected error occurred, this update may result in duplicate records. Please contact an admistrator or open a ticket'
            }
            return resultJson
        }catch (error) {
            return "Unknown issue adding or creating data"
        }
    },

    isMNCTenant: (tenantSetting) => {
        return tenantSetting.tenant.fields.hasOwnProperty('isMNC') && tenantSetting.tenant.fields.isMNC === "true";
    },

    getDefaultEyesChecked : (tenant, constants) => {
        let returnObj = {};
        let tempArr;
        
        if (tenant.tenant.fields.tenantslug === "LB-Level-Q") {
            tempArr = Object.keys(CONSTANTS.TENANT_SLUG_TO_EYES);
        } else {
            tempArr = CONSTANTS.EYES_FOR_TENANT_VIEW[tenant.tenant.fields.tenantslug];
        }
        tempArr.forEach( (thisEye) => {
          returnObj[thisEye] = 'on';
        });
        return returnObj;
    },
    
    convertInfoOwnerNumberToSlug: (infoOwner, listOfSlugs) => {
        let returnKey = Object.keys(listOfSlugs).filter( (thisKey) => {
            return listOfSlugs[thisKey].toString() === infoOwner.toString();
        });

        return returnKey.length > 0 ? returnKey[0] : undefined;
    },

    createStrSortOrder: function(a, b){
        if (a.toLowerCase() < b.toLowerCase()){
          return -1;
        } else if (a.toLowerCase() > b.toLowerCase()){
          return 1;
        } else {
          return 0;
        }
    },

    getIcon : (type) => {
        return (
            type === 'delete' ?
              <Trash3 className='btn-icon'/>
              : 
                type === 'archive' ?
                  <Archive className='btn-icon' />
                  : ''
          )
    },

    getTitle : (title) => {
        return (
            <>
              <InfoCircleFill className=' m-b-end-02'/>{title}
            </>
        )
    },

    getCustomFieldObjByName : (customFields, fieldName) => {

        if(customFields && Array.isArray(customFields)){
            let fieldValueArr = customFields.filter((item) => {
            return item.name.toLowerCase() === fieldName.toLowerCase();
            });
        
            if (fieldValueArr.length > 0) {
            return fieldValueArr[0]
            }
        }
        
        return null;
    },

    makeOrdinal: function(n){
        // Convert from a number, say 1, to the ordinal version "1st"
        const nStr = String(n)
        // Handle the non-conforming cases
        if ([11,12,13].includes(n)){
          return nStr+'th'
        }
        // Handle the standards for st, nd, and rd
        if (nStr.charAt(0) === '1'){
            return nStr+'st'
        }
        if (nStr.charAt(0) === '2'){
            return nStr+'nd'
        }
        if (nStr.charAt(0)==='3'){
            return nStr+'rd'
        }
        return nStr+'th'
      },

    numberWithCommas: function(x) {
        // Handle the falsies
        if (!x){
            return null
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
    exportExcel(theData, baseFileName){
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
          const fileName=baseFileName+moment(new Date()).format('YYYYMMDD')
          if(!theData){
              return
          }
          const ws = XLSX.utils.json_to_sheet(theData);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, fileName + fileExtension);
      },
    

  // Utility function to format numbers with commas for display
    formatNumberWithCommas: (number) => {
    if (number === null || number === undefined) {
      return '';
    }
    return number.toLocaleString();
  }



}

export default utils