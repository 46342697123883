const checkForBasicErrors = (product) => {
  let basicErrors = []
  // Check basic info errors
  if (product.vaccine_id === null){
    basicErrors.push("This product must have a vaccine")
  }
  if (product.lb_status === null) {
    basicErrors.push("Please select a product status")
  }
  if (product.min_age !== null && product.min_age_unit === null){
    basicErrors.push("The minimum age is defined but the unit is not")
  }
  if (product.max_age !== null && product.max_age_unit === null){
    basicErrors.push("The maximum age is defined but the unit is not")
  }
  return basicErrors
}

export default checkForBasicErrors;