import _ from 'lodash';

// gets a range of years from 2000 to 2040 and sets in variable
// by putting it here it doesn't get re-called on every render
const columnYears = _.range(2000,2041,1)

// get objects for each year and the estimate_value
const getTheYears = (capacityList) => {
  let tempObj = {};

  for( let i = 0; i < capacityList.length; i++) {
    tempObj[capacityList[i].year] = capacityList[i].estimate_value ? capacityList[i].estimate_value : 0;
  }
  return tempObj
}

const getCapacities = (capacityLevel) => {
  let tempObj = {}

  tempObj[capacityLevel.capacity_estimate_category] = getTheYears(capacityLevel.capacities)
  return tempObj;
}

//format capacities into an object to easily access values for validation
const formatCapacityOBJ = (capacityDetails) => {
  let returnObj = {};
  capacityDetails.forEach((curLevel) => {
    //create the capacity_type object set it equal to previously added objs and call function to get the estimate categories
    returnObj[curLevel.capacity_type] = {...returnObj[curLevel.capacity_type], ...getCapacities(curLevel)}
  })
  return {...returnObj};
}

//compares each year value with each capacity_type to make sure values are as expected
const compareCapacities = (obj, year, recipient, totalobj) => {
  //create variable for readability
  let low = obj.Low ? obj.Low[year] : 0;
  let base = obj.Base ? obj.Base[year] : 0;
  let high = obj.High ? obj.High[year] : obj.Base[year];
  let totalLow = totalobj && totalobj.Low ? totalobj.Low[year] : Number.MAX_SAFE_INTEGER;
  let totalBase = totalobj && totalobj.Base ? totalobj.Base[year] : Number.MAX_SAFE_INTEGER;
  let totalHigh = totalobj && totalobj.High ? totalobj.High[year] : Number.MAX_SAFE_INTEGER;
  let returnArray = [];
  low=Number(low)
  base=Number(base)
  high=Number(high)
  totalLow=Number(totalLow)
  totalBase=Number(totalBase)
  totalHigh=Number(totalHigh)
  //compares each capacity_type for each year
  if ( low > base ) {
    returnArray.push(`For ${year} ${recipient} low value(${low}) can't be greater than base value(${base})`);
  }
  
  if ( low > high) {
    returnArray.push(`For ${year} ${recipient} low value(${low}) can't be greater than high value(${high})`);
  }
  
  if ( base > high ) {
    returnArray.push(`For ${year} ${recipient} base value(${base}) can't be greater than high value(${high})`);
  }
  
  if ( low > totalLow ) {
    returnArray.push(`For ${year} ${recipient} low value(${low}) can't be greater than Total low value(${totalLow})`);
  }
  
  if ( base > totalBase ) {
    returnArray.push(`For ${year} ${recipient} base value(${base}) can't be greater than Total base value(${totalBase})`);
  }
  
  if ( high > totalHigh ) {
    returnArray.push(`For ${year} ${recipient} high value(${high}) can't be greater than Total high value(${totalHigh})`);
  }
  return returnArray;
}

//sends each year, recipient, the full object, and the total recipient object to comparison function
const capacityValidation = (obj, recipient, totalobj, years) => {
  let submissionErrors = []

  years.forEach((year) => {
    let submissionErrs = compareCapacities(obj, year, recipient, totalobj);
    if ( submissionErrs ) {
      submissionErrs.forEach((item) => {
        submissionErrors.push(item);
      })
    }
  })
  return submissionErrors;
}

//finds each recipient and sends to validation function
const findCapacityErrors = (capacityObj) => { 
  let total = capacityObj['Total'];
  let capacityErrors = [];
  const recipientsArray = Object.keys(capacityObj);
  for(let i = 0; i < recipientsArray.length; i++) {
    let recipientObj = capacityObj[recipientsArray[i]]

    //send recipient and recipient obj to validation funtion
    let newCapacityErrors = capacityValidation(recipientObj, recipientsArray[i], total, columnYears)
    capacityErrors = [...capacityErrors, ...newCapacityErrors];
  }
  return capacityErrors;
}

const checkForCapacityErrors = (capacityDetails) => {
  if (!capacityDetails){
    return []
  }
  // check for CapacityUI errors
  var capacityErrorsArr = []
  //format obj for easy value comparision
  let capacityObj = formatCapacityOBJ(capacityDetails);
  //send obj to errors function
  capacityErrorsArr = findCapacityErrors(capacityObj);
  
  return capacityErrorsArr
}

export default checkForCapacityErrors;