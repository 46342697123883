import React, {useState, useEffect} from 'react';
import Select from 'react-select';

// Required: options, value, onChange

function SimpleSelect({options, value, onChange, isMulti=false, isClearable=false, className=null, disabled=false, clearAfterSelect=false}){


  const [localOptions, setLocalOptions] = useState([]);
  const [selection, setSelection] = useState(null);



    useEffect(() => {
        setLocalOptions(options?.map((o) => {return {value: o, label: o}}));
      },[options]
    )
    useEffect(() => {
      if (value === null){
        setSelection(null);
        return;
      }

      if (isMulti === true){
        setSelection(value.map((l) => {return {value:l, label:l}}));
      } else{
        setSelection({value:value, label:value})
      }
        
    },[value, isMulti]
    )

    function handleChange(e){
      if (e===null){
        setSelection(null)
        onChange(null)
      }else{
        setSelection(e)
        if (isMulti){
          onChange(e.map((r) => {return r.value}))
        }else{
          onChange(e.value)
        }
      }
      if (clearAfterSelect === true){
        setSelection(null)
      }
    }

    return <Select
      className = {className !== undefined? className:null}
      isMulti = {isMulti === true}
      options={localOptions}
      isClearable={isClearable !== false}
      value = {selection}
      onChange = {handleChange}
      isDisabled={disabled}
    />

}

export default SimpleSelect;