import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CONSTANTS from '../../constants';

import LBGanttLabel from './LBGanttLabel';

function LBGanttRow({ viewStart, zoomlevel, header, task, colnum, handleShowIntModal}) {

    const getWidthInColsForFirstYear = (viewStart, zmlevel) => {
        //subtract total number of months per year - starting month number, this gives us width of the second year
        if(zmlevel === 'YEAR'){
            let colSpan = (12 - (viewStart.MONTH+1)) +2;

            return colSpan;
        }

        if(zmlevel === 'QUARTERS') {
            let colSpan = 5-viewStart.QUARTER;
            return colSpan;
        }
        
    }

    const getTopLevelGuide = (viewStart, zoomlevel) => {
        let shownDate;
        let jsxArray = [];

        if(zoomlevel === 'YEARS') {
            //top column is years but 1 per col
            for(let i=0; i < 12; i++){
                
                jsxArray.push(<Col className="LBGantt-header-top">{ viewStart.YEAR + i }</Col>);
                shownDate++;
            }

            return jsxArray;
        }

        if(zoomlevel === 'YEAR') {
            //top columns are years
            let numOfYearsToShow = viewStart.MONTH > 1 ? 2 : 1;
            
            for(let i=0; i < numOfYearsToShow; i++){
                jsxArray.push(<Col sm={i===0 ? getWidthInColsForFirstYear(viewStart, zoomlevel) :''} className="LBGantt-header-top">{ viewStart.YEAR + i }</Col>);
            }

            return jsxArray;

        } 

        if(zoomlevel === 'QUARTERS') {
            let numOfYearsToShow = viewStart.QUARTER > 1 ? 4 : 3; //since we don't show the first year if quarter > 1, we need to technically show 4 year header marks

            let firstColWidth = getWidthInColsForFirstYear(viewStart, zoomlevel);
            let lastColWidth = viewStart.QUARTER > 1 ? 4-firstColWidth : 4; //the last col should be the "opposite number" of cols as the first
            
            for(let i=0; i < numOfYearsToShow; i++){
                jsxArray.push(<Col sm={i===0 ? firstColWidth : i===(numOfYearsToShow-1) ? lastColWidth : 4} className="LBGantt-header-top">{ viewStart.QUARTER > 1 && i=== 0? '': viewStart.YEAR + i }</Col>);
            }

            return jsxArray;
        }
        
        if(zoomlevel === 'MONTHS') {
            //top columns are months ()
            shownDate = viewStart.MONTH-1;
            for(let i=0; i < 12; i++){
                shownDate = shownDate > 11 ? 0: shownDate;
                
                jsxArray.push(<Col className="LBGantt-header-top">{ CONSTANTS.NUMBER_TO_MONTHNAMES[shownDate] + ' ' + viewStart.YEAR }</Col>);
                shownDate++;
            }

            return jsxArray;

        }

        return jsxArray;
    }

    const getSecondLevelGuide = (viewStart, zoomlevel) => {
        let shownDate = 0;
        let jsxArray = [];

        if(zoomlevel === 'YEAR') {
            //bottom columns are months in year (12)
            shownDate = viewStart.MONTH-1;
            
            for(let x=0; x < 12; x++){
                //if viewStart.month + x > 11, reset to 0
                shownDate = shownDate > 11 ? 0: shownDate;
                
                jsxArray.push(<Col className="LBGantt-header-second">{ CONSTANTS.NUMBER_TO_MONTHNAMES[shownDate] }</Col>)
                shownDate++;
            }

            return jsxArray;
        } 

        if(zoomlevel === 'QUARTERS') {
            shownDate = viewStart.QUARTER;

            for(let x=1; x <= 12; x++){
                //if viewStart.month + x > 11, reset to 0
                shownDate = shownDate > 4 ? 1: shownDate;
                jsxArray.push(<Col xs={1} className="LBGantt-header-second">Q{ shownDate }</Col>)
                shownDate++;
            }
        }
        
        if(zoomlevel === 'MONTHS') {
            //bottom columns are weeks in the month (4)
            shownDate = 0;
            for(let i=1; i < 53; i++){
                shownDate = shownDate > 3 ? 0: shownDate;
                shownDate++;
                jsxArray.push(<Col className="LBGantt-header-second">{ shownDate }</Col>);
                
            }

            return jsxArray;
        }

        return jsxArray;
    }

    // const getDiffOfYears = (date1, date2) => {
    //     //just get the literal diff between month numbers

    //     let mDate1 = moment(date1);
    //     let mDate2 = moment(date2);

    //     return mDate1.diff(mDate2, 'years');
    // }

    // const getDiffOfMonths = (date1, date2) => {
    //     //just get the literal diff between month numbers

    //     let mDate1 = moment(date1);
    //     let mDate2 = moment(date2);

    //     return mDate1.diff(mDate2, 'months');
    // }

    const getDiffOfQuarters = (date1, date2) => {
        //calculate quarter, year of start date and end date

        if(Object.prototype.toString.call(date1) !== '[object Date]') {
            //make it a date
            date1 = new Date(date1);
        }

        if(Object.prototype.toString.call(date2) !== '[object Date]') {
            //make it a date
            date2 = new Date(date2);
        }

        //first get diff of years
        let firstDate, secDate;
        //we have two dates to compare, if 1 is greater 2, switch em so we don't have to mess with abs
        if(date1 > date2) {
            firstDate = date2;
            secDate = date1;
        } else {
            firstDate = date1;
            secDate = date2;
        }

        let diffOfYears = (parseInt(secDate.getFullYear()) - parseInt(firstDate.getFullYear()))-1;

        let qtrDate1 = Math.ceil((firstDate.getMonth()+1)/3);
        let qtrDate2 = Math.ceil((secDate.getMonth()+1)/3);


        if(diffOfYears < 0){
            diffOfYears = 0;
            //only need the diff between quarters
            return qtrDate2 - qtrDate1 < 1 ? 1 : qtrDate2 - qtrDate1;
        } else {
            return (diffOfYears * 4) + ((4-qtrDate1) + qtrDate2);
        }
    }

    const addQuarters = (dateToAddTo, numQuarters) => {
        let dateYear = dateToAddTo.getFullYear();
        let qtrDate1 = Math.ceil((dateToAddTo.getMonth()+1)/3);

        let yearsToAdd = Math.floor(numQuarters / 4); // get full years to add
        let qtrNumToAddStr = (numQuarters / 4).toString(); //get the decimal of the division in string

        let qtrNumToAddArr = qtrNumToAddStr.split('.');
        let qtrNumToAdd;

        if(qtrNumToAddArr.length > 1) {
            qtrNumToAdd = Math.ceil(parseFloat(qtrNumToAddArr[1]) * 4);  //gives us the decimal in qtrs  -ceil in case left over - it goes to next qtr
        } else {
            qtrNumToAdd = parseInt(qtrNumToAddStr);
        }


        return `${qtrDate1+qtrNumToAdd}/1/${dateYear+yearsToAdd}`;


    }

    const isSameOrBefore = (compareDate, queryDate, zoomlevel=null) => {

        let compareMonth = parseInt(compareDate.getMonth()+1);
        let queryMonth = parseInt(queryDate.getMonth())+1;
        let compareYear = parseInt(compareDate.getFullYear());
        let queryYear = parseInt(queryDate.getFullYear());

        if(queryYear === compareYear){
            if(zoomlevel && zoomlevel === 'QUARTERS') {
                //get the quarter of the comparedate and querydate

                let zmdCompDateQtr = Math.ceil(compareMonth / 3);
                let zmdQryDateQtr = Math.ceil(queryMonth / 3);

                return zmdQryDateQtr <= zmdCompDateQtr;
            }

            return queryMonth <= compareMonth;
        } else {
            return queryYear < compareYear;
        } 
    }

    const getDateStr = (viewStart, zoomlevel, type='START') => {
        if(zoomlevel === 'QUARTERS') {
            if(type === 'START') {
                return `${viewStart.QUARTER * 3}/1/${parseInt(viewStart.YEAR)}`;
            } else {
                let funcViewQtrEnd = viewStart.QUARTER - 1 < 1 ? 4 : viewStart.QUARTER - 1;
                let funcViewYrEnd = viewStart.QUARTER - 1 < 1 ? viewStart.YEAR-1 : viewStart.YEAR;

                return addQuarters(new Date(`${funcViewQtrEnd * 3}/1/${parseInt(funcViewYrEnd)}`), 12);
            }
        } else {
            if(type === 'START') {
                return `${viewStart.MONTH}/1/${viewStart.YEAR}`;
            } else {
                if(zoomlevel === 'YEARS') {
                    return `${viewStart.MONTH}/28/${(parseInt(viewStart.YEAR)+11)}`
                }

                //default return
                return `${viewStart.MONTH}/1/${(parseInt(viewStart.YEAR)+1)}`;
            }
        }
    }

    const getDisplacementFromStart = (viewStartDt, task, zoomlevel) => {
        //business rules - if no start date, displace 0, else displace is equal to where start qtr / year is from viewStart
        let taskStartDate = task.start && task.start !== '' ? new Date(task.start) : null;

        if(taskStartDate > viewStartDt) {
            return getDiffOfQuarters(viewStartDt, taskStartDate);
        } else {
            //start date is before view start date so no offset
            return 0;
        }
    }

    const getLengthOfBar = (viewStartDt, task, zoomlevel, displace) => {

        let taskStartDate = task.start && task.start !== '' ? new Date(task.start) : null;
        let taskEndDate = task.end && task.end !== '' ? new Date(task.end) : null;

        let viewEnd = addQuarters(viewStartDt,12);
        let viewEndDt = new Date(viewEnd);


        if(taskEndDate === null || taskEndDate > viewEndDt) {
            //should fill up from the start to the end
            return 12-Math.abs(displace);
        } else {
            let diffOfQtrsFromStToEnd = getDiffOfQuarters(taskEndDate, taskStartDate);
            // let funcDiffToEnd = Math.abs(getDisplacementFromEnd(viewStartDt, task, zoomlevel));
            let funcLength = Math.abs(diffOfQtrsFromStToEnd);
            
            if(funcLength + displace > 12) {
                funcLength = 12 - displace;
            }
            
            return funcLength;
            
            
        }
    }

    const getPlacementForBar = (viewStart, task, zoomlevel) => {
        
        let viewStartStr = getDateStr(viewStart, zoomlevel, 'START');
        let viewStartDate = new Date(viewStartStr);

        let distanceFromViewStart = null;
        let lengthOfBar = null;

        //first, determine the distance from view start
        distanceFromViewStart = getDisplacementFromStart(viewStartDate, task, zoomlevel);

        lengthOfBar = getLengthOfBar(viewStartDate, task, zoomlevel, distanceFromViewStart);

        return (
            <Col 
                sm={{ span: lengthOfBar, offset: distanceFromViewStart }} 
                className={`LBGantt-value-bar color-${task.status} ${task.start === '' || task.end === '' ? 'no-def-dates': ''}`} >

                <LBGanttLabel taskDetails={task} curLength={lengthOfBar} handleShowIntModal={handleShowIntModal} />
            </Col>);
    }

    const isWithinView = (viewStart, task, zoomlevel) => {
        //dates in js are 0-based for month
        let viewStartStr;
        let viewEndStr;

        switch(zoomlevel) {
            case 'YEAR':
                viewStartStr = `${viewStart.MONTH}/01/${viewStart.YEAR}`;
                viewEndStr = `${viewStart.MONTH}/01/${(parseInt(viewStart.YEAR)+1)}`;;
                break;
            case 'QUARTERS':
                viewStartStr = getDateStr(viewStart, zoomlevel, 'START');
                viewEndStr = getDateStr(viewStart, zoomlevel, 'END');
                break;
            default: 
                viewStartStr = `${viewStart.MONTH}/01/${viewStart.YEAR}`;
                viewEndStr = `${viewStart.MONTH}/28/${(parseInt(viewStart.YEAR)+11)}`;
                //YEARS
                break;
        }

        let viewStartDate = new Date(viewStartStr);

        let viewEndDate = new Date(viewEndStr);

        let taskStartDate = task.start && task.start !== '' ? new Date(task.start) : null;
        let taskEndDate = task.end && task.end !== '' ? new Date(task.end) : null;

        if(taskStartDate === null) {
            //now should hug the left side and remain in the view all the way
            return true;
        } else {
            if(taskEndDate === null) {
                //should show at most 4 cols
                return  isSameOrBefore(viewEndDate, taskStartDate,  zoomlevel);
            } else {
                return !isSameOrBefore( viewStartDate, taskEndDate, zoomlevel) && isSameOrBefore(viewEndDate, taskStartDate,  zoomlevel);
            }
        }
        
    }

    const getBarForTask = (task, zoomlevel, currentView) => {
        //interior is 12 cols - which represents the entire period displayed
        //offsets represent the difference between the start of the period and the start of the task
        // and the size represnts the difference between start and end date / end of period

        //first, make sure that end date of task is within the view
        if(isWithinView(currentView, task, zoomlevel) === false) {
            return null;
        } else {
            return (getPlacementForBar(currentView, task, zoomlevel));
        }

       
    }



    return (
        header ?
            <Row className="LBGantt-header-row">
                <Row>
                {
                    getTopLevelGuide(viewStart, zoomlevel)
                }
                </Row>
                <Row>
                {
                    getSecondLevelGuide(viewStart, zoomlevel)
                }
                </Row>
                
            </Row>
        :
        <Col>
            <Row className={`LBGantt-col ${colnum ? 'col-'+ colnum : ''} gantt-col`} >
                { getBarForTask(task, zoomlevel, viewStart) }
            </Row>
        </Col>
        

    )
}

export default LBGanttRow