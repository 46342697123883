import React, { useState } from 'react';
import { Button, ButtonGroup, Overlay } from 'react-bootstrap';
import { ArrowCounterclockwise, GearFill } from 'react-bootstrap-icons';
import SettingsPanel from './SettingsPanel';

function AlchemyToolbar({ editor, userSettings, defaultUserSettings, handleUpdateUserSettings, columnRowContent,
  data, handleOptionsChange, metaData, handleColumnChange, saveSettings, clearFilters, settingsContent, 
  toolbarButtons, settingsButtons, renderSortOrder, handleWidthChange, sortOptions, handleSortChange, appClass=null }) {

  const [showSettingsPanel, setShowSettingsPanel] = useState(false);
  const [target, setTarget] = useState(null);

  // Close Settings Panel
  const handleCloseSettings = () => {
    setShowSettingsPanel(false);
  };

  // Toggle Settings Panel
  const toggleSettingsPanel = (event) => {
    setShowSettingsPanel(!showSettingsPanel);
    setTarget(event.target);
  };

  // Save Settings and Close Settings Panel
  const handleSaveSettingsAndClose = async () => {
    await saveSettings();
    setShowSettingsPanel(false);
  };

  return (
    <div className="alchemy-toolbar">
      <ButtonGroup>
        <Button className='btn-sm-invisible-dark m-i-end-02' onClick={toggleSettingsPanel}><GearFill /></Button>
        <Overlay target={target} show={showSettingsPanel} placement="bottom-end" rootClose onHide={handleCloseSettings}>
          {({
            placement,
            arrowProps,
            show: _show,
            popper,
            ...props
          }) => (
            <div
              {...props}
              style={{
                position: 'absolute',
                width: '550px',
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '5px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                zIndex: 1000,
                ...props.style,
              }} 
            >
              <SettingsPanel
                settingsContent={settingsContent}
                show={showSettingsPanel}
                userSettings={userSettings || defaultUserSettings}
                handleUpdateUserSettings={handleUpdateUserSettings}
                editor={editor}
                columnRowContent={columnRowContent}
                dynamicColumns={userSettings.dynamic_columns}
                dynamicWidths={userSettings.dynamic_widths}
                data={data}
                metaData={metaData}
                handleOptionsChange={handleOptionsChange}
                handleColumnChange={handleColumnChange}
                handleWidthChange={handleWidthChange}
                saveSettings={saveSettings}
                settingsButtons={settingsButtons}
                renderSortOrder={renderSortOrder}
                handleCloseSettings={handleCloseSettings}
                handleSaveSettings={handleSaveSettingsAndClose}
                sortOptions={sortOptions}
                handleSortChange={handleSortChange}
                appClass={appClass}
              />
            </div>
          )}
        </Overlay>
        {toolbarButtons ? toolbarButtons() : ''}
        <Button className='btn-primary-lg surface-brand-primary' onClick={clearFilters}>
          Clear Filters<ArrowCounterclockwise />
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default AlchemyToolbar;
