// useEffects are React18 ready
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'

import StatefulSearchTable from '../StatefulSearchTable';
import VaccineEditor from './VaccineEditor'
import {useToast} from '../../ToastProvider'
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';

const metaData=[
  {title:'Vaccine', dispField:'vaccine', filterField:'vaccine'},
  {title:'Vaccine Subtype', dispField:'vaccine_subtype', filterField:'vaccine_subtype'},
  {title:'Antigens', dispField:'antigen_str', filterField:'antigen_list', isMulti:true},
  {title:'Gavi Visible', dispFields:'gavi_visible', filterField:'gavi_visible'}
]

const sortData=[
  {title:"Vaccine Subtype", field:'vaccine_subtype'}
]

async function fetchData(setVaccines, apiKey, addToast) {
  setVaccines([])
  let result = await utils.getData("get_vaccines", apiKey)
  let temp = result.map(obj => ({
    ...obj,
    antigen_list: obj.antigens.map(a => { return a.antigen }),
    antigen_str: obj.antigens.map(a => { return a.antigen }).join(', ')
  }))
  if (typeof result === 'string'){
    addToast({
      title: 'Failed to load data. Error code 2-d',
      body: result
    })
  } else {
    if (temp !== null) {
      setVaccines(temp);
    }
  } 
}

function VaccineSearch(props) {
  // Local representation with data augmentations
  const [vaccines, setVaccines] = useState([])
  // Product that gets edited when double clicked
  const [vaccineId, setVaccineId] = useState(null)
  // A toggle for showing the edit product modal
  const [showEditVaccine, setShowEditVaccine] = useState(false)
  // Automatic trigger to refetch the data whenever the user is done editing the data.
  // This is automatically triggered by switching the t/f variable for showing the editing modal to false
  
  const {addToast} = useToast();
  const [getFromLocalStore, setGetFromLocalStore] = useState(false);

  
  
  useEffect(() => {
    if (showEditVaccine === false) {
      fetchData(setVaccines, props.apiKey, addToast)
    }
    return () => {};
  }, [showEditVaccine, props.apiKey, addToast]
  )


  function toggleShowEditVaccine() {
    setShowEditVaccine(!showEditVaccine)
  };

  function createNew() {
    toggleShowEditVaccine()
    setVaccineId(null)
    setGetFromLocalStore(false);
  }

  function update(vaccine) {
    toggleShowEditVaccine()
    setVaccineId(vaccine.vaccine_id)
    setGetFromLocalStore(false);
  }

  function openLocalStoreVaccine(){
    setShowEditVaccine(true);
    setVaccineId(null);
    setGetFromLocalStore(true);
  }

  // Passed into search table to add buttons to the left of the required buttons
  const toolbarButtons = () => {
    return (
      <>
        <Button className='btn-primary m-i-end-03 surface-primary' onClick={createNew}>Create New</Button>{' '}
      </>
    )
  }

  return (
    <div>
      {showEditVaccine ?
        <VaccineEditor 
          id={vaccineId}
          handleSubmit={toggleShowEditVaccine}
          closePopup={toggleShowEditVaccine}
          apiKey={props.apiKey}
          appClass={props.appClass}
          getFromLocalStore={getFromLocalStore}
          toastClick={openLocalStoreVaccine}
        />
        : null
      }
      <StatefulSearchTable
        data={vaccines}
        metaData={metaData}
        sortData={sortData}
        onRowClick={update}
        apiKey={props.apiKey}
        editor={props.editor}
        module='vaccine'
        rowsPerPage={20}
        toolbarButtons={toolbarButtons}
        appClass={props.appClass}
      />
    </div>
  )
}

export default VaccineSearch;