import { Row } from "react-bootstrap"

import AIContentItem from "./AIContentItem";
import '../../../styles/layout/_layout.scss';


function AIContent({ turn }) {
    return (
        <Row>
            {turn.messages.map((m) => {
                return <AIContentItem message={m} role={turn.role} />;
            })}
        </Row>
    );
}

export default AIContent