import React from 'react';
import NewsApp from '../../../components/News/NewsApp';
import { useLocation, useHistory } from 'react-router-dom';
import CONSTANTS from '../../../constants';
import NavUtils from '../../../NavUtils';


function NewsAppPage({ tenant }) {

    const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.NEWS);

    if(!hasAccess) {
        history.push('/');
    }

    //look to see if there's a ?tagged= on the url, if so, pass that value in as forcetags
    function useQuery() {
        const { search } = useLocation();
      
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let tagsToUse=null;
    tagsToUse= query.get("tagged") || null;

    return (
        <div className="full-news-app">
           <NewsApp tenantSetting={ tenant } preview={ false } forceTags={tagsToUse} />
        </div>
    )
}

export default NewsAppPage
