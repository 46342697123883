import React, { useEffect, useState } from 'react';
import VaccineMarketsDisplay from '../../components/VaccineMarkets/VaccineMarketsDisplay';
import { useParams, useHistory } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import CONSTANTS from '../../constants';
import utils from '../../utils';
import ListSkeletonLoader from '../../components/Layout/ListSkeletonLoader';
import NavUtils from '../../NavUtils';

function VaccineMarketsAppPage({ tenant, token }) {

  const history = useHistory();
  let hasAccesstoVM = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.VACCINE_MARKETS);
  let hasAccesstoVA = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.VACCINE_ALMANAC);

  if(!hasAccesstoVA && !hasAccesstoVM) {
    history.push('/');
  }

  const { diseaseSlug } = useParams();

  let [ markets, setMarkets ] = useState();
  let [ navDescr, setNavDescr ] = useState();
  let [ loading, setLoading ] = useState(true);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  let tenantSlug = tenant.tenant.fields.tenantslug;

  let markedDxSlugArray = diseaseSlug.split('::');
  let funcDx;
  let mktFor;

  if(markedDxSlugArray.length > 1) {
    //tenant has been tacked on
    mktFor = markedDxSlugArray[1];
  }

  funcDx = markedDxSlugArray[0];

  const request = {
      scopes: ["User.Read"]
  };

  const getMarketMatch = ( mktList, dxSlug, diffMkt ) => {
    let resMktArray;
    
    if(diffMkt && diffMkt.length > 0){
      resMktArray = mktList.filter( (thisMkt) => {
        return (thisMkt.fields.market_slug === dxSlug && thisMkt.fields.tenantView_TextField.toLowerCase() === diffMkt.toLowerCase())
      });
    
    } else {
     
      resMktArray = mktList.filter( (thisMkt) => {
        return (thisMkt.fields.market_slug === dxSlug)
      });

    }
    

    if(resMktArray.length > 0) {
      return resMktArray[0];
    }

    return [];
  }

  const getAccessToken = async (instance, account) => {
      try{
          let theAccToken =  await instance.acquireTokenSilent({...request, account: account});
          return theAccToken;
      } catch(error) {
          console.log(error);
      }
  }

  const getMarkets = async (token) => {
      setLoading(true);

      let extraParams = 'force_id=' + tenant.tenant.fields.aDGroupID;

      let jsonresponse = await utils.getTenantContent(token, CONSTANTS.APPS_BY_APPTYPE.VACCINE_MARKETS, tenantSlug, tenant.ourUserID, extraParams);

      if( jsonresponse.appFields){
          setNavDescr(jsonresponse.appFields);
      }

      setMarkets(jsonresponse.content);

      setLoading(false);
  }

  useEffect( () => {
      try{
          (async () => {
              let accToken = await getAccessToken(instance, accounts[0]);
              getMarkets(accToken);
          })()
      } catch(error) {
          console.log(error);
          setLoading(false);
      }
  // eslint-disable-next-line
  },[ tenantSlug, isAuthenticated, diseaseSlug ]);

  return (
    token ?
      !loading ?
        markets && markets.length > 0 ?
          <div className='full-market-analysis-app'>
            <VaccineMarketsDisplay 
              tenant={tenant} 
              tntFor={mktFor}
              content={getMarketMatch(markets, funcDx, mktFor)} 
              navs={navDescr} />
          </div>
        : <div> No Market Found that matches { diseaseSlug } </div>
      : <ListSkeletonLoader preview={false} />
    : null
  )
}

export default VaccineMarketsAppPage;
