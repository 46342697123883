import React, { useState, useEffect } from 'react';
import { Spinner, Row, Col, Card } from 'react-bootstrap';
import CountryProfilePanel from './CountryProfilePanel';
import { Switch, Route, useRouteMatch, generatePath } from 'react-router-dom';

import AppSideNav from '../Layout/Nav/AppSideNav';
import CONSTANTS from '../../constants';
import NavPageTitle from '../Layout/Nav/NavPageTItle';
import { Redirect } from 'react-router-dom';
import PDFExportButton from './PDFExportButton';
import CountryProfileFieldWrapper from './CountryProfileFieldWrapper';


function CountryHarness({ navDescr, tenant, selCountry, fullCountryList, contentFromRec }) {
    //this component provides routing level for country id and passes it to children

    const [selectedCountry, setSelectedCountry] = useState();
    const [loading, setLoading] = useState(false);

    let { path } = useRouteMatch();

    let useNavs = navDescr;

    const getDefaultPath = (forCountryISO, endUrl) => {
        return generatePath(`${path}/:isoCode/${endUrl}`, { isoCode: forCountryISO });
    }

    const getCountryFromListByISO = (iso, cntryList) => {
        return cntryList.find((thisCntry) => {
            return thisCntry.ISO === iso;
        });
    }

    const getCountryByISO = async (iso) => {
        setLoading(true);
        setSelectedCountry(getCountryFromListByISO(selCountry, fullCountryList));

        setLoading(false);
    }

    //get country info for the selCountry
    useEffect(() => {
        //get the country info
        getCountryByISO(selCountry);
        // eslint-disable-next-line
    }, [selCountry]);


    return (
        loading ?
            <Spinner animation="border" />
            :
            selectedCountry ?
                <>
                    <Row>
                        <Col xs={12} sm={7}>
                            <h2 className='h2'>{selectedCountry.country}</h2>
                        </Col>
                        <Col xs={12} sm={5} className='text-end'>
                            {
                                <PDFExportButton country={selectedCountry.country} />       
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={3}>
                            <Card className="side-nav weird-corners app-sidenav">

                                <AppSideNav
                                    apppath={`${CONSTANTS.APPS_BY_APPTYPE.IRC}`}
                                    tenantSettings={tenant}
                                    appname={CONSTANTS.APPS_BY_APPTYPE.IRC}
                                    item={contentFromRec}
                                    navs={useNavs}
                                    subItems={true}
                                    subUrlVal={selCountry} />
                            </Card>
                        </Col>
                        <Col sm={12} md={9}>
                            <Switch>
                                <Route exact path={path}>
                                    {/* if no trailing url, show the country-profile panel */}
                                    <Redirect
                                        to={getDefaultPath(selCountry, 'country-profile')} />
                                </Route>
                                <Route exact path={`${path}/country-profile`}>
                                    <NavPageTitle
                                        navArray={useNavs}
                                        infoID={''}
                                        overrideTitle="Country Profile" />
                                    <CountryProfilePanel
                                        ISO={selCountry.ISO}
                                        countryInfo={selectedCountry}
                                        contentMaster={contentFromRec} />
                                </Route>
                                <Route path={`${path}/:infoID`}>
                                    {/* else, show the fieldDisplay panel */}
                                    <CountryProfileFieldWrapper
                                        tenant={tenant}
                                        showGenPageTitle={true}
                                        cntryInfo={selectedCountry}
                                        fields={contentFromRec}
                                        fieldsDescr={navDescr} />
                                    
                                </Route>
                            </Switch>

                        </Col>
                    </Row>
                </>

                : null
    )
}

export default CountryHarness