import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function PopupModal({ num, idx, specialParams, showModal=true, title=null, body=null, bodyDiv=null, buttonNo=null, buttonYes=null, hideBtnNo=false, hideBtnYes=false, navRoute=null, navPage=null, submitHandler}) {
  const [show, setShow] = useState(showModal);

  
  let count = idx +1

  const handleClose = () => setShow(false);

  const buttonHandler = (data) => {
    setShow(false);
    submitHandler(data)
  }

  return (
    <>
      <Modal className='toast-alert' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{`${title} (${count} of ${num})`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='body-1'>{`${specialParams.displayName} ${body}`}</div>
          <div className='body-2'>
            {bodyDiv}       
            <Link as={Link} to={navRoute}  onClick={() => document.body.click(setShow(false))}>{navPage}</Link>.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className={hideBtnNo = false ? 'hide-btn no-btn' : 'show-btn no-btn'} variant="secondary" onClick={handleClose}>
            {buttonNo}
          </Button>
          <Button className={hideBtnYes = false ? 'hide-btn yes-btn' : 'show-btn yes-btn'} variant="primary" onClick={() => {buttonHandler(specialParams)}}>
            {buttonYes}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PopupModal