import React from 'react';
import { PyramidEmbedContainer } from '@pyramid-embed/embed-react';

function PyramidFieldVisual({ initHeight, initWidth, embedOptions }) {

    // let [,setRefresh] = useState();
    // let [ height, setHeight ] = useState(initHeight);
    // let [ width, setWidth ] = useState(initWidth);

    // useEffect(() => {
    //     setRefresh({});
    //     setHeight(height);
    //     setWidth(width);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [height, width]);

    return (
        <PyramidEmbedContainer
            host={process.env.REACT_APP_PYRAMID_DEV_URL}
            options={embedOptions}
            style={{
                width: initWidth,
                height: initHeight
            }} />
    )
}

export default PyramidFieldVisual